import iconBackArrow from "../../../Icons/icons-white/back-arrow.png";
import iconWhiteViews from "../../../Icons/icons-white/views.png";
import iconWhiteLikes from "../../../Icons/icons-white/like.png";
import iconWhiteShare from "../../../Icons/icons-white/share.png";
import iconWhiteListed from "../../../Icons/icons-white/listed.png";
import iconBack from "../../../Icons/icons-charcoal-dark/back.png";
import iconAhead from "../../../Icons/icons-charcoal-dark/ahead.png";
import iconCharcoalDarklike from "../../../Icons/icons-charcoal-dark/like.png";
import iconCharcoalDarkliked from "../../../Icons/icons-charcoal-dark/liked.png";
import iconCharcoalDarkSave from "../../../Icons/icons-charcoal-dark/save.png";
import iconCharcoalDarkSaved from "../../../Icons/icons-charcoal-dark/saved.png";
import iconCharcoalDarkShare from "../../../Icons/icons-charcoal-dark/share.png";
import iconCharcoalDarkCall from "../../../Icons/icons-charcoal-dark/call.png";
import iconCharcoalDarkWhatsApp from "../../../Icons/icons-charcoal-dark/whatsapp.png";
import iconCharcoalDarkMenuUp from "../../../Icons/icons-charcoal-dark/menu-up.png";
import iconStar from "../../../Icons/icons-yellow/star.png";
import iconGrayLocation from "../../../Icons/icons-gray/location.png";

import GENRALHELPERS from "../GENRAL-HELPERS";
import handleAccount from "../register/handle-account";
import firebase from "../firebase";

class GenerateHtml {
  _generateBusinessRewiewStars(number, numberOfRewviews) {
    let starHtml = ``;
    const stars = Math.round(number);
    if (stars === 0) {
      starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
    } else {
      for (let i = 0; i < stars; i++) {
        starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
      }
    }

    return `<div class="RatingStarCon1">
        <div class="RatingStarCon2">
          <div class="RatingStarRatedAtNumber">
            ${number}
          </div>
          <div class="RatingStarCon3">
            ${starHtml}
          </div>
        </div>
      </div>`;
  }
  _initialCon(id) {
    return `

  <div id="${id}ForInstantSharing" class="DisplayListingEachCon1">
    <div class="DisplayListingEachCon2">
      <div class="DisplayListingEachCon3 CloseCurrentStackPageButton">
        <img height="24px" src="${iconBackArrow}" alt="">
        <div class="Regresar">
          regresar
        </div>
      </div>
    </div>
    <div class="DisplayListingEachCon4">
      <div id="DisplayListingEachTopOtherListingsCon" class="DisplayListingEachTopOtherListingsCon"></div>
      <div id="${id}" class="DisplayListingEachCon5"></div>
      <div id="DisplayListingEachBottomOtherListingsCon" class="DisplayListingEachBottomOtherListingsCon"></div>
    </div>
  </div>
`;
  }

  _imageDisplayer(imgArr) {
    return `
  <div class="ListingDisplayImagesDisplayCon1">
    <div class="ListingDisplayImagesDisplayCon2">
      <div class="ListingDisplayImagesDisplayCon3">
         ${imgArr
           .map(
             (img, index) => `
                <div class="ListingDisplayImagesDisplayImageForEachImg"><img 
              class="ListingDisplayImagesDisplayImage ImgForBigDisplay ${
                index === 0 ? "ListingDisplayImagesDisplayImageActive" : ""
              }" 
              src="${img}" 
              data-index="${index}" loading="lazy"
            ></div>
          `
           )
           .join("")}
      </div>
    </div>
    <div class="ListingDisplayImagesDisplayCon4">
         <button class="ListingDisplayImagesDisplayButtonImgHide ListingDisplayImagesDisplayButtonImgBack">
            <img height="18px" src="${iconBack}" alt="" />
        </button>
    ${imgArr
      .map(
        (_, index) => `
        <div 
          class="ListingDisplayImagesDisplayDotIndicator ${
            index === 0 ? "ListingDisplayImagesDisplayDotIndicatorActive" : ""
          }" 
          data-index="${index}">
        </div>
      `
      )
      .join("")}
            <button class="ListingDisplayImagesDisplayButtonImgHide ListingDisplayImagesDisplayButtonImgAhead">
            <img height="18px" src="${iconAhead}" alt="" />
            </button>
    </div>
  </div>
    `;
  }

  _allOtherInfo(data, business, businessImgUrls, openFromBusiness) {
    let saveButton = `
      <div id="DisplayListingEachButtonActionSave"  class="DisplayListingEachButtonActio">
        <img
          class="DisplayListingEachButtonActioIcons"
          src="${iconCharcoalDarkSave}"
          alt=""
        />
      </div>`;
    const checkIfSaved = (path) => {
      const account = firebase._getLatestCurrentUserData();

      let saved = false;

      if (account) {
        if (account.activeSaves) {
          if (account.activeSaves.includes(path)) {
            saved = true;
          }
          // forEach((curObj, i, arr) => {
          //   if (curObj.path === path) {
          //     console.log("found");
          //     saved = true;
          //   } else {

          //     console.log("not saved");
          //   }
          // });
        }
      }

      if (saved) {
        saveButton = `
        <div id="DisplayListingEachButtonActionSave" class="DisplayListingEachButtonActio">
        <img
          class="DisplayListingEachButtonActioIcons"
          src="${iconCharcoalDarkSaved}"
          alt=""
        />
      </div>`;
      }
    };
    const checkIfLiked = (path) => {
      console.log("check if liked called");
      const account = firebase._getLatestCurrentUserData();

      let liked = false;
      let likeIcoinImg = `<img class="DisplayListingEachButtonActioIcons" src="${iconCharcoalDarklike}" alt="">`;
      if (account) {
        console.log("acount availeble");
        console.log(account);
        console.log(path);
        if (account.likes && account.likes.includes(path)) {
          liked = true;
          // console.log("acount likes availeble");
          // account.likes.forEach((curObj, i, arr) => {
          //   if (curObj.path === path) {
          //     console.log("found");
          //     liked = true;
          //   } else {
          //     //   console.log("not liked");
          //     //   likeIcoinImg = `
          //     // <img class="DisplayListingEachButtonActioIcons" src="${iconCharcoalDarklike}" alt="">`;
          //   }
          // });
        } else {
          likeIcoinImg = `
              <img class="DisplayListingEachButtonActioIcons" src="${iconCharcoalDarklike}" alt="">`;
        }
      }
      console.log("isLiked", liked);
      if (liked) {
        likeIcoinImg = `
              <img class="DisplayListingEachButtonActioIcons" src="${iconCharcoalDarkliked}" alt="">`;
      }
      console.log(likeIcoinImg);
      return likeIcoinImg;
    };

    let listingPriceBefore = "null";
    let shareWhat = "listing";
    let whatsAppNumber = "";
    let callNumber = "";
    let businessId = null;
    let businessName = null;
    let sharesAndLikes = ``;
    let likeButton = `
      <div class="">
      </div>
    `;
    let businessHtml = ``;
    let locationHtml = "";

    if (business) {
      whatsAppNumber = business.contacts.whatsApp.contact;
      callNumber = business.contacts.call.contact;
      shareWhat = "linstingInBusiness";
      businessId = business.businessId;
      businessName = business.name;
      let checkPath = `businesses/${business.businessId}/listings/${data.listingId}`;
      checkIfSaved(checkPath);

      locationHtml = `
          <div class="DisplayListingEachCon13C">
            <img style="height: 20px" src="${iconGrayLocation}" alt="" />
            <a class="DisplayListingEachCon13CText" href="${business.place.locationUrl}">${business.place.name}</a>
          </div>
          `;

      sharesAndLikes = `
        <div class="DisplayListingEachStatsCon">
          <div class="DisplayListingEachStatsConText">${GENRALHELPERS._formatNumberShort(
            data.listingLikes
          )}</div>
          <img
            class="DisplayListingEachButtonActioIconsStats"
            src="${iconWhiteLikes}"
            alt=""
          />
        </div>
        <div class="DisplayListingEachStatsCon">
          <div class="DisplayListingEachStatsConText">${GENRALHELPERS._formatNumberShort(
            data.listingShares
          )}</div>
          <img
            class="DisplayListingEachButtonActioIconsStats"
            src="${iconWhiteShare}"
            alt=""
          />
        </div>
        `;

      likeButton = `
      <div id="DisplayListingEachButtonActionLike" class="DisplayListingEachButtonActio">
        ${checkIfLiked(checkPath)}
      </div>
        `;

      if (!openFromBusiness) {
        businessHtml = `
          <div class="DisplayListingEachCon24">
            <div class="DisplayListingEachCon24Text">
              Visita este negocio
            </div>
            <div data-businessid="${business.id}"
            class="DisplayListingEachCon25">
              <img
                id=""
                class="BusinessInSearchCon1BackgroundImg"
                src="${businessImgUrls[0]}"
                alt=""
              />
              <div class="BusinessInSearchCon3">
                <div class="BusinessInSearchCon4">
                  <div class="BusinessInSearchCon5">
                    <div class="BusinessInSearchCon6">
                      <div
                        style="color: #${business.colors.headerPrimary};"
                        class="BusinessInSearchBusinessName"
                      >
                        ${business.name}
                      </div>
                      <div
                        style="color: #${business.colors.headerSecondary};"
                        class="BusinessInSearchBusinessShortDescription"
                      >
                        ${business.shortDescription}
                      </div>
                    </div>
                    <div class="BusinessInSearchCon7">
                      ${this._generateBusinessRewiewStars(
                        business.ratedAt,
                        business.numberOfReviews
                      )}
                    </div>
                  </div>
                  <img
                    id="BusinessInSearchCon8ProfileImg"
                    class="BusinessInSearchCon8"
                    src="${businessImgUrls[1]}"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
                  `;
      }
    } else {
      whatsAppNumber = data.listingWhatsAppNumber;
      callNumber = data.listingCallNumber;
      checkIfSaved(`listings/${data.listingId}`);
      if (data.place) {
        locationHtml = `
        <div class="DisplayListingEachCon13C">
          <img style="height: 20px" src="${iconGrayLocation}" alt="" />
          <a class="DisplayListingEachCon13CText" href="${data.place.locationUrl}">${data.place.name}</a>
        </div>
        `;
      }
    }

    let priceToUse = GENRALHELPERS._convertToPrice(
      data.listingPrice,
      data.listingCoin
    );

    let priceBeforeHtml = ``;
    let onDiscountTagHtml = ``;
    if (data.onDiscount) {
      if (data.onDiscount.amount > 0) {
        listingPriceBefore = data.onDiscount.finalPrice;
        console.warn(data.onDiscount);

        priceToUse = GENRALHELPERS._convertToPrice(
          data.onDiscount.finalPrice,
          data.listingCoin
        );

        priceBeforeHtml = `<div class="DisplayListingEachCon17Text">Antes</div>
      <div class="DisplayListingEachItemBeforePrice">${GENRALHELPERS._convertToPrice(
        data.listingPrice,
        data.listingCoin
      )}</div>`;

        onDiscountTagHtml = `
        <div class="DisplayListingEachCon16">
          <div class="DisplayListingEachCon16Text">En oferta ahora ${data.onDiscount.percent}% ↓</div>
        </div>`;
      }
    }

    return `
  <div
  data-share="${shareWhat}"
  data-businessid="${businessId}"
  data-businessname="${businessName}"
  data-listingid="${data.listingId}"
  data-listingname="${data.listingName}"
  data-listingprice="${data.listingPrice}"
  data-listingpricebefore="${listingPriceBefore}"
  data-listingcoin="${data.listingCoin}"
  class="DisplayListingEachCon6 ActionForShare"
>
  <div class="DisplayListingEachCon7">
    ${likeButton}
    <div class="DisplayListingEachCon8">
       ${saveButton}
      <a href="tel:+52${callNumber}" class="DisplayListingEachButtonActio">
        <img
          class="DisplayListingEachButtonActioIcons"
          src="${iconCharcoalDarkCall}"
          alt=""
        />
      </a>
      <div 
      data-whatsappnumber="${whatsAppNumber}"
      data-callnumber="${callNumber}"
      id="DisplayListingEachButtonActionWhatsApp" class="DisplayListingEachButtonActio">
        <img
          class="DisplayListingEachButtonActioIcons"
          src="${iconCharcoalDarkWhatsApp}"
          alt=""
        />
      </div>
      <div
        id="DisplayListingEachButtonActionShare"
        class="DisplayListingEachButtonActio"
      >
        <img
          class="DisplayListingEachButtonActioIcons"
          src="${iconCharcoalDarkShare}"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="DisplayListingEachCon9">
    <div class="DisplayListingEachCon10">
      <div class="DisplayListingEachStatsCon">
        <div class="DisplayListingEachStatsConText">${GENRALHELPERS._formatNumberShort(
          data.listingViews
        )}</div>
        <img
          class="DisplayListingEachButtonActioIconsStats"
          src="${iconWhiteViews}"
          alt=""
        />
      </div>
      ${sharesAndLikes}
    </div>
    <div class="DisplayListingEachStatsCon">
      <div class="DisplayListingEachStatsConText">
        ${GENRALHELPERS._convertServerTimestampToReadableTime(data.createdAt)}
      </div>
      <img
        class="DisplayListingEachButtonActioIconsStats"
        src="${iconWhiteListed}"
        alt=""
      />
    </div>
  </div>
</div>
<div class="DisplayListingEachCon11">
  <div class="DisplayListingEachItemTitle">${data.listingName}</div>
</div>
<div class="DisplayListingEachCon12">
  <div class="DisplayListingEachCon13">
    <div class="DisplayListingEachCon13B">
        <div class="DisplayListingEachCon14">
        <div class="DisplayListingEachCon15">
            ${onDiscountTagHtml}
            <div class="DisplayListingEachItemPrice">
            ${priceToUse}
            </div>
        </div>
        <div class="DisplayListingEachItemCondition">${
          data.listingCondition
        }</div>
        </div>
        <div class="DisplayListingEachCon17">
            ${priceBeforeHtml}
        </div>
    </div>
    ${locationHtml}
  </div>
  <div class="DisplayListingEachCon18">
    <div data-active="active" id="DisplayListingEachtechnicalInfo" class="DisplayListingEachCon19">
      <div class="DisplayListingEachCon19Text">Información técnica</div>
      <img
        style="height: 12px"
        class="DisplayListingEachButtonActioIcons"
        src="${iconCharcoalDarkMenuUp}"
        alt=""
      />
    </div>
    <div class="DisplayListingEachCon20">
      <div class="DisplayListingEachCon21">
        <div class="DisplayListingEachCon21Text1">Máquina</div>
        <div class="DisplayListingEachCon21Text2">
          ${data.listingMachineType}
        </div>
      </div>
      <div class="DisplayListingEachCon21">
        <div class="DisplayListingEachCon21Text1">Marca</div>
        <div class="DisplayListingEachCon21Text2">${data.listingBrand}</div>
      </div>
      <div class="DisplayListingEachCon21">
        <div class="DisplayListingEachCon21Text1">Modelo</div>
        <div class="DisplayListingEachCon21Text2">${data.listingModel}</div>
      </div>
      <div class="DisplayListingEachCon21">
        <div class="DisplayListingEachCon21Text1">Año</div>
        <div class="DisplayListingEachCon21Text2">${data.listingYear}</div>
      </div>
      <div class="DisplayListingEachCon21">
        <div class="DisplayListingEachCon21Text1">Condición</div>
        <div class="DisplayListingEachCon21Text2">${data.listingCondition}</div>
      </div>
    </div>
  </div>
  <div class="DisplayListingEachCon22">
    <div class="DisplayListingEachCon22Text">Descripción</div>
    <div class="DisplayListingEachCon23">
      <div class="DisplayListingEachItemDescription">
        ${data.listingDescription}
      </div>
    </div>
  </div>
  ${businessHtml}
</div>
`;
  }
}

export default new GenerateHtml();
