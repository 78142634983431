import firebase from "../firebase.js";
import spinner from "../spinner";
import displayListingEach from "../dispay-listings-each/display";
import displayBusinessEach from "../display-business-each/display";

class openUrls {
  constructor() {
    this._parseLocateAndDisplay();
  }
  _init() {
    console.log("init");
  }

  // Start visitStats
  async _storeUserVisitWithData(creditTo) {
    // Helper functions to collect the data
    function getDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|ipad|ipod/.test(userAgent)) {
        return "Phone";
      } else if (/tablet|ipad/.test(userAgent)) {
        return "Tablet";
      }
      return "Desktop";
    }

    function getBrowser() {
      const userAgent = navigator.userAgent;
      if (/chrome|crios|crmo/i.test(userAgent)) {
        return "Chrome";
      } else if (/firefox|fxios/i.test(userAgent)) {
        return "Firefox";
      } else if (/safari/i.test(userAgent)) {
        return "Safari";
      } else if (/edge/i.test(userAgent)) {
        return "Edge";
      } else if (/opr|opera/i.test(userAgent)) {
        return "Opera";
      } else {
        return "Unknown";
      }
    }

    function getOperatingSystem() {
      const userAgent = navigator.userAgent;
      if (/windows/i.test(userAgent)) {
        return "Windows";
      } else if (/mac/i.test(userAgent)) {
        return "MacOS";
      } else if (/linux/i.test(userAgent)) {
        return "Linux";
      } else if (/android/i.test(userAgent)) {
        return "Android";
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        return "iOS";
      } else {
        return "Unknown";
      }
    }

    function getScreenResolution() {
      return `${window.screen.width}x${window.screen.height}`;
    }

    function getLanguage() {
      return navigator.language || navigator.userLanguage || "null";
    }

    function getTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || "null";
    }

    function isTouchDevice() {
      return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    }
    let creditToObj = {
      creditTo: null,
    };

    if (creditTo) {
      creditToObj.creditTo = creditTo;
    }

    // Construct the user data object
    const userData = {
      device: getDeviceType() || "null", // Added deviceType for Phone, Tablet, Desktop, etc.
      browser: getBrowser() || "null",
      os: getOperatingSystem() || "null",
      screenResolution: getScreenResolution() || "null",
      language: getLanguage(),
      timeZone: getTimeZone(),
      isTouchDevice: isTouchDevice() || "null",
      userAgent: navigator.userAgent || "null",
      ip: "not tracked", // IP will be added later if needed
      ...creditToObj,
    };
    const result = await firebase._writeVisitStats("siteVisits", userData);
    console.error(result);

    return userData;
  }
  // End visitStats

  _updateVisiteStats(creditTo) {
    this._storeUserVisitWithData(creditTo);
  }

  // https://mercadopromaq.netlify.app?creditTo=heramientashopelchen

  async _parseLocateAndDisplay() {
    spinner._displaySpinner("Estamos cargando la información solicitada");
    let businessId;
    let listingId;

    // Function to parse URL and get business and listing IDs
    const parseUrlAndDisplay = () => {
      const url = window.location.href;

      // Check for creditTo
      if (url.includes("creditTo")) {
        const creditToMatch = url.match(/creditTo=([^&?]+)/);
        if (creditToMatch) {
          console.log(`This visit is credited to: ${creditToMatch[1]}`);
          this._updateVisiteStats(creditToMatch[1]);
        } else {
          this._updateVisiteStats();
        }
      } else {
        this._updateVisiteStats();
      }

      // Extract 'negocio' id
      if (url.includes("negocio")) {
        const negocioMatch = url.match(/negocio=([^&?]+)/);
        if (negocioMatch) {
          businessId = negocioMatch[1]; // Set the businessId
        }
      }

      // Extract 'listado' id
      if (url.includes("listado")) {
        const listadoMatch = url.match(/listado=([^&?]+)/);
        if (listadoMatch) {
          listingId = listadoMatch[1]; // Set the listingId
        }
      }

      // Handle case where neither 'negocio' nor 'listado' are present
      if (!businessId && !listingId) {
        // console.log("No 'negocio' or 'listado' found in the URL.");
      }
    };

    // Run the function to parse the current URL
    parseUrlAndDisplay();

    // Log the IDs after parsing the URL

    let businessData;

    // Ensure businessId and listingId are set before trying to fetch data
    if (businessId) {
      businessData = await firebase._getBusinessById(businessId);

      displayBusinessEach._init(businessData);
    }

    if (listingId && businessId) {
      console.log("business and listing present");
      const listingData = await firebase._getAnyDocById(
        `businesses/${businessId}/listings/${listingId}`
      );
      console.log(listingData);
      displayListingEach._init(listingData, businessData, true);
    }
    if (listingId && !businessId) {
      console.log("no business but listing present");
      const listingData = await firebase._getAnyDocById(
        `listings/${listingId}`
      );
      console.log(listingData);
      displayListingEach._init(listingData);
    }
    spinner._hideSpinner();
  }

  _getUserOpenedUrl() {
    const currentUrl = window.location.href; // Get the full URL
    return currentUrl;
  }
}

export default new openUrls();
