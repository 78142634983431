import inAppNotification from "./in-app-notification";

class installApp {
  #promtLoaded = false;
  #needsInstalation = false;
  #deferredPrompt;
  #eventsInitialized = false;

  _setPromtLoaded() {
    this.#promtLoaded = true;
  }

  _getNeedsInstalation() {
    return this.#needsInstalation;
  }

  _installNow() {
    this.#deferredPrompt.prompt();
    this.#deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
        setTimeout(() => {
          inAppNotification._showNotification(
            "¿Ya estás convencido? ¡Puede ser más fácil!",
            "Si estás disfrutando esta aplicación, instálalo ahora y lo tendrás a un solo click.",
            "Si quiero instalar",
            "installmodule",
            12000
          );
        }, 420000);
      }
      this.#deferredPrompt = null;
      // Hide the button after installation attempt
      PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
    });
  }

  _showInstallModule() {
    const PreInstallCon = document.getElementById("PreInstallCon");
    PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
  }

  // Function to wait until this.#promtLoaded is true
  async #waitForPromptLoaded() {
    while (!this.#promtLoaded) {
      // Sleep for a short time before checking again
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  _events() {
    if (this.#eventsInitialized) return;
    const PrePageConForInstallationConClose = document.getElementById(
      "PrePageConForInstallationConClose"
    );
    const installButton = document.getElementById("installBtn");
    const PreInstallCon = document.getElementById("PreInstallCon");
    PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
    this.#needsInstalation = true;

    // Trigger the install prompt when the button is clicked
    installButton.addEventListener("click", () => {
      this._installNow();
    });
    PrePageConForInstallationConClose.addEventListener("click", () => {
      //   this.#deferredPrompt = null;
      PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
      setTimeout(() => {
        inAppNotification._showNotification(
          "¿Quieres Instalar Mercado MAI?",
          "Si estás disfrutando esta aplicación, instálalo ahora y lo tendrás a un solo click.",
          "Instalar ahora",
          "installnow",
          12000
        );
      }, 300000);
    });
    this.#eventsInitialized = true;
  }

  async _initiateInstallApp() {
    // Start Installation

    // Listen for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", async (e) => {
      // Prevent the default installation prompt
      e.preventDefault();
      this.#deferredPrompt = null;
      // Storing the event to trigger it later
      this.#deferredPrompt = e;
      // Show the install button
      await this.#waitForPromptLoaded();
      this._events();
    });

    // Listen for the appinstalled event to track the installation
    window.addEventListener("appinstalled", (e) => {
      console.log("PWA was installed");

      // TODO get thjis working 'updateInstallsCount();'
      this.#needsInstalation = false;

      // Optionally, you can hide the button permanently once the app is installed
      PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
    });
  }
}
export default new installApp();

// TODO 2

// import inAppNotification from "./in-app-notification";

// class installApp {
//   #promtLoaded = false;
//   #needsInstalation = false;
//   #deferredPrompt;
//   #eventsInitialized = false;

//   _setPromtLoaded() {
//     this.#promtLoaded = true;
//   }

//   _getNeedsInstalation() {
//     return this.#needsInstalation;
//   }

//   _installNow() {
//     this.#deferredPrompt.prompt();
//     this.#deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === "accepted") {
//         console.log("User accepted the A2HS prompt");
//       } else {
//         console.log("User dismissed the A2HS prompt");
//         setTimeout(() => {
//           inAppNotification._showNotification(
//             "¿Ya estás convencido? ¡Puede ser más fácil!",
//             "Si estás disfrutando esta aplicación, instálalo ahora y lo tendrás a un solo click.",
//             "Si quiero instalar",
//             "installmodule",
//             12000
//           );
//         }, 420000);
//       }
//       this.#deferredPrompt = null;
//       // Hide the button after installation attempt
//       PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//     });
//   }

//   _showInstallModule() {
//     const PreInstallCon = document.getElementById("PreInstallCon");
//     PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
//   }

//   // Function to wait until this.#promtLoaded is true
//   async #waitForPromptLoaded() {
//     while (!this.#promtLoaded) {
//       // Sleep for a short time before checking again
//       await new Promise((resolve) => setTimeout(resolve, 100));
//     }
//   }

//   _events() {
//     if (this.#eventsInitialized) return;
//     const PrePageConForInstallationConClose = document.getElementById(
//       "PrePageConForInstallationConClose"
//     );
//     const installButton = document.getElementById("installBtn");
//     const PreInstallCon = document.getElementById("PreInstallCon");
//     console.log("need to install");
//     PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
//     this.#needsInstalation = true;

//     // Trigger the install prompt when the button is clicked
//     installButton.addEventListener("click", () => {
//       this._installNow();
//     });

//     PrePageConForInstallationConClose.addEventListener("click", () => {
//       PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//       setTimeout(() => {
//         inAppNotification._showNotification(
//           "¿Quieres Instalar Mercado MAI?",
//           "Si estás disfrutando esta aplicación, instálalo ahora y lo tendrás a un solo click.",
//           "Instalar ahora",
//           "installnow",
//           12000
//         );
//       }, 300000);
//     });
//     this.#eventsInitialized = true;
//   }

//   async _initiateInstallApp() {
//     console.warn("Initiate Install app now");

//     // Start Installation
//     // Detect if the user is on iOS
//     const isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

//     // If the user is on iOS, show the custom install message
//     if (isIos()) {
//       this._showIosInstallPrompt();
//     } else {
//       // For Android (or other devices that support PWA install prompts)
//       window.addEventListener("beforeinstallprompt", async (e) => {
//         // Prevent the default installation prompt
//         e.preventDefault();
//         this.#deferredPrompt = e;
//         // Show the install button after the prompt is ready
//         await this.#waitForPromptLoaded();
//         this._events();
//       });
//     }

//     // Listen for the appinstalled event to track the installation
//     window.addEventListener("appinstalled", (e) => {
//       console.log("PWA was installed");

//       // TODO get thjis working 'updateInstallsCount();'
//       this.#needsInstalation = false;

//       // Optionally, you can hide the button permanently once the app is installed
//       PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//     });
//   }

//   // Custom method for iOS users to manually add to home screen
//   _showIosInstallPrompt() {
//     inAppNotification._showNotification(
//       "¿Quieres Instalar Mercado MAI?",
//       "Para agregar esta aplicación a tu pantalla de inicio, abre el menú de compartir en Safari y selecciona 'Agregar a la pantalla de inicio'.",
//       "Agregar a la pantalla de inicio",
//       "addtohomescreen",
//       12000
//     );
//   }
// }

// export default new installApp();

// TODO  3 have not tested this option yet

// import inAppNotification from "./in-app-notification";

// class installApp {
//   #promtLoaded = false;
//   #needsInstalation = false;
//   #deferredPrompt;
//   #eventsInitialized = false;

//   _setPromtLoaded() {
//     this.#promtLoaded = true;
//   }

//   _getNeedsInstalation() {
//     return this.#needsInstalation;
//   }

//   _installNow() {
//     if (this.#deferredPrompt) {
//       this.#deferredPrompt.prompt();
//       this.#deferredPrompt.userChoice.then((choiceResult) => {
//         if (choiceResult.outcome === "accepted") {
//           console.log("User accepted the A2HS prompt");
//         } else {
//           console.log("User dismissed the A2HS prompt");
//         }
//         this.#deferredPrompt = null;
//         PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//       });
//     }
//   }

//   _showInstallModule() {
//     const PreInstallCon = document.getElementById("PreInstallCon");
//     PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
//   }

//   // Function to check if the app is already installed
//   isPWAInstalled() {
//     const isStandalone = window.matchMedia(
//       "(display-mode: standalone)"
//     ).matches;
//     const isIosStandalone = window.navigator.standalone === true;
//     return isStandalone || isIosStandalone;
//   }

//   // Function to wait until this.#promtLoaded is true
//   async #waitForPromptLoaded() {
//     while (!this.#promtLoaded) {
//       await new Promise((resolve) => setTimeout(resolve, 100));
//     }
//   }

//   _events() {
//     if (this.#eventsInitialized) return;
//     const PrePageConForInstallationConClose = document.getElementById(
//       "PrePageConForInstallationConClose"
//     );
//     const installButton = document.getElementById("installBtn");
//     const PreInstallCon = document.getElementById("PreInstallCon");

//     // Only show the install prompt if the app is not installed
//     if (!this.isPWAInstalled()) {
//       PreInstallCon.classList.add("PrePageConForInstallationConDisplay");
//       this.#needsInstalation = true;
//     }

//     installButton.addEventListener("click", () => {
//       this._installNow();
//     });

//     PrePageConForInstallationConClose.addEventListener("click", () => {
//       PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//       setTimeout(() => {
//         inAppNotification._showNotification(
//           "¿Quieres Instalar Mercado MAI?",
//           "Si estás disfrutando esta aplicación, instálalo ahora y lo tendrás a un solo click.",
//           "Instalar ahora",
//           "installnow",
//           12000
//         );
//       }, 300000);
//     });

//     this.#eventsInitialized = true;
//   }

//   async _initiateInstallApp() {
//     console.warn("Initiate Install app now");

//     // Start Installation
//     if (this.isPWAInstalled()) {
//       console.log("App is already installed");
//       return;
//     }

//     // Listen for the beforeinstallprompt event
//     window.addEventListener("beforeinstallprompt", async (e) => {
//       e.preventDefault();
//       this.#deferredPrompt = e;
//       await this.#waitForPromptLoaded();
//       this._events();
//     });

//     // Listen for the appinstalled event to track the installation
//     window.addEventListener("appinstalled", () => {
//       console.log("PWA was installed");
//       this.#needsInstalation = false;
//       PreInstallCon.classList.remove("PrePageConForInstallationConDisplay");
//     });
//   }
// }

// export default new installApp();
