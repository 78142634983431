import GenerateHtml from "./business-own-generate-html";
// import StartUpMainApp from "../main-start-up-screen/start-up";
import businessPageEditor from "../business-page-editor/business-page-editor";
import firebase from "../firebase";
import handleAccount from "../register/handle-account";
import register from "../register/register";
import businessPageEditor from "../business-page-editor/business-page-editor";
import AllDisplaysInitial from "../handeling-all-displays/all-handeling";
import GENRALHELPERS from "../GENRAL-HELPERS";
import createListing from "../create-listing/create-listing";
import miniScreen from "../mini-screen/mini-screen";
import orders from "../orders/orders";
import accountProfileChange from "../main-menu/account-profile-change";
import nav from "../home-screen/nav";
import allHandeling from "../handeling-all-displays/all-handeling";

class BusinessOwn {
  #currentAccount;
  #currentBsiness;
  _displayRightNavWindow() {
    const windows = document.querySelectorAll(
      ".MainAppConWithNavigationContentCon"
    );

    windows.forEach((window) => {
      if (window.id === "MainAppConWithNavigationContentConBusiness") {
        window.style.display = "";
      } else {
        window.style.display = "none";
      }
    });
  }
  _clearContainer() {
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConBusiness"
    );
    HomeScreenContenConForAll.style.paddingTop = "0px";
    HomeScreenContenConForAll.innerHTML = "";
  }
  _init() {
    // AllDisplaysInitial._abortDisplays();
    this._clearContainer();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConBusiness"
    );
    HomeScreenContenConForAll.innerHTML = GenerateHtml._businesMain();
    console.log("business");
    this._checkForBusiness();
    this._displayRightNavWindow();
  }
  async _checkForBusiness() {
    const conForBusiness = document.getElementById(
      "OwnBusinessConBusinessMessageCon"
    );
    const conForBusinessListings = document.getElementById(
      "OwnBusinessConBusinessListingsCon"
    );
    this.#currentAccount = firebase._getLatestCurrentUserData();
    console.log(this.#currentAccount);

    if (this.#currentAccount && this.#currentAccount.business) {
      const business = await firebase._getBusinessById(
        this.#currentAccount.business
      );

      this.#currentBsiness = business;

      // const { listings, lastVisibleDoc } =
      //   await firebase._getRecentListingsWithFilter(
      //     null,
      //     null,
      //     `businesses/${this.#currentAccount.business}/listings`
      //   );

      const listings = await firebase._getAllDocsFromPathByMostRecent(
        `businesses/${this.#currentAccount.business}/listings`,
        true
      );
      console.log(listings);

      // Start display listings new
      let listingDirectionIds = [];
      let lisitingslistsHtml = ``;
      for (const list of business.lists) {
        const [lisitingslistHtml, id] = await this._displayLists(list.name);
        lisitingslistsHtml += lisitingslistHtml;
        console.log(list);

        listingDirectionIds.push({
          listName: list.name,
          listId: id,
          listType: list.type,
          listTag: list.tag,
          listingsIds: list.ids,
        });

        console.log(listingDirectionIds);
      }

      // End display listings new

      // conForBusiness.innerHTML = await GenerateHtml._business(business);

      conForBusiness.innerHTML = await GenerateHtml._business(business);

      const listingsCon = document.getElementById(
        "OwnBusinessConBusinessListingsCon"
      );
      console.log(listingDirectionIds);
      let listHtml = ``;
      listingDirectionIds.forEach((list) => {
        listHtml += GenerateHtml._listingsCon(list.listName, list.listId);
      });

      const finalHtml = GenerateHtml._businesListings(listHtml);

      listingsCon.innerHTML = finalHtml;
      this._fillInBusinessListings(listings, listingDirectionIds);

      this._eventsHasPage();
      this._generalEvents();
    } else {
      conForBusiness.innerHTML = GenerateHtml._createBusinessPageMessage(
        this.#currentAccount
      );
      conForBusinessListings.innerHTML =
        GenerateHtml._createBusinessPageButtons(this.#currentAccount);
      GENRALHELPERS._animateCTA("OwnBusinessConButtonCreateBusinessPage");
      this._eventsNoPage();
      this._generalEvents();
    }
  }

  _handelActionsOnBusiness(id, button) {
    console.log(id);
    // OwnBusinessCon3

    if (id === "OwnBusinessButtonsActionOnBusinessEdit") {
      businessPageEditor._init("edit");
    }

    OwnBusinessButtonsActionOnBusinessStats ||
      OwnBusinessButtonsActionOnBusinessBoost ||
      OwnBusinessButtonsActionOnBusinessShare;

    if (
      id === "OwnBusinessButtonsActionOnBusinessStats" ||
      id === "OwnBusinessButtonsActionOnBusinessBoost" ||
      id === "OwnBusinessButtonsActionOnBusinessActionMenu"
    ) {
      alert("Próximamente. Esta función aún no esta lista.");
    }
    if (
      id === "OwnBusinessConButtonEditBusinessPageLists1" ||
      id === "OwnBusinessConButtonEditBusinessPageLists2"
    ) {
      businessPageEditor._init("edit", "lists");
      console.log("yep here");
    }
    if (
      id === "OwnBusinessButtonsActionOnBusinessShare" ||
      id === "OwnBusinessButtonsActionOnBusinessOpen"
    ) {
      const businessId = button.closest(".OwnBusinessCon3").dataset.businessid;

      if (businessId) {
        if (id === "OwnBusinessButtonsActionOnBusinessShare") {
          allHandeling._displayTheRightWay(
            businessId,
            null,
            "business",
            null,
            true
          );
        } else {
          allHandeling._displayTheRightWay(businessId, null, "business");
        }
      } else {
        alert(
          "Hubio un error al abrir tu negocio. \n Si este error persiste, sugerijmos que cierras y luego abres esta aplicación de nuevo."
        );
      }
    }
  }

  async _displayLists(listName) {
    let htmlToReturn;
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();

    try {
      htmlToReturn = GenerateHtml._listingsCon(listName, htmlContainerId);
      return [htmlToReturn, htmlContainerId];
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display For You aborted.");
      } else {
        console.error(err);
      }
    }
  }

  async _fillInBusinessListings(listings, listingDirectionIds, businessData) {
    console.warn(listings);
    const fillInListings = async (listings, id) => {
      console.log(listings, id);
      const currentContainerForListingsInput = document.getElementById(id);
      console.log(currentContainerForListingsInput);
      for (const listing of listings) {
        // if (signal.aborted) throw new Error("Aborted");
        const html = await GenerateHtml._listing(listing);
        currentContainerForListingsInput.insertAdjacentHTML("beforeend", html);
      }
    };

    listingDirectionIds.forEach(async (list) => {
      let currentListings = [];
      console.log(list);

      // Check if list is a All list
      if (list.listType === "todos") {
        listings.forEach((listing) => {
          console.log("got taken for manual");
          currentListings.push(listing);
        });
        fillInListings(currentListings, list.listId);
      }

      // Check if list is a manual list
      if (list.listType === "manual") {
        console.log("manual");
        console.log(list.listingsIds);
        if (list.listingsIds) {
          listings.forEach((listing) => {
            if (list.listingsIds.includes(listing.id)) {
              console.log("got taken for manual");
              currentListings.push(listing);
            } else {
              console.log("not taken for manual");
            }
          });
          console.log(currentListings);
          fillInListings(currentListings, list.listId);
        }
      }

      // Check if list is a auto list
      if (list.listType === "auto") {
        // Check if list is a discount list
        if (list.listTag === "en oferta") {
          listings.forEach((listing) => {
            console.log(listing.onDiscount);
            if (listing.onDiscount) {
              currentListings.push(listing);
            }
          });
          fillInListings(currentListings, list.listId);
        } else {
          console.log("got here");
          listings.forEach((listing) => {
            if (listing.listingMachineType === list.listTag) {
              currentListings.push(listing);
            }
          });
          console.log(currentListings);
          fillInListings(currentListings, list.listId);
        }
      }
    });
  }
  _eventsNoPage() {
    const createPageButton = document.getElementById(
      "OwnBusinessConButtonCreateBusinessPage"
    );
    // const buttonLogin = document.getElementById("OwnBusinessConButtonLogin");
    // buttonLogin.addEventListener("click", () => {
    //   register._displayOptions("create_business_page");
    // });
    createPageButton.addEventListener("click", () => {
      const account = firebase._getLatestCurrentUserData();
      if (account) {
        if (!account.whatsAppNumber) {
          accountProfileChange._open();
          alert(
            "Debes tener un perfil de vendedor para poder publicar listados."
          );
          return;
        }
        businessPageEditor._init();
      } else {
        register._displayOptions("create_business_page");
      }
    });
  }

  _eventsHasPage() {
    const buttonsContainer = document.querySelectorAll(
      ".OwnBusinessButtonsActionOnBusinessForEventListining"
    );
    buttonsContainer.forEach((button) => {
      button.addEventListener("click", (e) => {
        // Check for the closest element with an ID
        const targetButton = e.target.closest(
          ".OwnBusinessButtonsActionOnBusinessForEventListining"
        );
        if (targetButton && targetButton.id) {
          this._handelActionsOnBusiness(targetButton.id, button);
        } else {
          console.log("No valid ID found.");
        }
      });
    });
  }

  async _openSubscription(dataIn) {
    let dataToSend = null;
    if (this.#currentBsiness?.currentSubscriptionId) {
      dataToSend = await firebase._getAnyDocById(
        `subscriptions/${this.#currentBsiness.currentSubscriptionId}`
      );
    }

    miniScreen._display(
      "show",
      "Plan de subscripción",
      GenerateHtml._subscriptionPlanHtml(dataToSend)
    );

    const subscriptionPlanTextHeader = document.querySelectorAll(
      ".subscriptionPlanTextHeader"
    );
    const subscriptionPlanExitButtons = document.querySelectorAll(
      ".subscriptionPlanExitButtons"
    );
    const subscriptionPlanNextButtons = document.querySelectorAll(
      ".subscriptionPlanNextButtons"
    );
    const subscriptionPlanSelectYearButton = document.getElementById(
      "subscriptionPlanSelectYearButton"
    );
    const subscriptionPlanSelectMonthButton = document.getElementById(
      "subscriptionPlanSelectMonthButton"
    );
    const subscriptionPlanConMonth = document.getElementById(
      "subscriptionPlanConMonth"
    );
    const subscriptionPlanConYear = document.getElementById(
      "subscriptionPlanConYear"
    );
    const subscriptionPlanInputPriorityPointsMonth = document.getElementById(
      "subscriptionPlanInputPriorityPointsMonth"
    );
    const subscriptionPlanInputPriorityPointsYear = document.getElementById(
      "subscriptionPlanInputPriorityPointsYear"
    );
    const subscriptionPlanFinalPriceMonth = document.getElementById(
      "subscriptionPlanFinalPriceMonth"
    );
    const subscriptionPlanFinalPriceYear = document.getElementById(
      "subscriptionPlanFinalPriceYear"
    );

    const subscriptionPlanSelectListingsMonth = document.getElementById(
      "subscriptionPlanSelectListingsMonth"
    );
    const allLisitngSelectorsInMonth =
      subscriptionPlanSelectListingsMonth.querySelectorAll(".SubBusPlanCon12");

    const subscriptionPlanSelectListingsYear = document.getElementById(
      "subscriptionPlanSelectListingsYear"
    );
    const allLisitngSelectorsInYear =
      subscriptionPlanSelectListingsYear.querySelectorAll(".SubBusPlanCon12");

    let subscriptionDataToSet = null;
    let planselected = "month";
    let listingSelected = "included";
    let priorityLevelSelected = "";
    subscriptionPlanTextHeader.textContent =
      "Escoge el plan perfecto para tu negocio";

    if (dataIn) {
      dataIn.items.forEach((item) => {
        if (item.type === "subscripción") {
          subscriptionDataToSet = item;
        }
      });
    }

    if (subscriptionDataToSet) {
      subscriptionPlanTextHeader.textContent = "Renueva tu plan";
      planselected = subscriptionDataToSet.plan;
      listingSelected = subscriptionDataToSet.listings;
      priorityLevelSelected = subscriptionDataToSet.priorityLevel;
    }

    const allowOnlyNumbers = (event) => {
      const input = event.target;
      const value = input.value;

      // Replace all non-digit characters
      input.value = value.replace(/\D/g, ""); // \D matches any non-digit character
    };

    const calculatePlanPrice = (data) => {
      let finalAmount = 0;

      if (data.plan === "month") {
        finalAmount += 290;
      }
      if (data.plan === "year") {
        finalAmount += 2958;
      }
      if (data.listings === "20") {
        finalAmount += 290;
      }
      if (data.listings === "30") {
        finalAmount += 480;
      }
      if (data.listings === "45") {
        finalAmount += 600;
      }
      if (data.listings === "240") {
        finalAmount += 2958;
      }
      if (data.listings === "360") {
        finalAmount += 4896;
      }
      if (data.listings === "540") {
        finalAmount += 5508;
      }
      if (data.plan === "month" && data.listings === "unlimited") {
        finalAmount += 1280;
      }
      if (data.plan === "year" && data.listings === "unlimited") {
        finalAmount += 13056;
      }

      finalAmount += Number(data.priorityLevel * 0.5);

      if (data.plan === "month") {
        console.log("month now");
        subscriptionPlanFinalPriceMonth.textContent = `$${GENRALHELPERS._formatToPrice(
          finalAmount
        )}`;
      }
      if (data.plan === "year") {
        console.log("yearNow");
        subscriptionPlanFinalPriceYear.textContent = `$${GENRALHELPERS._formatToPrice(
          finalAmount
        )}`;
      }
    };

    const getPlanData = () => {
      const planData = {
        plan: "",
        listings: "",
        priorityLevel: "",
      };
      console.log(planselected);
      if (planselected === "month") {
        allLisitngSelectorsInMonth.forEach((selector) => {
          if (selector.dataset.selected === "true") {
            planData.listings = selector.dataset.numberoflistings;
          }
        });

        planData.priorityLevel =
          subscriptionPlanInputPriorityPointsMonth.value.trim();
        if (planData.priorityLevel === "") {
          planData.priorityLevel = "0";
        }
        planData.plan = "month";
      }
      if (planselected === "year") {
        allLisitngSelectorsInYear.forEach((selector) => {
          if (selector.dataset.selected === "true") {
            planData.listings = selector.dataset.numberoflistings;
          }
        });

        planData.priorityLevel =
          subscriptionPlanInputPriorityPointsYear.value.trim();

        if (planData.priorityLevel === "") {
          planData.priorityLevel = "0";
        }
        planData.plan = "year";
      }
      calculatePlanPrice(planData);
      console.log(planData);
      return planData;
    };

    subscriptionPlanInputPriorityPointsMonth.addEventListener("blur", (e) => {
      allowOnlyNumbers(e);
      getPlanData();
    });
    subscriptionPlanInputPriorityPointsMonth.addEventListener("keyup", (e) => {
      allowOnlyNumbers(e);
      getPlanData();
    });
    subscriptionPlanInputPriorityPointsYear.addEventListener("blur", (e) => {
      allowOnlyNumbers(e);
      getPlanData();
    });
    subscriptionPlanInputPriorityPointsYear.addEventListener("keyup", (e) => {
      allowOnlyNumbers(e);
      getPlanData();
    });

    const setYearListingSelector = (closestSelector) => {
      console.log(closestSelector);
      allLisitngSelectorsInYear.forEach((selector) => {
        const text = selector.querySelector(".SubBusPlanCon12Text");
        selector.classList.remove("SubBusPlanCon12ActivePro");
        selector.classList.add("SubBusPlanCon12NoneActive");
        text.classList.remove("SubBusPlanConText8");
        text.classList.add("SubBusPlanConText10B");
        selector.dataset.selected = "false";
        if (
          selector.dataset.numberoflistings ===
          closestSelector.dataset.numberoflistings
        ) {
          selector.dataset.selected = "true";
          text.classList.add("SubBusPlanConText8");
          text.classList.remove("SubBusPlanConText10B");
          console.log(selector.dataset.numberoflistings);
          selector.classList.add("SubBusPlanCon12ActivePro");
          selector.classList.remove("SubBusPlanCon12NoneActive");
        }
      });
      getPlanData();
    };
    const setMonthListingSelector = (closestSelector) => {
      console.log(closestSelector);
      allLisitngSelectorsInMonth.forEach((selector) => {
        const text = selector.querySelector(".SubBusPlanCon12Text");
        selector.classList.remove("SubBusPlanCon12Active");
        selector.classList.add("SubBusPlanCon12NoneActive");
        text.classList.remove("SubBusPlanConText8");
        text.classList.add("SubBusPlanConText10");
        selector.dataset.selected = "false";
        if (
          selector.dataset.numberoflistings ===
          closestSelector.dataset.numberoflistings
        ) {
          selector.dataset.selected = "true";
          text.classList.add("SubBusPlanConText8");
          text.classList.remove("SubBusPlanConText10");
          console.log(selector.dataset.numberoflistings);
          selector.classList.add("SubBusPlanCon12Active");
          selector.classList.remove("SubBusPlanCon12NoneActive");
        }
      });
      getPlanData();
    };

    if (planselected === "month" && subscriptionDataToSet) {
      allLisitngSelectorsInMonth.forEach((selector) => {
        if (selector.dataset.numberoflistings === listingSelected) {
          setMonthListingSelector(selector);
        }
      });
      subscriptionPlanConMonth.style.display = "";
      subscriptionPlanConYear.style.display = "none";
      planselected = "month";
      subscriptionPlanInputPriorityPointsMonth.value = priorityLevelSelected;
    }
    if (planselected === "year" && subscriptionDataToSet) {
      allLisitngSelectorsInYear.forEach((selector) => {
        if (selector.dataset.numberoflistings === listingSelected) {
          setYearListingSelector(selector);
        }
      });
      subscriptionPlanConMonth.style.display = "none";
      subscriptionPlanConYear.style.display = "";
      planselected = "month";
      subscriptionPlanInputPriorityPointsYear.value = priorityLevelSelected;
    }

    subscriptionPlanSelectListingsMonth.addEventListener("click", (e) => {
      const closestSelector = e.target.closest(".SubBusPlanCon12");
      if (closestSelector) {
        setMonthListingSelector(closestSelector);
      }
    });
    subscriptionPlanSelectListingsYear.addEventListener("click", (e) => {
      const closestSelector = e.target.closest(".SubBusPlanCon12");
      if (closestSelector) {
        setYearListingSelector(closestSelector);
      }
    });

    subscriptionPlanSelectYearButton.addEventListener("click", () => {
      subscriptionPlanConMonth.style.display = "none";
      subscriptionPlanConYear.style.display = "";
      planselected = "year";
    });

    subscriptionPlanSelectMonthButton.addEventListener("click", () => {
      subscriptionPlanConMonth.style.display = "";
      subscriptionPlanConYear.style.display = "none";
      planselected = "month";
    });

    subscriptionPlanExitButtons.forEach((button) => {
      button.addEventListener("click", () => {
        miniScreen._display("hide");
      });
    });
    subscriptionPlanNextButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const plan = getPlanData();
        console.log(plan);
        miniScreen._display("hide");
        orders._start([
          { ...plan, type: "subscripción", name: "Subscripción" },
        ]);
      });
    });
  }
  _generalEvents() {
    const OwnBusinessButtonCreateListing = document.getElementById(
      "OwnBusinessButtonCreateListing"
    );
    const OwnBusinessConBusinessMessageConSubscriptionButton =
      document.getElementById(
        "OwnBusinessConBusinessMessageConSubscriptionButton"
      );
    OwnBusinessButtonCreateListing.addEventListener("click", () => {
      createListing._init();
    });

    if (OwnBusinessConBusinessMessageConSubscriptionButton) {
      OwnBusinessConBusinessMessageConSubscriptionButton.addEventListener(
        "click",
        () => {
          this._openSubscription();
        }
      );
    }
  }
}

export default new BusinessOwn();
