import { doc } from "firebase/firestore";
import miniScreen from "../mini-screen/mini-screen";
import generateHtml from "./generate-html";
import spinner from "../spinner";
import firebase from "../firebase";
import yourListings from "../main-menu/your-listings";
import businessOwnGenerateHtml from "../business-page-own-veiw/business-own-generate-html";
import wallet from "../wallet/wallet";
import menu from "../main-menu/menu";
import GENRALHELPERS from "../GENRAL-HELPERS";
import busineOwn from "../business-page-own-veiw/busine-own";

// const dataObj = {
//   discount: {
//     text1: "Disfruta de nuestros descuentos si pagas ahora",
//     text2: "Te damos un 12% de descuento",
//     text3:
//       "Ademas te damos un 35% de cashBack, que ingresa a tu Wallet, y lo podrás usar para compras futuras.",
//   },
// };

class orders {
  #openedFromWallet = null;
  #eventsInitialized = false;
  #eventsPoitHandlerInitialized = false;
  #eventsCuponHandlerInitialized = false;
  #eventsAddOrderHandlerInitialized = false;
  #currentOrder;
  #paymentInfo;
  #currentPoints = 0;
  constructor() {
    this._isAborted = false;
    // this._start();
  }
  _abortHandleDraft() {
    this._isAborted = true;
    console.log("Order creation aborted.");
  }

  async _handleDraft(data, cuponIn) {
    let draftId;
    let cupon = cuponIn;
    if (this.#currentOrder) {
      if (this.#currentOrder.moreData.draftId) {
        draftId = this.#currentOrder.moreData.draftId;
      }
      if (this.#currentOrder.moreData.cupon) {
        cupon = this.#currentOrder.moreData.cupon;
      }
    }
    const orderData = {
      moreData: {
        cupon: cupon,
        draftId: draftId,
      },
      items: [],
    };
    console.log(data);
    data.forEach((item) => {
      let priority = { points: 0 };
      if (item.priority?.points) {
        priority.points = item.priority.points;
      }
      orderData.items.push({ ...item, priority });
    });
    if (this._isAborted) return; // Check if the operation is aborted
    spinner._displaySpinner("Estamos solicitando el inicio de un pedido");
    const orderDraft = await firebase._getOrderDraft(orderData);
    console.warn(orderDraft);
    if (orderDraft.data.status !== "success") {
      alert(`Error: ${orderDraft.data.message}`);
      spinner._hideSpinner();
      miniScreen._display("hide");
      return;
    } else {
      this.#currentOrder = orderDraft.data.data;
    }

    console.log(this.#currentOrder);

    // Some async task, check if we need to abort at each step
    if (this._isAborted) return;
    this._fillOrders(this.#currentOrder.items);
    this._setTotals(this.#currentOrder);

    if (this._isAborted) return;
    this._events();

    spinner._hideSpinner();
  }

  async _handleCupon() {
    const OrderInputCupon = document.getElementById("OrderInputCupon");
    const OrderCuponApliedMessage = document.getElementById(
      "OrderCuponApliedMessage"
    );
    const OrderCuponAplyButton = document.getElementById(
      "OrderCuponAplyButton"
    );
    const OrderCuponImportantMessage = document.getElementById(
      "OrderCuponImportantMessage"
    );

    if (this.#currentOrder?.moreData?.cupon) {
      OrderInputCupon.value = this.#currentOrder.moreData.cupon;
    } else {
      OrderInputCupon.value = "";
    }
    OrderCuponApliedMessage.style.display = "none";
    OrderCuponApliedMessage.textContent = "";
    OrderCuponImportantMessage.style.display = "none";
    OrderCuponImportantMessage.textContent = "";

    if (this.#currentOrder?.moreData?.cuponData) {
      const cuponData = this.#currentOrder?.moreData?.cuponData;
      if (cuponData.status === "success") {
        OrderCuponApliedMessage.style.display = "";
        OrderCuponApliedMessage.textContent = cuponData.message;
        OrderCuponImportantMessage.style.display = "";
        OrderCuponImportantMessage.textContent = cuponData.secondMessage;
      }
      if (cuponData.status === "error") {
        OrderCuponApliedMessage.style.display = "none";
        OrderCuponApliedMessage.textContent = "";
        OrderCuponImportantMessage.style.display = "";
        OrderCuponImportantMessage.textContent = cuponData.message;
      }
    }

    if (this.#eventsCuponHandlerInitialized) return;
    OrderInputCupon.addEventListener("keyup", (e) => {
      OrderCuponApliedMessage.style.display = "none";
      OrderCuponApliedMessage.textContent = "";
      OrderCuponImportantMessage.style.display = "none";
      OrderCuponImportantMessage.textContent = "";
      if (e.target.value.trim().length > 0) {
        OrderCuponAplyButton.style.display = "";
        if (e.target.value.trim().length > 3) {
          OrderCuponAplyButton.classList.remove("OrderCuponAplyButtonNoneAct");
          OrderCuponAplyButton.dataset.active = true;
        } else {
          OrderCuponAplyButton.classList.add("OrderCuponAplyButtonNoneAct");
          OrderCuponAplyButton.dataset.active = false;
        }
      } else {
        OrderCuponAplyButton.classList.add("OrderCuponAplyButtonNoneAct");
        OrderCuponAplyButton.dataset.active = false;
        OrderCuponAplyButton.style.display = "none";
      }
    });

    OrderCuponAplyButton.addEventListener("click", () => {
      if (!OrderCuponAplyButton.dataset.active) return;
      const cupon = OrderInputCupon.value.trim();
      this.#currentOrder.moreData.cupon = cupon;
      this._handleDraft(this.#currentOrder.items);
      console.log(this.#currentOrder);

      OrderCuponAplyButton.classList.add("OrderCuponAplyButtonNoneAct");
      OrderCuponAplyButton.dataset.active = false;
      OrderCuponAplyButton.style.display = "none";
    });
    this.#eventsCuponHandlerInitialized = true;
  }

  _createDiscountMesages(paymentInfo) {
    const data = {
      text1: "Disfruta de nuestros descuentos si pagas ahora",
      text2: `Te damos un ${this.#paymentInfo.discountPercent}% de descuento`,
      text3: `Ademas te damos un ${
        this.#paymentInfo.cashBackPercent
      }% de cashBack, que ingresa a tu Wallet, y lo podrás usar para compras futuras.`,
    };
    return data;
  }

  async _start(data, cuponIn, openedFromWallet) {
    this.#openedFromWallet = openedFromWallet;
    this.#currentOrder = null;
    this._isAborted = false;
    this.#eventsInitialized = false;
    this.#eventsPoitHandlerInitialized = false;
    this.#eventsCuponHandlerInitialized = false;
    this.#eventsAddOrderHandlerInitialized = false;
    this._handleDraft(data, cuponIn);
    miniScreen._display("show", "Pedido", generateHtml._main());
    const OrderButtonCancel = document.getElementById("OrderButtonCancel");
    const OrderTotalPayAheadButton = document.getElementById(
      "OrderTotalPayAheadButton"
    );
    OrderButtonCancel.addEventListener("click", () => {
      const leaveModal = confirm("¿Estás seguro que quiere dejar tu pedido?");
      if (!leaveModal) return;
      miniScreen._display("hide");
      this._abortHandleDraft();
      if (this.#openedFromWallet) {
        yourListings._close();
      }
    });
    OrderTotalPayAheadButton.addEventListener("click", () => {
      spinner._displaySpinner("Un momento");
      wallet._setConfirmPayOrder(
        this.#currentOrder.moreData.draftId,
        this.#currentOrder.finalPrice,
        this.#openedFromWallet
      );
      miniScreen._display("hide");
      this._abortHandleDraft();
      yourListings._close();
      menu._close();
    });
  }

  _fillOrders(data) {
    const OrderConForOrdersInsert = document.getElementById(
      "OrderConForOrdersInsert"
    );
    OrderConForOrdersInsert.innerHTML = "";
    let ordersHtml = ``;

    data.forEach((order) => {
      if (order.type === "listado") {
        ordersHtml += generateHtml._makeOrder(order);
      }
      if (order.type === "subscripción") {
        ordersHtml += generateHtml._makeSubscriptionOrder(order);
      }
    });
    OrderConForOrdersInsert.insertAdjacentHTML("beforeend", ordersHtml);
  }

  _pointsHandler(id) {
    console.log(id);
    const OrderConPointHandler = document.getElementById(
      "OrderConPointHandler"
    );
    const OrderConInputPointsInput = document.getElementById(
      "OrderConInputPointsInput"
    );
    const OrderConInputPointsButtonSet = document.getElementById(
      "OrderConInputPointsButtonSet"
    );
    OrderConInputPointsButtonSet.dataset.id = id;
    OrderConPointHandler.style.display = "";

    OrderConInputPointsInput.addEventListener("input", function (e) {
      // Allow only digits (no decimal points)
      this.value = this.value.replace(/[^0-9]/g, "");
    });

    for (let obj of this.#currentOrder.items) {
      if (obj.id === id) {
        OrderConInputPointsInput.value = obj.priority.points;
        this.#currentPoints = obj.priority.points;
        OrderConInputPointsInput.focus();
        break; // Exit the loop once the object is updated
      }
    }

    const handdle = (idFromButton) => {
      console.warn(idFromButton);
      if (this.#currentPoints === OrderConInputPointsInput.value.trim()) {
        OrderConPointHandler.style.display = "none";
        return;
      }
      for (let obj of this.#currentOrder.items) {
        if (obj.id === idFromButton) {
          if (
            OrderConInputPointsInput.value.trim().length < 1 ||
            OrderConInputPointsInput.value.trim() === "."
          ) {
            obj.priority.points = 0;
          }
          obj.priority.points = OrderConInputPointsInput.value.trim();
          break; // Exit the loop once the object is updated
        }
      }
      OrderConPointHandler.style.display = "none";
      this._handleDraft(this.#currentOrder.items);
    };

    if (this.#eventsPoitHandlerInitialized) return;
    OrderConPointHandler.addEventListener("click", (e) => {
      const OrderConInputPointsCon1 = e.target.closest(
        ".OrderConInputPointsCon1"
      );
      const OrderConPointsHendler = e.target.closest("#OrderConPointHandler");
      if (OrderConPointsHendler && !OrderConInputPointsCon1) {
        OrderConPointHandler.style.display = "none";
      }
    });

    OrderConInputPointsInput.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        handdle(OrderConInputPointsButtonSet.dataset.id);
      }
    });
    OrderConInputPointsButtonSet.addEventListener("click", () => {
      handdle(OrderConInputPointsButtonSet.dataset.id);
    });
    OrderConInputPointsButtonSet.addEventListener("click", () => {
      OrderConPointHandler.style.display = "none";
    });
    this.#eventsPoitHandlerInitialized = true;
  }

  async _addOrdersHandler() {
    const OrderConAddOrdersHandler = document.getElementById(
      "OrderConAddOrdersHandler"
    );
    const OrderConAddOrdersOtherOrdersCon = document.getElementById(
      "OrderConAddOrdersOtherOrdersCon"
    );
    const OrderConAddOrdersButton = document.getElementById(
      "OrderConAddOrdersButton"
    );
    OrderConAddOrdersHandler.style.display = "";

    let inOrderIds = [];
    let listingsForUse = [];
    this.#currentOrder.items.forEach((item) => {
      inOrderIds.push(item.id);
    });

    const listings = yourListings._getLocalListings();
    console.log(listings);

    listings.forEach((listing) => {
      if (
        listing.status === "waitingPayment" &&
        !inOrderIds.includes(listing.listingId)
      ) {
        listingsForUse.push(listing);
      }
    });

    let listingHtml = ``;
    for (const listing of listingsForUse) {
      listingHtml += await businessOwnGenerateHtml._listing(
        listing,
        "selectForPayment"
      );
    }

    const tryIfPack = document.getElementById("listingsInsertForAddToOrder");
    if (tryIfPack) tryIfPack.parentElement.remove();

    const packHtml = businessOwnGenerateHtml._listingsCon(
      "Esperando pago",
      "listingsInsertForAddToOrder"
    );
    OrderConAddOrdersOtherOrdersCon.insertAdjacentHTML("afterbegin", packHtml);

    const conForInsert = document.getElementById("listingsInsertForAddToOrder");
    console.log(conForInsert);

    conForInsert.parentElement.style.paddingRight = "12px";
    conForInsert.insertAdjacentHTML("beforeend", listingHtml);

    let selectedListingsForOrder = [];

    if (this.#eventsAddOrderHandlerInitialized) return;
    OrderConAddOrdersHandler.addEventListener("click", (e) => {
      const button = e.target.closest(".OwnBusinessListingButtonActionSelect");
      if (button) {
        const id = e.target.closest(".OwnBusinessListingCon1").id;
        button.classList.toggle("OwnBusinessListingButtonActionSelectSelected");
        if (
          button.classList.contains(
            "OwnBusinessListingButtonActionSelectSelected"
          )
        ) {
          selectedListingsForOrder.push(id);
          button.textContent = "Seleccionado";
        } else {
          const index = selectedListingsForOrder.indexOf(id);
          if (index !== -1) {
            selectedListingsForOrder.splice(index, 1);
          }
          button.textContent = "Seleccionar";
        }
      }
      if (!e.target.closest("#OrderConAddOrdersOtherOrdersCon")) {
        selectedListingsForOrder = [];
        OrderConAddOrdersHandler.style.display = "none";
      }
    });

    OrderConAddOrdersButton.addEventListener("click", () => {
      if (selectedListingsForOrder.length < 1) {
        OrderConAddOrdersHandler.style.display = "none";
        return;
      }
      selectedListingsForOrder.forEach((id) => {
        const foundListing = listings.find((obj) => obj.listingId === id);

        let nearestImgSrc = null; // Variable to store the result
        const conForInsertLocal = document.getElementById(
          "listingsInsertForAddToOrder"
        );

        // Loop over all child elements of the parent element
        for (let i = 0; i < conForInsertLocal.children.length; i++) {
          const child = conForInsertLocal.children[i];
          // Check if the child's ID matches the desired ID
          if (child.id === id) {
            // Retrieve the src of the nearestImg
            nearestImgSrc = child
              .querySelector(".OwnBusinessListingCon2")
              .querySelector("img").src;
            // Exit the loop once the child is found
            break;
          }
        }

        if (foundListing) {
          this.#currentOrder.items.push({
            id: foundListing.listingId,
            imgId: nearestImgSrc,
            name: foundListing.listingName,
            type: "listado",
          });
        }
      });

      this._handleDraft(this.#currentOrder.items);

      selectedListingsForOrder = [];
      OrderConAddOrdersHandler.style.display = "none";
    });
    this.#eventsAddOrderHandlerInitialized = true;
  }

  async _setTotals(data) {
    const OrderDiscountConDisplayText1 = document.getElementById(
      "OrderDiscountConDisplayText1"
    );
    const OrderDiscountConDisplayText2 = document.getElementById(
      "OrderDiscountConDisplayText2"
    );
    const OrderDiscountConDisplayText3 = document.getElementById(
      "OrderDiscountConDisplayText3"
    );
    const OrderTotalPayTotal = document.getElementById("OrderTotalPayTotal");
    const OrderTotalPayDiscountGeneral = document.getElementById(
      "OrderTotalPayDiscountGeneral"
    );
    const OrderTotalPayDiscountCupon = document.getElementById(
      "OrderTotalPayDiscountCupon"
    );
    const OrderTotalPayCashBackTotal = document.getElementById(
      "OrderTotalPayCashBackTotal"
    );
    const OrderTotalPayFinal = document.getElementById("OrderTotalPayFinal");

    OrderTotalPayTotal.textContent =
      "$" + GENRALHELPERS._formatToPrice(data.totalPrice);
    OrderTotalPayDiscountGeneral.textContent =
      "$" + GENRALHELPERS._formatToPrice(data.discount);

    const cashBackCon = OrderTotalPayCashBackTotal.closest(
      ".OrderConCashBackMessageCon1"
    );

    this.#paymentInfo = await firebase._getAnyDocById(
      "PaymentInfo/forPaymentInfo"
    );
    const discountData = this._createDiscountMesages();
    OrderDiscountConDisplayText1.textContent = discountData.text1;
    OrderDiscountConDisplayText2.textContent = discountData.text2;
    OrderDiscountConDisplayText3.textContent = discountData.text3;

    if (Number(data.cashBack) > 0) {
      cashBackCon.style.display = "";
      OrderTotalPayCashBackTotal.textContent =
        "$" + GENRALHELPERS._formatToPrice(data.cashBack);
    } else {
      cashBackCon.style.display = "none";
    }
    // OrderTotalPayDiscountCupon.textContent = "$" + data.discountCupon;
    OrderTotalPayFinal.textContent =
      "$" + GENRALHELPERS._formatToPrice(data.finalPrice);
    this._handleCupon();
  }
  _events() {
    if (this.#eventsInitialized) return;
    const OrderConButtonAddOrders = document.getElementById(
      "OrderConButtonAddOrders"
    );
    OrderConButtonAddOrders.addEventListener("click", () => {
      this._addOrdersHandler();
    });

    let itemName = "este listado";
    const OrderConB1 = document.getElementById("OrderConB1");
    OrderConB1.addEventListener("click", (e) => {
      const targetOfClick = e.target;
      const OrderConPriorityPoint = targetOfClick.closest(
        "#OrderConPriorityPoint"
      );
      const OrderConDeleteFromOrder = targetOfClick.closest(
        "#OrderConDeleteFromOrder"
      );
      const subscriptionEditButtonInOrder = targetOfClick.closest(
        ".subscriptionEditButtonInOrder"
      );
      if (!OrderConDeleteFromOrder && OrderConPriorityPoint) {
        const thisItem = targetOfClick.closest(".MainItemConForSelectUse");
        if (!thisItem) return;
        this._pointsHandler(thisItem.id);
      }

      if (subscriptionEditButtonInOrder) {
        // TODO open plan here
        miniScreen._display("hide");
        this._abortHandleDraft();
        busineOwn._openSubscription(this.#currentOrder);
      }

      if (OrderConDeleteFromOrder && !OrderConPriorityPoint) {
        const confirmRemove = confirm(
          `¿Estás seguro que quieres borrar ${itemName} de este pedido?`
        );
        if (!confirmRemove) return;
        const thisItem = targetOfClick.closest(".MainItemConForSelectUse");
        console.log(thisItem);
        const id = thisItem.id;
        let newItems = [];
        this.#currentOrder.items.forEach((item) => {
          console.log(id, item.id);
          if (item.id === id) return;
          newItems.push(item);
        });
        this.#currentOrder.items = newItems;
        this._handleDraft(this.#currentOrder.items);
      }
    });
    this.#eventsInitialized = true;
  }
}

export default new orders();
