import iconCamara from "../../../Icons/icons-charcoal-dark/camara.png";
import iconUpDown from "../../../Icons/icons-gray/up-down.png";
import iconDelete from "../../../Icons/icons-gray/delete.png";
import iconExit from "../../../Icons/icons-charcoal-dark/exit.png";
import iconAdd from "../../../Icons/icons-charcoal-dark/add.png";
import iconLocation from "../../../Icons/icons-gray/location.png";
import iconCurrentLocation from "../../../Icons/icons-blue/current-location.png";
import iconTargetLocation from "../../../Icons/icons-blue/target-location.png";
import iconColor from "../../../Icons/icons-white/color.png";

{
  /* <img height="20px" src="${iconCamara}" alt="" /> */
}

class GenerateHtmlEditorTools {
  // FUNCTION Part header
  _headerInputs(data) {
    return `   
<div class="InputMiniCon1">
  <div class="InputMiniCon2">
    <div class="InputMiniLabel">Nombre de negocio</div>
    <input id="BusinessEditorNameInput"
      placeholder="Nombre del negocio"
      class="InputMiniInput InputForFocusCheck"
      type="text"
      value="${data.name}"
    />
  </div>
</div>
<div class="InputMiniCon1">
  <div class="InputMiniCon2">
    <div class="InputMiniLabel">Descripción corta de negocio</div>
    <input id="BusinessEditorShortDescriptionInput"
      placeholder="Descripción corta del negocio"
      class="InputMiniInput InputForFocusCheck"
      type="text"
      value="${data.shortDescription}"
    />
  </div>
</div>
    `;
  }
  _headerImages() {
    return `
<label
  for="BusinessPageEditorSelectImgBackground"
  class="BusinessPageEditorSelectImgCon1"
>
  <img height="20px" src="${iconCamara}" alt="" />
  <div class="BusinessPageEditorSelectImgLabel">Escoge una imagen de fondo</div>
</label>
<label
  for="BusinessPageEditorSelectImgProfile"
  class="BusinessPageEditorSelectImgCon1"
>
  <img height="20px" src="${iconCamara}" alt="" />
  <div class="BusinessPageEditorSelectImgLabel">
    Escoge una imagen de negocio
  </div>
</label>
    `;
  }

  // FUNCTION Part listings
  _listingsApear() {
    return `
<div class="BusinessPageEditorListingsApearCon1A">
  <label class="BusinessPageEditorListingsApearCon1">
    <div class="BusinessPageEditorListingsApearCon2">
      <div class="BusinessPageEditorListingsApearCon3">
        <div class="BusinessPageEditorListingsApearConB1"></div>
        <div class="BusinessPageEditorListingsApearConB2">
          <div class="BusinessPageEditorListingsApearConB3"></div>
          <div class="BusinessPageEditorListingsApearConB4"></div>
          <div class="BusinessPageEditorListingsApearConB5"></div>
          <div class="BusinessPageEditorListingsApearConB6">
            <div class="BusinessPageEditorListingsApearConB7"></div>
            <div class="BusinessPageEditorListingsApearConB8"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="BusinessPageEditorListingsApearText">Vertical</div>
    <input id="BusinessEditorListsAppearVirtical"
      type="radio"
      name="layout"
      value="vertical"
      class="BusinessPageEditorRadio"
    />
  </label>
  <label class="BusinessPageEditorListingsApearCon4">
    <div class="BusinessPageEditorListingsApearCon5">
      <div class="BusinessPageEditorListingsApearCon6">
        <div class="BusinessPageEditorListingsApearConB9"></div>
        <div class="BusinessPageEditorListingsApearConB10">
          <div class="BusinessPageEditorListingsApearConB11"></div>
          <div class="BusinessPageEditorListingsApearConB12"></div>
          <div class="BusinessPageEditorListingsApearConB13"></div>
          <div class="BusinessPageEditorListingsApearConB14">
            <div class="BusinessPageEditorListingsApearConB15"></div>
            <div class="BusinessPageEditorListingsApearConB16"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="BusinessPageEditorListingsApearText">Horizontal</div>
    <input id="BusinessEditorListsAppearHorizontal"
      type="radio"
      name="layout"
      value="horizontal"
      class="BusinessPageEditorRadio"
    />
  </label>
</div>

    `;
  }

  _creatLists(lists, tags) {
    console.log(lists);
    let html = ``;
    lists.forEach((list) => {
      let tagOptions = `<option value="${list.tag}">${list.tag}</option>`;
      let autoManualOptions = ``;
      tags.forEach((tag) => {
        tagOptions += `<option value="${tag}">${tag}</option>`;
      });

      if (list.type !== "auto") {
        if (list.type === "todos") {
          autoManualOptions = `
          <option value="todos">todos</option>
          `;
          html += this._listingsList(
            list,
            "",
            tagOptions,
            autoManualOptions,
            list.type
          );
        } else {
          autoManualOptions = `
          <option value="auto">Por etiqueta</option>
          `;
          // { <option value="manual">Agregar listados individualmente</option>}
          html += this._listingsList(list, "", tagOptions, autoManualOptions);
        }

        // html += this._listingsList(list, "none", tagOptions, autoManualOptions);
      } else {
        autoManualOptions = `
        <option value="auto">Por etiqueta</option>
        `;
        // <option value="manual">Agregar listados individualmente</option>
        html += this._listingsList(list, "", tagOptions, autoManualOptions);
      }
    });
    return this._listingsLists(html);
  }

  _listingsList(list, tagDisplay, tagOptions, autoManualOptions, type) {
    return `
    <div data-idsarray="${
      list.ids
    }" class="BusinessPageEditorListingsListsCon2" draggable="true">
  <div class="BusinessPageEditorListingsListsCon2B">
 ${
   type !== "todos"
     ? `<div class="BusinessPageEditorListingsListsCon3 BusinessPageEditorListingsListDelete"> <img height="16px" src="${iconDelete}" alt="" /></div>`
     : ""
 }
    <div class="BusinessPageEditorListingsListsCon3 BusinessPageEditorListingsListOrder" draggable="false">
    <img height="16px" src="${iconUpDown}" alt="" />
    </div>
  </div>
  <div class="BusinessPageEditorListingsListsCon4">
    <div class="BusinessPageEditorListingsListsCon5">
      <div class="BusinessPageEditorListingsListsLabel">Nombre de lista</div>
      <input
        class="BusinessPageEditorListingsListsInputName InputForFocusCheck"
        id="BusinessPageEditorListingsListsListName"
        placeholder="en oferta"
        type="text"
        value="${list.name}"
      />
    </div>
    <div class="BusinessPageEditorListingsListsCon5">
      <div class="BusinessPageEditorListingsListsLabel">Tipo de lista</div>
      <select ${type === "todos" ? `disabled` : ""}
        class="BusinessPageEditorListingsListsChoose"
        name=""
        id="BusinessPageEditorListingsListsListTypeSelect"
      >
      ${autoManualOptions}
      </select>
    </div>
    <div style="display: ${tagDisplay}" class="BusinessPageEditorListingsListsCon5">
      <div class="BusinessPageEditorListingsListsLabel">Etiquetas</div>
      <select
        class="BusinessPageEditorListingsListsChoose"
        name=""
        id="BusinessPageEditorListingsListsListTagSelect"
      >
      ${tagOptions}
      </select>
    </div>
  </div>
</div>

  `;
  }

  _listingsLists(listHtml) {
    return `
<div class="BusinessPageEditorListingsListsCon1">
  ${listHtml}
</div>
<div class="BusinessPageEditorListingsListsCon7">
  <button id="BusinessPageEditorListingsListsButtonNewList" class="BusinessPageEditorListingsListsButtonNewList">
      <img height="16px" src="${iconAdd}" alt="" />
    Nueva lista
  </button>
</div>

  `;
  }
  // FUNCTION Part info
  _infoLocation(data) {
    return `
<div class="BusinessPageEditorInfoCon2">
  <label for="BusinessPageEditorLocationInput">
    <img
      id="BusinessPageEditorOpenMapButton"
      height="20px"
      src="${iconLocation}"
      alt=""
    />
  </label>
  <input
    type="text"
    placeholder="Search for a location..."
    id="BusinessPageEditorLocationInput"
    value="${data.name}"
  />
  <div id="BusinessEditorLocationMyLocationCon1" style="display: none" class="BusinessEditorLocationMyLocationCon1">
      <img
        id="BusinessPageEditorOpenMapButtonLocator"
        height="20px"
        src="${iconCurrentLocation}"
        alt=""
      />
    <div class="BusinessEditorLocationMyLocationText">
      Ubicar
    </div>
  </div>
</div>
<div style="display: none" id="BusinessPageEditorMapContainer1">
  <div
    id="BusinessPageEditorMapContainer"
    class="BusinessPageEditorMapContainer"
  ></div>
  <div id="BusinessPageEditorMapCenterMarker"><img
    id="BusinessPageEditorOpenMapButtonLocator"
    height="24px"
    src="${iconTargetLocation}"
    alt=""
  /></div>
</div>
<div class="InputMiniCon2">
  <div class="InputMiniCon2">
    <div class="InputMiniLabel">Referencias</div>
    <input
      id="BusinessPageEditorLocationDescriptionInput"
      class="InputMiniInput InputForFocusCheck"
      type="text"
      placeholder="Describe el lugar"
      value="${data.description}"
    />
  </div>
</div>

    `;
  }
  _infoContacts(data) {
    let callNumber = "";
    let whatsAppNumber = "";
    let email = "";
    let website = "";

    if (data.contacts.call && data.contacts.call.contact !== "") {
      callNumber = data.contacts.call.contact;
    }
    if (data.contacts.whatsApp && data.contacts.whatsApp.contact !== "") {
      whatsAppNumber = data.contacts.whatsApp.contact;
    }
    if (data.contacts.email && data.contacts.email.contact !== "") {
      email = data.contacts.email.contact;
    }
    if (data.contacts.website && data.contacts.website.contact !== "") {
      website = data.contacts.website.contact;
    }

    return `
    <div class="InputCon1">
      <div class="InputMiniCon2">
        <div class="InputMiniLabel">
          Numero de teléfono para llamadas
        </div>
        <div class="CreateListingImportantMessage">
          El número de llamadas debe ser del país de México, ya que ocupamos '+52' en
          todos los números.
        </div>
        <input class="InputMiniInput InputForFocusCheck" type="text" placeholder="0000000000" value="${callNumber}" />
      </div>
    </div>
    <div class="InputCon1">
      <div class="InputMiniCon2">
        <div class="InputMiniLabel">
          Numero de teléfono para WhatsApp
        </div>
        <div class="CreateListingImportantMessage">
          El número de WhatsApp debe ser del país de México, ya que ocupamos '+52' en
          todos los números.
        </div>
        <input class="InputMiniInput InputForFocusCheck" type="text" placeholder="0000000000" value="${whatsAppNumber}" />
      </div>
    </div>
    <div class="InputCon1">
      <div class="InputMiniCon2">
        <div class="InputMiniLabel">
          Correo electrónico
        </div>
        <input class="InputMiniInput InputForFocusCheck" type="text" placeholder="@gmail.com" value="${email}" />
      </div>
    </div>
    <div class="InputCon1">
      <div class="InputMiniCon2">
        <div class="InputMiniLabel">
          Página web
        </div>
        <input class="InputMiniInput InputForFocusCheck" type="text" placeholder="https://ejemplo.com" value="${website}" />
      </div>
    </div>`;
  }

  _infoAbout(data) {
    return `

    <textarea
    id="BusinessEditorInfoTextAreaCon1"
      class="BusinessEditorInfoTextAreaCon1 InputForFocusCheck"
      name=""
      id=""
      placeholder="Describe tu negocio aquí. Dile a la gente lo que quieres que sepan sobre tu negocio."
    >${data.about}</textarea>

  `;
  }

  // TODO Maybe use color sets later
  // {<div class="BusinessPageEditorColorsCon4">
  //   <div class="BusinessPageEditorColorsHeaderText">
  //     Elige un conjunto colores
  //   </div>
  // </div>
  // <div class="BusinessEditorColorGroupSelectionCon1A">
  //   <div
  //     class="BusinessEditorColorGroupSelectionCon1 BusinessEditorColorGroupSelectionCon1Active"
  //   >
  //     <div class="BusinessEditorColorGroupSelectionCon2">
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ffffff"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #e0e0e0"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #aeaeae"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #6d6d6d"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #2b2b2b"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #3a7d44"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ff7043"
  //       ></div>
  //     </div>
  //   </div>
  //   <div
  //     class="BusinessEditorColorGroupSelectionCon1"
  //   >
  //     <div class="BusinessEditorColorGroupSelectionCon2">
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ffffff"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #e0e0e0"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #aeaeae"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #6d6d6d"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #2b2b2b"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #3a7d44"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ff7043"
  //       ></div>
  //     </div>
  //   </div>
  //   <div
  //     class="BusinessEditorColorGroupSelectionCon1"
  //   >
  //     <div class="BusinessEditorColorGroupSelectionCon2">
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ffffff"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #e0e0e0"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #aeaeae"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #6d6d6d"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #2b2b2b"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #3a7d44"
  //       ></div>
  //       <div
  //         class="BusinessEditorColorGroupSelectionColor"
  //         style="background: #ff7043"
  //       ></div>
  //     </div>
  //   </div>
  // </div>}

  _colors(colors) {
    return `
<div class="BusinessPageEditorColorsCon4">
  <div class="BusinessPageEditorColorsHeaderText">
    Personaliza tus colores
  </div>
</div>
<div class="BusinessPageEditorColorsCon1">
  <div class="BusinessPageEditorColorsTitle">Negocio</div>
  <div class="BusinessPageEditorColorsCon2">
    <label
      for="colorPickerForOpining"
      data-color="${colors.headerPrimary}"
      data-for="headerPrimary"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Nombre de negocio</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.headerPrimary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-color="${colors.headerSecondary}"
      data-for="headerSecondary"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">
      Descripción corta de negocio
    </div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.headerSecondary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
  </div>
</div>
<div class="BusinessPageEditorColorsCon1">
  <div class="BusinessPageEditorColorsTitle">Listados</div>
  <div class="BusinessPageEditorColorsCon2">
    <label
      for="colorPickerForOpining"
      data-for="packTitles"
      data-color="${colors.packTitles}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Titulos de listas</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.packTitles};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="packTitlesHighlight"
      data-color="${colors.packTitlesHighlight}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">'Ver todo' de listas</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.packTitlesHighlight};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemBackgroundPrimary"
      data-color="${colors.itemBackgroundPrimary}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">
      Fondo de nombre de listado
    </div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemBackgroundPrimary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemBackgroundSecondary"
      data-color="${colors.itemBackgroundSecondary}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">
      Fondo de descripción de listado
    </div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemBackgroundSecondary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemTextPrimary"
      data-color="${colors.itemTextPrimary}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Nombre de listado</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemTextPrimary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemTextFour"
      data-color="${colors.itemTextFour}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Precio de listado</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemTextFour};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemTextSecondary"
      data-color="${colors.itemTextSecondary}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Descripción de listado</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemTextSecondary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemTextTerteriary"
      data-color="${colors.itemTextTerteriary}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Otro texto de listado</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemTextTerteriary};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemHighlight"
      data-color="${colors.itemHighlight}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">Resaltos en listados</div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemHighlight};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
    <label
      for="colorPickerForOpining"
      data-for="itemHighlightText"
      data-color="${colors.itemHighlightText}"
      class="BusinessPageEditorColorsCon3"
    >
    <div class="BusinessPageEditorColorsLabel">
      Texto de resalto en listados
    </div>
    <div class="BusinessPageEditorColorsCon3B">
      <div
        style="background-color: #${colors.itemHighlightText};"
        class="BusinessPageEditorColorsColorInEditor"
      ></div>
      <img height="20px" src="${iconColor}" alt="" />
      </div>
    </label>
  </div>
</div>


    `;
  }

  // FUNCTION Portion creator
  _helperPortionCreator(title, html) {
    return ` 
  <div class="BusinessPageEditorPortionCon1">
    <div class="BusinessPageEditorColorsCon4">
      <div class="BusinessPageEditorColorsHeaderText">
        ${title}
      </div>
    </div>
    <div class="BusinessPageEditorPortionCon3">${html}</div>
  </div>
    `;
  }

  _colorThemeTheme() {
    // TODO Need to only feature 6 colors and divide them.
    return `
<div class="ColorThemeCon1">
  <div class="ColorThemeText1">Professional</div>
  <div class="ColorThemeCon2">
    <div class="ColorThemeCon3">
      <div class="ColorThemeCon4"></div>
      <div class="ColorThemeCon4"></div>
      <div class="ColorThemeCon4"></div>
      <div class="ColorThemeCon4"></div>
      <div class="ColorThemeCon4"></div>
      <div class="ColorThemeCon4"></div>
    </div>
    <div class="White">
      <div class="Group">
        <div class="Vector"></div>
        <div class="Vector"></div>
      </div>
    </div>
  </div>
</div>
    `;
  }
}
export default new GenerateHtmlEditorTools();
