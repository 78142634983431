import FireBaseHelperOwn from "../firebase";
import GenerateHTML from "./generate-html.js";
import Spinner from "../spinner.js";
import Register from "../register/register.js";
// import startUp from "../main-start-up-screen/start-up.js";
// import handleAccount from "../register/handle-account.js";
import GENRALHELPERS from "../GENRAL-HELPERS.js";
import spinner from "../spinner.js";
import firebase from "../firebase";
import yourListings from "../main-menu/your-listings.js";
import accountProfileChange from "../main-menu/account-profile-change.js";
import DisplayBusinessOwn from "../business-page-own-veiw/busine-own.js";

let listingData = {
  listingName: "",
  listingMachineType: "",
  listingBrand: "",
  listingModel: "",
  listingCondition: "",
  listingYear: "",
  listingPrice: "",
  priorityLevelPoints: 1,
  listingCoin: "",
  listingDescription: "",
  listingTokens: [],
  onDiscount: {
    finalPrice: "",
    percent: "",
    amount: "",
  },
  listingMainImg: "",
  listingImgsIds: [],
  listingWhatsAppNumber: "",
  listingCallNumber: "",
};

class CreateListing {
  #machineBrandsOnCleared = false;
  #machineModelsOnCleared = false;
  #initialPreselectedSet = false;
  #prefixId = "";
  #publishIn = null;
  #isInEdit = false;
  #listingId = null;
  #businessId = null;
  #listingCreatedAt;
  #listingUpdatedAt;
  #newType = false;
  // #currenMachine;
  // #currenBrand;
  // #currenModel;
  // #machineTypes = [
  //   "tractor",
  //   "sembradora",
  //   "trilladora",
  //   "fertilizadora",
  //   "arado",
  //   "rastra",
  // ];

  // #brands = {
  //   tractor: ["JohnDeere", "Kubota", "CaseIH"],
  //   siembradora: ["JohnDeere", "Case", "CaseIH"],
  // };

  // #models = {
  //   tractor: {
  //     JohnDeere: ["Model 1025R", "Model 2032R", "Model 4052R"],
  //     Kubota: ["BX1880", "L3902", "M8"],
  //     Case: ["Farmall 75C", "Puma 240", "Magnum 310"],
  //   },
  //   siembradora: {
  //     Claas: ["LEXION 760", "TUCANO 430", "DOMINATOR 130"],
  //     JohnDeere: ["S760", "S780", "T560"],
  //     NewHolland: ["CR10.90", "CX8.90", "TC5.30"],
  //   },
  // };

  async _init(dataIn) {
    this.#initialPreselectedSet = false;
    this.#newType = false;
    let contactsInfo;
    this.#prefixId = GENRALHELPERS._getUniqueIdfunction();
    const account = firebase._getLatestCurrentUserData();

    if (!account) {
      Register._displayOptions("create_listing");
      return;
    } else {
      if (!account.whatsAppNumber) {
        accountProfileChange._open();
        alert(
          "Debes tener un perfil de vendedor para poder publicar listados."
        );
        return;
      }
      if (account.business) {
        const business = await firebase._getBusinessById(account.business);
        contactsInfo = business.contacts;
      }
      if (dataIn) {
        listingData = dataIn;
        this.#isInEdit = true;
        this.#listingId = dataIn.listingId;
        this.#businessId = dataIn.businessId;
        this.#listingCreatedAt = dataIn.createdAt;
        this.#listingUpdatedAt = dataIn.updatedAt;
      } else {
        listingData.listingName = "";
        listingData.listingMachineType = "";
        listingData.listingBrand = "";
        listingData.listingModel = "";
        listingData.listingCondition = "";
        listingData.listingYear = "";
        listingData.listingPrice = "";
        listingData.listingCoin = "";
        listingData.listingDescription = "";
        listingData.listingMainImg = "";
        listingData.listingImgsIds = [];
        listingData.listingWhatsAppNumber = "";
        listingData.listingCallNumber = "";
        this.#isInEdit = false;
        this.#listingId = null;
        this.#businessId = null;
        this.#listingCreatedAt = null;
        this.#listingUpdatedAt = null;
      }

      if (!listingData.onDiscount) {
        listingData["onDiscount"] = {
          finalPrice: listingData.listingPrice,
          amount: 0,
          percent: 0,
        };
      }
      console.log(this.#listingId);

      console.log(listingData);
      const container = document.getElementById("MainAppContentContainer");
      const content = GenerateHTML._display(this.#prefixId);
      const numberOfListingsAvailable = document.querySelector(
        ".numberOfListingsAvailable"
      );
      if (numberOfListingsAvailable) {
        numberOfListingsAvailable.remove();
      }

      container.insertAdjacentHTML("beforeend", content);
      this._imageHandle();
      this._events(this.#prefixId);
      this._eventsForOptions(this.#prefixId);
    }
  }

  _cancelCreateListing() {
    const con = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentAllCon`
    );
    console.log(con);
    con.remove();
  }

  async _handleSelectPublishProfile(selected) {
    this.#publishIn = null;

    return new Promise(async (resolve) => {
      if (this.#isInEdit && this.#businessId) {
        this.#publishIn = "business";
        resolve(true);
        return;
      }
      if (this.#isInEdit && !this.#businessId) {
        this.#publishIn = "account";
        resolve(true);
        return;
      }
      // Get DOM elements
      const SelectPublishProfileCon1SelectAccountName = document.getElementById(
        "SelectPublishProfileCon1SelectAccountName"
      );
      const SelectPublishProfileCon1SelectBusinessName =
        document.getElementById("SelectPublishProfileCon1SelectBusinessName");
      const SelectPublishProfileCon1 = document.getElementById(
        "SelectPublishProfileCon1"
      );
      const SelectPublishProfileCon1SelectAccount = document.getElementById(
        "SelectPublishProfileCon1SelectAccount"
      );
      const SelectPublishProfileCon1SelectBusiness = document.getElementById(
        "SelectPublishProfileCon1SelectBusiness"
      );
      const SelectPublishProfileCon1ButtonBack = document.getElementById(
        "SelectPublishProfileCon1ButtonBack"
      );
      const SelectPublishProfileCon1ButtonPublish = document.getElementById(
        "SelectPublishProfileCon1ButtonPublish"
      );
      const currentAccount = firebase._getLatestCurrentUserData();
      // Display the confirmation dialog
      SelectPublishProfileCon1.style.display = "";
      SelectPublishProfileCon1ButtonPublish.style.opacity = ".3";
      SelectPublishProfileCon1SelectAccountName.textContent =
        currentAccount.userName;

      if (currentAccount.business) {
        const business = await firebase._getAnyDocById(
          `businesses/${currentAccount.businessId}`
        );

        if (business) {
          SelectPublishProfileCon1SelectBusinessName.textContent =
            business.name;
          console.log(business.name);
          console.log(business);
          console.log(currentAccount);
        } else {
          SelectPublishProfileCon1SelectBusinessName.textContent =
            "Tu negocio en Mercado MAI";
        }
      }

      const setActive = (profileOrPage) => {
        const profileCon2 = document.getElementById(
          "SelectPublishProfileCon2SelectAccount"
        );
        const profileCon3 = document.getElementById(
          "SelectPublishProfileCon3SelectAccount"
        );
        const pageCon2 = document.getElementById(
          "SelectPublishProfileCon2SelectBusiness"
        );
        const pageCon3 = document.getElementById(
          "SelectPublishProfileCon3SelectBusiness"
        );

        // Ensure that the elements exist before trying to modify classList
        if (profileCon2 && profileCon3 && pageCon2 && pageCon3) {
          // Reset active classes
          SelectPublishProfileCon1SelectAccount.classList.remove(
            "SelectPublishProfileSelectActiveCon1"
          );
          profileCon2.classList.remove("SelectPublishProfileSelectActiveCon2");
          profileCon3.classList.remove("SelectPublishProfileSelectActiveCon3");

          SelectPublishProfileCon1SelectBusiness.classList.remove(
            "SelectPublishProfileSelectActiveCon1"
          );
          pageCon2.classList.remove("SelectPublishProfileSelectActiveCon2");
          pageCon3.classList.remove("SelectPublishProfileSelectActiveCon3");

          // Add active classes for selected option
          if (profileOrPage === "account") {
            SelectPublishProfileCon1SelectAccount.classList.add(
              "SelectPublishProfileSelectActiveCon1"
            );
            profileCon2.classList.add("SelectPublishProfileSelectActiveCon2");
            profileCon3.classList.add("SelectPublishProfileSelectActiveCon3");
            this.#publishIn = "account";
          } else if (profileOrPage === "business") {
            SelectPublishProfileCon1SelectBusiness.classList.add(
              "SelectPublishProfileSelectActiveCon1"
            );
            pageCon2.classList.add("SelectPublishProfileSelectActiveCon2");
            pageCon3.classList.add("SelectPublishProfileSelectActiveCon3");
            this.#publishIn = "business";
          }
          if (this.#publishIn) {
            SelectPublishProfileCon1ButtonPublish.style.opacity = "1";
            localStorage.setItem(
              "defaultPublishListingIn",
              JSON.stringify(this.#publishIn)
            );
          }
        } else {
          console.error("Some elements are missing from the DOM");
        }
      };
      setActive(selected);

      // Handle account selection
      SelectPublishProfileCon1SelectAccount.addEventListener("click", () => {
        setActive("account"); // Using the dataset value directly to select "profile"
      });

      // Handle business selection
      SelectPublishProfileCon1SelectBusiness.addEventListener("click", () => {
        setActive("business"); // Using the dataset value directly to select "page"
      });

      // Event listener for the "Back" button
      SelectPublishProfileCon1ButtonBack.addEventListener("click", () => {
        SelectPublishProfileCon1.style.display = "none";
        resolve(false); // Return false when the "Back" button is clicked
      });

      // Event listener for the "Publish" button
      SelectPublishProfileCon1ButtonPublish.addEventListener("click", () => {
        if (!this.#publishIn) {
          alert("Selecciona una opción.");

          return;
        }
        SelectPublishProfileCon1.style.display = "none";
        resolve(true); // Return true when the "Publish" button is clicked
      });
    });
  }

  _events(prefixId) {
    const CoinPicker = () => {
      const coinPickerCon = document.getElementById("CreateListingCoinPic");
      const coinMXN = document.getElementById("CreateListingCoinPicMXN");
      const coinUSD = document.getElementById("CreateListingCoinPicUSD");
      coinPickerCon.addEventListener("click", (e) => {
        coinPickerCon
          .closest(".CreateListingOptionsPicCon1")
          .querySelector(".CreateListingErrorMessage").textContent = "";
        const coin = e.target;
        if (coin.id === "CreateListingCoinPicMXN") {
          listingData.listingCoin = "MXN";
          coinPickerCon.dataset.coin = "MXN";
          coinUSD.classList.remove("CreateListingOptionsPicCon3Active");
          coinMXN.classList.add("CreateListingOptionsPicCon3Active");
        }
        if (coin.id === "CreateListingCoinPicUSD") {
          listingData.listingCoin = "USD";
          coinPickerCon.dataset.coin = "USD";
          coinUSD.classList.add("CreateListingOptionsPicCon3Active");
          coinMXN.classList.remove("CreateListingOptionsPicCon3Active");
        }
      });
    };
    CoinPicker();

    this._imageHandle(listingData.listingImgsIds);

    // TODO find out why the button do not work after reload.
    const CreateListingBtnPublish = document.getElementById(
      "CreateListingBtnPublish"
    );
    const CreateListingBtnCancel = document.getElementById(
      "CreateListingBtnCancel"
    );

    if (this.#isInEdit) {
      CreateListingBtnPublish.textContent = "Actualizar listado";
    } else {
      CreateListingBtnPublish.textContent = "Publicar listado";
    }

    CreateListingBtnPublish.addEventListener("click", async () => {
      this._handleListingPublish();
    });
    CreateListingBtnCancel.addEventListener("click", () => {
      this._cancelCreateListing(prefixId);
    });

    const CreateListingName = document.getElementById(
      `${prefixId}CreateListingName`
    );
    const CreateListingPrice = document.getElementById(
      `${prefixId}CreateListingPrice`
    );
    const CreateListingDiscountPercent = document.getElementById(
      `${prefixId}CreateListingDiscountPercent`
    );
    const CreateListingDiscountAmount = document.getElementById(
      `${prefixId}CreateListingDiscountAmount`
    );
    const CreateListingDiscountFinalPrice = document.getElementById(
      `${prefixId}CreateListingDiscountFinalPrice`
    );
    const CreateLisitngDescription = document.getElementById(
      `${prefixId}CreateListingDescription`
    );

    CreateListingName.addEventListener("keyup", (e) => {
      const targetValue = e.target.value.trim();
      listingData.listingName = targetValue;
      CreateListingName.closest(".CreateListingNameCon1").querySelector(
        ".CreateListingErrorMessage"
      ).textContent = "";
    });

    CreateListingPrice.addEventListener("keyup", (e) => {
      const targetValue = e.target.value;

      // Remove any non-numeric characters except periods
      const extractedNumbers = targetValue.replace(/[^\d.]/g, "");

      // Format the number with thousands separators
      const formattedPrice = formatToPriceWithThousands(extractedNumbers);

      // Update the input value with the formatted price
      e.target.value = formattedPrice;
      listingData.listingPrice = formattedPrice.replace(/,/g, "");
      CreateListingPrice.closest(
        ".CreateListingOptionsInputCon2"
      ).querySelector(".CreateListingErrorMessage").textContent = "";
      discountTakeCare("percent");
    });

    function formatToPriceWithThousands(value) {
      if (!value) return ""; // Handle empty input gracefully
      // Ensure there are no multiple decimals and format to two decimal places
      const parts = value.split(".");
      const integerPart = parts[0].replace(/^0+/, ""); // Remove leading zeros
      const decimalPart = parts[1] ? parts[1].slice(0, 2) : "";

      // Add thousands separators to the integer part
      const formattedInteger = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return decimalPart
        ? `${formattedInteger}.${decimalPart}`
        : formattedInteger;
    }

    const discountTakeCare = (calculateOn) => {
      const price =
        parseInt(
          CreateListingPrice.value.trim().replace(/,/g, "") // ✅ Removes commas
        ) || 0;
      const discountPercent =
        parseInt(CreateListingDiscountPercent.value.trim().replace(/,/g, "")) ||
        0;
      const discountAmount =
        parseInt(CreateListingDiscountAmount.value.trim().replace(/,/g, "")) ||
        0;
      const finalPrice =
        parseInt(
          CreateListingDiscountFinalPrice.value.trim().replace(/,/g, "")
        ) || 0;

      let calcdiscountPercent = discountPercent;
      let calcdiscountAmount = discountAmount;
      let calcfinalPrice = finalPrice;

      if (calculateOn === "percent") {
        const ad = parseFloat(price * (discountPercent / 100)).toFixed(2);
        calcdiscountAmount = ad;
        calcfinalPrice = parseFloat(price - ad).toFixed(2);

        CreateListingDiscountAmount.value =
          formatToPriceWithThousands(calcdiscountAmount);
        CreateListingDiscountFinalPrice.value =
          formatToPriceWithThousands(calcfinalPrice);
      }
      if (calculateOn === "amount") {
        const pd =
          price !== 0
            ? parseFloat((discountAmount / price) * 100).toFixed(2)
            : 0;
        calcfinalPrice = parseFloat(price - discountAmount).toFixed(2);
        calcdiscountPercent = pd;

        CreateListingDiscountPercent.value =
          formatToPriceWithThousands(calcdiscountPercent);
        CreateListingDiscountFinalPrice.value =
          formatToPriceWithThousands(calcfinalPrice);
      }
      if (calculateOn === "final") {
        const ad = parseFloat(price - finalPrice).toFixed(2);
        calcdiscountAmount = ad;
        const pd = price !== 0 ? parseFloat((ad / price) * 100).toFixed(2) : 0;
        calcdiscountPercent = pd;

        CreateListingDiscountPercent.value =
          formatToPriceWithThousands(calcdiscountPercent);
        CreateListingDiscountAmount.value =
          formatToPriceWithThousands(calcdiscountAmount);
      }

      listingData.onDiscount.finalPrice = parseInt(calcfinalPrice);
      listingData.onDiscount.percent = calcdiscountPercent;
      listingData.onDiscount.amount = parseInt(calcdiscountAmount);

      console.log(listingData);
    };

    const discountEvents = () => {
      CreateListingDiscountPercent.addEventListener("keyup", (e) => {
        const targetValue = e.target.value;
        const extractedNumbers = targetValue.replace(/[^\d.]/g, "");
        e.target.value = extractedNumbers;
        discountTakeCare("percent");
      });
      CreateListingDiscountAmount.addEventListener("keyup", (e) => {
        const targetValue = e.target.value;
        const extractedNumbers = targetValue.replace(/[^\d.]/g, "");
        const formattedPrice = formatToPriceWithThousands(extractedNumbers);
        e.target.value = formattedPrice;
        discountTakeCare("amount");
      });
      CreateListingDiscountFinalPrice.addEventListener("keyup", (e) => {
        const targetValue = e.target.value;
        const extractedNumbers = targetValue.replace(/[^\d.]/g, "");
        const formattedPrice = formatToPriceWithThousands(extractedNumbers);
        e.target.value = formattedPrice;
        discountTakeCare("final");
      });
    };

    discountEvents();

    CreateLisitngDescription.addEventListener("keyup", (e) => {
      const targetValue = e.target.value.trim();
      listingData.listingDescription = targetValue;
      CreateLisitngDescription.closest(
        ".CreateListingDescriptionCon1"
      ).querySelector(".CreateListingErrorMessage").textContent = "";
    });
  }

  async _handleListingPublish() {
    let validation;
    Spinner._displaySpinner("Validando el formulario");
    await new Promise((resolve) => {
      setTimeout(() => {
        validation = this._checkForm();
        resolve();
      }, 1000);
    });
    if (!validation) {
      Spinner._hideSpinner();
      return;
    }

    const currentAccount = firebase._getLatestCurrentUserData();
    if (currentAccount.business) {
      Spinner._hideSpinner();
      let loadedDefaultPublishListingInData;
      const loadedDefaultPublishListingIn = localStorage.getItem(
        "defaultPublishListingIn"
      );
      if (loadedDefaultPublishListingIn) {
        loadedDefaultPublishListingInData = JSON.parse(
          loadedDefaultPublishListingIn
        );
      } else {
        console.log("No array found in localStorage.");
      }

      const result = await this._handleSelectPublishProfile(
        loadedDefaultPublishListingInData
      );
      if (!result) {
        return;
      }
    } else {
      Spinner._hideSpinner();
      this.#publishIn = "account";
      console.log("No business for this user to select to publish in");
    }

    Spinner._displaySpinner("Subiendo imágenes");
    try {
      const imgs = await FireBaseHelperOwn._uploadImagesAndGetReferences(
        "uploaded-images-container"
      );
      listingData.listingMainImg = imgs[0];
      listingData.listingImgsIds = imgs;
    } catch (error) {
      console.error("Image upload failed:", error.message);
      alert("Hubo un error al subir los imagenes. Porfavor intente de nuevo.");
      Spinner._hideSpinner();
      return;
    }

    Spinner._displaySpinner("Creando listado");
    let metaData = {
      publishIn: this.#publishIn,
      isInEdit: this.#isInEdit,
      id: this.#listingId,
      createdAt: this.#listingCreatedAt,
      updatedAt: this.#listingUpdatedAt,
      newType: this.#newType,
    };
    let dataToSend = {
      listingData: listingData,
      metaData: metaData,
    };
    console.log(dataToSend);
    const resultUploadListing = await firebase._uploadNewListing(
      dataToSend,
      this.#isInEdit
    );
    console.log(resultUploadListing);

    if (resultUploadListing.data.status === "success") {
      if (resultUploadListing.data.publishedTo === "account") {
        if (resultUploadListing.data.listingStatus === "waitingPayment") {
          const payNow = confirm(
            `¿Pagar ahora? ${resultUploadListing.data.message}`
          );
          if (payNow) {
            spinner._displaySpinner("Un momento");
            setTimeout(() => {
              yourListings._open();
              spinner._hideSpinner();
            }, 1300);
          } else {
            alert(`Tu listado no será visible en Mercado MAI. 
            Primero tienes que hacer el pago.
            Para hacer el pago debes ir a 'menu', 'mis listados'
            y seleccionar el listado que nesecita el pago.`);
          }
        } else if (resultUploadListing.data.listingStatus === "active") {
          console.log("here");
          alert(`Éxito. ${resultUploadListing.data.message}`);
          if (this.#isInEdit) {
            if (this.#businessId) {
              DisplayBusinessOwn._init();
            } else {
              yourListings._open();
            }
          }
        } else {
          console.log("here");
          alert(resultUploadListing.data.message);
          if (this.#isInEdit) {
            if (this.#businessId) {
              DisplayBusinessOwn._init();
            } else {
              yourListings._open();
            }
          }
        }
      } else {
        console.log("here");
        alert(`Éxito. ${resultUploadListing.data.message}`);
        if (this.#isInEdit) {
          if (this.#businessId) {
            DisplayBusinessOwn._init();
          } else {
            yourListings._open();
          }
        }
      }
      this._cancelCreateListing();
      Spinner._hideSpinner();
    } else {
      alert(
        `Hubo un error al subir este listado. Error: ${resultUploadListing.data.message}`
      );
      Spinner._hideSpinner();
    }
  }

  _displayErrosInForm(errors) {
    const allParentCon = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentAllCon`
    );
    console.log(allParentCon);
    let scrolled = false;
    if (errors.length > 0) {
      alert("Por favor completa el formulario para publicar este listado.");
    }
    errors.forEach((error) => {
      const errorMessageCon = document.getElementById(
        `${this.#prefixId}CreateListingErrorMessage${error.what}`
      );
      if (!scrolled) {
        // Scroll into view
        const container = document.querySelector(".CreateListingCon2A"); // Scrollable container

        container.scrollTo({
          top: errorMessageCon.offsetTop - (container.offsetTop + 80), // Adjusts position relative to container
          behavior: "smooth",
        });

        scrolled = true;
      }

      console.log(error.what);
      errorMessageCon.textContent = error.message;
    });

    // const input=documet.
  }

  _checkForm() {
    let errors = [];

    const imagesPresent = this._hasImagesInContainer(
      "uploaded-images-container"
    );
    if (!imagesPresent) {
      errors.push({
        message:
          "Agrega unos imágenes a este listado. Puedes agregar hasta 6 imágenes.",
        what: "Images",
      });
    }

    if (listingData.listingName.trim() === "") {
      errors.push({
        message: "El nombre no puede estar vacío.",
        what: "Name",
      });
    } else {
      if (listingData.listingName.length < 8) {
        errors.push({
          message: "El nombre tiene que ser al menos 8 carácteres.",
          what: "Name",
        });
      }
    }
    if (listingData.listingMachineType.trim() === "") {
      errors.push({
        message: "Seleciona el tipo de máquina",
        what: "MachineType",
      });
    }
    if (listingData.listingBrand.trim() === "") {
      errors.push({
        message: "Seleciona una marca",
        what: "Brand",
      });
    }
    if (listingData.listingModel.trim() === "") {
      errors.push({
        message: "Seleciona un modelo",
        what: "Model",
      });
    }
    if (listingData.listingYear.trim() === "") {
      errors.push({ message: "Seleciona un año", what: "Year" });
    }
    if (listingData.listingCondition.trim() === "") {
      errors.push({ message: "Seleciona una condición", what: "Condition" });
    }
    if (listingData.listingPrice.trim() === "") {
      errors.push({
        message: "Pon un precio válido a este listado",
        what: "Price",
      });
    }
    if (listingData.listingCoin.trim() === "") {
      errors.push({
        message: "Selecciona la moneda del precio",
        what: "Coin",
      });
    }
    // if (listingData.listingWhatsAppNumber.trim() === "") {
    //   errors.push({
    //     message: "Agrega un número de WhatsApp a este listado",
    //     what: "WhatsAppNumber",
    //   });
    // } else if (
    //   isNaN(listingData.listingWhatsAppNumber.trim()) ||
    //   listingData.listingWhatsAppNumber.trim() === ""
    // ) {
    //   errors.push({
    //     message: "Este no es un número válido",
    //     what: "WhatsAppNumber",
    //   });
    // }
    // if (listingData.listingCallNumber.trim() === "") {
    //   errors.push({
    //     message: "Agrega un número de Llamadas a este listado",
    //     what: "CallingNumber",
    //   });
    // } else if (
    //   isNaN(listingData.listingCallNumber.trim()) ||
    //   listingData.listingCallNumber.trim() === ""
    // ) {
    //   errors.push({
    //     message: "Este no es un número válido",
    //     what: "CallingNumber",
    //   });
    // }
    if (listingData.listingDescription.trim() === "") {
      errors.push({
        message:
          "Agrega una descripción a este listado. Con más información es mejor.",
        what: "Description",
      });
    } else if (listingData.listingDescription.trim().split(" ").length < 8) {
      errors.push({
        message:
          "La descripción tiene que tener a lo menos 8 palabras. Con más información es mejor.",
        what: "Description",
      });
    }

    this._displayErrosInForm(errors);
    let validForm = true;
    if (errors.length > 0) {
      validForm = false;
    }
    return validForm;
  }

  _hasImagesInContainer(containerId) {
    const imagesContainer = document.getElementById(containerId);

    // Check if the container exists and has any children
    if (!imagesContainer || imagesContainer.children.length === 0) {
      return false;
    }

    return true;
  }

  _imageHandle(imgsArr) {
    // Drag-and-drop event handlers
    let draggedItem = null;
    console.log(imgsArr);
    const CreateListingImageOrganizeMessage = document.getElementById(
      "CreateListingImageOrganizeMessage"
    );
    const uploadTrigger = document.getElementById("upload-trigger");
    const fileInput = document.getElementById("image-upload-input");
    const imagesContainer = document.getElementById(
      "uploaded-images-container"
    );

    if (imgsArr) {
      console.log("yep");
      imgsArr.forEach((img) => {
        console.log("img here");
        const imageDiv = createImageDiv(img, "foto de listado");
        imagesContainer.appendChild(imageDiv);
      });
      return;
    }

    const MAX_IMAGES = 6; // Maximum allowed images

    // Set file input attributes
    fileInput.setAttribute("accept", "image/*"); // Accept only image files
    fileInput.setAttribute("multiple", "true"); // Allow multiple file selection

    // Trigger file input when clicking the upload area
    uploadTrigger.addEventListener("click", () => {
      uploadTrigger
        .closest(".CreateListingImagesCon1")
        .querySelector(".CreateListingErrorMessage").textContent = "";
      console.log("click");
      if (imagesContainer.children.length >= MAX_IMAGES) {
        alert(`You can only upload up to ${MAX_IMAGES} images.`);
        return;
      }
      fileInput.click();
    });

    // Handle file input changes
    fileInput.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);

      // Calculate remaining slots
      const remainingSlots = MAX_IMAGES - imagesContainer.children.length;

      // Limit files to remaining slots
      const limitedFiles = files.slice(0, remainingSlots);

      limitedFiles.forEach((file) => {
        if (!file.type.startsWith("image/")) {
          console.warn(`${file.name} is not an image.`);
          return;
        }

        const reader = new FileReader();

        // When the file is loaded
        reader.onload = (e) => {
          const imageUrl = e.target.result;

          // Create a new image container
          const imageDiv = createImageDiv(imageUrl, file.name);

          imagesContainer.appendChild(imageDiv);
        };

        reader.readAsDataURL(file);
      });

      // Warn if some files were ignored
      if (files.length > limitedFiles.length) {
        alert(
          `You selected ${files.length} files, but only ${remainingSlots} more images can be uploaded.`
        );
      }

      // Clear the file input value to allow re-selecting the same files
      fileInput.value = "";
      CreateListingImageOrganizeMessage.classList.remove(
        "CreateListingImagesAddImagesTextHidden"
      );
    });

    // Create image div with drag-and-drop functionality
    function createImageDiv(imageUrl, fileName) {
      const imageDiv = document.createElement("div");
      imageDiv.classList.add("CreateListingImagesImageCon1");
      imageDiv.setAttribute("draggable", "true"); // Make it draggable

      // Handle drag events
      imageDiv.addEventListener("dragstart", handleDragStart);
      imageDiv.addEventListener("dragover", handleDragOver);
      imageDiv.addEventListener("drop", handleDrop);
      imageDiv.addEventListener("dragend", handleDragEnd);
      imageDiv.addEventListener("dragleave", handleDragLeave);

      // Create the image element
      const img = document.createElement("img");
      img.src = imageUrl;
      img.alt = fileName;
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.objectFit = "cover";
      img.style.borderRadius = "8px";

      // Create the 'X' button
      const closeButton = document.createElement("button");
      closeButton.textContent = "X";
      closeButton.style.position = "absolute";
      closeButton.style.top = "4px";
      closeButton.style.right = "4px";
      closeButton.style.background = "rgba(0, 0, 0, 0.7)";
      closeButton.style.color = "#fff";
      closeButton.style.border = "none";
      closeButton.style.borderRadius = "50%";
      closeButton.style.width = "24px";
      closeButton.style.height = "24px";
      closeButton.style.cursor = "pointer";

      // Handle image removal
      closeButton.addEventListener("click", () => {
        imagesContainer.removeChild(imageDiv);
      });

      // Append elements
      imageDiv.appendChild(img);
      imageDiv.appendChild(closeButton);

      return imageDiv;
    }

    // // Drag-and-drop event handlers
    // let draggedItem = null;

    function handleDragStart(e) {
      draggedItem = this;
      setTimeout(() => {
        this.style.opacity = "0.5";
      }, 0);
    }

    function handleDragOver(e) {
      e.preventDefault(); // Allow drop
      if (this !== draggedItem) {
        this.classList.add("drop-target"); // Add a class to indicate the drop target
      }
    }

    function handleDrop(e) {
      e.preventDefault();
      this.classList.remove("drop-target");
      if (draggedItem !== this) {
        const container = imagesContainer;
        const items = Array.from(container.children);
        const draggedIndex = items.indexOf(draggedItem);
        const targetIndex = items.indexOf(this);

        if (draggedIndex < targetIndex) {
          container.insertBefore(draggedItem, this.nextSibling);
        } else {
          container.insertBefore(draggedItem, this);
        }
      }
    }

    function handleDragLeave() {
      this.classList.remove("drop-target"); // Remove the drop target indicator
    }

    function handleDragEnd() {
      this.style.opacity = "1";
      draggedItem = null;
    }
  }

  _eventsForOptions(prefixId) {
    this._setMachineTypes(prefixId);
    this._setMachineYears(prefixId);
    this._setMachineConditions(prefixId);
  }
  // Start Inovation with new selects

  _initiateSelectOptions = async (
    isMultiple,
    parrentId,
    parrentAllId,
    prefixId,
    optionsData
  ) => {
    const options = [];
    if (!optionsData) {
      return;
    } else if (!optionsData.length > 0) return;
    optionsData.forEach((option) => {
      options.push({
        id: option.id,
        label: GENRALHELPERS._formatReadableString(option.id),
      });
    });

    let selectedValues = [];
    let createdOptions = []; // To track options created by the user

    const parentAllConEl = document.getElementById(parrentAllId);
    const parentConEl = document.getElementById(parrentId);
    const selectedOptionsDiv = parentConEl.querySelector(`#selected-options`);
    const searchInput = parentConEl.querySelector(`#search-input`);
    const optionList = parentConEl.querySelector(`#option-list`);
    parentConEl.classList.add("SearchStartUpComponentCon10Clear");

    searchInput.addEventListener("click", () => {
      searchInput
        .closest(".SearchStartUpComponentCon8")
        .querySelector(".CreateListingErrorMessage").textContent = "";
    });

    // Function to render the selected options
    const renderSelectedOptions = () => {
      selectedOptionsDiv.innerHTML = "";
      selectedValues.forEach((value) => {
        const option =
          options.find((opt) => opt.id === value) ||
          createdOptions.find((opt) => opt.id === value);
        const optionDiv = document.createElement("div");
        optionDiv.classList.add("selected-option");
        optionDiv.innerHTML = `${option.label} ${
          option.isCreated ? "<span class='created-tag'>(Creado)</span>" : ""
        } <span class="remove" data-id="${option.id}">x</span>`;
        selectedOptionsDiv.appendChild(optionDiv);
      });

      // Attach remove functionality to each selected option
      parentConEl.querySelectorAll(".remove").forEach((removeButton) => {
        removeButton.addEventListener("click", (e) => {
          const id = e.target.dataset.id;
          selectedValues = selectedValues.filter((value) => value !== id);

          let Type;

          if (parentConEl.id.includes("Type")) {
            Type = "Type";

            listingData.listingMachineType = "";
            listingData.listingBrand = "";
            listingData.listingModel = "";
          } else if (parentConEl.id.includes("Brand")) {
            Type = "Brand";

            listingData.listingBrand = "";
            listingData.listingModel = "";
          } else if (parentConEl.id.includes("Model")) {
            Type = "Model";

            listingData.listingModel = "";
          } else if (parentConEl.id.includes("Year")) {
            Type = "Year";
            listingData.listingYear = "";
          } else if (parentConEl.id.includes("Condition")) {
            Type = "Condition";

            listingData.listingCondition = "";
          }
          this._callTheRightFunction(prefixId, Type);

          // Log when a value is removed
          renderSelectedOptions();
          renderOptionList();
        });
      });
    };

    // Function to render the option list based on search input
    const renderOptionList = () => {
      if (parrentId.includes("Brand") && this.#machineBrandsOnCleared) {
        return;
      }
      if (parrentId.includes("Model") && this.#machineModelsOnCleared) {
        return;
      }
      const query = searchInput.value.toLowerCase();
      const filteredOptions = options
        .concat(createdOptions)
        .filter(
          (option) =>
            option.label.toLowerCase().includes(query) &&
            !selectedValues.includes(option.id)
        );

      optionList.innerHTML = ""; // Clear the current list

      if (filteredOptions.length > 0) {
        filteredOptions.forEach((option) => {
          const optionItem = document.createElement("div");
          optionItem.classList.add("option-item");
          optionItem.textContent = option.label;
          optionItem.addEventListener("click", () => {
            if (isMultiple) {
              if (!selectedValues.includes(option.id)) {
                selectedValues.push(option.id);
              }
            } else {
              selectedValues = [option.id];
            }

            let Type;

            console.log(option.id);

            if (parentConEl.id.includes("Type")) {
              Type = "Type";
              listingData.listingMachineType = option.id;
              listingData.listingBrand = "";
              listingData.listingModel = "";
            } else if (parentConEl.id.includes("Brand")) {
              Type = "Brand";
              listingData.listingBrand = option.id;
              listingData.listingModel = "";
            } else if (parentConEl.id.includes("Model")) {
              Type = "Model";
              listingData.listingModel = option.id;
            } else if (parentConEl.id.includes("Year")) {
              Type = "Year";
              listingData.listingYear = option.id;
            } else if (parentConEl.id.includes("Condition")) {
              Type = "Condition";
              listingData.listingCondition = option.id;
            }
            this._callTheRightFunction(prefixId, Type);

            renderSelectedOptions();
            renderOptionList();
            searchInput.value = "";
          });
          optionList.appendChild(optionItem);
        });
      } else if (query.trim() !== "") {
        const createOption = document.createElement("div");
        createOption.classList.add("create-option");
        createOption.textContent = `Crear nueva opción: "${query}"`;
        createOption.addEventListener("click", () => {
          const optionIdForHere = query.toLowerCase().split(" ").join("-"); // Unique ID for created options
          const newOption = {
            id: optionIdForHere,
            label: GENRALHELPERS._formatReadableString(query),
            isCreated: true,
          };
          this.#newType = true;
          console.log(`User created and selected: ${newOption.label}`); // Log the created and selected value
          let Type;

          if (parentConEl.id.includes("Type")) {
            Type = "Type";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: Type`);
            listingData.listingMachineType = optionIdForHere;
            listingData.listingBrand = "";
            listingData.listingModel = "";
          } else if (parentConEl.id.includes("Brand")) {
            Type = "Brand";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: Brand`);
            listingData.listingBrand = optionIdForHere;
            listingData.listingModel = "";
          } else if (parentConEl.id.includes("Model")) {
            Type = "Model";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: model`);
            listingData.listingModel = optionIdForHere;
          } else if (parentConEl.id.includes("Year")) {
            Type = "Year";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: year`);
            listingData.listingYear = optionIdForHere;
          } else if (parentConEl.id.includes("Condition")) {
            Type = "Condition";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: condition`);
            listingData.listingCondition = optionIdForHere;
          }
          this._callTheRightFunction(prefixId, Type);
          createdOptions.push(newOption);

          if (isMultiple) {
            // Allow multiple selections
            selectedValues.push(newOption.id);
          } else {
            // Single selection: Replace the current selection
            selectedValues = [newOption.id];
          }

          renderSelectedOptions();
          renderOptionList();
          searchInput.value = "";
        });

        optionList.appendChild(createOption);
      }

      optionList.classList.add("active");
    };

    // Handle the search input
    searchInput.addEventListener("input", renderOptionList);

    // Show option list when input is focused
    searchInput.addEventListener("focus", () => {
      renderOptionList();
      searchInput.placeholder = "Buscar opciones";
      optionList.classList.add("active");
    });

    // Hide option list when input loses focus or user clicks outside
    parentAllConEl.addEventListener("click", (e) => {
      if (!searchInput.contains(e.target) && !optionList.contains(e.target)) {
        optionList.classList.remove("active");
      }
    });

    // Prevent closing the dropdown when clicking on the options or removing selected options
    optionList.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("option-item") ||
        e.target.classList.contains("create-option")
      ) {
        if (isMultiple) {
          e.stopPropagation(); // Prevent closing the dropdown when an option is clicked
        }
      }
    });

    selectedOptionsDiv.addEventListener("click", (e) => {
      if (e.target.classList.contains("remove")) {
        e.stopPropagation(); // Prevent closing the dropdown when the "x" is clicked
      }
    });
    if (
      listingData.listingBrand &&
      listingData.listingModel &&
      !this.#initialPreselectedSet
    ) {
      console.log("these here");
      this._setMachineBrand(prefixId);
      this._setMachineModel(prefixId);
      this.#initialPreselectedSet = true;
      this._setOtherData();
    } else {
      console.log("not set");
    }
  };

  _callTheRightFunction = (prefixId, Type) => {
    console.log("called with:", prefixId, Type);
    console.log("data now:", listingData);
    const machineSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineType`
    );
    const brandSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineBrand`
    );
    const modelSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineModel`
    );
    if (Type === "Type") {
      brandSelect.querySelector("#selected-options").innerHTML = "";
      brandSelect.querySelector("#option-list").innerHTML = "";

      this.#machineBrandsOnCleared = true;
      this.#machineModelsOnCleared = true;

      brandSelect.classList.remove("SearchStartUpComponentCon10Clear");
      modelSelect.classList.remove("SearchStartUpComponentCon10Clear");
      brandSelect.querySelector("#search-input").placeholder = "Selecionar";
      modelSelect.querySelector("#search-input").placeholder = "Selecionar";

      // SearchStartUpComponentCon10Clear

      modelSelect.querySelector("#selected-options").innerHTML = "";
      modelSelect.querySelector("#option-list").innerHTML = "";
      if (!listingData.listingMachineType) return;
      this._setMachineBrand(prefixId);
    }
    if (Type === "Brand") {
      this.#machineModelsOnCleared = true;
      modelSelect.classList.remove("SearchStartUpComponentCon10Clear");

      modelSelect.querySelector("#selected-options").innerHTML = "";
      modelSelect.querySelector("#option-list").innerHTML = "";
      modelSelect.querySelector("#search-input").placeholder = "Selecionar";

      if (!listingData.listingBrand) return;
      this._setMachineModel(prefixId);
    }
  };
  _setMachineTypes = async (prefixId) => {
    const SearchStartUpComponentSelectMachineType = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineType`
    );
    SearchStartUpComponentSelectMachineType.querySelector(
      "#search-input"
    ).value = listingData.listingMachineType;
    spinner._displaySpinner("Cargando información");
    const machineData = await firebase._getMachineData("machineTypes");
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineType`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineData
    );
    spinner._hideSpinner();
  };
  _setMachineBrand = async (prefixId) => {
    const SearchStartUpComponentSelectMachineBrand = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineBrand`
    );
    SearchStartUpComponentSelectMachineBrand.querySelector(
      "#search-input"
    ).value = listingData.listingBrand;

    spinner._displaySpinner("Cargando marcas");
    let machineDataForUse;
    const machineData = await firebase._getMachineData(
      `machineTypes/${listingData.listingMachineType}/brands`
    );
    if (machineData.length < 1) {
      machineDataForUse = [{ id: "marca" }];
    } else {
      machineDataForUse = machineData;
    }
    this.#machineBrandsOnCleared = false;
    this.#machineModelsOnCleared = false;
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineBrand`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineDataForUse,
      listingData.listingBrand
    );

    spinner._hideSpinner();
  };
  _setMachineModel = async (prefixId) => {
    const SearchStartUpComponentSelectMachineModel = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineModel`
    );
    SearchStartUpComponentSelectMachineModel.querySelector(
      "#search-input"
    ).value = listingData.listingModel;
    spinner._displaySpinner("Cargando modelos");
    let machineDataForUse;
    const machineData = await firebase._getMachineData(
      `machineTypes/${listingData.listingMachineType}/brands/${listingData.listingBrand}/models`
    );

    if (machineData.length < 1) {
      machineDataForUse = [{ id: "modelo" }];
    } else {
      machineDataForUse = machineData;
    }

    this.#machineModelsOnCleared = false;
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineModel`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineDataForUse,
      listingData.listingModel
    );
    spinner._hideSpinner();
  };
  _setMachineYears = async (prefixId) => {
    const SearchStartUpComponentSelectMachineYear = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineYear`
    );
    SearchStartUpComponentSelectMachineYear.querySelector(
      "#search-input"
    ).value = listingData.listingYear;

    const years = GENRALHELPERS._generateYearsArray(1990);
    const stringYearsOptions = [];

    years.forEach((year) => {
      stringYearsOptions.push({ id: year.toString() });
    });
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineYear`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      stringYearsOptions,
      listingData.listingYear
    );
  };
  _setMachineConditions = async (prefixId) => {
    const SearchStartUpComponentSelectMachineCondition =
      document.getElementById(
        `${prefixId}SearchStartUpComponentSelectMachineCondition`
      );
    SearchStartUpComponentSelectMachineCondition.querySelector(
      "#search-input"
    ).value = listingData.listingCondition;

    const conditions = ["nuevo", "usado"];
    const stringConditionOptions = [];

    conditions.forEach((condition) => {
      stringConditionOptions.push({ id: condition.toString() });
    });
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineCondition`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      stringConditionOptions,
      listingData.listingCondition
    );
  };
  _setOtherData() {
    const coinPickerCon = document.getElementById("CreateListingCoinPic");
    const coinMXN = document.getElementById("CreateListingCoinPicMXN");
    const coinUSD = document.getElementById("CreateListingCoinPicUSD");
    const CreateListingPrice = document.getElementById(
      `${this.#prefixId}CreateListingPrice`
    );
    const CreateListingName = document.getElementById(
      `${this.#prefixId}CreateListingName`
    );
    const CreateListingDiscountPercent = document.getElementById(
      `${this.#prefixId}CreateListingDiscountPercent`
    );
    const CreateListingDiscountAmount = document.getElementById(
      `${this.#prefixId}CreateListingDiscountAmount`
    );
    const CreateListingDiscountFinalPrice = document.getElementById(
      `${this.#prefixId}CreateListingDiscountFinalPrice`
    );
    const CreateListingDescription = document.getElementById(
      `${this.#prefixId}CreateListingDescription`
    );
    console.log(listingData.onDiscount);

    CreateListingName.value = listingData.listingName;
    CreateListingPrice.value = listingData.listingPrice;
    CreateListingDescription.textContent = listingData.listingDescription;
    CreateListingDiscountPercent.value = listingData.onDiscount.percent;
    CreateListingDiscountAmount.value = listingData.onDiscount.amount;
    CreateListingDiscountFinalPrice.value = listingData.onDiscount.finalPrice;

    if (listingData.listingCoin === "MXN") {
      listingData.listingCoin = "MXN";
      coinPickerCon.dataset.coin = "MXN";
      coinUSD.classList.remove("CreateListingOptionsPicCon3Active");
      coinMXN.classList.add("CreateListingOptionsPicCon3Active");
    }
    if (listingData.listingCoin === "USD") {
      listingData.listingCoin = "USD";
      coinPickerCon.dataset.coin = "USD";
      coinUSD.classList.add("CreateListingOptionsPicCon3Active");
      coinMXN.classList.remove("CreateListingOptionsPicCon3Active");
    }
  }
}
export default new CreateListing();
