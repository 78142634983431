import firebase from "../firebase";
import generateHtml from "./generate-html";

import iconInProcess from "../../../Icons/icons-wallet/in-process.png";
import iconSuccess from "../../../Icons/icons-wallet/success.png";
import iconSuccessMini from "../../../Icons/icons-wallet/success-mini.png";
import iconError from "../../../Icons/icons-wallet/error.png";
import iconErrorMini from "../../../Icons/icons-wallet/error-mini.png";
import nav from "../home-screen/nav";
import miniScreen from "../mini-screen/mini-screen";
import register from "../register/register";
import spinner from "../spinner";
import { toQueryRef } from "firebase/data-connect";
import GENRALHELPERS from "../GENRAL-HELPERS";
import orders from "../orders/orders";
import yourListings from "../main-menu/your-listings";
import termsConditions from "../terms-conditions";

class wallet {
  #moneyInEventsInitialized;
  #walletOverlayConfirmOrder;
  #walletActive = false;
  #accountState;
  #alreadlyInitialized;
  #orderToConfirmPay = null;
  #useCardForPayment = true;
  constructor() {}

  _eventsOnPendingOrders() {
    console.log("called");

    const WalletCon12BPendingPaymentsCon = document.getElementById(
      "WalletCon12BPendingPaymentsCon"
    );

    if (!WalletCon12BPendingPaymentsCon) {
      console.error("Element 'WalletCon12BPendingPaymentsCon' not found.");
      return;
    }

    // Add click event listener to the container
    WalletCon12BPendingPaymentsCon.addEventListener("click", async (e) => {
      // Get the exact clicked element
      const targetClick = e.target;
      console.log("Clicked element:", targetClick);

      // Get the closest parent with the class 'WalletConPedingOrder1'
      const MainOrderCon = targetClick.closest(".WalletConPedingOrder1");

      const WalletCon25OrderButtonDelete = targetClick.closest(
        ".WalletCon25OrderButtonDelete"
      );
      const WalletCon25OrderButtonView = targetClick.closest(
        ".WalletCon25OrderButtonView"
      );
      const WalletCon25OrderButtonPay = targetClick.closest(
        ".WalletCon25OrderButtonPay"
      );

      // Handle specific button actions
      if (WalletCon25OrderButtonDelete) {
        const MainOrderConId = MainOrderCon?.dataset.id;
        const deleteConfirm = confirm(
          "¿Quiere borrar este pedido de tus pedidos pendientes?"
        );
        if (!deleteConfirm) return;
        this._removeOrderById(MainOrderConId);
      }

      if (WalletCon25OrderButtonView) {
        spinner._displaySpinner("Cargando tu pedido");
        const MainOrderConId = MainOrderCon?.dataset.id;
        if (!MainOrderConId) {
          spinner._hideSpinner();
          return;
        }
        const data = await firebase._getAnyDocById(
          `orderDrafts/${MainOrderConId}`
        );

        console.log(data);
        yourListings._open();
        orders._start(data.items, data.moreData.cupon, MainOrderConId);
      }

      if (WalletCon25OrderButtonPay) {
        const MainOrderConId = MainOrderCon?.dataset.id;
        const MainOrderConAmount = MainOrderCon?.dataset.amount;

        this._setConfirmPayOrder(MainOrderConId, MainOrderConAmount);
      }
    });
  }

  _removeOrderById(removeId) {
    let RealOrderArr = [];
    const gottenArr = localStorage.getItem("pendingOrders");
    if (gottenArr) {
      RealOrderArr = JSON.parse(gottenArr);
      console.log(RealOrderArr);
    } else {
      console.log("No array found in localStorage.");
    }
    // WalletConPedingOrder1
    if (!Array.isArray(RealOrderArr)) {
      throw new Error("First argument must be an array.");
    }

    // Filter out the object with the matching id
    const updatedArr = RealOrderArr.filter((order) => order.id !== removeId);
    localStorage.setItem("pendingOrders", JSON.stringify(updatedArr));

    this._displayPendingOrders(updatedArr);
    // const account = firebase._getLatestCurrentUserData();
    // if (!account) {
    //   register._displayOptions(
    //     "open_wallet",
    //     "Una cuenta es requerida para acceder a tu wallet."
    //   );
    //   firebase._onSnapShotForWallet(account.wallet);
    // }

    return updatedArr;
  }

  _displayPendingOrders(orders) {
    let html = ``;
    orders.forEach((order) => {
      html += generateHtml._pendingOrders(order);
    });

    const WalletCon12BPendingPaymentsCon = document.getElementById(
      "WalletCon12BPendingPaymentsCon"
    );
    WalletCon12BPendingPaymentsCon.innerHTML = html;
    this._eventsOnPendingOrders();
  }

  _setConfirmPayOrder(orderId, amount, openedFromWallet) {
    console.log(orderId, amount);
    this.#orderToConfirmPay = {
      orderId: orderId,
      amount: amount,
    };
    console.log(openedFromWallet);
    if (openedFromWallet) {
      this._removeOrderById(openedFromWallet);
    }
    this._open();
  }

  async _open() {
    this.#walletActive = false;
    spinner._hideSpinner();
    nav._changeNav("Wallet");
    const account = firebase._getLatestCurrentUserData();
    if (!account) {
      register._displayOptions(
        "open_wallet",
        "Una cuenta es requerida para acceder a tu wallet."
      );
      return;
    }

    if (account.wallet) {
      this.#walletActive = true;
    }

    console.log("opening wallet");
    this.#accountState = firebase._getLatestCurrentUserData();
    console.log(this.#accountState);
    this._clearContainer();
    this._displayRightNavWindow();
    this._setContent();
    if (this.#walletActive) {
      firebase._onSnapShotForWallet(account.wallet);
      // await this._setWalletData(account);
    } else {
      this._openActivateWallet();
    }
  }

  async _setWalletData(wallet, logs) {
    console.warn("called here");
    const WalletCon1MainWalletConBalanceNow = document.getElementById(
      "WalletCon1MainWalletConBalanceNow"
    );
    const WalletCon1MainWalletConBalanceNowCents = document.getElementById(
      "WalletCon1MainWalletConBalanceNowCents"
    );
    spinner._displaySpinner("Cargando tu Wallet");
    // const wallet = await firebase._getAnyDocById(`wallets/${account.wallet}`);

    if (!wallet) {
      alert("Hubo un porblema al cargar los datos de tu Wallet.");
      spinner._hideSpinner();
      return;
    }

    // const logs = await firebase._getAllDocsFromPathByMostRecent(
    //   `wallets/${account.wallet}/logs`
    // );

    let cents = wallet.balanceCents;
    let amount = wallet.balance;

    WalletCon1MainWalletConBalanceNow.textContent =
      GENRALHELPERS._formatToPrice(amount);
    WalletCon1MainWalletConBalanceNowCents.textContent = cents;

    if (this.#orderToConfirmPay) {
      this._openConfirmPayment(wallet);
    }
    this._displayLogs(logs);
    spinner._hideSpinner();

    let RealOrderArr = [];
    const gottenArr = localStorage.getItem("pendingOrders");
    if (gottenArr) {
      RealOrderArr = JSON.parse(gottenArr);
      console.log(RealOrderArr);
      this._displayPendingOrders(RealOrderArr);
    } else {
      console.log("No array found in localStorage.");
    }
  }

  _displayLogs(logs) {
    const WalletCon1MainWalletConLogsInsert = document.getElementById(
      "WalletCon1MainWalletConLogsInsert"
    );
    const WalletCon1MainWalletConLogsInsertViewMoreButton =
      document.getElementById(
        "WalletCon1MainWalletConLogsInsertViewMoreButton"
      );
    let html = ``;
    logs.forEach((log) => {
      html += generateHtml._logs(log);
    });

    WalletCon1MainWalletConLogsInsert.innerHTML = html;

    WalletCon1MainWalletConLogsInsertViewMoreButton.addEventListener(
      "click",
      () => {
        WalletCon1MainWalletConLogsInsert.classList.toggle(
          "WalletCon18MaxHeight"
        );
      }
    );
  }

  async _openActivateWallet() {
    const WalletConOverlayActivateWallet = document.getElementById(
      "WalletConOverlayActivateWallet"
    );
    const WalletConOverlayActivateWalletButtonCancel = document.getElementById(
      "WalletConOverlayActivateWalletButtonCancel"
    );
    const WalletConOverlayActivateWalletButtonActivate =
      document.getElementById("WalletConOverlayActivateWalletButtonActivate");
    const WalletConOverlayActivateWalletInputName = document.getElementById(
      "WalletConOverlayActivateWalletInputName"
    );
    const WalletConOverlayActivateWalletInputErrorMessage =
      document.getElementById(
        "WalletConOverlayActivateWalletInputErrorMessage"
      );

    WalletConOverlayActivateWallet.style.display = "";

    WalletConOverlayActivateWalletInputName.addEventListener("focus", () => {
      WalletConOverlayActivateWalletInputErrorMessage.textContent = "";
    });

    WalletConOverlayActivateWalletButtonCancel.addEventListener("click", () => {
      WalletConOverlayActivateWallet.style.display = "none";
    });
    WalletConOverlayActivateWalletButtonActivate.addEventListener(
      "click",
      async () => {
        const name = WalletConOverlayActivateWalletInputName.value.trim();
        if (!name) {
          WalletConOverlayActivateWalletInputErrorMessage.textContent =
            "Este nombre no es válido.";
          return;
        }
        console.log("Wallet created");
        spinner._displaySpinner("Estamos activando tu Wallet.");
        const activateWalletResults = await firebase._activateWallet({
          name: name,
        });
        if (activateWalletResults.data.status === "error") {
          alert(
            `Hubo un error en activar tu wallet. Intentalo de nuevo mas tarde. Error: ${activateWalletResults.data.message}`
          );
        }
        if (activateWalletResults.data.status === "success") {
          alert(
            `Has activado tu wallet con éxito. Ahora podrás hacer los pagos en Mercado MAI.`
          );
          this.#walletActive = true;
          this._open();
        }
        WalletConOverlayActivateWallet.style.display = "none";
        spinner._hideSpinner();
      }
    );
  }

  _setContent() {
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConWallet"
    );
    HomeScreenContenConForAll.style.paddingTop = "0px";
    HomeScreenContenConForAll.innerHTML = generateHtml._main();
    this.#moneyInEventsInitialized = false;
    this.#walletOverlayConfirmOrder = false;
    this._events();
  }

  _clearContainer() {
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConWallet"
    );
    HomeScreenContenConForAll.innerHTML = "";
  }

  _displayRightNavWindow() {
    const windows = document.querySelectorAll(
      ".MainAppConWithNavigationContentCon"
    );

    windows.forEach((window) => {
      if (window.id === "MainAppConWithNavigationContentConWallet") {
        window.style.display = "";
      } else {
        window.style.display = "none";
      }
    });
  }

  _displayPosibleMessage(error, message1, message2, data) {
    const WalletConOverlayConfirmPaymentAmount = document.getElementById(
      "WalletConOverlayConfirmPaymentAmount"
    );
    const WalletConOverlayConfirmPaymentButtonPayNow = document.getElementById(
      "WalletConOverlayConfirmPaymentButtonPayNow"
    );
    const WalletConOverlayConfirmPaymentButtonDeposit = document.getElementById(
      "WalletConOverlayConfirmPaymentButtonDeposit"
    );
    const WalletConOverlayConfirmPaymentIcon = document.getElementById(
      "WalletConOverlayConfirmPaymentIcon"
    );
    const WalletConOverlayConfirmPaymentMessage1 = document.getElementById(
      "WalletConOverlayConfirmPaymentMessage1"
    );
    const WalletConOverlayConfirmPaymentMessage2Con = document.getElementById(
      "WalletConOverlayConfirmPaymentMessage2Con"
    );
    const WalletConOverlayConfirmPaymentMessage2 = document.getElementById(
      "WalletConOverlayConfirmPaymentMessage2"
    );
    WalletConOverlayConfirmPaymentMessage1.textContent = message1;
    WalletConOverlayConfirmPaymentMessage2.textContent = message2;
    WalletConOverlayConfirmPaymentAmount.textContent = `$ ${GENRALHELPERS._formatToPrice(
      data.amount
    )}`;

    if (error) {
      WalletConOverlayConfirmPaymentMessage1.classList.remove(
        "Wallet2Text3Success"
      );
      WalletConOverlayConfirmPaymentMessage1.classList.add("Wallet2Text3Error");

      WalletConOverlayConfirmPaymentMessage2Con.classList.remove(
        "Wallet2Text3Success"
      );
      WalletConOverlayConfirmPaymentMessage2Con.classList.add(
        "WalletCon27Error"
      );
      WalletConOverlayConfirmPaymentIcon.src = iconErrorMini;
      WalletConOverlayConfirmPaymentButtonDeposit.style.display = "";
      WalletConOverlayConfirmPaymentButtonDeposit.querySelector(
        ".Wallet2Text6"
      ).textContent = `Ingresar $${GENRALHELPERS._formatToPrice(
        data.amountNeeded
      )} a tu wallet`;
    } else {
      WalletConOverlayConfirmPaymentMessage1.classList.remove(
        "Wallet2Text3Error"
      );
      WalletConOverlayConfirmPaymentMessage1.classList.add(
        "Wallet2Text3Success"
      );

      WalletConOverlayConfirmPaymentMessage2Con.classList.remove(
        "WalletCon27Error"
      );
      WalletConOverlayConfirmPaymentMessage2Con.classList.add(
        "WalletCon27Succes"
      );
      WalletConOverlayConfirmPaymentIcon.src = iconSuccessMini;
      WalletConOverlayConfirmPaymentButtonPayNow.style.display = "";
    }
  }

  async _openConfirmPayment(wallet) {
    nav._blockNav(true);
    const { orderId, amount } = this.#orderToConfirmPay;

    const WalletConOverlayConfirmPayment = document.getElementById(
      "WalletConOverlayConfirmPayment"
    );
    const WalletConOverlayConfirmPaymentButtonPayNow = document.getElementById(
      "WalletConOverlayConfirmPaymentButtonPayNow"
    );
    const WalletConOverlayConfirmPaymentButtonDeposit = document.getElementById(
      "WalletConOverlayConfirmPaymentButtonDeposit"
    );
    const WalletConOverlayConfirmPaymentButtonBack = document.getElementById(
      "WalletConOverlayConfirmPaymentButtonBack"
    );
    WalletConOverlayConfirmPayment.style.display = "";
    WalletConOverlayConfirmPaymentButtonPayNow.style.display = "none";
    WalletConOverlayConfirmPaymentButtonDeposit.style.display = "none";

    let RealOrderArr = [];
    const newOrder = {
      id: orderId,
      amount: amount,
    };
    const gottenArr = localStorage.getItem("pendingOrders");
    if (gottenArr) {
      RealOrderArr = JSON.parse(gottenArr);
    } else {
      console.log("No array found in localStorage.");
      RealOrderArr.push(newOrder);
      localStorage.setItem("pendingOrders", JSON.stringify(RealOrderArr));
    }

    console.log(newOrder);

    function doesOrderExist() {
      const exist = RealOrderArr.some((order) => order.id === orderId);
      console.log(exist);
      if (exist) return;
      RealOrderArr.push(newOrder);
      localStorage.setItem("pendingOrders", JSON.stringify(RealOrderArr));
    }
    doesOrderExist();

    const data = { amountNeeded: 0, balanceAfter: 0, amount: amount };
    let error = false;
    let message1 = ``;
    let message2 = ``;

    const balance = wallet.balance || 0; // Default to 0 if not provided
    let cents = wallet.balanceCents || 0; // Default to 0 if not provided
    if (cents < 10 && cents !== 0) {
      cents = "0" + cents; // Add leading zero for cents less than 10
    }

    const currentBalance = parseFloat(`${balance}.${cents}`);

    if (Number(amount) > currentBalance) {
      const amountNeeded = Number(amount) - currentBalance;
      data.amountNeeded = amountNeeded.toFixed(2);
      message1 = `Saldo insuficiente`;
      message2 = `Debes ingresar $${GENRALHELPERS._formatToPrice(
        amountNeeded
      )} a tu wallet para hacer este pago.`;
      error = true;
    } else {
      const balanceAfter = currentBalance - Number(amount);
      data.balanceAfter = balanceAfter.toFixed(2);
      message1 = `Saldo suficiente`;
      message2 = `$${GENRALHELPERS._formatToPrice(
        balanceAfter
      )} disponible en tu wallet despues de este pago.`;
      error = false;
    }
    this._displayPosibleMessage(error, message1, message2, data);

    nav._changeNav("Wallet");
    if (this.#walletOverlayConfirmOrder) return;
    WalletConOverlayConfirmPaymentButtonBack.addEventListener("click", () => {
      console.log("click");
      WalletConOverlayConfirmPayment.style.display = "none";
      this.#orderToConfirmPay = null;
      nav._blockNav();
    });
    WalletConOverlayConfirmPaymentButtonDeposit.addEventListener(
      "click",
      () => {
        console.log("click");
        this._openMoneyIn(data.amountNeeded);
        WalletConOverlayConfirmPayment.style.display = "none";
        nav._blockNav();
      }
    );

    WalletConOverlayConfirmPaymentButtonPayNow.addEventListener(
      "click",
      async () => {
        console.warn("WalletConOverlayConfirmPaymentButtonPayNow", "click");
        this._changePayStatus(
          "inProcess",
          "Pago en proceso.",
          "Estamos procesando tu pago."
        );
        const paymentResult = await firebase._processPaymentOnOrder({
          orderId: orderId,
        });
        console.log(paymentResult.data);
        if (!paymentResult.data) {
          console.error("Some unespected error");
          this._changePayStatus("error", "Error.", paymentResult.data.message);
          return;
        }
        if (paymentResult.data.status === "success") {
          this._changePayStatus(
            "success",
            "Pago exitoso.",
            paymentResult.data.message
          );
          this.#orderToConfirmPay = null;
          WalletConOverlayConfirmPayment.style.display = "none";
          this._removeOrderById(paymentResult.data.orderId);
          return;
        }
        if (paymentResult.data.status === "error") {
          this._changePayStatus(
            "error",
            "Pago fallido.",
            paymentResult.data.message
          );
          return;
        }
      }
    );
    this.#walletOverlayConfirmOrder = true;
  }

  _changePayStatus(status, message1, message2) {
    const WalletConOverlayPaymentStatus = document.getElementById(
      "WalletConOverlayPaymentStatus"
    );
    const WalletCon30Success = document.getElementById("WalletCon30Success");
    const WalletCon30SuccessTexts = WalletCon30Success.querySelector(
      ".WalletCon32Success"
    ).querySelectorAll("p");
    const WalletCon30ASuccessIcon = document.getElementById(
      "WalletCon30ASuccessIcon"
    );
    const WalletCon30ASuccessMessage1 = document.getElementById(
      "WalletCon30ASuccessMessage1"
    );
    const WalletCon30ASuccessMessage2 = document.getElementById(
      "WalletCon30ASuccessMessage2"
    );
    const WalletCon30ASuccessButton = document.getElementById(
      "WalletCon30ASuccessButton"
    );
    WalletConOverlayPaymentStatus.style.display = "";
    console.log("called");

    const shrinkAndExpand = () => {
      nav._blockNav(true);
      function shrink() {
        WalletCon30Success.style.width = "10px";
        WalletCon30ASuccessMessage1.textContent = " ";
        WalletCon30ASuccessMessage2.textContent = " ";
      }

      // Function to expand the width
      function expand() {
        WalletCon30Success.style.width = "100%";
        setTimeout(() => {
          WalletCon30ASuccessMessage1.textContent = message1;
          WalletCon30ASuccessMessage2.textContent = message2;
        }, 300);
      }

      // Example: Toggle width with delays
      shrink();
      setTimeout(expand, 600);
    };

    if (status === "inProcess") {
      WalletCon30Success.style.display = "";
      WalletCon30ASuccessButton.style.display = "none";
      shrinkAndExpand();
      setTimeout(() => {
        WalletCon30ASuccessIcon.src = iconInProcess;
        WalletCon30Success.classList.remove("WalletCon30SuccessSuccess");
        WalletCon30Success.classList.remove("WalletCon30SuccessError");
        WalletCon30Success.classList.add("WalletCon30SuccessProcess");

        WalletCon30SuccessTexts.forEach((text) => {
          text.classList.remove("WalletCon30SuccessTextWhite");
          text.classList.add("WalletCon30SuccessTextGreen");
        });
      }, 300);
    }
    if (status === "success") {
      WalletCon30Success.style.display = "";
      shrinkAndExpand();
      setTimeout(() => {
        WalletCon30ASuccessButton.style.display = "";
        WalletCon30ASuccessIcon.src = iconSuccess;
        WalletCon30Success.classList.add("WalletCon30SuccessSuccess");
        WalletCon30Success.classList.remove("WalletCon30SuccessError");
        WalletCon30Success.classList.remove("WalletCon30SuccessProcess");
        WalletCon30SuccessTexts.forEach((text) => {
          text.classList.add("WalletCon30SuccessTextWhite");
          text.classList.remove("WalletCon30SuccessTextGreen");
        });
      }, 300);
    }
    if (status === "error") {
      WalletCon30Success.style.display = "";
      shrinkAndExpand();
      setTimeout(() => {
        WalletCon30ASuccessButton.style.display = "";
        WalletCon30ASuccessIcon.src = iconError;

        WalletCon30Success.classList.remove("WalletCon30SuccessSuccess");
        WalletCon30Success.classList.add("WalletCon30SuccessError");
        WalletCon30Success.classList.remove("WalletCon30SuccessProcess");
        WalletCon30SuccessTexts.forEach((text) => {
          text.classList.add("WalletCon30SuccessTextWhite");
          text.classList.remove("WalletCon30SuccessTextGreen");
        });
      }, 300);
    }
    WalletCon30ASuccessButton.addEventListener("click", () => {
      console.log("click");
      WalletConOverlayPaymentStatus.style.display = "none";
      nav._blockNav();
    });
  }

  _openPayPal2(orderId) {
    miniScreen._display(
      "show",
      "Opciones de Ingresos",
      `
      <div id="PaypalButtonContainerMain">
      <div id="paypal-button-container"></div>
        <div id="PaypalButtonContainerMainButtonCancel"  style="background: white" class= "WalletCon30">
          <div class="Wallet2Text6B">Cancelar</div>
        </div>
      </div>`
    );
    const paypalCon = document.getElementById("paypal-button-container");
    if (!paypalCon) return;
    const PaypalButtonContainerMainButtonCancel = document.getElementById(
      "PaypalButtonContainerMainButtonCancel"
    );
    PaypalButtonContainerMainButtonCancel.addEventListener("click", () => {
      miniScreen._display("hide");
    });

    paypal
      .Buttons({
        // When the button is clicked, we are passing the orderId already created
        createOrder: async (data, actions) => {
          // Just return the existing orderId you have
          return orderId; // Return the pre-created orderId
        },

        // This method is triggered after the user approves the payment
        onApprove: async (data, actions) => {
          try {
            // Capture the payment directly using the existing orderId
            const details = await actions.order.capture();

            // Check if the payment was successfully completed
            if (details.status === "COMPLETED") {
              alert(
                "Pago con éxito! Puede tardar un momento para reflejar en tu saldo."
              );
              miniScreen._display("hide");
              this._closeMoneyIn();
              firebase._setNotificationOnWalletChange(
                "Ingreso Recibido",
                "Ya puedes usar tu saldo en tu wallet.",
                "Abrir Wallet",
                "openwallet"
              );
            } else {
              alert("Pago fallido");
              miniScreen._display("hide");
            }
          } catch (err) {
            console.error("Error capturing PayPal order:", err);
            alert(
              "Ocurio un error al hacer este pago. Intenta de nuevo mas tarde."
            );
            miniScreen._display("hide");
          }
        },

        // Optional: handle any errors during the process
        onError: (err) => {
          console.error("Error:", err);
          alert("An error occurred during the payment process.");
          miniScreen._display("hide");
        },
      })
      .render("#paypal-button-container");
  }
  _openPayPal(orderId, cardOrLogin) {
    miniScreen._display(
      "show",
      "Opciones de Ingresos",
      `
      <div id="PaypalButtonContainerMain">
        <div id="paypal-button-container"></div>
        <div id="PaypalButtonContainerMainButtonCancel" style="background: white" class="WalletCon30">
          <div class="Wallet2Text6B">Cancelar</div>
        </div>
      </div>`
    );

    const paypalCon = document.getElementById("paypal-button-container");
    if (!paypalCon) return;

    const cancelBtn = document.getElementById(
      "PaypalButtonContainerMainButtonCancel"
    );
    cancelBtn.addEventListener("click", () => {
      miniScreen._display("hide");
    });

    // Determine funding source based on cardOrLogin
    let fundingSource;
    if (cardOrLogin === "card") {
      fundingSource = paypal.FUNDING.CARD; // 💳 Card only
    } else if (cardOrLogin === "login") {
      fundingSource = paypal.FUNDING.PAYPAL; // 🔑 PayPal login only
    } else {
      console.error("Invalid cardOrLogin value:", cardOrLogin);
      return;
    }

    // Render PayPal button with the selected funding source
    paypal
      .Buttons({
        fundingSource: fundingSource, // ✅ Set the funding source dynamically
        createOrder: async () => orderId, // Return pre-created orderId

        onApprove: async (data, actions) => {
          try {
            const details = await actions.order.capture();
            if (details.status === "COMPLETED") {
              alert(
                "Pago con éxito! Puede tardar un momento para reflejar en tu saldo."
              );
              miniScreen._display("hide");
              this._closeMoneyIn();
              firebase._setNotificationOnWalletChange(
                "Ingreso Recibido",
                "Ya puedes usar tu saldo en tu wallet.",
                "Abrir Wallet",
                "openwallet"
              );
            } else {
              alert("Pago fallido");
              miniScreen._display("hide");
            }
          } catch (err) {
            console.error("Error capturing PayPal order:", err);
            alert(
              "Ocurrió un error al hacer este pago. Intenta de nuevo más tarde."
            );
            miniScreen._display("hide");
          }
        },

        onError: (err) => {
          console.error("Error:", err);
          alert("An error occurred during the payment process.");
          miniScreen._display("hide");
        },
      })
      .render("#paypal-button-container");
  }

  async _initializedPayPal(amount, what) {
    spinner._displaySpinner("Un momento");
    const orderRsult = await firebase._requestPayPalOrder({ amount: amount });

    if (orderRsult.data.status === "success") {
      this._openPayPal(orderRsult.data.orderId, what);
      console.log(orderRsult.data.orderId);
    } else if (orderRsult.data.status === "error") {
      alert("Hubo un error. Intente mas de nuevo mas tarde.");
    } else {
      alert("Hubo un error. Intente mas de nuevo mas tarde.");
    }

    spinner._hideSpinner();
  }

  _closeMoneyIn() {
    const WalletConOverlayMoneyIn = document.getElementById(
      "WalletConOverlayMoneyIn"
    );

    WalletConOverlayMoneyIn.style.display = "none";
    nav._blockNav();
  }

  _openMoneyIn(amount) {
    const currentAccountForLocal = firebase._getLatestCurrentUserData();
    // TODO Fix this here. Make it so the monney works again.
    console.log(currentAccountForLocal);
    if (
      !currentAccountForLocal ||
      currentAccountForLocal.userId !== "ZPww1k6WI6TfnGBlhIbHSByRbLI2"
    ) {
      alert("Hubo un error. Estamos trabajando para solucionarlo.");
      return;
    }

    nav._blockNav(true);
    const optionButtons = document.querySelectorAll(
      ".WalletConMoneyInButtonCon1"
    );

    const WalletConOverlayMoneyInInputAmount = document.getElementById(
      "WalletConOverlayMoneyInInputAmount"
    );
    const WalletConOverlayMoneyIn = document.getElementById(
      "WalletConOverlayMoneyIn"
    );
    const WalletConOverlayMoneyInButtonBack = document.getElementById(
      "WalletConOverlayMoneyInButtonBack"
    );
    const WalletConOverlayMoneyInButtonChangeAmount = document.getElementById(
      "WalletConOverlayMoneyInButtonChangeAmount"
    );
    const WalletConOverlayMoneyInErrorMessage = document.getElementById(
      "WalletConOverlayMoneyInErrorMessage"
    );

    WalletConOverlayMoneyIn.style.display = "";
    nav._changeNav("Wallet");

    const errorShow = (show) => {
      const WalletConMoneyIn5 = document.querySelector(".WalletConMoneyIn5");
      if (show) {
        WalletConOverlayMoneyInErrorMessage.style.display = "";
        WalletConMoneyIn5.classList.add("WalletConMoneyInButtonCon1NoneAct");
      } else {
        WalletConOverlayMoneyInErrorMessage.style.display = "none";
        WalletConMoneyIn5.classList.remove("WalletConMoneyInButtonCon1NoneAct");
      }
    };
    const onKeyUp = (amount, inputElement) => {
      console.log("onKeyUp called");

      // Remove non-numeric and non-decimal characters
      let value = amount.replace(/[^0-9.]/g, "");

      // Ensure only two digits after the decimal
      const decimalIndex = value.indexOf(".");
      if (decimalIndex !== -1) {
        const integerPart = value.slice(0, decimalIndex);
        const decimalPart = value.slice(decimalIndex + 1, decimalIndex + 3); // Max 2 decimal places
        value = `${integerPart}.${decimalPart}`;
      }

      // Format with commas for thousands
      const [integer, decimal] = value.split(".");
      const formattedInteger = integer
        ? integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "";
      value =
        decimal !== undefined
          ? `${formattedInteger}.${decimal}`
          : formattedInteger;

      // Update the input field with the formatted value
      inputElement.value = value;

      // Check if the numeric value is less than 10
      const numericValue = parseFloat(value.replace(/,/g, "")) || 0;
      if (numericValue < 10) {
        errorShow(true); // Show error
      } else {
        errorShow(); // Hide error
      }
    };
    if (amount) {
      WalletConOverlayMoneyInInputAmount.value = Number(amount);
      onKeyUp(amount.toString(), WalletConOverlayMoneyInInputAmount);
    }

    // Attach the keyup event listener to the input element

    if (this.#moneyInEventsInitialized) return;
    if (WalletConOverlayMoneyInInputAmount) {
      WalletConOverlayMoneyInInputAmount.addEventListener("keyup", (e) => {
        const value = e.target.value.trim();
        onKeyUp(value, e.target); // Pass the value and input element to onKeyUp
      });
    }
    WalletConOverlayMoneyInInputAmount.addEventListener("focus", () => {
      WalletConOverlayMoneyInButtonChangeAmount.style.display = "none";
    });
    WalletConOverlayMoneyInInputAmount.addEventListener("blur", () => {
      WalletConOverlayMoneyInButtonChangeAmount.style.display = "";
    });

    optionButtons.forEach((button) => {
      button.addEventListener("click", () => {
        console.log("click");
        console.log(button.dataset.option);
        if (
          button.dataset.option === "paypal" ||
          button.dataset.option === "card"
        ) {
          let what = "";
          if (button.dataset.option === "paypal") {
            what = "login";
          }
          if (button.dataset.option === "card") {
            what = "card";
          }

          const rawValue = WalletConOverlayMoneyInInputAmount.value.trim();
          const cleanedValue = rawValue.replace(/,/g, "");
          this._initializedPayPal(cleanedValue, what);
          console.log(cleanedValue);
        }
      });
    });

    WalletConOverlayMoneyInButtonBack.addEventListener("click", () => {
      console.log("click");
      WalletConOverlayMoneyIn.style.display = "none";
      nav._blockNav();
    });
    this.#moneyInEventsInitialized = true;
  }

  async _events() {
    const WalletCon1MainWalletCon = document.getElementById(
      "WalletCon1MainWalletCon"
    );
    const WalletConTermsConditionsNew = document.getElementById(
      "WalletConTermsConditionsNew"
    );

    WalletCon1MainWalletCon.addEventListener("click", async (e) => {
      const targetAction = e.target.closest(".Action");

      if (!targetAction) return;

      if (!this.#walletActive) {
        console.log("You have to active your wallet before you can use it.");
        this._openActivateWallet();
        // const walletActivationsResult = await firebase._activateWallet({
        //   name: "Ben",
        // });
        return;
      }
      if (targetAction.dataset.action === "open-deposit") {
        console.log("Should open deposit");

        this._openMoneyIn();
        // miniScreen._display("show", "Ingresar", "Ingresa dinero a tu Wallet.");
      }
      if (targetAction.dataset.action === "open-transfer") {
        console.log("Should open deposit");
        alert("Proximamente. Esta función aún no está lista");
      }
      if (targetAction.dataset.action === "open-withdraw") {
        console.log("Should open deposit");
        alert("Proximamente. Esta función aún no está lista");
      }
      if (targetAction.dataset.action === "open-more") {
        console.log("Should open deposit");
        alert("Proximamente. Esta función aún no está lista");
      }
    });
    WalletConTermsConditionsNew.addEventListener("click", () => {
      termsConditions._open();
    });
    // setTimeout(() => {
    //   this._changePayStatus(
    //     "inProcess",
    //     "Pago en proceso",
    //     "Espera un momento"
    //   );
    //   setTimeout(() => {
    //     this._changePayStatus(
    //       "success",
    //       "Pago exitoso",
    //       "El pago fué prosesado con éxito."
    //     );
    //     setTimeout(() => {
    //       this._changePayStatus(
    //         "error",
    //         "Pago fallido",
    //         "Hubo un error en hacer este pago."
    //       );
    //     }, 3000);
    //   }, 3000);
    // }, 3000);
  }
}

export default new wallet();
