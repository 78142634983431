import iconExit from "../../Icons/icons-white/exit.png";
import installApp from "./install-app";
import wallet from "./wallet/wallet";

class InAppNotification {
  constructor() {
    // this._showNotification("title", "body here", "Open Wallet", "openwallet");
  }

  _notificationAction(action) {
    console.warn(action);
    if (action === "openwallet") {
      wallet._open();
    }
    if (action === "installnow") {
      installApp._installNow();
    }
  }
  _showNotification(title, body, buttonLabel, buttonAction, showTime = 8000) {
    const notificationContainer = document.getElementById(
      "notification-container"
    );

    // Create the notification element
    const notification = document.createElement("div");
    notification.classList.add("notification");
    let actionButtonHtml = ``;
    if (buttonAction) {
      actionButtonHtml = `
        <div class="InnAppNotificationCon3">
            <div data-action="${buttonAction}"
                id="InnAppNotificationButtonAction"
                class="InnAppNotificationText4">
                ${buttonLabel}
            </div>
        </div>
        `;
    }

    // Add title and body
    notification.innerHTML = `
          <p class="InnAppNotificationText1">${title}</p>
          <p class="InnAppNotificationText2">${body}</p>
          <div class="InnAppNotificationCon2">
                ${actionButtonHtml}
                <div class="InnAppNotificationCon4">
                  <img height="20px" src="${iconExit}"/>
                </div>
            </div>
        `;

    // Append the notification to the container
    notificationContainer.appendChild(notification);

    // Show the notification by adding the 'show' class
    setTimeout(() => {
      notification.classList.add("show");
    }, 0);

    // Auto hide after 8 seconds
    setTimeout(() => {
      hideNotification(notification);
    }, showTime);

    const InnAppNotificationButtonAction = document.getElementById(
      "InnAppNotificationButtonAction"
    );
    if (InnAppNotificationButtonAction) {
      InnAppNotificationButtonAction.addEventListener("click", () => {
        const action = InnAppNotificationButtonAction.dataset.action;
        if (action) {
          this._notificationAction(action);
        }
      });
    }
    // Allow user to slide it up to dismiss
    notification.addEventListener("click", () => {
      hideNotification(notification);
    });

    // Function to hide the notification (slide it up)
    function hideNotification(notification) {
      notification.classList.add("hide");

      // Remove notification from the DOM after the animation ends
      notification.addEventListener("transitionend", () => {
        notification.remove();
      });
    }
  }
}

export default new InAppNotification();
