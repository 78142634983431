import AppLogo from "../../images/fav-big.png";
import iconShow from "../../../Icons/icons-charcoal-dark/eye-show.png";
import iconDone from "../../../Icons/icons-charcoal-dark/done.png";

//

//

class GenarateHtml {
  _options(title) {
    return `
      <div class="AccessCon1">
        <div class="AccessCon2">
            <div class="MiniScreenContentHeader">
            ${title}
            </div>
        </div>
        <div class="AccessCon3">
            <div class="AccessCon4">
            <button id="BtnOptionsLogIn" class="ButtonAction">Acceder a tu cuenta</button>
            <button id="BtnOptionsCreateAccount" class="ButtonGhots">Crear una cuenta</button>
            </div>
            <button id="BtnOptionsCancel" class="ButtonCancel">Cancelar</button>
          <div data-helpwith="accountOptions" class="WalletCon8 openHelp">
            <div class="WalletText4">¿Como sigo?</div>
          </div>
        </div>
      </div>
    `;
  }
  _logIn() {
    return `
        <div class="LogInCon1">
            <div class="LogInCon2">
                <div class="MiniScreenContentHeader">
                Introduce la información necesaria para acceder a tu cuenta
                </div>
            </div>
            <div class="LogInCon3">
                <div class="LogInCon4">
                <div class="InputCon1">
                    <p class="InputLabel">Correo electrónico</p>
                    <div class="InputCon2">
                    <input id="InputLogInEmailUserName" class="Input" type="text" placeholder="@gmail.com" />
                    </div>
                    <p class="InputMessage"></p>
                </div>
                <div class="InputCon1">
                    <p class="InputLabel">Contraseña</p>
                    <div class="InputCon2">
                    <input id="InputLogInPassword" class="Input" type="password" placeholder="********" />
                    <div class="InputShowHideBtn">
                        <img class="IconsImgInputShowHide" src="${iconShow}" alt="" />
                    </div>
                    </div>
                    <p class="InputMessage"></p>
                </div>
                <a class="OtherOptionTextBtn" id="passwordForgottenHref" href="https://wa.me/9967306118?text=Olvidé%20mi%20contraseña%20en%20Mercado%20MAI" target="_blank">
                </a>
                <div id="BtnLogInForgotPassword" class="OtherOptionTextBtn">Olvidé mi contraseña</div>
                </div>
                <div class="LogInCon5">
                <button id="BtnLogInCancel" class="ButtonCancel">Cancelar</button>
                <button id="BtnLogInLogIn" class="ButtonAction">Acceder</button>
                </div>
            </div>
            <div class="LogInCon6">
            <div data-helpwith="logIn" class="WalletCon8 openHelp">
              <div class="WalletText4">¿Como puedo acceder a mi cuenta?</div>
            </div>
            </div>
            <div class="LogInCon6">
                <div class="OtherOptionText">¿No tienes una cuenta aún?</div>
                <div id="BtnLogInCreateAccount" class="OtherOptionTextBtn">Regístrate aqui</div>
            </div>
        </div>
    `;
  }
  _createAccount() {
    return `
  <div class="CreateAccountCon1">
    <div class="CreateAccountCon2">
      <div class="MiniScreenContentHeader">
        Introduce la información necesaria para crear una cuenta
      </div>
    </div>
    <div class="CreateAccountCon3">
      <div class="CreateAccountCon4">
        <div class="InputCon1">
          <p class="InputLabel">Correo electrónico</p>
          <div class="InputCon2">
            <input
              id="InputCreateAccountEmail"
              class="Input"
              type="email"
              placeholder="@gmail.com"
            />
          </div>
          <p class="InputMessage"></p>
        </div>
        <div class="InputCon1">
          <p class="InputLabel">Contraseña</p>
          <div class="InputCon2">
            <input
              id="InputCreateAccountPassword"
              class="Input"
              type="password"
              placeholder="********"
            />
            <div class="InputShowHideBtn">
              <img class="IconsImgInputShowHide" src="${iconShow}" alt="" />
            </div>
          </div>
          <p class="InputMessage"></p>
        </div>
        <div class="InputCon1">
          <p class="InputLabel">Confirma tu contraseña</p>
          <div class="InputCon2">
            <input
              id="InputCreateAccountPasswordConfirm"
              class="Input"
              type="password"
              placeholder="********"
            />
            <div class="InputShowHideBtn">
              <img class="IconsImgInputShowHide" src="${iconShow}" alt="" />
            </div>
          </div>
          <p class="InputMessage"></p>
        </div>
      </div>
      <div
        id="CreateAccountAccepterms"
        data-accepted="false"
        class="CreateAccountCon9"
      >
        <div class="CreateAccountCon5">
          <div id="CreateAccountCon6Accept" class="CreateAccountCon6"></div>
          <div
            id="CreateAccountCon6Accepted"
            style="display: none"
            class="CreateAccountCon6Accepted"
          >
            <img
              class="CreateAccountCon6AcceptedIcon"
              src="${iconDone}"
              alt=""
            />
          </div>
          <p id="TermsAndConditionsInCreateAccount" class="TermsAndConditionsInCreateAccount">
            Acepto los condiciones y términos de esta plataforma
          </p>
        </div>
        <p id="termsAcceptedMessage" class="InputMessage"></p>
      </div>
      <div class="CreateAccountCon7">
        <button id="BtnCreateAccountCancel" class="ButtonCancel">
          Cancelar
        </button>
        <button id="BtnCreateAccountCreateAccount" class="ButtonAction">
          Siguiente
        </button>
      </div>
    </div>
    <div class="LogInCon6">
      <div data-helpwith="createAccount" class="WalletCon8 openHelp">
        <div class="WalletText4">¿Como puedo crear una cuenta?</div>
      </div>
    </div>
    <div class="CreateAccountCon8">
      <div class="OtherOptionText">¿Ya tienes una cuenta?</div>
      <div id="BtnCreateAccountLogIn" class="OtherOptionTextBtn">
        Accede aqui
      </div>
    </div>
  </div>

    `;
  }
  _confirmEmail(email) {
    return `
        <div class="ConfirmEmailCon1">
            <div class="ConfirmEmailCon2">
            <div class="MiniScreenContentHeader">
                Hemos enviado un correo a ${email} con un código de verificación.
            </div>
            </div>
            <div class="ConfirmEmailCon3">
            <div class="CodeInputCon1">
                <div class="InputCodeLabel">Introduce el código</div>
                <input
                    id="ConfirmEmailCodeInput"
                    class="CodeInput"
                    data-inputNumber="1"
                    type="text"
                    placeholder="--- ---"
                    maxlength="6"
                    inputmode="numeric"
                />
            </div>
                <button id="BtnConfirmEmailConfirmEmail" class="ButtonAction">Verificar
                </button>
            </div>
            <div class="ConfirmEmailCon5">
              <div class="OtherOptionText">¿No recibiste el código?</div>
              <div id="BtnConfirmEmailNoCode" class="OtherOptionTextBtn">Da click aqui para reenviar el código</div>
            </div>
            <div class="CreateAccountButtonChangeEmailCon1">
              <div id="BtnConfirmChangeEmail" class="CreateAccountButtonChangeEmailCon2">
                <div class="CreateAccountButtonChangeEmailText">Cambiar correo electrónico</div>
              </div>
            </div>
          <div class="LogInCon6">
            <div data-helpwith="createAccount" class="WalletCon8 openHelp">
              <div class="WalletText4">¿Que hago ahora?</div>
            </div>
          </div>
        </div>
    `;
  }
  _createAccountDone(message) {
    return `
        <div class="CreateAccountDoneCon1">
        <div class="CreateAccountDoneCon2">
            <div class="MiniScreenContentHeader">${message}</div>
            <div class="CreateAccountSecondText">Disfruta esta plataforma</div>
        </div>
        <div class="CreateAccountDoneCon3">
            <img class="AppLogo" src="${AppLogo}" alt="" />
            <button id="BtnCreateAccountDoneDone" class="ButtonAction">Da click aquí</button>
        </div>
        </div>
    `;
  }
}
export default new GenarateHtml();
