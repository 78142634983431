import iconExitDarkCharcoal from "../../../Icons/icons-charcoal-dark/exit.png";
import iconCamara from "../../../Icons/icons-charcoal-dark/camara.png";
import iconEditList from "../../../Icons/icons-charcoal-dark/edit-list.png";
import iconListed from "../../../Icons/icons-charcoal-dark/listed.png";
import iconGrayWhatsApp from "../../../Icons/icons-gray/whatsApp.png";
import iconGrayCall from "../../../Icons/icons-gray/call.png";
import iconGrayLocation from "../../../Icons/icons-gray/location.png";
import iconGrayEmail from "../../../Icons/icons-gray/email.png";
import iconGrayWebsite from "../../../Icons/icons-gray/website.png";
import iconColor from "../../../Icons/icons-white/color.png";
import iconCurrentLocation from "../../../Icons/icons-blue/current-location.png";
import iconTargetLocation from "../../../Icons/icons-blue/target-location.png";

import firebase from "../firebase";

class GenerateHtml {
  _initialPopulation(html, data, colors) {
    return `
<div id="MainContainerBusinessPageBuild" class="MainContainerBusinessPageBuild">
  <div class="BusinessPageVisualizerCon1">
    <div class="BusinessEditorBCon1A">
      <div class="BusinessEditorBCon1">
        <div id="BusinessPageEditorButtonCancel" class="BusinessEditorBCon2">
          <img height="20px" src="${iconExitDarkCharcoal}"/>
        </div>
        <div id="BusinessPageEditorButtonCreatePage" class="BusinessEditorBCon3">
          <div class="BusinessEditorBText2">Publicar esta página</div>
          <img height="20px" src="${iconListed}"/>
        </div>
      </div>
      <div class="BusinessEditorBCon3B">
        <div class="BusinessEditorBCon4">
          <div id="BusinessEditorBCon9EditorToolsShowHideButton" class="BusinessEditorBCon5">
            <div class="BusinessEditorBText3">Editor de páginas</div>
          </div>
          <div class="BusinessEditorBCon6">
            <!-- <div class="BusinessEditorBCon7">
              <div class="BusinessEditorBText4">Mercado MAI</div>
            </div> -->
            <div data-helpwith="createBusinessPage" class="WalletCon8 openHelp">
              <div class="WalletText4">Ayuda para crear mi página de negocio</div>
            </div>
          </div>
        </div>
        <div class="BusinessEditorBCon8">
          <div id="BusinessEditorBCon9EditorTools" class="BusinessEditorBCon9 BusinessEditorBCon9Expanded">
          <div class="BusinessEditorBCon9B">
            <div class="BusinessEditorBCon10">
              <div class="BusinessInfoPortionLinkCon1B">
                <label id="BusinessEditorBCon9EditorToolsButtonBackgroungImg" for="BusinessPageEditorSelectImgBackground" class="BusinessEditorBCon11">
                  <img height="20px" src="${iconCamara}"/>
                  <div class="BusinessEditorBText5">Escoge una imagen de fondo de negocio</div>
                </label>
                <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
              </div>
              <div class="BusinessInfoPortionLinkCon1B">
                <label id="BusinessEditorBCon9EditorToolsButtonProfileImg" for="BusinessPageEditorSelectImgProfile" class="BusinessEditorBCon11">
                  <img height="20px" src="${iconCamara}"/>
                  <div class="BusinessEditorBText5">Escoge una imagen de perfil de negocio</div>
                </label>
                <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
              </div>
            </div>
            <div class="BusinessEditorBCon12">
              <div id="BusinessEditorBCon13ButtonEditColors" class="BusinessEditorBCon13">
                <img height="16px" src="${iconColor}"/>
                <div class="BusinessEditorBText5">Editar colores de página</div>
              </div>
              <div id="BusinessEditorBCon13ButtonEditLists" class="BusinessEditorBCon13">
                <img height="16px" src="${iconEditList}"/>
                <div class="BusinessEditorBText5">Editar listas</div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div id="BusinessPageVisualizerConForInsert" class="BusinessPageVisualizerCon3"></div>
  </div>
  <div class="BusinessPageEditorCon1 BusinessPageEditorCon1Hidden">
    <div class="BusinessPageEditorCon2">
      <div class="BusinessPageEditorListingsApearButtonHideMoreTools BusinessPageEditorListingsApearButtonCon1">
        Cerrar
      </div>
      <div class="BusinessPageEditorConSilderUopDownCon1">
        <div class="BusinessPageEditorConSilderUopDownCon2"></div>
      </div>
         <div class="BusinessPageEditorListingsApearButtonHideMoreTools BusinessPageEditorListingsApearButtonCon2">
        Listo
      </div>
    </div>
    <div class="BusinessPageEditorCon3">
      <div id="BusinessPageEditorCon4" class="BusinessPageEditorCon4"></div>
    </div>
  </div>
</div>

        `;
  }

  async _visualizerPopulateHeader(
    data,
    colors,
    edit,
    backgroudImgChange,
    profileImgChange
  ) {
    let backgroundImg = data.backgroundImg;
    let profileImg = data.profileImg;

    if (edit) {
      if (!backgroudImgChange) {
        backgroundImg = data.backgroundImg;
        // backgroundImg = await firebase._getImageUrl(data.backgroundImg);
      }
      if (!profileImgChange) {
        profileImg = data.profileImg;
        // profileImg = await firebase._getImageUrl(data.profileImg);
      }
    }

    return `
<div id="SectionIndicatedBusinessEditorBusinessHeaderCon1"></div>
<div
  id="BusinessEditorBusinessHeaderCon1"
  class="BusinessEditorBusinessHeaderCon1"
>
  <img
    id="BusinessEditorBusinessHeaderCon6BackgroundImgCon"
    class="BusinessEditorBusinessHeaderCon6ImgBackground"
    src="${backgroundImg}"
    alt=""
  />
  <div class="BusinessEditorBusinessHeaderCon2">
    <div class="BusinessEditorBusinessHeaderCon3">
      <div class="BusinessEditorBusinessHeaderCon4">
        <div class="BusinessEditorBusinessHeaderCon5">
          <div class="BusinessEditorBusinessHeaderCon10">
            <div class="BusinessInfoPortionLinkCon1B">
              <div
                id="BusinessEditorInputBusinessName"
                contenteditable="true"
                style="color: #${colors.headerPrimary}"
                class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessEditorBusinessHeaderBusinessName BusinessEditorInputBusinesTextEditableView"
              >
                ${data.name}
              </div>
              <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
            </div>
            <div class="BusinessInfoPortionLinkCon1B">
              <div
                id="BusinessEditorInputBusinessShortDescription"
                contenteditable="true"
                style="color: #${colors.headerSecondary}"
                class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessEditorBusinessHeaderBusinessShortDescription"
              >
                ${data.shortDescription}
              </div>
              <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
            </div>
          </div>
        </div>
        <div class="BusinessEditorBusinessHeaderCon6">
          <img
            id="BusinessEditorBusinessHeaderCon6ProfileImgCon"
            class="BusinessEditorBusinessHeaderCon6Img"
            src="${profileImg}"
            alt=""
          />
        </div>
      </div>
      <div class="BusinessEditorBusinessHeaderCon7">
        <div class="BusinessEditorBusinessHeaderCon8">
          <div class="BusinessEditorBusinessHeaderButtonCon1">
            <div class="BusinessEditorBusinessHeaderButtonCon2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.905 14.52 11.0007 14.2827 11 14V10C11 9.71667 10.904 9.47933 10.712 9.288C10.52 9.09667 10.2827 9.00067 10 9C9.71733 8.99933 9.48 9.09533 9.288 9.288C9.096 9.48067 9 9.718 9 10V14C9 14.2833 9.096 14.521 9.288 14.713C9.48 14.905 9.71733 15.0007 10 15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667932 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                  fill="#${colors.headerPrimary}"
                />
              </svg>
            </div>
            <div
              style="color: #${colors.headerPrimary}"
              class="BusinessEditorBusinessHeaderButtonLabel"
            >
              Info
            </div>
          </div>
          <div class="BusinessEditorBusinessHeaderButtonCon1">
            <div class="BusinessEditorBusinessHeaderButtonCon2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
              >
                <path
                  d="M20.7093 16.6718C19.9458 15.9026 18.0967 14.7801 17.1996 14.3277C16.0313 13.7393 15.9351 13.6912 15.0168 14.3734C14.4043 14.8287 13.9971 15.2354 13.2802 15.0825C12.5634 14.9296 11.0056 14.0676 9.64167 12.7081C8.27769 11.3485 7.36565 9.7457 7.21228 9.03131C7.05891 8.31691 7.47239 7.91453 7.92336 7.30061C8.55895 6.43526 8.51087 6.29104 7.96759 5.12282C7.54402 4.2142 6.38871 2.38255 5.61657 1.62297C4.79059 0.807138 4.79059 0.951363 4.25836 1.17251C3.82505 1.35477 3.40936 1.57636 3.01651 1.8345C2.24726 2.34553 1.82032 2.77003 1.52176 3.40799C1.22319 4.04594 1.08905 5.54155 2.63092 8.34239C4.17278 11.1432 5.25454 12.5754 7.49354 14.808C9.73254 17.0406 11.4542 18.241 13.9711 19.6525C17.0846 21.3962 18.2789 21.0563 18.9188 20.7582C19.5587 20.4601 19.9852 20.0371 20.4972 19.2679C20.756 18.8757 20.9781 18.4605 21.1607 18.0276C21.3824 17.4973 21.5266 17.4973 20.7093 16.6718Z"
                  stroke="#${colors.headerPrimary}"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
            <div
              style="color: #${colors.headerPrimary}"
              class="BusinessEditorBusinessHeaderButtonLabel"
            >
              Llamar
            </div>
          </div>
          <div class="BusinessEditorBusinessHeaderButtonCon1">
            <div class="BusinessEditorBusinessHeaderButtonCon2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M17.2519 2.91005C16.3304 1.98416 15.2329 1.25002 14.0233 0.750414C12.8137 0.250812 11.5163 -0.00426316 10.2067 5.3895e-05C4.71927 5.3895e-05 0.246906 4.45004 0.246906 9.91003C0.246906 11.66 0.709218 13.36 1.57354 14.86L0.166504 20L5.44289 18.62C6.90018 19.41 8.53837 19.83 10.2067 19.83C15.6942 19.83 20.1665 15.38 20.1665 9.92003C20.1665 7.27004 19.1313 4.78004 17.2519 2.91005ZM10.2067 18.15C8.71927 18.15 7.26199 17.75 5.9856 17L5.6841 16.82L2.54842 17.64L3.38259 14.6L3.18158 14.29C2.355 12.9771 1.91618 11.4593 1.91525 9.91003C1.91525 5.37004 5.63384 1.67005 10.1967 1.67005C12.4077 1.67005 14.4881 2.53005 16.0459 4.09004C16.8174 4.85391 17.4287 5.76258 17.8445 6.76337C18.2603 7.76415 18.4722 8.83712 18.468 9.92003C18.4881 14.46 14.7695 18.15 10.2067 18.15ZM14.7494 11.99C14.4982 11.87 13.272 11.27 13.0509 11.18C12.8198 11.1 12.659 11.06 12.4881 11.3C12.3173 11.55 11.8449 12.11 11.7042 12.27C11.5635 12.44 11.4127 12.46 11.1615 12.33C10.9102 12.21 10.1062 11.94 9.16148 11.1C8.41777 10.44 7.9253 9.63003 7.77455 9.38003C7.63384 9.13003 7.75445 9.00003 7.8851 8.87003C7.99565 8.76003 8.13636 8.58003 8.25696 8.44003C8.37756 8.30003 8.42782 8.19003 8.50822 8.03003C8.58862 7.86004 8.54842 7.72004 8.48812 7.60004C8.42782 7.48004 7.9253 6.26004 7.7243 5.76004C7.52329 5.28004 7.31224 5.34004 7.16148 5.33004H6.67907C6.50822 5.33004 6.24691 5.39004 6.01575 5.64004C5.79465 5.89004 5.15143 6.49004 5.15143 7.71004C5.15143 8.93003 6.0459 10.11 6.16651 10.27C6.28711 10.44 7.9253 12.94 10.4178 14.01C11.0107 14.27 11.473 14.42 11.8349 14.53C12.4278 14.72 12.9705 14.69 13.4027 14.63C13.8851 14.56 14.8801 14.03 15.0811 13.45C15.2921 12.87 15.2921 12.38 15.2218 12.27C15.1514 12.16 15.0007 12.11 14.7494 11.99Z"
                  fill="#${colors.headerPrimary}"
                />
              </svg>
            </div>
            <div
              style="color: #${colors.headerPrimary}"
              class="BusinessEditorBusinessHeaderButtonLabel"
            >
              WhatsApp
            </div>
          </div>
          <div class="BusinessEditorBusinessHeaderButtonCon1">
            <div class="BusinessEditorBusinessHeaderButtonCon2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.39687 14.7937C5.32954 14.7937 3.58009 14.0776 2.14851 12.6452C0.716928 11.2129 0.000759255 9.46344 6.02106e-07 7.39687C-0.000758051 5.3303 0.715411 3.58084 2.14851 2.14851C3.5816 0.716169 5.33106 0 7.39687 0C9.46268 0 11.2125 0.716169 12.6464 2.14851C14.0802 3.58084 14.796 5.3303 14.7937 7.39687C14.7937 8.23139 14.661 9.01849 14.3954 9.75818C14.1299 10.4979 13.7696 11.1522 13.3144 11.7212L19.6871 18.0939C19.8957 18.3025 20 18.568 20 18.8905C20 19.2129 19.8957 19.4784 19.6871 19.6871C19.4784 19.8957 19.2129 20 18.8905 20C18.568 20 18.3025 19.8957 18.0939 19.6871L11.7212 13.3144C11.1522 13.7696 10.4979 14.1299 9.75818 14.3954C9.01849 14.661 8.23139 14.7937 7.39687 14.7937ZM7.39687 12.5178C8.81935 12.5178 10.0286 12.0201 11.0248 11.0247C12.0209 10.0294 12.5185 8.8201 12.5178 7.39687C12.517 5.97364 12.0193 4.76472 11.0248 3.77013C10.0302 2.77553 8.82086 2.27748 7.39687 2.27596C5.97288 2.27444 4.76396 2.7725 3.77013 3.77013C2.77629 4.76776 2.27824 5.97667 2.27596 7.39687C2.27368 8.81707 2.77174 10.0264 3.77013 11.0247C4.76852 12.0231 5.97743 12.5208 7.39687 12.5178Z"
                  fill="#${colors.headerPrimary}"
                />
              </svg>
            </div>
            <div
              style="color: #${colors.headerPrimary}"
              class="BusinessEditorBusinessHeaderButtonLabel"
            >
              Buscar
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    `;
  }

  _visualizerPopulateListingsSection(html, data, colors) {
    return `
      <div id="ListingsExampleDisplayCon1" class="ListingsExampleDisplayCon1">
    <div class="ListingsExampleDisplayCon2">
      <div style="color: #${colors.packTitles}" class="ListingsExampleDisplayHeaderTitle">
        ${data.name}
      </div>
      <div style="color: #${colors.packTitlesHighlight}" class="ListingsExampleDisplayHeaderSeeAll">
        Ver todo
      </div>
    </div>
    <div class="ListingsExampleDisplayCon3">${html}</div>
  </div>
    `;
  }

  _visualizerPopulateListing(colors) {
    return `
  <div
    class="ListingVeriticalCon1"
  >
    <div class="ListingExampleVeriticalCon2">
      <img class="BusinessEditorBusinessHeaderImportantIconExit" src="${iconCamara}" alt="" />
    </div>
    <div
      style="background: #${colors.itemBackgroundSecondary}; "
      class="ListingVeriticalCon3"
    >
      <div class="ListingVeriticalCon4">
        <div
          style="background: #${colors.itemBackgroundPrimary};"
          class="ListingVeriticalCon5"
        >
          <div style="color: #${colors.itemTextPrimary};" class="ListingVeriticalTitle">
            Nombre de listado
          </div>
        </div>
        <div class="ListingVeriticalCon6">
          <div class="ListingVeriticalCon7">
            <div class="ListingVeriticalCon8">
              <div class="ListingVeriticalCon9">
                <div
                  style="background: #${colors.itemHighlight};"
                  class="ListingVeriticalCon10"
                  >
                  <div
                    style="color: #${colors.itemHighlightText};"
                    class="ListingVeriticalOnDiscount"
                  >
                    En oferta 4% ↓
                  </div>
                </div>
                <div
                  style="color: #${colors.itemTextFour};"
                  class="ListingVeriticalPrice"
                > 25,000 USD </div>
              </div>
              <div
                style="color: #${colors.itemTextFour};"
                class="ListingVeriticalCondition"
              >
                Usado
              </div>
            </div>
            <div
              style="color: #${colors.itemHighlight};"
              class="ListingVeriticalPriceBeforeText"
              >
              <div class="Antes">Antes</div>
              <div
                style="color: #${colors.itemHighlight};"
                class="ListingVeriticalPriceBeforePrice"
              >26,000 USD </div>
            </div>
          </div>
          <div
            style="color: #${colors.itemTextSecondary};"
            class="ListingVeriticalDescription"
          >
            Descripción de listado. Mas información de la maquina.
          </div>
        </div>
      </div>
      <div class="ListingVeriticalCon12">
        <div class="ListingDisplayDateCon">
          <p
            class="ListingDisplayDateText"
            style="color: #${colors.itemTextTerteriary};"
          > Hoy, 8:45 a. m.</p>
        </div>
        <div class="ListingMiniVeriticalCon12">
          <div
            class="ListingDisplayViewsText"
            style="color: #${colors.itemTextTerteriary};"
          >
            1.3k
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 8 8"
            fill="none"
          >
            <path
              d="M7.46885 5.36328L7.46807 5.3625L7.46729 5.36172C7.12275 4.63281 6.58916 4.25781 5.879 4.25781C5.16885 4.25781 4.63525 4.63359 4.28994 5.36172V5.3625C4.24775 5.45234 4.24775 5.55703 4.28994 5.64687C4.63525 6.375 5.16885 6.75 5.879 6.75C6.58916 6.75 7.12275 6.37422 7.46807 5.64609C7.51025 5.55625 7.51025 5.45234 7.46885 5.36328ZM5.879 6.25C5.39385 6.25 5.03994 6.01562 4.77666 5.50391C5.03916 4.99219 5.39385 4.75781 5.879 4.75781C6.36416 4.75781 6.71807 4.99219 6.98135 5.50391C6.71885 6.01562 6.36416 6.25 5.879 6.25Z"
            fill="#${colors.itemTextTerteriary}"
            />
            <path
              d="M5.44531 5.50781C5.44531 5.62384 5.49141 5.73512 5.57345 5.81717C5.6555 5.89922 5.76678 5.94531 5.88281 5.94531C5.99884 5.94531 6.11012 5.89922 6.19217 5.81717C6.27422 5.73512 6.32031 5.62384 6.32031 5.50781C6.32031 5.39178 6.27422 5.2805 6.19217 5.19845C6.11012 5.11641 5.99884 5.07031 5.88281 5.07031C5.76678 5.07031 5.6555 5.11641 5.57345 5.19845C5.49141 5.2805 5.44531 5.39178 5.44531 5.50781ZM1.0625 1.8125H6.5625V3.78906H7.125V1.5C7.125 1.36172 7.01328 1.25 6.875 1.25H0.75C0.611719 1.25 0.5 1.36172 0.5 1.5V5.5625C0.5 5.70078 0.611719 5.8125 0.75 5.8125H3.5V5.25H1.0625V1.8125Z"
            fill="#${colors.itemTextTerteriary}"
            />
            <path
              d="M5.66348 2.64136L5.37598 2.35386C5.35176 2.32964 5.31191 2.32964 5.2877 2.35386L3.85176 3.79136L3.1791 3.11792C3.15488 3.0937 3.11504 3.0937 3.09082 3.11792L1.96348 4.24526C1.93926 4.26948 1.93926 4.30933 1.96348 4.33354L2.25098 4.62104C2.2752 4.64526 2.31504 4.64526 2.33926 4.62104L3.13457 3.82573L3.80723 4.49917C3.83145 4.52339 3.87129 4.52339 3.89551 4.49917L5.66348 2.72964C5.68848 2.70542 5.68848 2.66558 5.66348 2.64136Z"
            fill="#${colors.itemTextTerteriary}"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  
      `;
  }

  _visualizerPopulateBusinessInfoMain(html) {
    return `
  <div id="SectionIndicatedBusinessInfoCon1" class="BusinessInfoCon1">
    <div class="BusinessInfoCon2">
      <div class="BusinessInfoHeaderText">
        Información de este negocio
      </div>
    </div>
    <div class="BusinessInfoCon3">${html}</div>
  </div>
    `;
  }
  _visualizerPopulateBusinessInfoPortion(title, html) {
    return ` 
  <div id="BusinessInfoPortionCon1" class="BusinessInfoPortionCon1">
    <div class="BusinessInfoPortionCon2">
      <div class="BusinessInfoPortionHeaderText">
        ${title}
      </div>
    </div>
    ${html}
  </div>
    `;
  }
  _visualizerPopulateBusinessInfoPlace(data) {
    return `
<div id="BusinessInfoLocationCon1" class="BusinessInfoLocationCon1">
  <div class="BusinessPageEditorPortionCon3">
    <div class="BusinessPageEditorInfoCon2">
      <label for="BusinessPageEditorLocationInput">
        <img
          id="BusinessPageEditorOpenMapButton"
          height="20px"
          src="${iconGrayLocation}"
          alt=""
        />
      </label>
      <div class="BusinessInfoPortionLinkCon1B">
        <div class="BusinessEditorInputBusinessInfoLabel Wallet2Text3 Wallet2Text3Dark">Ubicación de tu negocio</div>
        <input
          type="text"
          placeholder="Busca una ubicacion"
          class="BusinessEditorInputBusinessForKeyUpListeners"
          id="BusinessPageEditorLocationInput"
          value="${data.name}"
        />
        <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
      </div>
      <div
        id="BusinessEditorLocationMyLocationCon1"
        style="display: none"
        class="BusinessEditorLocationMyLocationCon1"
      >
        <img
          id="BusinessPageEditorOpenMapButtonLocator"
          height="20px"
          src="${iconCurrentLocation}"
          alt=""
        />
        <div class="BusinessEditorLocationMyLocationText">Ubicar</div>
      </div>
    </div>
    <div style="display: none" id="BusinessPageEditorMapContainer1">
      <div
        id="BusinessPageEditorMapContainer"
        class="BusinessPageEditorMapContainer"
      ></div>
      <div id="BusinessPageEditorMapCenterMarker">
        <img
          id="BusinessPageEditorOpenMapButtonLocator"
          height="24px"
          src="${iconTargetLocation}"
          alt=""
        />
      </div>
    </div>
    <div class="InputMiniCon2">
      <div class="InputMiniCon2">
        <div class="InputMiniLabel">Referencias</div>
        <div
          id="BusinessEditorInputBusinessLocationDescription"
          contenteditable="true"
          class="InputMiniInput BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners"
        >${data.description}</div>
        <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
      </div>
    </div>
  </div>
</div>
    `;
  }
  _visualizerPopulateBusinessInfoContact(data) {
    let call = "Número de llamadas";
    let whatsApp = "Número de WhatsApp";
    let email = "Correo electrónico";
    let website = "Sitio web";
    let contactWays = ``;

    if (data.call?.contact) {
      call = data.call?.contact;
    }
    contactWays += `
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayCall}" alt="" />
        <div class="BusinessInfoPortionLinkCon1B">
          <div class="BusinessEditorInputBusinessInfoLabel Wallet2Text3 Wallet2Text3Dark"></div>
          <div id="BusinessEditorInputBusinessInfoPhoneNumber" contenteditable="true" class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessInfoPortionLinkText">${call}</div>
          <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
        </div>
      </div>`;

    if (data.whatsApp?.contact) {
      whatsApp = data.whatsApp?.contact;
    }
    contactWays += `
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayWhatsApp}" alt="" />
        <div class="BusinessInfoPortionLinkCon1B">
          <div class="BusinessEditorInputBusinessInfoLabel Wallet2Text3 Wallet2Text3Dark"></div>
          <div id="BusinessEditorInputBusinessInfoWhatsAppNumber" contenteditable="true" class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessInfoPortionLinkText">${whatsApp}</div>
          <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
        </div>
      </div>`;

    if (data.email?.contact) {
      email = data.email?.contact;
    }
    contactWays += `
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayEmail}" alt="" />
        <div class="BusinessInfoPortionLinkCon1B">
          <div class="BusinessEditorInputBusinessInfoLabel Wallet2Text3 Wallet2Text3Dark"></div>
          <div id="BusinessEditorInputBusinessInfoEmail" contenteditable="true" class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessInfoPortionLinkText">${email}</div>
          <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
        </div>
      </div>`;

    if (data.website?.contact) {
      website = data.website?.contact;
    }
    contactWays += `
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayWebsite}" alt="" />
        <div class="BusinessInfoPortionLinkCon1B">
          <div class="BusinessEditorInputBusinessInfoLabel Wallet2Text3 Wallet2Text3Dark"></div>
          <div id="BusinessEditorInputBusinessInfoWebsite" contenteditable="true" class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessInfoPortionLinkText">${website}</div>
          <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
        </div>
      </div>`;

    return `<div class="BusinessInfoContactCon1">${contactWays}</div>`;
  }

  _visualizerPopulateBusinessInfoAbout(about) {
    return `
  <div id="BusinessInfoAboutCon1" class="BusinessInfoAboutCon1">
    <div class="BusinessInfoPortionLinkCon1B">
      <div id="BusinessEditorInputBusinessInfoAbout" contenteditable="true" class="BusinessEditorInputBusinesTextEditableView BusinessEditorInputBusinessForKeyUpListeners BusinessInfoAboutText">
        ${about}
      </div>
      <div class="Wallet2Text3 Wallet2Text3Error editorErrorInputMessage"></div>
    </div>
  </div>
    `;
  }
}
export default new GenerateHtml();
