import AppLogo from "../../../Icons/logos/App-logo.png";
import iconCharcoalDarkSaved from "../../../Icons/icons-charcoal-dark/saved.png";
import iconCharcoalDarkliked from "../../../Icons/icons-charcoal-dark/liked.png";
import iconCharcoalDarkSave from "../../../Icons/icons-charcoal-dark/save.png";
import iconCharcoalNotify from "../../../Icons/icons-charcoal-dark/notify.png";
import iconCharcoalNotNotify from "../../../Icons/icons-charcoal-dark/not-notify.png";

import generateHtml from "./generate-html";
import StackDisplay from "../stack-display/display";
import spinner from "../spinner";
import firebase from "../firebase";

import handleAccount from "../register/handle-account";
import register from "../register/register";

import GENRALHELPERS from "../GENRAL-HELPERS";
import displayListingGenerateHtml from "../display-listing/generate-html";

import allHandeling from "../handeling-all-displays/all-handeling";
import display from "../stack-display/display";

class DisplayBusinessEach {
  #initialized = false;
  #account;
  #inBusinessSearchActivated;

  _returnMiniSpinner() {
    return `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20"
      height="20"
      class="miniSpinneForButtons">
      <circle
        cx="25"
        cy="25"
        r="20"
        stroke="#2B2B2B"
        stroke-width="4"
        fill="none"
        stroke-dasharray="125.6"
        stroke-dashoffset="94.2"
        stroke-linecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.8s"
          repeatCount="indefinite" />
      </circle>
    </svg>`;
  }

  async _init(businessData, share) {
    const thisBusinessId = GENRALHELPERS._getUniqueIdfunction();
    spinner._displaySpinner("Cargando negocio");
    console.log("called");
    const headerHtml = await generateHtml._generateHeader(businessData);
    let listingDirectionIds = [];
    let lisitingslistsHtml = ``;
    for (const list of businessData.lists) {
      const [lisitingslistHtml, id] = await this._displayLists(
        list.name,
        businessData
      );
      lisitingslistsHtml += lisitingslistHtml;
      console.log(list);

      listingDirectionIds.push({
        listName: list.name,
        listId: id,
        listType: list.type,
        listTag: list.tag,
        listingsIds: list.ids,
      });
    }

    let infoHtml = generateHtml._generateInfo(businessData);

    // Continue with the rest of your function here
    this._fillInBusinessListings(businessData, listingDirectionIds);

    const anouncementHtml =
      generateHtml._generateSpecialAnouncment(businessData);

    const allHtmlForBusiness = generateHtml._generateMain(
      businessData,
      headerHtml,
      anouncementHtml,
      lisitingslistsHtml,
      infoHtml,
      thisBusinessId
    );
    StackDisplay._display(allHtmlForBusiness);
    // firebase._updateDocStats(
    //   `businesses/${businessData.businessId}`,
    //   "views",
    //   1
    // );

    const businessShareButton = document.getElementById(
      `${thisBusinessId}BusinessViewEachUserActionsCon6`
    );
    if (businessShareButton && share) {
      this._shareActionForBusiness(businessShareButton);
    } else {
      console.log("not valid button");
    }
    firebase._updateViewsOnBusiness({ businessId: businessData.businessId });
    spinner._hideSpinner();
    this._businessEvents();
  }

  async _fillInBusinessListings(businessData, listingDirectionIds) {
    const listings = await firebase._getAllDocsFromPathByMostRecent(
      `businesses/${businessData.businessId}/listings`,
      true
    );
    // const { listings, lastVisibleDoc } =
    //   await firebase._getRecentListingsWithFilter(
    //     null,
    //     null,
    //     `businesses/${businessData.businessId}/listings`
    //   );

    console.warn(businessData.businessId);
    console.warn(listings);
    const fillInListings = async (listings, id) => {
      const containerForListingsInput = document.getElementById(id);
      for (const listing of listings) {
        const html = await displayListingGenerateHtml._listingVertical(
          listing,
          businessData.colors,
          "listingInBusiness",
          businessData.businessId,
          true,
          "business"
        );
        containerForListingsInput.insertAdjacentHTML("beforeend", html);
      }
    };

    listingDirectionIds.forEach(async (list) => {
      let currentLisitngs = [];
      console.log(list);

      // Check if list is a All list
      if (list.listType === "todos") {
        listings.forEach((listing) => {
          console.log("got taken for All listings");
          currentLisitngs.push(listing);
        });
        fillInListings(currentLisitngs, list.listId);
      }

      // Check if list is a manual list
      if (list.listType === "manual") {
        console.log("manual");
        console.log(list.listingsIds);
        if (list.listingsIds) {
          listings.forEach((listing) => {
            if (list.listingsIds.includes(listing.id)) {
              console.log("got taken for manual");
              currentLisitngs.push(listing);
            } else {
              console.log("not taken for manual");
            }
          });
          console.log(currentLisitngs);
          fillInListings(currentLisitngs, list.listId);
        }
      }

      // Check if list is a auto list
      if (list.listType === "auto") {
        // Check if list is a discount list
        if (list.listTag === "en oferta") {
          listings.forEach((listing) => {
            console.log(listing);

            if (listing?.onDiscount?.amount > 0) {
              currentLisitngs.push(listing);
            }
          });
          fillInListings(currentLisitngs, list.listId);
        } else {
          console.log("got here");
          listings.forEach((listing) => {
            if (listing.listingMachineType === list.listTag) {
              currentLisitngs.push(listing);
            }
          });
          console.log(currentLisitngs);
          fillInListings(currentLisitngs, list.listId);
        }
      }
    });
  }

  async _displayLists(listName, business) {
    let htmlToReturn;
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();

    try {
      htmlToReturn = displayListingGenerateHtml._businessPackOfPacks(
        displayListingGenerateHtml._listingPack(
          listName,
          business.colors,
          htmlContainerId
        )
      );

      return [htmlToReturn, htmlContainerId];
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display For You aborted.");
      } else {
        console.error(err);
      }
    }
  }

  // Start Making an image
  async _captureElementScreenshot(elementCl) {
    const elementForImgGeting = document.querySelector(
      `.BusinessViewEachHeaderCon1`
    );
    const element = document.querySelector(`.${elementCl}`);
    if (!element) {
      throw new Error("Element not found");
    }
    const imgUrl = elementForImgGeting.querySelector("img").src;
    console.log(imgUrl);

    function setBackgroundImage(element, imageUrl) {
      if (!element || !imageUrl) {
        console.error("Element or image URL is missing");
        return;
      }

      // Set the background image and styles
      element.style.backgroundImage = `url('${imageUrl}')`;
      element.style.backgroundSize = "cover";
      element.style.backgroundRepeat = "no-repeat";
      element.style.backgroundPosition = "center";

      //   Add some more
    }
    setBackgroundImage(element, imgUrl);

    // Ensure the element is styled properly for rendering
    const originalStyle = element.style.cssText;

    // Set inline styles to ensure full rendering
    element.style.paddingBottom = "6px";
    element.style.width = "300px";
    element.querySelector(".addForShareText").style.display = "flex";

    try {
      // Use html2canvas to capture the element
      const canvas = await html2canvas(element, {
        useCORS: true, // Allows cross-origin images
        backgroundColor: null, // Ensures the background is captured as it is
        scale: 5, // Improves resolution of the captured image
      });

      // Convert the canvas to a Blob
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/png")
      );

      if (!blob) {
        throw new Error("Failed to create Blob from canvas");
      }

      // Optionally, create a URL and trigger a download
      const url = URL.createObjectURL(blob);
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.download = "screenshot.png";
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(url);

      //   Return the Blob for further use
      return url;
      //   return blob;
    } catch (error) {
      console.error("Error capturing element screenshot:", error);
      throw error;
    } finally {
      // Restore the original styles
      element.style.cssText = originalStyle;
      element.style.backgroundImage = ``;
      element.querySelector(".addForShareText").style.display = "none";
    }
  }
  // End Making an image

  _filterListingsByTokens(listings, tokens) {
    // Ensure tokens are unique for efficient lookup
    const tokenSet = new Set(tokens);

    // Filter listings based on whether any token matches
    return listings.filter((listing) => {
      if (!Array.isArray(listing.listingTokens)) return false; // Skip listings without valid tokens
      return listing.listingTokens.some((token) => tokenSet.has(token));
    });
  }

  _shareActionForBusiness = async (target) => {
    const getUrlToCurrentBusiness = async () => {
      const parentForData = target.closest(".ActionForShare");
      const businessName = parentForData.dataset.businessname;
      const businessId = parentForData.dataset.businessid;
      console.log(businessId);
      const url = await GENRALHELPERS._createUrlForSharing(
        businessId,
        businessName
      );
      return url;
    };

    const ButtonActionShare = target.closest(
      "#BusinessViewEachUserActionsCon6Share"
    );
    const parentEL = ButtonActionShare.closest(".ActionForShare");
    const businessId = parentEL.dataset.businessid;
    const businessName = parentEL.dataset.businessname;
    const businessShortDescription = parentEL.dataset.businessshordescription;

    const imgBlob = await this._captureElementScreenshot(
      "BusinessViewEachHeaderCon8"
    );

    const businessUrl = await getUrlToCurrentBusiness();
    const textForBusinessName = "```" + businessName + "```";
    const textForDescription = "``" + businessShortDescription + "``";

    const descriptionForBusinessShare = `*${businessName}*\n${textForDescription}\n\n*Visita este negocio en:* _${businessUrl}_\n\n *Visita nuestro mercado de maquinaria agricola e industrial en:* _https://maquinariasonline.com_\n\n🔗 *COMPARTE*`;

    const sharingResult = await GENRALHELPERS._shareImagesWithText(
      businessName,
      descriptionForBusinessShare,
      [imgBlob]
    );
    if (sharingResult.success) {
      firebase._updateSharesOnBusiness({ businessId: businessId });
    }
  };

  async _businessEvents() {
    this.#account = firebase._getLatestCurrentUserData();
    if (!this.#initialized) {
      window.addEventListener("click", async (e) => {
        // Start Action Function

        // Get url to business
        const getUrlToCurrentBusiness = async () => {
          const parentForData = e.target.closest(".ActionForShare");
          const businessName = parentForData.dataset.businessname;
          const businessId = parentForData.dataset.businessid;
          console.log(businessId);
          const url = await GENRALHELPERS._createUrlForSharing(
            businessId,
            businessName
          );
          return url;
        };

        // Save
        const saveAction = async () => {
          const BusinessViewEachUserActionsCon6Save = e.target.closest(
            "#BusinessViewEachUserActionsCon6Save"
          );
          if (this.#account) {
            console.log("clicked");
            const parentEL =
              BusinessViewEachUserActionsCon6Save.closest(".ActionForShare");
            const businessId = parentEL.dataset.businessid;
            const businessName = parentEL.dataset.businessname;
            const businessShortDescription =
              parentEL.dataset.businessshordescription;

            let path = `businesses/${businessId}`;

            const saveObj = {
              path: path,
              time: GENRALHELPERS._getTimeStampJS(),
            };

            BusinessViewEachUserActionsCon6Save.insertAdjacentHTML(
              "beforeend",
              this._returnMiniSpinner()
            );

            console.log(businessId);

            const saveResult = await firebase._updateSavesOnBusiness({
              businessId: businessId,
            });

            if (saveResult.data.status === "success") {
              if (saveResult.data.action === "saved") {
                handleAccount._getAccountState();
                BusinessViewEachUserActionsCon6Save.querySelector("img").src =
                  iconCharcoalDarkSaved;
                BusinessViewEachUserActionsCon6Save.querySelector(
                  ".miniSpinneForButtons"
                ).remove();
              }
              if (saveResult.data.action === "removed") {
                handleAccount._getAccountState();
                BusinessViewEachUserActionsCon6Save.querySelector("img").src =
                  iconCharcoalDarkSave;
                BusinessViewEachUserActionsCon6Save.querySelector(
                  ".miniSpinneForButtons"
                ).remove();
              }
            }

            // const pastSavesResult = await firebase._modifyArrayInFirestore(
            //   `accounts`,
            //   this.#account.userId,
            //   "pastBusinessSaves",
            //   "add",
            //   saveObj
            // );
            // const activeSavesResult = await firebase._modifyArrayInFirestore(
            //   `accounts`,
            //   this.#account.userId,
            //   "activeBusinessSaves",
            //   "add",
            //   saveObj
            // );
            // if (pastSavesResult === "added") {
            //   handleAccount._getAccountState();
            //   firebase._updateDocStats(path, "saves", 1);
            // }

            // if (activeSavesResult === "added") {
            //   handleAccount._getAccountState();
            //   BusinessViewEachUserActionsCon6Save.querySelector("img").src =
            //     iconCharcoalDarkSaved;
            //   BusinessViewEachUserActionsCon6Save.querySelector(
            //     ".miniSpinneForButtons"
            //   ).remove();
            // } else if (activeSavesResult === "exists") {
            //   const removeActiveSavesResult =
            //     await firebase._modifyArrayInFirestore(
            //       `accounts`,
            //       this.#account.userId,
            //       "activeBusinessSaves",
            //       "remove",
            //       null,
            //       "path",
            //       path
            //     );
            //   if (removeActiveSavesResult === "Object removed successfully") {
            //     handleAccount._getAccountState();
            //     BusinessViewEachUserActionsCon6Save.querySelector("img").src =
            //       iconCharcoalDarkSave;
            //     BusinessViewEachUserActionsCon6Save.querySelector(
            //       ".miniSpinneForButtons"
            //     ).remove();
            //   }
            // }
          } else {
            register._displayOptions(
              null,
              "Para guardar un negocio tienes que tener una cuenta activa en este dispositivo."
            );
          }
        };
        const followAction = async () => {
          alert("Próximamente. Esta función aún no está lista.");
          return;

          const BusinessViewEachUserActionsCon4Follow = e.target.closest(
            "#BusinessViewEachUserActionsCon4Follow"
          );
          if (this.#account) {
            const parentEL =
              BusinessViewEachUserActionsCon4Follow.closest(".ActionForShare");
            const businessId = parentEL.dataset.businessid;
            console.log(businessId);
            const businessName = parentEL.dataset.businessname;
            const businessShortDescription =
              parentEL.dataset.businessshordescription;

            let path = `businesses/${businessId}`;

            const saveObj = {
              path: path,
              time: GENRALHELPERS._getTimeStampJS(),
            };
            const followerObj = {
              userName: this.#account.userName,
              userId: this.#account.userId,
              time: GENRALHELPERS._getTimeStampJS(),
            };
            BusinessViewEachUserActionsCon4Follow.insertAdjacentHTML(
              "beforeend",
              this._returnMiniSpinner()
            );

            const followResult = await firebase._updateFollowsOnBusiness({
              businessId: businessId,
            });
            console.log(followResult);

            if (followResult.data.status === "success") {
              if (followResult.data.action === "followed") {
                handleAccount._getAccountState();

                BusinessViewEachUserActionsCon4Follow.querySelector("img").src =
                  iconCharcoalNotNotify;
                BusinessViewEachUserActionsCon4Follow.querySelector(
                  ".miniSpinneForButtons"
                ).remove();
                BusinessViewEachUserActionsCon4Follow.querySelector(
                  "p"
                ).textContent = "Dejar de seguir";
                BusinessViewEachUserActionsCon4Follow.classList.add(
                  "BusinessViewEachUserActionsCon4B"
                );
              }
              if (followResult.data.action === "removed") {
                handleAccount._getAccountState();
                BusinessViewEachUserActionsCon4Follow.querySelector("img").src =
                  iconCharcoalNotify;
                BusinessViewEachUserActionsCon4Follow.querySelector(
                  ".miniSpinneForButtons"
                ).remove();
                BusinessViewEachUserActionsCon4Follow.querySelector(
                  "p"
                ).textContent = "Seguir";
                BusinessViewEachUserActionsCon4Follow.classList.remove(
                  "BusinessViewEachUserActionsCon4B"
                );
              }
            }
          } else {
            register._displayOptions(
              null,
              "Para seguir un negocio tienes que tener una cuenta activa en este dispositivo."
            );
          }
        };
        // Info
        const goToInfoAction = async () => {
          const parentForData = e.target.closest(".ActionForShare");

          const childToScrollTo =
            parentForData.querySelector(".BusinessInfoCon1"); // Get the last child
          console.log(childToScrollTo);
          if (childToScrollTo) {
            parentForData.scrollTo({
              top: childToScrollTo.offsetTop - parentForData.offsetTop, // Scroll to the last child's position
              behavior: "smooth", // Enable smooth scrolling
            });
          }
        };
        // Info
        const searchInBusinessAction = async () => {
          const mainSearchCon = e.target.closest(".BusinessEachSearchCon1");
          const searchInput = mainSearchCon.querySelector(
            ".BusinessEachSearchInput"
          );
          const closeSearchButton = mainSearchCon.querySelector(
            ".BusinessEachSearchCon6"
          );
          const searchResultsMain = mainSearchCon.querySelector(
            ".BusinessEachSearchCon4"
          );
          const searchResultsContainer = mainSearchCon.querySelector(
            ".BusinessEachSearchCon4ResultsCon"
          );
          const searchResultsNumber = mainSearchCon.querySelector(
            ".SearchStartUpComponentCon13Text"
          );
          const parentEL = mainSearchCon.closest(".ActionForShare");
          const businessId = parentEL.dataset.businessid;

          closeSearchButton.addEventListener("click", () => {
            searchResultsContainer.innerHTML = "";
            searchInput.value = "";
            searchResultsMain.classList.remove("BusinessEachSearchCon4Active");
            closeSearchButton.classList.remove("BusinessEachSearchCon6Active");
          });

          searchInput.addEventListener("keyup", async (e) => {
            const targetValue = e.target.value.trim();
            closeSearchButton.classList.add("BusinessEachSearchCon6Active");
            searchResultsMain.classList.add("BusinessEachSearchCon4Active");
            const currenBusinessData =
              allHandeling._getLocalBusinessData(businessId);
            console.log(currenBusinessData.listings);

            const searchTokens = GENRALHELPERS._createTokens(targetValue, [
              "nothing",
            ]);

            const searchedListings = this._filterListingsByTokens(
              currenBusinessData.listings,
              searchTokens
            );

            const rankedListings = GENRALHELPERS._rankListings(
              searchedListings,
              targetValue,
              ["nothing"]
            );

            const currentBusiness =
              allHandeling._getLocalBusinessData(businessId);
            console.log(currentBusiness);
            console.log(currentBusiness.colors);

            let html = ``;

            for (const listing of rankedListings) {
              // const business
              if (currentBusiness.listingsApear === "horizontal") {
                html += await displayListingGenerateHtml._listingHorizontal(
                  listing,
                  currentBusiness.colors,
                  "listingInBusiness",
                  listing.businessId,
                  true
                );
              }
              if (currentBusiness.listingsApear === "vertical") {
                html += await displayListingGenerateHtml._listingVertical(
                  listing,
                  currentBusiness.colors,
                  "listingInBusiness",
                  listing.businessId,
                  true
                );
              }
            }
            console.log(html);
            console.log(searchResultsContainer);

            console.log(searchedListings);
            searchResultsNumber.textContent = `${rankedListings.length} resultados.`;

            if (!targetValue.includes(" ") && rankedListings.length < 1) {
              searchResultsNumber.textContent = `${rankedListings.length} resultados. Sigue escribiendo para encontrar más.`;
            }
            searchResultsContainer.innerHTML = html;
          });

          // TODO finish the in business search first thing

          console.log("search taped");
        };
        // WhatsApp
        const contactByWhatsAppAction = async () => {
          const openWhatsApp = (phoneNumber, message) => {
            try {
              // Encode the phone number and message for URL safety
              const encodedMessage = encodeURIComponent(message);
              const whatsappUrl = `https://wa.me/+52${phoneNumber}?text=${encodedMessage}`;

              // Open the WhatsApp link in a new tab or current tab
              window.open(whatsappUrl, "_blank");

              console.log("WhatsApp link opened:", whatsappUrl);
            } catch (error) {
              console.error("Error creating or opening WhatsApp link:", error);
            }
          };

          console.log("whatsapp taped");
          const parentForData = e.target.closest(".ActionForShare");
          const whatsAppNumber = parentForData.dataset.businesswhatsappnumber;
          const businessName = parentForData.dataset.businessname;

          const businessUrl = await getUrlToCurrentBusiness();

          let businessUrlForUse = "`" + businessUrl + "`";

          const message = `${GENRALHELPERS._getSpanishGreetingForDay()}, *${businessName}*.\nLes estoy contactando desde ${businessUrlForUse}\n\n*Mesaje:*`;

          openWhatsApp(whatsAppNumber, message);
        };
        // Email
        const openEmailWithPrefilledMessage = async () => {
          const parrentEAll = e.target.closest(".ActionForShare");
          const parrentEL = e.target.closest(
            "#DsiplayBusinessEachInfoPortionEmail"
          );
          const email = parrentEL.dataset.email;
          const url = await getUrlToCurrentBusiness();
          const businessName = parrentEAll.dataset.businessname;
          const subject = `${GENRALHELPERS._getSpanishGreetingForDay()}, ${businessName}`;
          const body = `Les estoy contactando desde: ${url}.\n\nMensaje:`; // The body of the email

          // Create the mailto URL
          const mailtoURL = `mailto:${email}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`;

          // Open the default email client
          window.location.href = mailtoURL;
        };

        if (e.target.closest("#BusinessViewEachUserActionsCon6Save")) {
          saveAction();
        }
        if (e.target.closest("#BusinessViewEachUserActionsCon6Share")) {
          this._shareActionForBusiness(e.target);
        }
        if (e.target.closest("#BusinessViewEachUserActionsCon4Follow")) {
          followAction();
        }
        if (e.target.closest("#BusinessEditorBusinessHeaderButtonInfo")) {
          goToInfoAction();
        }
        if (
          e.target.closest("#BusinessEditorBusinessHeaderButtonWhatsApp") ||
          e.target.closest("#DsiplayBusinessEachInfoPortionWahtsApp")
        ) {
          contactByWhatsAppAction();
        }
        if (e.target.closest(".BusinessEachSearchCon2")) {
          searchInBusinessAction();
        }
        if (e.target.closest("#DsiplayBusinessEachInfoPortionEmail")) {
          openEmailWithPrefilledMessage();
        }
      });
      this.#initialized = true;
    }
  }
}

export default new DisplayBusinessEach();
