import iconSearch from "../../../Icons/icons-charcoal-dark/search.png";
import iconDotsMenu from "../../../Icons/icons-charcoal-dark/dots-menu.png";

class generateHtml {
  _main() {
    return `
<div id="SavedConMainSavedCon" class="SavedAndFollowingCon1">
    <div class="SavedAndFollowingCon2">
        <div class="SavedAndFollowingCon3">
            <div class="SavedAndFollowingCon4" style="display: none">
                <div class="SavedAndFollowingCon5">
                <img height="20px" src="${iconSearch}"/>
                <div class="SavedAndFollowingText1">Buscar en guardados</div>
                </div>
                <div class="SavedAndFollowingCon6">
                    <img height="20px" src="${iconDotsMenu}"/>
                </div>
            </div>
            <div class="SavedAndFollowingCon7">
                <div id="SavedConMainSavedButtonSortAll" class="SavedAndFollowingCon8">
                    <div class="SavedAndFollowingText2">Todos</div>
                </div>      
                 <div id="SavedConMainSavedButtonSortBusiness" class="SavedAndFollowingCon8">
                    <div class="SavedAndFollowingText2">Negocios</div>
                </div>
                <div id="SavedConMainSavedButtonSortListings" class="SavedAndFollowingCon8">
                    <div class="SavedAndFollowingText2">Listados</div>
                </div>
            </div>
        </div>
        <div class="SavedAndFollowingCon9B">
        <div id="SavedAndFollowingCon9InsertSaved" class="SavedAndFollowingCon9">
            <div class="SavedAndFollowingConB10">
                <div class="SavedAndFollowingConB11">
                    <div id="SavedAndFollowingTextB10UpateMessage" class="SavedAndFollowingTextB10">Cargando...</div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
`;

    //   TODO Later add this option
    {
      /* <div id="SavedConMainSavedButtonSortFollowing" class="SavedAndFollowingCon8">
<div class="SavedAndFollowingText2">Siguiendo</div>
</div> */
    }
  }
}
export default new generateHtml();
