export function headerIn(string) {
  const shiftAmount = 5; // Define how much to shift each character by
  let encodedString = "";

  for (let i = 0; i < string.length; i++) {
    let charCode = string.charCodeAt(i); // Get character code of each character
    let shiftedCharCode = charCode + shiftAmount; // Shift the character by the shiftAmount
    encodedString += String.fromCharCode(shiftedCharCode); // Convert back to a character and append it to the result
  }

  return encodedString;
}

export function headerOut(encodedString) {
  const shiftAmount = 5; // Same shift as used in encoding
  let decodedString = "";

  for (let i = 0; i < encodedString.length; i++) {
    let charCode = encodedString.charCodeAt(i); // Get character code of each character
    let shiftedCharCode = charCode - shiftAmount; // Reverse the shift by subtracting the shiftAmount
    decodedString += String.fromCharCode(shiftedCharCode); // Convert back to character and append to result
  }

  return decodedString;
}
