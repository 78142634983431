import busineOwn from "../business-page-own-veiw/busine-own";
import busineOwnGenerateHtml from "../business-page-own-veiw/business-own-generate-html";
import createListing from "../create-listing/create-listing";
import noListingFileImport from "../display-listing/generate-html";
import firebase from "../firebase";
import nav from "../home-screen/nav";
import orders from "../orders/orders";
import spinner from "../spinner";
import menu from "./menu";

const yourListingsMainCon = document.getElementById("yourListingsMainCon");
const yourListingsMainCon4 = document.querySelector(".yourListingsMainCon4");
const YourListingsMainButtonNewListing = document.getElementById(
  "YourListingsMainButtonNewListing"
);
const YourListingsMainButtonOpenBusiness = document.getElementById(
  "YourListingsMainButtonOpenBusiness"
);
const yourListingsMainConButtonBack = document.getElementById(
  "yourListingsMainConButtonBack"
);
const yourListingsAllListingsCon = document.getElementById(
  "yourListingsAllListingsCon"
);
const yourListingsAtentionRequieredListingsCon = document.getElementById(
  "yourListingsAtentionRequieredListingsCon"
);

class yourListings {
  #listings = [];
  constructor() {
    this._events();
  }

  _getLocalListings() {
    return this.#listings;
  }
  async _open() {
    spinner._displaySpinner("Cargando tus listados");
    yourListingsMainCon.style.display = "flex";
    console.log("opining now");
    const account = firebase._getLatestCurrentUserData();
    if (!account) {
      spinner._hideSpinner();
      return;
    }
    if (!account.listings) {
      spinner._hideSpinner();
      return;
    }
    const listingsIdsArray = account.listings.slice().reverse();
    // const listings = [];
    spinner._hideSpinner();
    yourListingsAllListingsCon.innerHTML = "";
    yourListingsAtentionRequieredListingsCon.innerHTML = "";
    const atentionNeededParrentCon =
      yourListingsAtentionRequieredListingsCon.closest(
        ".ListingPackDisplayCon1"
      );

    this.#listings = [];
    for (const id of listingsIdsArray) {
      const listing = await firebase._getAnyDocById(`listings/${id}`);
      if (listing) {
        this.#listings.push(listing);
        let listingHtml;
        if (listing.status === "waitingPayment") {
          listingHtml = await busineOwnGenerateHtml._listing(
            listing,
            "waitingPayment"
          );
        } else {
          listingHtml = await busineOwnGenerateHtml._listing(listing);
        }
        yourListingsAllListingsCon.insertAdjacentHTML("beforeend", listingHtml);

        //   Check for atention needed

        if (listing.status === "waitingPayment") {
          atentionNeededParrentCon.style.display = "";
          yourListingsMainCon4.style.display = "";
          yourListingsMainCon4.textContent = "Pagar todos";
          yourListingsMainCon4.dataset.action = "payall";
          yourListingsMainCon4.classList.add("yourListingsMainCon4Action");
          yourListingsAtentionRequieredListingsCon.insertAdjacentHTML(
            "beforeend",
            listingHtml
          );
        }
      }
    }
    if (this.#listings.length < 1) {
      let listingHtml =
        noListingFileImport._listingVerticalMiniNoListings(true);
      yourListingsAllListingsCon.innerHTML = "";
      yourListingsAllListingsCon.insertAdjacentHTML("beforeend", listingHtml);
    }
    console.log(this.#listings);
  }
  _close() {
    yourListingsMainCon.style.display = "none";
  }

  _events() {
    YourListingsMainButtonNewListing.addEventListener("click", () => {
      createListing._init();
    });
    YourListingsMainButtonOpenBusiness.addEventListener("click", () => {
      busineOwn._init();
      this._close();
      menu._close();
      nav._changeNav("Business");
    });
    yourListingsMainConButtonBack.addEventListener(
      "click",
      this._close.bind(this)
    );

    yourListingsMainCon.addEventListener("click", (e) => {
      const targetOfClicked = e.target;

      if (targetOfClicked.closest(".yourListingsMainActionButton")) {
        if (targetOfClicked.dataset.action === "payall") {
          const data = [];
          this.#listings.forEach((listing) => {
            if (listing.status !== "waitingPayment") return;

            let listingImgUrl = "";
            const children = yourListingsAtentionRequieredListingsCon.children;

            // Iterate over each child element
            Array.from(children).forEach((child) => {
              if (child.dataset.id === listing.listingId) {
                // Use dataset.imgid to access the data-imgid attribute
                listingImgUrl = child.dataset.imgid;
              }
            });

            // Push the gathered data to the data array
            data.push({
              id: listing.listingId,
              imgId: listingImgUrl,
              name: listing.listingName,
              type: "listado",
            });
          });
          orders._start(data);
        }
      }
      if (targetOfClicked.closest("#OwnBusinessListingButtonActionPayNow")) {
        const listing = targetOfClicked.closest(".ActionOpenForDisplay");
        if (!listing) return;
        const data = [
          {
            id: listing.dataset.id,
            imgId: listing.dataset.imgid,
            name: listing.dataset.name,
            type: "listado",
          },
        ];
        orders._start(data);
      }
    });
  }
}

export default new yourListings();
