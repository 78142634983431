import firebase from "../firebase";
import nav from "../home-screen/nav";
import register from "../register/register";
import spinner from "../spinner";
import profileImgPlaceHolder from "../../../Icons/icons-white/profile-img-placeholder.png";
// import { connectFirestoreEmulator } from "firebase/firestore";

const ProfileOpenButtonBack = document.getElementById("ProfileOpenButtonBack");
const ProfileOpenCon6B = document.getElementById("ProfileOpenCon6B");
const ProfileOpenConContactNumberMessage = document.getElementById(
  "ProfileOpenConContactNumberMessage"
);
const ProfileOpenInputEmail = document.getElementById("ProfileOpenInputEmail");
const ProfileOpenInputSellerName = document.getElementById(
  "ProfileOpenInputSellerName"
);
const ProfileOpenInputPhoneNumber = document.getElementById(
  "ProfileOpenInputPhoneNumber"
);
const ProfileOpenInputWhatsAppNumber = document.getElementById(
  "ProfileOpenInputWhatsAppNumber"
);
const ProfileOpenCon1 = document.getElementById("ProfileOpenCon1Main");
const ProfileOpenSellerProfileSaveButton = document.getElementById(
  "ProfileOpenSellerProfileSaveButton"
);
const ProfileOpenButtonLogOut = document.getElementById(
  "ProfileOpenButtonLogOut"
);
const ProfileOpenButtonDeleteAccount = document.getElementById(
  "ProfileOpenButtonDeleteAccount"
);
const ProfileOpenFileInput = document.getElementById("ProfileOpenFileInput");
const profileImage = document.getElementById("ProfileOpenProfileFoto");
const ProfileOpenFotoDeleteButton = document.getElementById(
  "ProfileOpenFotoDeleteButton"
);

class ManageAccountAndProfileChanges {
  #changedProfileImg = false;
  #changesToBeSaved = false;
  #initializedEventOnOpen = false;
  #initializedEvent = false;
  #sellerProfile = {
    userName: "",
    callNumber: "",
    whatsAppNumber: "",
    profileImageUrl: "",
  };
  constructor() {
    this._events();
    this._imageSelectHandle();
  }
  async _saveSellerProfile(close) {
    let valid = true;
    if (!this._checkIfReady("name")) {
      valid = this._checkIfReady("name");
    }

    if (!this._checkIfReady("callNumber")) {
      valid = this._checkIfReady("callNumber");
    }
    if (!this._checkIfReady("whatsAppNumber")) {
      valid = this._checkIfReady("whatsAppNumber");
    }
    if (!valid) {
      alert("Porfavor completa todos los campos, incluso la foto de perfil.");
    }
    try {
      if (
        !this.#sellerProfile.userName ||
        this.#sellerProfile.userName === "" ||
        !this.#sellerProfile.callNumber ||
        this.#sellerProfile.callNumber === "" ||
        !this.#sellerProfile.whatsAppNumber ||
        this.#sellerProfile.whatsAppNumber === ""
      ) {
        alert(
          "Porfavor completa los campos antes de subir tu perfil de vendedor"
        );
        return;
      }
      if (this.#changedProfileImg) {
        spinner._displaySpinner("Subiendo imagen de perfil");
        const profileImgUrl = await firebase._uploadImageById(
          "ProfileOpenProfileFoto"
        );
        this.#sellerProfile.profileImageUrl = profileImgUrl;
        this.#changedProfileImg = false;
      }
      spinner._displaySpinner("Subiendo perfil de vendedor");
      const updateProfileResult = await firebase._updateUserTemplate(
        this.#sellerProfile
      );
      console.log(updateProfileResult);
      if (updateProfileResult.data.status === "error") {
        alert(
          `Hubo un error en actualizar tu perfil de vendedor. ${updateProfileResult.message}`
        );
        spinner._hideSpinner();
        return;
      }
      if (updateProfileResult.data.status === "success") {
        ProfileOpenSellerProfileSaveButton.style.display = "none";
        ProfileOpenSellerProfileSaveButton.classList.remove(
          "ProfileOpenSellerProfileSaveButtonNoneAct"
        );
        this.#changesToBeSaved = false;
        if (close) {
          this._close();
        }
        alert("Éxito. ¡Perfil de vendedor actualizado correctamente!");
      }
      spinner._hideSpinner();
    } catch (error) {
      console.error(error);
    }
  }
  async _open() {
    const messages = document.querySelectorAll(".ProfileOpenInputMessage");

    messages.forEach((ms) => {
      ms.textContent = "";
      ms.classList.remove("ProfileOpenInputMessageSuccess");
      ms.classList.remove("ProfileOpenInputMessageError");
    });
    const currentAccount = firebase._getLatestCurrentUserData();
    if (!currentAccount) {
      register._displayOptions(
        "account_and_profile",
        "Escoge una opción para seguir"
      );
      return;
    }
    ProfileOpenInputEmail.textContent = currentAccount.email;
    if (!currentAccount.userName || currentAccount.userName === "sin nombre") {
      console.warn("Not a seller profile");
      ProfileOpenCon6B.style.display = "flex";
      this._eventsOnOpen();
      ProfileOpenInputSellerName.value = "";
      ProfileOpenInputPhoneNumber.value = "";
      ProfileOpenInputWhatsAppNumber.value = "";
      ProfileOpenFileInput.value = "";
      profileImage.src = profileImgPlaceHolder;
      ProfileOpenFotoDeleteButton.style.display = "none";
      ProfileOpenSellerProfileSaveButton.style.display = "none";
      ProfileOpenSellerProfileSaveButton.classList.remove(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
    } else {
      spinner._displaySpinner("Cargando");
      this._eventsOnOpen();
      ProfileOpenFotoDeleteButton.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.style.display = "none";
      ProfileOpenSellerProfileSaveButton.classList.remove(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      if (currentAccount.profileImageUrl) {
        const imgUrl = currentAccount.profileImageUrl;

        // const imgUrl = await firebase._getImageUrl(
        //   currentAccount.profileImageUrl
        // );
        profileImage.src = imgUrl;
      }
      this.#sellerProfile.userName = currentAccount.userName;
      this.#sellerProfile.callNumber = currentAccount.callNumber;
      this.#sellerProfile.whatsAppNumber = currentAccount.whatsAppNumber;
      this.#sellerProfile.profileImageUrl = currentAccount.profileImageUrl;

      ProfileOpenInputSellerName.value = currentAccount.userName;
      ProfileOpenInputPhoneNumber.value = currentAccount.callNumber;
      ProfileOpenInputWhatsAppNumber.value = currentAccount.whatsAppNumber;
      spinner._hideSpinner();
    }
    ProfileOpenCon1.style.display = "flex";
  }
  _checkIfReady = (what) => {
    this.#changesToBeSaved = true;
    let allValid = true; // Tracks overall validity
    const checkImg = () => {
      const messageCon = profileImage
        .closest(".ProfileOpenFotoCon")
        .querySelector(".ProfileOpenInputMessage");

      // Check if the profile image is the placeholder or empty
      if (
        profileImage.src.includes("profile-img-placeholder") ||
        profileImage.src === ""
      ) {
        console.warn("this was true somehow");
        console.log(profileImage.src.includes("profile-img-placeholder"));
        console.log(profileImage.src);
        messageCon.classList.add("ProfileOpenInputMessageError");
        messageCon.classList.remove("ProfileOpenInputMessageSuccess");
        messageCon.textContent = "Una foto de perfil es requerida"; // Grammar correction
        return false;
      } else {
        messageCon.classList.remove("ProfileOpenInputMessageError");
        messageCon.classList.add("ProfileOpenInputMessageSuccess");
        messageCon.textContent = ""; // Provide positive feedback
        return true;
      }
    };

    const checkName = () => {
      const messageCon = ProfileOpenInputSellerName.closest(
        ".ProfileOpenInputCon2"
      ).querySelector(".ProfileOpenInputMessage");

      if (
        !this.#sellerProfile.userName ||
        this.#sellerProfile.userName.length < 3
      ) {
        if (what === "name") {
          messageCon.classList.add("ProfileOpenInputMessageError");
          messageCon.classList.remove("ProfileOpenInputMessageSuccess");
          messageCon.textContent = !this.#sellerProfile.userName
            ? "El nombre de vendedor es requerido"
            : "El nombre de vendedor no puede ser menor a 3 carácteres";
        }
        return false;
      }
      if (what === "name") {
        messageCon.classList.remove("ProfileOpenInputMessageError");
        messageCon.classList.add("ProfileOpenInputMessageSuccess");
        messageCon.textContent = "Nombre válido";
      }
      return true;
    };

    const checkCallNumber = () => {
      const messageCon = ProfileOpenInputPhoneNumber.closest(
        ".ProfileOpenInputCon2"
      ).querySelector(".ProfileOpenInputMessage");

      if (
        !this.#sellerProfile.callNumber ||
        this.#sellerProfile.callNumber.length !== 10
      ) {
        if (what === "callNumber") {
          messageCon.classList.add("ProfileOpenInputMessageError");
          messageCon.classList.remove("ProfileOpenInputMessageSuccess");
          messageCon.textContent = !this.#sellerProfile.callNumber
            ? "Número de llamadas es requerido"
            : "Número de llamadas no válido";
        }
        return false;
      }
      if (what === "callNumber") {
        messageCon.classList.remove("ProfileOpenInputMessageError");
        messageCon.classList.add("ProfileOpenInputMessageSuccess");
        messageCon.textContent = "Número válido";
      }
      return true;
    };

    const checkWhatsAppNumber = () => {
      const messageCon = ProfileOpenInputWhatsAppNumber.closest(
        ".ProfileOpenInputCon2"
      ).querySelector(".ProfileOpenInputMessage");

      if (
        !this.#sellerProfile.whatsAppNumber ||
        this.#sellerProfile.whatsAppNumber.length !== 10
      ) {
        if (what === "whatsAppNumber") {
          messageCon.classList.add("ProfileOpenInputMessageError");
          messageCon.classList.remove("ProfileOpenInputMessageSuccess");
          messageCon.textContent = !this.#sellerProfile.whatsAppNumber
            ? "Número de WhatsApp es requerido"
            : "Número de WhatsApp no válido";
        }
        return false;
      }
      if (what === "whatsAppNumber") {
        messageCon.classList.remove("ProfileOpenInputMessageError");
        messageCon.classList.add("ProfileOpenInputMessageSuccess");
        messageCon.textContent = "Número válido";
      }
      return true;
    };

    // Perform all checks and track validity
    allValid = checkImg() && allValid;
    allValid = checkName() && allValid;
    allValid = checkCallNumber() && allValid;
    allValid = checkWhatsAppNumber() && allValid;

    // Toggle save button based on overall validity
    if (allValid) {
      ProfileOpenSellerProfileSaveButton.classList.remove(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      return allValid;
    } else {
      ProfileOpenSellerProfileSaveButton.classList.add(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      return allValid;
    }
  };
  _eventsOnOpen() {
    if (this.#initializedEventOnOpen) return;
    this.#sellerProfile = { userName: "", callNumber: "", whatsAppNumber: "" };
    const forRemoveMessage = ProfileOpenCon6B.closest(".ProfileOpenCon5");
    forRemoveMessage.addEventListener("click", () => {
      ProfileOpenCon6B.style.display = "none";
    });
    ProfileOpenCon6B.addEventListener("click", () => {
      ProfileOpenInputSellerName.focus();
    });

    ProfileOpenInputSellerName.addEventListener("keyup", (e) => {
      ProfileOpenSellerProfileSaveButton.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.classList.add(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      const trimedValue = e.target.value.trim();
      this.#sellerProfile.userName = trimedValue;
      this._checkIfReady("name");
    });
    ProfileOpenInputPhoneNumber.addEventListener("keyup", (e) => {
      ProfileOpenConContactNumberMessage.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.classList.add(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      const trimedValue = e.target.value.trim();
      this.#sellerProfile.callNumber = trimedValue;
      this._checkIfReady("callNumber");
    });
    ProfileOpenInputWhatsAppNumber.addEventListener("keyup", (e) => {
      ProfileOpenConContactNumberMessage.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.style.display = "flex";
      ProfileOpenSellerProfileSaveButton.classList.add(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      const trimedValue = e.target.value.trim();
      this.#sellerProfile.whatsAppNumber = trimedValue;
      this._checkIfReady("whatsAppNumber");
    });
    ProfileOpenSellerProfileSaveButton.addEventListener("click", () => {
      if (
        ProfileOpenSellerProfileSaveButton.classList.contains(
          "ProfileOpenSellerProfileSaveButtonNoneAct"
        )
      )
        return;

      ProfileOpenSellerProfileSaveButton.classList.add(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      this._saveSellerProfile();
    });
    this.#initializedEventOnOpen = true;
  }
  _close() {
    console.log("Close was called");
    console.log(this.#changesToBeSaved);
    if (this.#changesToBeSaved) {
      const saveChanges = confirm(
        "Tienes cambios en tu perfil. ¿Quieres guardarlos antes de salir?"
      );
      if (saveChanges) {
        this._saveSellerProfile(true);
      } else {
        ProfileOpenCon1.style.display = "none";
        this.#changesToBeSaved = false;
      }
    } else {
      ProfileOpenCon1.style.display = "none";
    }
  }
  _imageSelectHandle() {
    // Start image select

    // Listen for file input change
    ProfileOpenFileInput.addEventListener("change", (event) => {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        const reader = new FileReader();

        // When the file is loaded, set the src of the image
        reader.onload = (e) => {
          profileImage.src = e.target.result; // Set the image src to the file's data URL
          this.#changedProfileImg = true;
        };

        reader.readAsDataURL(file); // Read the file as a Data URL
        ProfileOpenFotoDeleteButton.style.display = "flex";
        ProfileOpenSellerProfileSaveButton.style.display = "flex";
        ProfileOpenSellerProfileSaveButton.classList.add(
          "ProfileOpenSellerProfileSaveButtonNoneAct"
        );
      }
      setTimeout(() => {
        this._checkIfReady();
      }, 10);
    });

    const clearInputAndImage = () => {
      // const profileImage = document.getElementById("ProfileOpenProfileFoto");

      // Clear the file input
      ProfileOpenFileInput.value = "";

      // Reset the profile image
      profileImage.src = profileImgPlaceHolder;
      ProfileOpenFotoDeleteButton.style.display = "none";
      ProfileOpenSellerProfileSaveButton.style.display = "none";
      ProfileOpenSellerProfileSaveButton.classList.remove(
        "ProfileOpenSellerProfileSaveButtonNoneAct"
      );
      this._checkIfReady();
    };
    ProfileOpenFotoDeleteButton.addEventListener("click", clearInputAndImage);
  }
  _events() {
    if (this.#initializedEvent) return;
    ProfileOpenButtonBack.addEventListener("click", (e) => {
      // e.target.closest(".ProfileOpenCon1").style.display = "none";
      this._close();
    });
    ProfileOpenButtonLogOut.addEventListener("click", async () => {
      const MainMenuCon1A = document.querySelector(".MainMenuCon1A");
      const confirmLogOut = confirm(
        `¿Estás seguro que quieres cerar sesión con esta cuenta?
        Tus datos no se borarán.
        Los podras acceder al iniciar sesión con esta cuenta otra vez.`
      );
      console.log(confirmLogOut);

      if (!confirmLogOut) return;

      await firebase._signOutUser();
      spinner._displaySpinner("Un momento");
      this._close();
      if (MainMenuCon1A) {
        MainMenuCon1A.remove();
      }
      nav._changeNav("last");
      setTimeout(() => {
        spinner._hideSpinner();
      }, 800);
    });
    ProfileOpenButtonDeleteAccount.addEventListener("click", async () => {
      alert("Proximamente. Esta función aún no esta lista.");
    });
    this.#initializedEvent = true;
  }
}

export default new ManageAccountAndProfileChanges();
