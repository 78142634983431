import iconNavHome from "../../../Icons/nav-icons/none-active/home.png";
import iconNavSaved from "../../../Icons/nav-icons/none-active/saved.png";
import iconNavWallet from "../../../Icons/nav-icons/none-active/wallet.png";
import iconNavBusiness from "../../../Icons/nav-icons/none-active/business.png";
import iconNavMenu from "../../../Icons/nav-icons/none-active/menu.png";
import iconAhead from "../../../Icons/icons-white/go.png";
import iconExit from "../../../Icons/icons-white/exit.png";
import iconIstall from "../../../Icons/icons-beige/icon-install.png";

class generateHtml {
  // Start new type of Main screen

  _HeaderTop(helloMesage, userName) {
    return `
<div id="HeaderCon2ForColorChangeWhenMiniScreenOnDisplay" class="HeaderCon1">
  <div id="HeaderCon1BolckAction" class="HeaderCon1BolckAction" style="display: none"></div>
  <div class="HeaderCon2">
    <div class="HeaderCon3">
      <div class="HeaderCon4">
        <div class="HeaderCon5">
          <div class="AppNameMain">Mercado</div>
          <div class="AppNameSecondary">MAI</div>
        </div>
        <div class="AppNameContry"></div>
      </div>
      <div id="HeaderConWellcomeWithName" class="HeaderCon6">
        <div class="HeaderUserHola">${helloMesage}</div>
        <div class="HeaderUserName">${userName}</div>
        <img height="18px" src="${iconAhead}"/>
      </div>
    </div>
        <div
          id="PreInstallCon"
          class="PrePageConForInstallationCon"
        >
          <div class="PrePageCon2A3">
            <div id="PrePageConForInstallationConClose" class="PrePageConForInstallationConClose">
              <img height="20px" src="${iconExit}"/>
            </div>
            <div class="PrePageCon2A3Title">Instalar esta aplicación</div>
            <div class="PrePageCon2A4">
              <div class="PrePageCon2A4Description">
                Ahora también puedes instalar Mercado MAI en tu celular como una
                aplicación móvil, y lo puedes abrir con un solo click.
              </div>
              <div class="PrePageCon2A4Button">
                <img height="32px" src="${iconIstall}"/>
                <div id="installBtn" class="PrePageCon2A4ButtonText">
                  Instalar ahora
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
    `;
  }
  _FooterNavBar() {
    return `
       <div class="NavBarCon1">
       <div id="NavBarCon1BlockNav" class="NavBarCon1BlockNav" style="display: none"></div>
       <div class="NavBarCon1B">
          <div data-menuoption="Home" class="NavBarCon2">
            <img class="NavBarIconCon" src="${iconNavHome}" alt="Home Icon" />
            <div class="NavBarText">Inicio</div>
          </div>
          <div data-menuoption="Saved" class="NavBarCon2">
            <img class="NavBarIconCon" src="${iconNavSaved}" alt="Saved Icon" />
            <div class="NavBarText">Guardados</div>
          </div>
          <div data-menuoption="Wallet" class="NavBarCon2">
            <img class="NavBarIconCon" src="${iconNavWallet}" alt="Wallet Icon" />
            <div class="NavBarText">Tu Wallet</div>
          </div>
          <div data-menuoption="Business" class="NavBarCon2">
            <img class="NavBarIconCon" src="${iconNavBusiness}" alt="Business Icon" />
            <div class="NavBarText">Tu Negocio</div>
          </div>
          <div data-menuoption="Menu" class="NavBarCon2">
            <img class="NavBarIconCon" src="${iconNavMenu}" alt="Menu Icon" />
            <div class="NavBarText">Menu</div>
          </div>
        </div>
        </div>
    `;
  }

  _initialMainScreenGeneration(htmlHeaderNav, htmlFooterNav) {
    return `
  <div class="MainAppConWithNavigationCon2">
    ${htmlHeaderNav}
    <div class="MainAppConWithNavigationCon">
      <div id="MainAppConWithNavigationContentConHome" style="display: none" class="MainAppConWithNavigationContentCon"></div>
      <div id="MainAppConWithNavigationContentConBusiness" style="display: none" class="MainAppConWithNavigationContentCon"></div>
      <div id="MainAppConWithNavigationContentConWallet" style="display: none" class="MainAppConWithNavigationContentCon"></div>
      <div id="MainAppConWithNavigationContentConSaved" style="display: none" class="MainAppConWithNavigationContentCon"></div>
    </div>
    ${htmlFooterNav}
  </div>
    `;
  }
}

export default new generateHtml();
