import iconDone from "../../../Icons/icons-charcoal-dark/done.png";
import iconCamara from "../../../Icons/icons-charcoal-dark/camara.png";

import iconBack from "../../../Icons/icons-white/back-arrow.png";
import iconListed from "../../../Icons/icons-charcoal-dark/listed.png";
import iconAccount from "../../../Icons/icons-charcoal-dark/account.png";
import iconBusiness from "../../../Icons/nav-icons/active/business.png";

{
  /* <div class="CreateListingOptionsInputCon1">
        <div class="CreateListingOptionsInputCon2">
          <div class="SearchStartUpComponentCon10Label">Número para llamadas</div>
          <div class="CreateListingImportantMessage">
            El número debe ser del país de México, ya que ocupamos '+52' en todos los
            números.
          </div>
          <div class="CreateListingOptionsInputCon3">
            <input class="CreateListingOptionsInputInput" type="text" name=""
            placeholder="1234567890" id="${prefixId}CreateListingCallNumber" inputmode="numeric"
            maxlength="10"
            value="${callNumber}" ${edit ? "" : "readonly"} />
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageCallingNumber" class="CreateListingErrorMessage"></div>
      </div>
      <div class="CreateListingOptionsInputCon1">
        <div class="CreateListingOptionsInputCon2">
          <div class="SearchStartUpComponentCon10Label">Número para WhatsApp</div>
          <div class="CreateListingImportantMessage">
            El número debe ser del país de México, ya que ocupamos '+52' en todos los
            números.
          </div>
          <div class="CreateListingOptionsInputCon3">
            <input class="CreateListingOptionsInputInput" type="text" name=""
            placeholder="1234567890" id="${prefixId}CreateListingWhatsNumber" inputmode="numeric"
            maxlength="10"
            value="${whatsNumber}" ${edit ? "" : "readonly"} />
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageWhatsAppNumber" class="CreateListingErrorMessage"></div>
      </div> */
}

class GenerateHTML {
  _display(prefixId) {
    let edit = true;

    //   <div class="CreateListingNameUlrText">
    //   https://mqmexico.com/listings/john-deere-9600-2018
    // </div>
    return `
<div  id="${prefixId}SearchStartUpComponentAllCon" class="CreateListingCon1">
  <div class="CreateListingCon5B">
    <div id="CreateListingBtnCancel" class="CreateListingCon5ButtonCancel">
      <img height="20px" src="${iconBack}"/>cancelar
    </div>
    <div id="CreateListingBtnPublish" class="CreateListingCon5ButtonCreateListing">
        Publicar listado
        <img height="20px" src="${iconListed}"/>
    </div>
  </div>
  <div class="CreateListingCon2A">
  <div class="LogInCon6">
    <div data-helpwith="createListing" class="WalletCon8 openHelp">
      <div class="WalletText4">¿Como puedo crear un listado?</div>
    </div>
  </div>
  <div class="CreateListingImagesCon1">
    <div class="CreateListingImagesCon2">
      <div class="CreateListingImagesCon3" id="uploaded-images-container">
        <!-- Images will be dynamically added here -->
      </div>
      <div class="CreateListingImagesCon4">
        <div
          id="CreateListingImageOrganizeMessage"
          class="CreateListingImagesOrganizeText CreateListingImagesAddImagesTextHidden"
        >
          Puedes organizar tus imágenes de la primera a la última, tomándolas y
          arrastrándolas al lugar que quieras. La primer imagen será mostrada en
          las busquedas.
        </div>
      </div>
    </div>
    <div class="CreateListingImagesCon5" id="upload-trigger">
      <img class="CreateListingImageUploadIcon" src="${iconCamara}" alt="" />
      <div class="CreateListingImagesAddImagesText">Agregar imágenes</div>
    </div>
    <!-- Hidden file input -->
    <input
      type="file"
      id="image-upload-input"
      multiple
      accept="image/*"
      style="display: none"
    />
    <div id="${prefixId}CreateListingErrorMessageImages" class="CreateListingErrorMessage"></div>
  </div>
  <div class="CreateListingCon1B">
  <div class="CreateListingCon2">
    <div class="CreateListingNameCon1">
      <div class="SearchStartUpComponentCon10Label">Nombre de listados</div>
      <input
        class="CreateListingNameInput"
        type="text"
        name=""
        placeholder="maquina, marca, modelo"
        id="${prefixId}CreateListingName"
      />
      <div class="CreateListingNameCon2"></div>
      <div id="${prefixId}CreateListingErrorMessageName" class="CreateListingErrorMessage"></div>
    </div>
    <div class="CreateListingCon3">
      <div class="CreateListingOptionsHeader">Características</div>
      <div class="CreateListingCon4">
        
        <div
        id="${prefixId}SearchStartUpComponentSelectMachineType"
        class="SearchStartUpComponentCon8"
        >
        <div class="SearchStartUpComponentCon9">
          <div
            class="SearchStartUpComponentCon10"
          >
            <div class="SearchStartUpComponentCon10Label">Maquina</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones de maquina"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageMachineType" class="CreateListingErrorMessage"></div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineBrand"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div class="SearchStartUpComponentCon10">
            <div class="SearchStartUpComponentCon10Label">Marca</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones de marca"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageBrand" class="CreateListingErrorMessage"></div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineModel"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div class="SearchStartUpComponentCon10">
            <div class="SearchStartUpComponentCon10Label">Modelo</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones modelo"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageModel" class="CreateListingErrorMessage"></div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineYear"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div
            class="SearchStartUpComponentCon10 SearchStartUpComponentCon10Clear"
          >
            <div class="SearchStartUpComponentCon10Label">Año</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Seleccionar año"
                maxlength="4"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageYear" class="CreateListingErrorMessage"></div>
      </div>
      <div id="${prefixId}SearchStartUpComponentSelectMachineCondition" class="SearchStartUpComponentCon8">
        <div class="SearchStartUpComponentCon9">
          <div
            class="SearchStartUpComponentCon10 SearchStartUpComponentCon10Clear"
          >
            <div class="SearchStartUpComponentCon10Label">Condición</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Seleccionar Condición"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
        <div id="${prefixId}CreateListingErrorMessageCondition" class="CreateListingErrorMessage"></div>
      </div>
      </div> 
    </div>


      <div class="CreateListingOptionsInputCon1B">
        <div class="CreateListingOptionsInputCon2">
          <div class="SearchStartUpComponentCon10Label">Precio</div>
          <div class="CreateListingOptionsInputCon3">
            <input
              class="CreateListingOptionsInputInput"
              type="text"
              name=""
              placeholder="$0"
              id="${prefixId}CreateListingPrice"
              inputmode="numeric"
            />
          </div>
          <div id="${prefixId}CreateListingErrorMessagePrice" class="CreateListingErrorMessage"></div>
        </div>
        <div class="CreateListingOptionsPicCon1">
          <div class="CreateListingOptionsLabelSecondary">Moneda</div>
          <div
            data-coin="none"
            id="CreateListingCoinPic"
            class="CreateListingOptionsPicCon2"
          >
            <div id="CreateListingCoinPicMXN" class="CreateListingOptionsPicCon3">
              MXN
            </div>
            <div id="CreateListingCoinPicUSD" class="CreateListingOptionsPicCon3">
              USD
            </div>
          </div>
          <div id="${prefixId}CreateListingErrorMessageCoin" class="CreateListingErrorMessage"></div>
        </div>


        <div class="CreateInputAdjustDiscountOnPriceCon1">
          <div class="SearchStartUpComponentCon10Label">Ajustar descuento</div>
          <div class="CreateInputAdjustDiscountOnPriceCon2">
            <div class="CreateListingOptionsPicCon1">
                <div class="CreateListingOptionsLabelSecondary">porcentaje %</div>
                <input
                  class="CreateListingOptionsInputInput"
                  type="text"
                  name=""
                  placeholder="0%"
                  id="${prefixId}CreateListingDiscountPercent"
                  inputmode="numeric"
                />
            </div>
            <div class="CreateListingOptionsPicCon1">
                <div class="CreateListingOptionsLabelSecondary">descuento $</div>
                <input
                  class="CreateListingOptionsInputInput"
                  type="text"
                  name=""
                  placeholder="$0"
                  id="${prefixId}CreateListingDiscountAmount"
                  inputmode="numeric"
                />
            </div>
            <div class="CreateListingOptionsPicCon1">
                <div class="CreateListingOptionsLabelSecondary">precio ahora $</div>
                <input
                  class="CreateListingOptionsInputInput"
                  type="text"
                  name=""
                  placeholder="$0"
                  id="${prefixId}CreateListingDiscountFinalPrice"
                  inputmode="numeric"
                />
            </div>
          </div>
        </div>




      </div>
    <div class="CreateListingDescriptionCon1">
      <div class="SearchStartUpComponentCon10Label">Descripción de la máquina</div>
      <textarea
        class="CreateListingDescriptionTextarea"
        name=""
        id="${prefixId}CreateListingDescription"
        placeholder=" Escribe todo los demás detalles aquí..."
      ></textarea>
      <div id="${prefixId}CreateListingErrorMessageDescription" class="CreateListingErrorMessage"></div>
    </div>
   
  </div>
  </div>
  </div>



  <div id="SelectPublishProfileCon1" class="SelectPublishProfileCon1" style="display: none">
    <div class="SelectPublishProfileCon2">
      <div class="SelectPublishProfileCon3">
        <div class="SelectPublishProfileText1">Selecciona en donde quieres publicar este listado</div>
        <div class="SelectPublishProfileCon4">
          <div id="SelectPublishProfileCon1SelectAccount" data-select="profile" class="SelectPublishProfileSelectCon1">
            <div id="SelectPublishProfileCon2SelectAccount" class="SelectPublishProfileSelectCon2">
              <div id="SelectPublishProfileCon3SelectAccount" class="SelectPublishProfileSelectCon3">
                <img height="30px" src="${iconAccount}"/>
              </div>
              <div id="SelectPublishProfileCon1SelectAccountName" class="SelectPublishProfileText3"></div>
            </div>
            <div class="SelectPublishProfileText4">Seleccionar perfil de vendedor</div>
          </div>
          <div id="SelectPublishProfileCon1SelectBusiness" data-select="page" class="SelectPublishProfileSelectCon1">
            <div id="SelectPublishProfileCon2SelectBusiness" class="SelectPublishProfileSelectCon2">
              <div id="SelectPublishProfileCon3SelectBusiness" class="SelectPublishProfileSelectCon3">
                <img height="30px" src="${iconBusiness}"/>
              </div>
              <div id="SelectPublishProfileCon1SelectBusinessName" class="SelectPublishProfileText3"></div>
            </div>
            <div class="SelectPublishProfileText4">Seleccionar página de negocio</div>
          </div>
        </div>
      </div>
      <div class="SelectPublishProfileCon5">
        <div class="SelectPublishProfileCon6B">
          <div id="SelectPublishProfileCon1ButtonBack" class="SelectPublishProfileCon6">
            <div class="SelectPublishProfileText2">Regresar</div>
          </div>
        </div>
        <div class="SelectPublishProfileCon6B">
          <div id="SelectPublishProfileCon1ButtonPublish" class="SelectPublishProfileCon7">
            <div class="SelectPublishProfileText2">Publicar</div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>



      `;
  }
}
export default new GenerateHTML();
