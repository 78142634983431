import iconCharcoalDarkSaved from "../../../Icons/icons-charcoal-dark/saved.png";
import iconCharcoalDarkliked from "../../../Icons/icons-charcoal-dark/liked.png";
import iconCharcoalDarkSave from "../../../Icons/icons-charcoal-dark/save.png";
import iconCharcoalDarkMenuUp from "../../../Icons/icons-charcoal-dark/menu-up.png";
import iconCharcoalDarkMenuDown from "../../../Icons/icons-charcoal-dark/menu-down.png";
import iconWhiteExit from "../../../Icons/icons-white/exit.png";

import generateHtml from "./generate-html";
import firebase from "../firebase";
import spinner from "../spinner";

import GENRALHELPERS from "../GENRAL-HELPERS";
import handleAccount from "../register/handle-account";
import register from "../register/register";
import StackDisplay from "../../modules/stack-display/display";
import BusinessDisplayEach from "../display-business-each/display";
import allHandeling from "../handeling-all-displays/all-handeling";

const MainOneOnOverTheOther = document.getElementById("MainOneOnOverTheOther");

let debounceTimeout;

const images = [
  "355c0a80-0eb4-4bc7-b28e-cbe202dfa29b.jpg",
  "e612ac7a-ee51-466f-a682-79c2db0c6ee3.jpg",
  "7bee6f00-cdf6-415d-ae51-ff197f76f61b.jpg",
  "c0b2dfa0-f006-487b-9ee8-ef21d2245af3.jpg",
];

class display {
  #inititlazied = false;
  constructor() {}

  _returnMiniSpinner() {
    return `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20"
      height="20"
      class="miniSpinneForButtons">
      <circle
        cx="25"
        cy="25"
        r="20"
        stroke="#2B2B2B"
        stroke-width="4"
        fill="none"
        stroke-dasharray="125.6"
        stroke-dashoffset="94.2"
        stroke-linecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.8s"
          repeatCount="indefinite" />
      </circle>
    </svg>`;
  }

  async _init(data, business, openFrom, share) {
    console.log(data);
    spinner._displaySpinner("Cargando información");
    this._mainEvenst(); // Initialize events

    const idForConTainer = GENRALHELPERS._getUniqueIdfunction();
    StackDisplay._display(generateHtml._initialCon(idForConTainer));
    // Fetch all image URLs in parallel
    const imgUrls = data.listingImgsIds;
    // const imgUrls = await Promise.all(
    //   data.listingImgsIds.map((imgId) => firebase._getImageUrl(imgId))
    // );

    // Display the images once all URLs are retrieved
    this._displayImages(imgUrls, idForConTainer);
    await this._displayOtherData(data, business, idForConTainer, openFrom);

    console.log(share);
    if (share) {
      const listingCon = document.getElementById(
        `${idForConTainer}ForInstantSharing`
      );
      console.log(listingCon);
      const shareButton = listingCon.querySelector(
        "#DisplayListingEachButtonActionShare"
      );
      console.log(shareButton);
      // shareButton.click();
      this._shareListing(shareButton);
    }

    spinner._hideSpinner();
    if (this.#inititlazied) return;
    this.#inititlazied = true;
    this._listingEvents();
  }

  _shareListing = async (e) => {
    const parentEL = e.closest(".ActionForShare");
    const shareWhat = parentEL.dataset.share;
    const businessId = parentEL.dataset.businessid;
    const listingId = parentEL.dataset.listingid;
    const businessName = parentEL.dataset.businessname;
    const listingName = parentEL.dataset.listingname;
    const listingPrice = parentEL.dataset.listingprice;
    const listingPriceBefore = parentEL.dataset.listingpricebefore;
    const listingCoin = parentEL.dataset.listingcoin;

    let imgsUrls = [];
    let imgsUrlsAll = [];
    const siblingWithImgCon = parentEL.nextElementSibling?.classList.contains(
      "imgCon"
    )
      ? parentEL.nextElementSibling
      : parentEL.previousElementSibling?.classList.contains(
          "ListingDisplayImagesDisplayCon1"
        )
      ? parentEL.previousElementSibling
      : null;

    if (siblingWithImgCon) {
      siblingWithImgCon
        .querySelectorAll(".ListingDisplayImagesDisplayImage")
        .forEach((img) => {
          imgsUrlsAll.push(img.src);
        });
      imgsUrls = [imgsUrlsAll[0]];
    }

    console.log("Sibling with class 'imgCon':", siblingWithImgCon);

    let businessNameForUse = false;
    console.log(businessName);
    if (businessName !== "null" && businessName !== null) {
      businessNameForUse = businessName;
    }

    const url = await GENRALHELPERS._createUrlForSharing(
      businessId,
      businessNameForUse,
      listingId,
      listingName
    );
    let shareDescription = "";

    if (listingPriceBefore !== "null") {
      shareDescription = `En venta: *${listingName}*
      ${GENRALHELPERS._convertToPrice(listingPrice, listingCoin)}
      \n*Está en oferta.*
       _Ahora en_ *${GENRALHELPERS._convertToPrice(
         listingPriceBefore,
         listingCoin
       )}*
        \n *Ver más información en:* ${url}
        \n *Visita el nuevo centro de _*Compra y Venta*_ *de maquinaria agricola en:* _https://maquinariasonline.com_
        \n 📱 *Un lugar para todo* \n 🔗 *COMPARTE*`;
    } else {
      shareDescription = `En venta: *${listingName}*
      ${GENRALHELPERS._convertToPrice(listingPrice, listingCoin)}
      \n *Ver más información en:* ${url} 
      \n *Visita el nuevo centro de _*Compra y Venta*_ *de maquinaria agricola en:* _https://maquinariasonline.com_ 
      \n 📱 *Un lugar para todo* \n 🔗 *COMPARTE*`;
    }

    const sharingResult = await GENRALHELPERS._shareImagesWithText(
      listingName,
      shareDescription,
      imgsUrls
    );
    if (sharingResult.success) {
      console.log(sharingResult.message); // "Shared successfully"
      // Perform any post-share actions, like updating stats
    } else {
      console.error(sharingResult.message); // "Error: <error message>"
      // Show an error message to the user
    }

    let dataSendToShare = {
      listingId: listingId,
    };

    if (businessNameForUse) {
      dataSendToShare = {
        listingId: listingId,
        businessId: businessId,
      };
    }

    if (sharingResult.success) {
      firebase._updateSharesOnListings(dataSendToShare);
    }
  };

  _listingEvents() {
    // if (this.#inititlazied) return;
    window.addEventListener("click", async (e) => {
      // Start Tecnical info disp hide
      const tecnicalTakeCare = () => {
        console.log("call");
        const parentButtonEl = e.target.closest(".DisplayListingEachCon19");
        const parentAllEl = e.target.closest(".DisplayListingEachCon18");
        const siblingEL = parentAllEl.querySelector(".DisplayListingEachCon20");
        const img = parentButtonEl.querySelector("img");

        if (parentButtonEl.dataset.active === "active") {
          img.src = iconCharcoalDarkMenuDown;
          siblingEL.classList.toggle("DisplayListingEachtechnicalInfoHide");
          parentButtonEl.dataset.active = "noneActive";
          console.log("here");
        } else if (parentButtonEl.dataset.active === "noneActive") {
          img.src = iconCharcoalDarkMenuUp;
          siblingEL.classList.toggle("DisplayListingEachtechnicalInfoHide");
          parentButtonEl.dataset.active = "active";
          console.log("here");
        }
      };

      // Start OpeningAnotherBusiness
      const openListingsBusinessFromListing = async () => {
        const closetsForOpenBusiness = e.target.closest(
          ".DisplayListingEachCon25"
        );
        console.log(closetsForOpenBusiness);
        const businessId = closetsForOpenBusiness.dataset.businessid;
        console.log(businessId);

        if (closetsForOpenBusiness) {
          const businessToOpen = await allHandeling._getBusinessDataById(
            businessId
          );
          console.log(businessToOpen);

          BusinessDisplayEach._init(businessToOpen);
        }
      };
      // Start Saving function
      const saveTakeCare = async () => {
        console.log("clicked");
        const parentEL = e.target.closest(".ActionForShare");
        const parentButtonEL = e.target.closest(
          "#DisplayListingEachButtonActionSave"
        );
        const listingId = parentEL.dataset.listingid;
        const businessId = parentEL.dataset.businessid;
        const businessName = parentEL.dataset.businessname;
        let isBusiness = false;

        let path;
        if (businessName !== "null" && businessName !== null) {
          path = `businesses/${businessId}/listings/${listingId}`;
          isBusiness = true;
        } else {
          path = `listings/${listingId}`;
        }

        const saveObj = { path: path };

        const account = firebase._getLatestCurrentUserData();

        let dataSendToSave = { listingId: listingId };

        if (isBusiness) {
          dataSendToSave = { listingId: listingId, businessId: businessId };
        }

        if (!account) {
          console.log("no account");
          register._displayOptions(
            null,
            "Para guardar listados tienes que tener una cuenta activa en este dispositivo."
          );
          return;
        }

        parentButtonEL.insertAdjacentHTML(
          "beforeend",
          this._returnMiniSpinner()
        );

        const pastSavesResult = await firebase._updateSavesOnListings(
          dataSendToSave
        );

        console.log(pastSavesResult);

        if (pastSavesResult.data.status === "success") {
          if (pastSavesResult.data.action === "saved") {
            handleAccount._getAccountState();
            parentButtonEL.querySelector("img").src = iconCharcoalDarkSaved;
            parentButtonEL.querySelector(".miniSpinneForButtons").remove();
          }
          if (pastSavesResult.data.action === "removed") {
            handleAccount._getAccountState();
            parentButtonEL.querySelector("img").src = iconCharcoalDarkSave;
            parentButtonEL.querySelector(".miniSpinneForButtons").remove();
          }
        }
      };
      // Start Liking function
      const likeTakeCare = async () => {
        const parentEL = e.target.closest(".ActionForShare");
        const parentButtonEL = e.target.closest(
          "#DisplayListingEachButtonActionLike"
        );
        const listingId = parentEL.dataset.listingid;
        const businessId = parentEL.dataset.businessid;
        const businessName = parentEL.dataset.businessname;

        let path;
        if (businessName !== "null" && businessName !== null) {
          path = `businesses/${businessId}/listings/${listingId}`;
        }

        const account = firebase._getLatestCurrentUserData();
        if (!account) {
          console.log("no account");
          register._displayOptions(
            null,
            "Para dar un like tienes que tener una cuenta activa en este dispositivo."
          );
          return;
        }

        parentButtonEL.insertAdjacentHTML(
          "beforeend",
          this._returnMiniSpinner()
        );

        let dataSendToLike = {
          listingId: listingId,
          businessId: businessId,
        };

        const likesResult = await firebase._updateLikesOnListings(
          dataSendToLike
        );

        console.log(likesResult);

        if (likesResult.data.status === "success") {
          handleAccount._getAccountState();

          parentButtonEL.querySelector("img").src = iconCharcoalDarkliked;
          parentButtonEL.querySelector(".miniSpinneForButtons").remove();
        } else {
          alert("No puedes quitar el like de los listados");
          parentButtonEL.querySelector(".miniSpinneForButtons").remove();
        }
      };

      // Start Contacting
      const openWhatsApp = (phoneNumber, message) => {
        try {
          // Encode the phone number and message for URL safety
          const encodedMessage = encodeURIComponent(message);
          const whatsappUrl = `https://wa.me/+52${phoneNumber}?text=${encodedMessage}`;

          // Open the WhatsApp link in a new tab or current tab
          window.open(whatsappUrl, "_blank");

          console.log("WhatsApp link opened:", whatsappUrl);
        } catch (error) {
          console.error("Error creating or opening WhatsApp link:", error);
        }
      };
      // Start WhatsApp function
      const whatsAppTakeCare = async () => {
        const parentButtonEL = e.target.closest(
          "#DisplayListingEachButtonActionWhatsApp"
        );
        const whatsAppNumber = parentButtonEL.dataset.whatsappnumber;
        const parentEL = parentButtonEL.closest(".ActionForShare");
        const listingId = parentEL.dataset.listingid;
        const businessName = parentEL.dataset.businessname;
        const businessId = parentEL.dataset.businessid;
        const listingName = parentEL.dataset.listingname;

        let businessNameForUse = false;
        console.log(businessName);
        if (businessName !== "null" && businessName !== null) {
          businessNameForUse = businessName;
        }

        const url = await GENRALHELPERS._createUrlForSharing(
          businessId,
          businessNameForUse,
          listingId,
          listingName
        );
        console.log(url);
        console.log(whatsAppNumber);
        let listingNameForUse = listingName;
        // let listingNameForUse = "``" + listingName + "``";
        openWhatsApp(
          whatsAppNumber,
          `Estoy interesado en *${listingNameForUse}* \n \n Listado: _${url}_ \n \n`
        );
      };
      // Start Share function
      // const shareListing = async (e) => {
      //   const parentEL = e.target.closest(".ActionForShare");
      //   const shareWhat = parentEL.dataset.share;
      //   const businessId = parentEL.dataset.businessid;
      //   const listingId = parentEL.dataset.listingid;
      //   const businessName = parentEL.dataset.businessname;
      //   const listingName = parentEL.dataset.listingname;
      //   const listingPrice = parentEL.dataset.listingprice;
      //   const listingCoin = parentEL.dataset.listingcoin;

      //   let imgsUrls = [];
      //   let imgsUrlsAll = [];
      //   const siblingWithImgCon =
      //     parentEL.nextElementSibling?.classList.contains("imgCon")
      //       ? parentEL.nextElementSibling
      //       : parentEL.previousElementSibling?.classList.contains(
      //           "ListingDisplayImagesDisplayCon1"
      //         )
      //       ? parentEL.previousElementSibling
      //       : null;

      //   if (siblingWithImgCon) {
      //     siblingWithImgCon
      //       .querySelectorAll(".ListingDisplayImagesDisplayImage")
      //       .forEach((img) => {
      //         imgsUrlsAll.push(img.src);
      //       });
      //     imgsUrls = [imgsUrlsAll[0]];
      //   }

      //   console.log("Sibling with class 'imgCon':", siblingWithImgCon);

      //   let businessNameForUse = false;
      //   console.log(businessName);
      //   if (businessName !== "null" && businessName !== null) {
      //     businessNameForUse = businessName;
      //   }

      //   const url = await GENRALHELPERS._createUrlForSharing(
      //     businessId,
      //     businessNameForUse,
      //     listingId,
      //     listingName
      //   );

      //   const shareDescription = `En venta: *${listingName}*\n _${GENRALHELPERS._convertToPrice(
      //     listingPrice,
      //     listingCoin
      //   )}_ \n \n *Ver más información en:* ${url} \n \n *Visita nuestro mercado de maquinaria agricola e industrial en:* _http://mercadopromaq.com_ \n \n 🔗 *COMPARTE*`;

      //   const sharingResult = await GENRALHELPERS._shareImagesWithText(
      //     listingName,
      //     shareDescription,
      //     imgsUrls
      //   );
      //   if (sharingResult.success) {
      //     console.log(sharingResult.message); // "Shared successfully"
      //     // Perform any post-share actions, like updating stats
      //   } else {
      //     console.error(sharingResult.message); // "Error: <error message>"
      //     // Show an error message to the user
      //   }

      //   let dataSendToShare = {
      //     listingId: listingId,
      //   };

      //   if (businessNameForUse) {
      //     dataSendToShare = {
      //       listingId: listingId,
      //       businessId: businessId,
      //     };
      //   }

      //   if (sharingResult.success) {
      //     firebase._updateSharesOnListings(dataSendToShare);
      //   }
      // };

      // Start technical Info
      if (e.target.closest(".DisplayListingEachCon19")) {
        tecnicalTakeCare();
      }

      // Start liking action
      if (e.target.closest("#DisplayListingEachButtonActionSave")) {
        saveTakeCare();
      }
      // Start saving action
      if (e.target.closest("#DisplayListingEachButtonActionLike")) {
        likeTakeCare();
      }
      // Start calling action
      if (e.target.closest("#DisplayListingEachButtonActionWhatsApp")) {
        whatsAppTakeCare();
      }
      // Start sharing action
      if (e.target.closest("#DisplayListingEachButtonActionShare")) {
        this._shareListing(e.target);
      }
      // Start go to listing's business
      if (e.target.closest(".DisplayListingEachCon25")) {
        openListingsBusinessFromListing();
      }
    });

    // this.#inititlazied = true;
  }

  async _displayOtherData(data, business, id, openFrom) {
    console.log(business);
    const DisplayListingEachCon5 = document.getElementById(id);
    let viewsData = {};

    const businessImgUrls = [];
    if (business) {
      businessImgUrls.push(business.backgroundImg);
      // businessImgUrls.push(await firebase._getImageUrl(business.backgroundImg));
      businessImgUrls.push(business.profileImg);
      // businessImgUrls.push(await firebase._getImageUrl(business.profileImg));

      viewsData = {
        businessId: business.businessId,
        listingId: data.listingId,
      };

      // firebase._updateDocStats(
      //   `businesses/${business.businessId}/listings/${data.listingId}`,
      //   "listingViews",
      //   1
      // );
    }

    if (!business) {
      viewsData = {
        listingId: data.listingId,
      };

      // firebase._updateListingViews({ id: data.listingId });
      // firebase._updateDocStats(`listings/${data.listingId}`, "listingViews", 1);
    }

    const html = generateHtml._allOtherInfo(
      data,
      business,
      businessImgUrls,
      openFrom
    );
    DisplayListingEachCon5.insertAdjacentHTML("beforeend", html);

    firebase._updateViewsOnListings(viewsData);

    // this.#inititlazied = true;
  }

  _displayImages(imgUrls, id) {
    const DisplayListingEachCon5 = document.getElementById(id);

    console.log(imgUrls);
    const html = generateHtml._imageDisplayer(imgUrls);
    DisplayListingEachCon5.insertAdjacentHTML("beforeend", html);
    this._initializeImageSlider(imgUrls);
  }
  _initializeImageSlider(imgUrls) {
    let activatedSnap = false;
    const container = document.querySelector(
      ".ListingDisplayImagesDisplayCon2"
    );

    if (container) {
      container.scrollTo(0, 0);
      container.addEventListener("scroll", () => {
        if (activatedSnap) return;
        container.style.scrollSnapType = "x mandatory";
        activatedSnap = true;
      });
      // container.addEventListener("click", (e) => {
      //   const isImg = e.target.closest("img");
      //   if (isImg) {
      //     this._myOwnImgDisplayerNotAIs([isImg.src]);
      //     console.log("clicked on img");
      //   }
      // });
    }

    const images = Array.from(
      document.querySelectorAll(".ListingDisplayImagesDisplayImage")
    );
    const dots = document.querySelectorAll(
      ".ListingDisplayImagesDisplayDotIndicator"
    );
    let currentIndex = 0;
    let userInitiatedScroll = true;

    const updateActiveState2 = (index) => {
      images.forEach((img, i) => {
        if (i === index) {
          img.classList.add("ListingDisplayImagesDisplayImageNoneActive");
        } else {
          img.classList.remove("ListingDisplayImagesDisplayImageNoneActive");
        }
      });
    };

    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = images.indexOf(entry.target);

          if (entry.isIntersecting && !userInitiatedScroll) {
          } else if (!entry.isIntersecting) {
            updateActiveState2(index);
          }
        });
      },
      { root: container, threshold: 0.33 }
    );

    images.forEach((image) => observer2.observe(image));

    // Utility to update active classes for images and dots
    const updateActiveState = (index) => {
      dots.forEach((dot, i) => {
        dot.classList.toggle(
          "ListingDisplayImagesDisplayDotIndicatorActive",
          i === index
        );
      });
    };

    // Utility to scroll to a specific image and center it
    const scrollToImage = (index) => {
      console.log("called", userInitiatedScroll);
      const image = images[index];
      const offsetLeft =
        image.offsetLeft - (container.offsetWidth - image.offsetWidth) / 2;
      container.scrollTo({ left: offsetLeft, behavior: "smooth" });
    };
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !userInitiatedScroll) {
            const index = images.indexOf(entry.target);
            if (index !== currentIndex) {
              currentIndex = index;
              updateActiveState(currentIndex);
            }
          }
        });
      },
      { root: container, threshold: 0.9 }
    );
    images.forEach((image) => observer.observe(image));

    container.addEventListener("scroll", () => {
      userInitiatedScroll = false;
    });

    // Dot click event for navigation
    dots.forEach((dot) => {
      dot.addEventListener("click", (event) => {
        userInitiatedScroll = true;
        const index = parseInt(event.target.dataset.index, 10);
        currentIndex = index;
        updateActiveState(index);
        scrollToImage(index);
        setTimeout(() => {
          userInitiatedScroll = false;
        }, 500); // Allow time for scrolling animation
      });
    });

    const ListingDisplayImagesDisplayButtonImgBack = document.querySelector(
      ".ListingDisplayImagesDisplayButtonImgBack"
    );
    const ListingDisplayImagesDisplayButtonImgAhead = document.querySelector(
      ".ListingDisplayImagesDisplayButtonImgAhead"
    );

    const imgBack = () => {
      if (currentIndex > 0) {
        currentIndex--;
        userInitiatedScroll = true;
        updateActiveState(currentIndex);
        scrollToImage(currentIndex);
        setTimeout(() => {
          userInitiatedScroll = false;
        }, 500); // Allow time for scrolling animation
      }
    };

    const imgAhead = () => {
      if (currentIndex < imgUrls.length - 1) {
        currentIndex++;
        userInitiatedScroll = true;
        updateActiveState(currentIndex);
        scrollToImage(currentIndex);
        setTimeout(() => {
          userInitiatedScroll = false;
        }, 500); // Allow time for scrolling animation
      }
    };

    ListingDisplayImagesDisplayButtonImgBack.addEventListener("click", imgBack);
    ListingDisplayImagesDisplayButtonImgAhead.addEventListener(
      "click",
      imgAhead
    );

    window.addEventListener("keyup", (event) => {
      if (event.altKey && event.key === "ArrowLeft") {
        console.log("Back action detected");
        imgBack();
      }

      if (event.altKey && event.key === "ArrowRight") {
        console.log("Forward action detected");
        imgAhead();
      }
    });

    // Initial setup
    updateActiveState(0);
    scrollToImage(0);
  }

  _mainEvenst() {
    MainOneOnOverTheOther.addEventListener("click", (e) => {});
  }

  _hideImgOnSwipe() {
    let startY = 0;
    let startTime = 0;
    const swipeThreshold = 80; // Minimum swipe distance (in px)
    const maxDuration = 180; // Max swipe duration (in ms)

    const container = document.getElementById("MainContainerForImgDisplayer");
    const container2 = document.getElementById("MyOwnImgDisplayerNotAIsCon1");

    container2.addEventListener("touchstart", (e) => {
      const touch = e.touches[0];
      startY = touch.pageY; // Initial touch position (Y)
      startTime = e.timeStamp; // Start time of the touch
    });

    // container2.addEventListener("touchmove", (e) => {
    //   // We can track the move, but the actual swipe will be checked on touchend.
    // });

    container2.addEventListener("touchend", (e) => {
      const touch = e.changedTouches[0];
      const endY = touch.pageY; // Final touch position (Y)
      const endTime = e.timeStamp; // End time of the touch

      const distance = endY - startY; // Distance traveled by the swipe (positive for swipe down)
      const duration = endTime - startTime; // Duration of the touch (time in ms)

      // Check if swipe was down, distance >= 80px, and duration <= 800ms
      if (distance >= swipeThreshold && duration <= maxDuration) {
        console.log("Swipe down detected!");
        container.style.display = "none";
        // Trigger the desired action for the swipe down gesture here
      }
    });
  }

  _myOwnImgDisplayerNotAIs(imgUrls) {
    const container = document.getElementById("MainContainerForImgDisplayer");
    container.style.display = "flex";

    container.addEventListener("click", (e) => {
      const isCloseButton = e.target.closest(".closeImgBig");
      if (isCloseButton) {
        console.log("but clicked here");
        container.style.display = "none";
      }
    });

    let imgHtml = ``;
    imgUrls.forEach((img) => {
      imgHtml += `<img id="MyOwnImgDisplayerNotAIsConIMG" class="MyOwnImgDisplayerNotAIsConIMG IsForZoom" src="${img}" alt="">`;
    });

    const finalHtml = `
      <div class="ExitButtonForImgDisplayer closeImgBig">
        <img height="32px" src="${iconWhiteExit}" alt="" />
      </div>
      <div id="MyOwnImgDisplayerNotAIsCon1" class="MyOwnImgDisplayerNotAIsCon1">${imgHtml}</div>`;

    container.innerHTML = finalHtml;

    // Declare scale globally inside the zoom functionality scope
    let scale = 1;

    // Function to update the container height dynamically based on the image zoom
    function updateContainerHeight(image) {
      const imageHeight = image.naturalHeight * scale; // Use the global scale variable
      const container = document.getElementById("MyOwnImgDisplayerNotAIsCon1");
      container.style.height = `${Math.min(imageHeight, window.innerHeight)}px`; // Limit to viewport height
    }

    const enableZoomForAll = () => {
      const images = document.querySelectorAll(".IsForZoom"); // Select all images with the 'IsForZoom' class

      images.forEach((image) => {
        let initialDistance = null;

        // Helper function to calculate the distance between two touch points
        function getDistance(touches) {
          const dx = touches[0].clientX - touches[1].clientX;
          const dy = touches[0].clientY - touches[1].clientY;
          return Math.sqrt(dx * dx + dy * dy);
        }

        // Function to adjust the transform origin based on the cursor or touch position
        function setTransformOrigin(e) {
          const rect = image.getBoundingClientRect();
          const x = (e.clientX || e.touches[0].clientX) - rect.left;
          const y = (e.clientY || e.touches[0].clientY) - rect.top;
          const originX = (x / rect.width) * 100;
          const originY = (y / rect.height) * 100;
          image.style.transformOrigin = `${originX}% ${originY}%`;
        }

        // Mouse wheel zoom
        image.addEventListener("wheel", (e) => {
          e.preventDefault();
          setTransformOrigin(e); // Adjust transform origin based on mouse position

          const zoomStep = 0.1;
          scale += e.deltaY < 0 ? zoomStep : -zoomStep; // Zoom in/out
          scale = Math.min(Math.max(scale, 1), 3); // Limit zoom level
          image.style.transform = `scale(${scale})`;

          // Update container height after zoom
          updateContainerHeight(image);
        });

        // Mouse move zoom (for better control over zooming while dragging)
        image.addEventListener("mousemove", (e) => {
          if (e.buttons === 1) {
            // Only zoom when mouse is pressed down
            setTransformOrigin(e);
          }
        });

        // Touch events (mobile support)
        image.addEventListener("touchstart", (e) => {
          if (e.touches.length === 2) {
            initialDistance = getDistance(e.touches);
          } else if (e.touches.length === 1) {
            setTransformOrigin(e); // Adjust transform origin on touch start
          }
        });

        image.addEventListener("touchmove", (e) => {
          if (e.touches.length === 2) {
            e.preventDefault();
            const currentDistance = getDistance(e.touches);
            if (initialDistance) {
              const zoomFactor = currentDistance / initialDistance;
              scale = Math.min(Math.max(scale * zoomFactor, 1), 3); // Limit zoom level
              image.style.transform = `scale(${scale})`;

              // Update container height after zoom
              updateContainerHeight(image);

              initialDistance = currentDistance;
            }
          } else if (e.touches.length === 1) {
            setTransformOrigin(e);
          }
        });

        image.addEventListener("touchend", () => {
          initialDistance = null;
        });
      });

      // Reset zoom on image navigation (for example, next/prev button)
      function resetZoom() {
        images.forEach((image) => {
          image.style.transform = "scale(1)"; // Reset zoom level
        });

        // Update container height after reset
        updateContainerHeight(images[0]); // Assuming we reset the first image
      }

      // Example usage: reset zoom when navigating to the next image
      const nextButton = document.getElementById("nextButton");
      if (nextButton) {
        nextButton.addEventListener("click", resetZoom);
      }

      this._hideImgOnSwipe();
    };

    // Call this function when images are displayed or after adding new images
    enableZoomForAll();
  }
}
export default new display();
