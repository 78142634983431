import firebase from "../firebase.js";
import nav from "../home-screen/nav.js";
// import HandlenAccount from "../register/handle-account";
import generateHtml from "./generate-html.js";
import homeScreen from "../home-screen/home-screen";
import installApp from "../install-app.js";
import ratingNow from "../rating-now.js";

// ratingNow._open();

// import openLinks from "../open-links/open-links.js";
// import { doc } from "firebase/firestore";

// TODO This code here is for onbording tours
// import OnboardingTour from "../onboarding-tour";
// const message = [
//   {
//     message: "Take this onboarding tour",
//     attachEl: `${this.#prefixId}ActivateOnlyBusinessSeachingButton`,
//     // hideAfter: 3000,
//     buttonNext: "Step 3",
//     buttonBack: "Step 1",
//     buttonFinish: "Done",
//   },
//   {
//     message: "Take this onboarding tour step 2",
//     attachEl: `${this.#prefixId}ActivateOnlyBusinessSeachingButton`,
//     // hideAfter: 3000,
//     buttonNext: "Step 3",
//     buttonBack: "Step 1",
//     buttonFinish: "Done",
//   },
// ];

// const TakeTour = new OnboardingTour(message);
// TakeTour.start();

class StartUpMainApp {
  #accountState;
  #mainNavInitialized;
  #lastReloadedTimeStamp;
  #reloadActive;
  constructor() {
    // TODO activate to track user visits
  }

  _changeUserNameOnHeader() {
    const HeaderUserName = document.querySelector(".HeaderUserName");

    let name = "! cuenta";
    HeaderUserName.style.color = "#FF8400";
    if (this.#accountState) {
      if (this.#accountState.userName) {
        name = this.#accountState.userName;
        HeaderUserName.style.color = "#FFFFFF";
      } else {
        name = "! nombre";
        HeaderUserName.style.color = "#FF8400";
      }
    }
    HeaderUserName.textContent = name;
  }

  async _init(loggedOut) {
    this.#reloadActive = false;
    this.#mainNavInitialized = false;
    if (!loggedOut) {
      this.#accountState = firebase._getLatestCurrentUserData();
    } else {
      this.#accountState = null;
    }
    if (!this.#accountState) {
      console.log("no account found");
    }
    this._setHomeScreen();
    nav._init();
    homeScreen._init(true);

    this._changeUserNameOnHeader();
    installApp._setPromtLoaded();
  }

  _clearContainer() {
    const MainAppContentContainer = document.getElementById(
      "MainAppContentContainer"
    );
    MainAppContentContainer.innerHTML = "";
  }
  _setHomeScreen() {
    const container = document.getElementById("MainAppContentContainer");
    let helloMesage = "Hola,";
    let userName = "Sin cuenta";
    if (this.#accountState) {
      helloMesage = "Hola,";
      userName = this.#accountState.userName;
    }
    const HeaderHtml = generateHtml._HeaderTop(helloMesage, userName);
    const FooterHtml = generateHtml._FooterNavBar();
    container.innerHTML = "";
    if (!this.#mainNavInitialized) {
      const MainScreenHtml = generateHtml._initialMainScreenGeneration(
        HeaderHtml,
        FooterHtml
      );
      container.insertAdjacentHTML("beforeend", MainScreenHtml);
    }

    this._RELOAD();
    this.#mainNavInitialized = true;

    // this._clearContainer();
  }

  _RELOAD() {
    const container = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    let isCountDonwRunnig = false;
    let countDown;
    // Listen for the scroll event to detect pull down
    container.addEventListener("scroll", () => {
      const HomeScreenContenConForSeachAndAddReloadMessage =
        document.getElementById(
          "HomeScreenContenConForSeachAndAddReloadMessage"
        );
      if (container.scrollTop < 1) {
        HomeScreenContenConForSeachAndAddReloadMessage.textContent =
          "Mantener para actualizar";
        const now = Date.now();
        const calculated = now - this.#lastReloadedTimeStamp;
        if (this.#lastReloadedTimeStamp) {
          if (calculated < 1000) return;
        }
        if (isCountDonwRunnig) return;
        isCountDonwRunnig = true;
        countDown = setTimeout(async () => {
          await firebase._getUserDataFromBase();
          isCountDonwRunnig = false;
          this.#lastReloadedTimeStamp = Date.now();
        }, 250);
      } else {
        HomeScreenContenConForSeachAndAddReloadMessage.textContent =
          "Deslizar hacia abajo para actualizar";
      }
      if (container.scrollTop > 0 && isCountDonwRunnig) {
        isCountDonwRunnig = false;
        clearTimeout(countDown);
      }
    });
    this.#reloadActive = true;
  }
}

export default new StartUpMainApp();
