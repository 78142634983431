import imageHere from "../../Icons/logos/justanimagehere.png";

const url = "https://maquinariasonline.com/negocio/MyBusiness/listado/12345";
const img = "https://marketplace.mx/assets/images/listing-image.jpg";
const title = "Amazing Product Listing!";
const description = "Check out this amazing listing on our marketplace!";

class generalHelpers {
  _callSharing() {
    this._shareImageWithText(title, description, imageHere);
  }

  _convertToPrice(price, coin) {
    // Convert to a number if it's a string
    const numericPrice = typeof price === "string" ? parseFloat(price) : price;

    // Check if the conversion resulted in a valid number
    if (isNaN(numericPrice)) {
      throw new Error(
        "Invalid price value. Please provide a valid number or string."
      );
    }

    // Format the price with commas
    const formattedPrice = numericPrice.toLocaleString();

    // Return the formatted price with the coin appended
    return `${formattedPrice} ${coin}`;
  }

  _calculateFutureDate(serverTimestamp, days) {
    // Convert Firebase serverTimestamp to a Date object
    const timestamp = serverTimestamp.toDate();

    // Add days to the timestamp
    const calculatedDate = new Date(timestamp);
    calculatedDate.setDate(calculatedDate.getDate() + days); // Add days to the current date

    // Get the current date in Mexico timezone (or your desired timezone)
    const mexicoTimeZone = "America/Mexico_City"; // Set timezone to Mexico
    const currentDate = new Date();

    // Use Intl.DateTimeFormat to format the date in Mexico timezone
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const currentDateString = new Intl.DateTimeFormat("es-MX", options).format(
      currentDate
    );

    // Check if we're past the calculated date
    const areWePast = currentDate > calculatedDate;

    // Calculate the time difference in days
    const timeDifferenceInMs = calculatedDate - currentDate;
    const timeNeeded = Math.ceil(timeDifferenceInMs / (1000 * 3600 * 24)); // Convert milliseconds to days

    // If past, show how many days ago
    if (areWePast) {
      timeNeeded *= -1; // If past, show negative number of days (e.g., 2 days ago)
    }

    // Return the results
    return {
      calculatedDate: calculatedDate.toLocaleString("es-MX", options), // Format the calculated date in Mexico timezone
      areWePast,
      timeNeeded,
    };
  }

  _convertServerTimestampToReadableTime(serverTimestamp) {
    // Convert Firestore serverTimestamp to a JavaScript Date object
    const date = serverTimestamp.toDate();

    // Helper function to get the time in short format (e.g., "2:00 PM")
    const getTimeFormat = (date) => {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return date.toLocaleString("es-ES", options); // Using Spanish locale
    };

    // Helper function for the "Hoy" (Today) and "Ayer" (Yesterday) time
    const getTodayOrYesterday = (date) => {
      const now = new Date();
      const timeDiff = now - date;
      const msInDay = 24 * 60 * 60 * 1000;

      // Check for "Hoy" (today)
      if (timeDiff < msInDay && now.getDate() === date.getDate()) {
        return `Hoy, ${getTimeFormat(date)}`;
      }

      // Check for "Ayer" (yesterday)
      if (timeDiff < msInDay && now.getDate() === date.getDate() + 1) {
        const options = {
          weekday: "long",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        return `Ayer, ${getTimeFormat(date)}`; // Now correctly return "Ayer"
      }

      return null; // Not today or yesterday
    };

    // Helper function for the "current week" format (e.g., "Tuesday, 2:00 PM")
    const getCurrentWeekFormat = (date) => {
      const now = new Date();
      const timeDiff = now - date;
      const msInDay = 24 * 60 * 60 * 1000;
      const msInWeek = 7 * msInDay;

      if (timeDiff < msInWeek) {
        const options = {
          weekday: "long",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        return `${date.toLocaleString("es-ES", options)}`;
      }

      return null; // Not in the current week
    };

    // Helper function for "current year" format (e.g., "Nov 12, 2:00 PM")
    const getCurrentYearFormat = (date) => {
      const options = {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return `${date.toLocaleString("es-ES", options)}`;
    };

    // Helper function for "previous year" format (e.g., "Nov 12, 2023, 2:00 PM")
    const getPreviousYearFormat = (date) => {
      const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return `${date.toLocaleString("es-ES", options)}`;
    };

    // First check for today or yesterday
    let result = getTodayOrYesterday(date);
    if (result) return result;

    // Check if it's in the current week
    result = getCurrentWeekFormat(date);
    if (result) return result;

    // If it's not today, yesterday, or in the current week, check for the current year
    if (date.getFullYear() === new Date().getFullYear()) {
      return getCurrentYearFormat(date);
    }

    // If it's not in the current year, return full date with year
    return getPreviousYearFormat(date);
  }

  async _createUrlForSharing2WORKSWITHSLASH(
    businessId,
    businessName,
    listingId,
    listingName
  ) {
    console.log(businessName);
    let url = "";

    // if (businessName) {
    //   // if (businessName && businessName !== "null" && businessName !== null) {
    //   const bsName = businessName.split(" ").join("_");
    //   url += `negocio/${bsName}`;
    // }

    if (businessId) {
      if (url) {
        url += "/"; // Add a separator if there's already a business name
      }
      url += `negocio/${businessId}`;
      if (businessName) {
        const lsName = businessName.split(" ").join("");
        url += `?name=${encodeURIComponent(lsName)}`; // Encode listing name
      }
    }
    if (listingId) {
      if (url) {
        url += "/"; // Add a separator if there's already a business name
      }
      url += `listado/${listingId}`;
      if (listingName) {
        const lsName = listingName.split(" ").join("");
        url += `?name=${encodeURIComponent(lsName)}`; // Encode listing name
      }
    }

    const wholeUrl = "https://maquinariasonline.com/" + url;
    console.log(wholeUrl);

    return wholeUrl;
  }
  async _createUrlForSharing(businessId, businessName, listingId, listingName) {
    console.log(businessId);
    console.log(businessName);
    console.log(listingId);
    console.log(listingName);

    // Base URL
    // let url = "http://localhost:1234";
    // TODO set the corect url here
    let url = "https://maquinariasonline.com";

    // Build query parameters
    const businessQueryParams = [];
    if (businessId && businessId !== "null") {
      businessQueryParams.push(`negocio=${encodeURIComponent(businessId)}`);
    }

    if (businessName && businessName !== "null") {
      businessQueryParams.push(
        `nombre=${encodeURIComponent(businessName.split(" ").join(""))}`
      );
    }
    console.log(businessQueryParams);

    if (businessQueryParams.length > 0) {
      url += `?${businessQueryParams.join("&")}`;
    }

    const listingsQueryParams = [];

    if (listingId && listingId !== "null") {
      listingsQueryParams.push(`listado=${encodeURIComponent(listingId)}`);
    }

    if (listingName && listingName !== "null") {
      listingsQueryParams.push(
        `nombre=${encodeURIComponent(listingName.split(" ").join(""))}`
      );
    }

    // Append query parameters to the URL
    if (listingsQueryParams.length > 0) {
      url += `?${listingsQueryParams.join("&")}`;
    }

    console.log(url);
    return url;
  }

  async _share(url, img, title, description) {
    const text = `${title}\n${description}\n${url}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: url, // Optional if you include it in the text
        });
        console.log("Shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API not supported in this browser.");
      // Fallback: Open a sharing dialog
    }
  }

  async _shareImagesWithText(title, description, imgUrlsIn) {
    try {
      // Array to hold File objects
      const files = [];

      // Loop through each image URL and fetch the image
      for (const imgUrl of imgUrlsIn) {
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const file = new File([blob], "shared-image.jpg", { type: blob.type });
        files.push(file);
      }

      // Reorder the files array to move the last image to the start
      const reorderedFiles = files;
      //   const reorderedFiles = [files.pop(), ...files];

      // Check if the browser supports sharing multiple files
      if (navigator.canShare && navigator.canShare({ files: reorderedFiles })) {
        await navigator.share({
          files: reorderedFiles, // Array with the last image first
          title: title,
          text: description, // Text accompanies the first file (the last image)
        });
        console.log("Images and text shared successfully!");
        return { success: true, message: "Shared successfully" }; // Return success
      } else {
        console.error(
          "Sharing multiple files is not supported on this browser."
        );
        return { success: false, message: "Sharing not supported" }; // Return error
      }
    } catch (error) {
      console.error("Error sharing images and text:", error);
      return { success: false, message: `Error: ${error.message}` }; // Return error
    }
  }

  async _shareImageWithText(title, description, listingUrl, imgUrl) {
    try {
      // Fetch the image and convert it to a File object
      const response = await fetch(imageHere);
      const blob = await response.blob();
      const file = new File([blob], "shared-image.jpg", { type: blob.type });

      // Check if the browser supports sharing files
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: title,
          text: title + " " + description + ":" + " " + listingUrl, // Text to accompany the image
        });
        console.log("Image and text shared successfully!");
      } else {
        console.error("Sharing files is not supported on this browser.");
      }
    } catch (error) {
      console.error("Error sharing image and text:", error);
    }
  }

  _getTimeStampJS() {
    const now = new Date();

    // Format components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Combine into timestamp
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  _getUniqueIdfunction() {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }
  _getSpanishGreetingForDay() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 6 && hour < 12) {
      return "Buenos días"; // Morning: 6 AM to 11:59 AM
    } else if (hour >= 12 && hour < 20) {
      return "Buenas tardes"; // Afternoon: 12 PM to 7:59 PM
    } else {
      return "Buenas noches"; // Evening/Night: 8 PM to 5:59 AM
    }
  }
  _formatNumberShort(number) {
    if (!number) {
      return 0;
    }
    if (number < 1000) {
      return number.toString(); // No formatting needed for numbers less than 1,000
    }

    const suffixes = ["", "K", "M", "B", "T"]; // Suffixes for thousand, million, billion, etc.
    let index = 0;

    while (number >= 1000 && index < suffixes.length - 1) {
      number /= 1000;
      index++;
    }

    return `${number.toFixed(1).replace(/\.0$/, "")}${suffixes[index]}`;
  }
  _formatReadableString(input) {
    return input
      .split(/[-_]/) // Split by hyphen (-) or underscore (_)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
      .join(" "); // Join words with a space
  }
  _formatToPrice(value) {
    if (isNaN(value)) {
      return "NaN";
    }

    // Convert to a float to keep the decimal part
    const floatValue = parseFloat(value);

    // Use toLocaleString to format the number, and ensure to keep the decimal part
    return floatValue.toLocaleString();
  }

  _sortAndExtractNumbers(input) {
    // Extract numbers from the string using a regular expression
    const numbers = input.match(/\d+/g);

    // If no numbers are found, return an empty string
    if (!numbers) return "";

    // Sort the numbers, parse them as integers, and join them into a single string
    return numbers
      .map(Number)
      .sort((a, b) => a - b)
      .join("");
  }

  _generateYearsArray(yearStart) {
    const currentYear = new Date().getFullYear(); // Get the current year
    const years = [];

    for (let year = currentYear + 1; year >= yearStart; year--) {
      years.push(year);
    }

    return years;
  }

  _createTokens(inputString, omittedWords) {
    console.log(inputString, omittedWords);
    if (!inputString || typeof inputString !== "string") return [];

    // Convert string to lowercase for uniformity
    const lowerCased = inputString.toLowerCase();
    console.log(lowerCased);

    // Split into words
    const words = lowerCased.split(/\s+/);

    // Filter out omitted words
    const filteredWords = words.filter((word) => !omittedWords.includes(word));

    // Generate tokens (single words and bigrams for partial matches)
    const tokens = new Set(filteredWords);

    // Add bigrams (pairs of adjacent words)
    for (let i = 0; i < filteredWords.length - 1; i++) {
      tokens.add(`${filteredWords[i]} ${filteredWords[i + 1]}`);
    }

    // Convert Set back to Array
    return Array.from(tokens);
  }
  _rankListings(foundListings, searchQuery, omittedWords) {
    // Tokenize the user's search query
    const searchTokens = this._createTokens(searchQuery, omittedWords);

    if (searchTokens.length === 0) {
      console.warn("No valid tokens generated from the search query.");
      return foundListings; // Return as is if no tokens are generated
    }

    // Compute relevance score for each listing
    const rankedListings = foundListings.map((listing) => {
      let score = 0;

      if (listing.businessId) {
        score += 150;
      }

      // Count matches for single tokens, considering frequency of each token
      searchTokens.forEach((token) => {
        const tokenCount = listing.listingTokens.filter(
          (t) => t === token
        ).length;
        if (tokenCount > 0) {
          score += tokenCount * 10; // Base score for each token match, multiplied by frequency
        }

        if (listing.listingMachineType === token) {
          score += tokenCount * 90; // Score for token match in machine type, multiplied by frequency
        }
        if (listing.listingBrand === token) {
          score += tokenCount * 100; // Score for token match in brand, multiplied by frequency
        }
        if (listing.listingModel === token) {
          score += tokenCount * 50; // Score for token match in model, multiplied by frequency
        }
        if (listing.listingYear === token) {
          score += tokenCount * 70; // Score for token match in year, multiplied by frequency
        }
      });

      // Optional: Boost score for matching bigrams
      searchTokens.forEach((token) => {
        if (token.includes(" ") && listing.listingTokens.includes(token)) {
          const tokenCount = listing.listingTokens.filter(
            (t) => t === token
          ).length;
          score += tokenCount * 20; // Higher score for bigram matches, multiplied by frequency
        }
      });

      return { ...listing, score };
    });

    // Sort listings by score in descending order
    rankedListings.sort((a, b) => b.score - a.score);

    return rankedListings;
  }
  _rankBusinesses(foundListings, searchQuery, omittedWords) {
    // Tokenize the user's search query
    const searchTokens = this._createTokens(searchQuery, omittedWords);

    if (searchTokens.length === 0) {
      console.warn("No valid tokens generated from the search query.");
      return foundListings; // Return as is if no tokens are generated
    }

    // Compute relevance score for each listing
    const rankedListings = foundListings.map((listing) => {
      let score = 0;

      if (listing.businessId) {
        score += 150;
      }

      // Count matches for single tokens, considering frequency of each token
      searchTokens.forEach((token) => {
        const tokenCount = listing.businessTokens.filter(
          (t) => t === token
        ).length;
        if (tokenCount > 0) {
          score += tokenCount * 10; // Base score for each token match, multiplied by frequency
        }
      });

      // Optional: Boost score for matching bigrams
      searchTokens.forEach((token) => {
        if (token.includes(" ") && listing.businessTokens.includes(token)) {
          const tokenCount = listing.listingTokens.filter(
            (t) => t === token
          ).length;
          score += tokenCount * 20; // Higher score for bigram matches, multiplied by frequency
        }
      });

      return { ...listing, score };
    });

    // Sort listings by score in descending order
    rankedListings.sort((a, b) => b.score - a.score);

    return rankedListings;
  }

  _shortenText(inputText, limitWords) {
    // Split the text into an array of words
    const words = inputText.split(/\s+/);

    // Check if the word count exceeds the limit
    if (words.length <= limitWords) {
      return inputText; // Return the full text if within the limit
    }

    // Join only the allowed number of words and add '...'
    const shortenedText = words.slice(0, limitWords).join(" ") + "...";

    return shortenedText;
  }

  // BUTTON ANIMATIONS
  _animateCTA(buttonId) {
    const button = document.getElementById(buttonId);
    if (!button) return;

    button.classList.add("cta-animate");

    // Optional: Remove animation and restart it after a delay
    setTimeout(() => {
      button.classList.remove("cta-animate");
    }, 2000);
  }
  _startLoopAnimation(buttonId, isClass) {
    if (isClass) {
    } else {
      const button = document.getElementById(buttonId);
      if (!button) return;

      button.classList.add("cta-loop");
    }
  }
}

export default new generalHelpers();
