import iconVideoPlay from "../../../Icons/logos/video-mercado-mai.png";
class generateHtml {
  _helpVideo(data, sections) {
    return `
        <div data-videourl="${data.videoUrl}" class="HelpSectionVideoCon1 openVideo">
            <div class="HelpSectionVideoCon2">
              <div class="HelpSectionVideoCon3">
                <img
                  class="HelpSectionVideoThumbnailCon"
                  src="${data.thumbnailImgUrl}"
                  alt=""
                />
                <img
                  class="HelpSectionVideoPLayVideoIcon"
                  height="32px"
                  src="${iconVideoPlay}"
                  alt=""
                />
              </div>
            </div>
            <div class="HelpSectionVideoCon4">
              <div class="HelpSectionVideoText1">${data.name}</div>
              <div class="HelpSectionVideoCon5">
                <div class="HelpSectionVideoText2">
                  ${data.description}
                </div>
              </div>    
                <div class="HelpSectionVideoCon5">
                    ${sections}
                </div>
            </div>
        </div>`;
  }
}
export default new generateHtml();
