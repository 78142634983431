import iconMenu from "../../../Icons/icons-white/menu.png";
import iconHelp from "../../../Icons/icons-white/help.png";
import iconFilter from "../../../Icons/icons-charcoal-dark/filter.png";
import iconPlus from "../../../Icons/icons-charcoal-dark/add.png";
import iluCombine from "../../images/combine.png";
import iluTractor from "../../images/tractor.png";

class GenarateHtml {
  _topToolsSetter(id) {
    return `
<div id="" class="HomeScreenCon3B">
  <div id="HomeScreenContenConForSeachAndAdd" class="HomeScreenCon3C">
    <div id="HomeScreenContenConForSeachAndAddReloadMessage">Deslizar hacia abajo para actualizar</div>
      <div id="" class="HomeScreenCon3">
          <button id="HomeScreenButtonHelp" class="HomeScreenButtonHelp">
            <img style="height: 24px" src="${iconHelp}" alt="" /> Ayuda
          </button>
          <button
            id="HomeScreenButtonCreateListing"
            class="HomeScreenButtonCreateListing"
          >
                   <img
  id="iluUploadButtonCombine"
  class="iluUploadButton"
  style="height: 24px"
  style="display: none"
  src="${iluCombine}"
  alt=""
/>
<img
  id="iluUploadButtonTractor"
  class="iluUploadButton"
  style="height: 24px"
  style="display: none"
  src="${iluTractor}"
  alt=""
/>


          
          
          Publicar tu maquina
          </button>
      </div>
    </div>
  </div>`;
  }
}
{
  /* <img style="height: 24px" src="${iconPlus}" alt="" />  */
}
{
  // <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <style>
  //     @keyframes upload {
  //       0% { transform: translateY(4px); opacity: 0.6; }
  //       50% { transform: translateY(0); opacity: 1; }
  //       100% { transform: translateY(4px); opacity: 0.6; }
  //     }
  //     .arrow {
  //       animation: upload 1.5s infinite ease-in-out;
  //     }
  //   </style>
  //   <path d="M50 25a14 14 0 0 0-26-7A10 10 0 0 0 10 27c0 6 4 10 9 10h5" stroke="#2B2B2B" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
  //   <path class="arrow" d="M32 50V30m-7 7 7-7 7 7" stroke="#2B2B2B" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
  // </svg>
}
export default new GenarateHtml();
