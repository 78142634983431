import firebase from "../firebase";

import iconBoost from "../../../Icons/icons-white/boost.png";
import iconStats from "../../../Icons/icons-charcoal-dark/stats.png";
import iconEdit from "../../../Icons/icons-charcoal-dark/edit.png";
import iconWhiteEdit from "../../../Icons/icons-white/edit.png";
import iconViews from "../../../Icons/icons-charcoal-dark/views.png";
import iconWhiteAdd from "../../../Icons/icons-white/add.png";
import iconExit2Px from "../../../Icons/icons-white/exit-2px.png";
import iconDarkAdd from "../../../Icons/icons-charcoal-dark/add.png";
import iconCreateBusinessPage from "../../../Icons/icons-charcoal-dark/add-business-page.png";
import iconDotsMenu from "../../../Icons/icons-white/dots-menu.png";
import iconDotsMenuRed from "../../../Icons/icons-red-danger/dots-menu.png";
import iconForward from "../../../Icons/icons-white/forward.png";
import iconDarkForward from "../../../Icons/icons-charcoal-dark/forward.png";
import iconShare from "../../../Icons/icons-charcoal-dark/share.png";
import appIconMini from "../../images/fav-mini.png";
import GENRALHELPERS from "../GENRAL-HELPERS";

class GenerateHtml {
  async _listing(data, atention) {
    const itemImg = data.listingMainImg;
    // const itemImg = await firebase._getImageUrl(data.listingMainImg);
    let dotsMenuHtml = `
    <div class="OwnBusinessListingButtonMenu returnForActionOpenForDisplay openListingEditOptionsGlobal">
    <img height="20px" src="${iconDotsMenu}"/>
    </div>`;
    let actionButtonsHtml = `
          <div class="OwnBusinessListingButtonAction openListingShareGlobal">
            <img height="14px" src="${iconShare}"/>
          </div>
          <div class="OwnBusinessListingButtonAction openListingBoostGlobal">
            <img height="14px" src="${iconBoost}"/>
          </div>`;
    if (atention) {
      dotsMenuHtml = ``;
      // dotsMenuHtml = `<img height="20px" src="${iconDotsMenuRed}"/>`;
      if (atention === "waitingPayment") {
        actionButtonsHtml = `
          <div id="OwnBusinessListingButtonActionPayNow" class="OwnBusinessListingButtonActionPayNow">Pagar</div>
        `;
      }
      if (atention === "selectForPayment") {
        actionButtonsHtml = `
          <div id="OwnBusinessListingButtonActionSelectForPayment" class="OwnBusinessListingButtonActionSelect">Seleccionar</div>
        `;
      }
    }

    return `
  <div id="${data.listingId}" data-id="${data.listingId}" data-businessid="${
      data.businessId
    }" data-imgid="${itemImg}" data-name="${
      data.listingName
    }" class="OwnBusinessListingCon1 ActionOpenForDisplay">
    <div class="OwnBusinessListingCon2">
     <img class="OwnBusinessListingCon2Image ImgForBigDisplay" src="${itemImg}" />
        ${dotsMenuHtml}
    </div>
    <div class="OwnBusinessListingCon3">
      <div class="OwnBusinessListingCon4">
        <div class="OwnBusinessListingTitle">
          ${data.listingName}
        </div>
      </div>
        <div class="OwnBusinessListingCon6 returnForActionOpenForDisplay">
          ${actionButtonsHtml}
        </div>
        <div class="OwnBusinessListingCon7">
          <div class="OwnBusinessListingCon8">
            <div class="OwnBusinessListingTime">
              ${GENRALHELPERS._convertServerTimestampToReadableTime(
                data.updatedAt
              )}
            </div>
          </div>
          <div class="OwnBusinessListingCon9">
            <div class="OwnBusinessListingTime">
              ${data.listingViews}
            </div>
            <img height="12px" src="${iconViews}"/>
          </div>
        </div>
    </div>
  </div>
    `;
  }
  _listingsCon(title, id) {
    return `
  <div class="OwnBusinessListingsCon2">
    <div class="OwnBusinessListingsCon3">
      <div class="OwnBusinessListingsTitle">
        ${title}
      </div>
      <div data-generall="expandforviewallEditMode" class="OwnBusinessListingsSeeAll">
        Ver todo
      </div>
    </div>
    <div id="${id}" class="OwnBusinessListingsCon4">
    </div>
  </div>`;
  }
  async _business(data) {
    console.log(data);
    const backImgUrl = data.backgroundImg;
    // const backImgUrl = await firebase._getImageUrl(data.backgroundImg);
    const ProfileImgUrl = data.profileImg;
    // const ProfileImgUrl = await firebase._getImageUrl(data.profileImg);
    return `
  <div data-businessid="${data.businessId}" class="OwnBusinessCon3">
  <div class="OwnBusinessConBusinessMessageConSubscriptionButtonCon">
    <div id="OwnBusinessConBusinessMessageConSubscriptionButton" class="OwnBusinessConBusinessMessageConSubscriptionButton">
      <div class="OwnBusinessConBusinessMessageConSubscriptionButtonText">Ver subscripción</div>
      <img height="20px" src="${iconDarkForward}" alt="" />
    </div>
  </div>
  <div class="OwnBusinessCon5A">
  <div class="OwnBusinessCon5">
    <img class="BusinessInSearchCon1BackgroundImg" src="${backImgUrl}" />
    <div class="OwnBusinessCon6">
      <div class="OwnBusinessCon7">
        <div class="OwnBusinessCon9">
          <img class="OwnBusinessCon9Img ImgForBigDisplay" src="${ProfileImgUrl}" />
        </div>
        <div class="OwnBusinessCon10">
          <div class="OwnBusinessCon11">
            <div style="color: #${
              data.colors.headerPrimary
            }" class="TratoresKiotyHopelchN">${data.name}</div>
            <div style="color: #${
              data.colors.headerSecondary
            }" class="TratoresKiotyHopelchNDescription">${
      data.shortDescription
    }</div>
          </div>
          <div
            id="OwnBusinessButtonsActionOnBusinessActionMenu"
            class="OwnBusinessButtonsActionOnBusinessOpen OwnBusinessButtonsActionOnBusinessForEventListining"
          >
            <img src="${iconDotsMenu}" alt="" />
          </div>
        </div>
      </div>
      <div class="OwnBusinessCon12">
        <div class="OwnBusinessCon13">
          <div class="OwnBusinessCon13">
            <div class="OwnBusinessCon14">
              <div
                id="OwnBusinessButtonsActionOnBusinessShare"
                class="OwnBusinessButtonsActionOnBusiness OwnBusinessButtonsActionOnBusinessForEventListining"
              >
                <img src="${iconShare}" alt="" />
              </div>
              <div
                id="OwnBusinessButtonsActionOnBusinessBoost"
                class="OwnBusinessButtonsActionOnBusiness OwnBusinessButtonsActionOnBusinessForEventListining"
              >
                <img src="${iconBoost}" alt="" />
              </div>
              <div
                id="OwnBusinessButtonsActionOnBusinessStats"
                class="OwnBusinessButtonsActionOnBusiness OwnBusinessButtonsActionOnBusinessForEventListining"
              >
                <img src="${iconStats}" alt="" />
              </div>
              <div
                id="OwnBusinessButtonsActionOnBusinessEdit"
                class="OwnBusinessButtonsActionOnBusiness OwnBusinessButtonsActionOnBusinessForEventListining"
              >
                <img src="${iconEdit}" alt="" />
              </div>
            </div>
          </div>
          <div
            id="OwnBusinessButtonsActionOnBusinessOpen"
            class="OwnBusinessButtonsActionOnBusinessOpen OwnBusinessButtonsActionOnBusinessForEventListining"
          >
            <img src="${iconForward}" alt="" />
          </div>
        </div>
        <div class="OwnBusinessCon15">
          <div class="KVisitasLtimos7Dias">${GENRALHELPERS._formatNumberShort(
            data.views
          )} Visitas total</div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="LogInCon6">
    <div data-helpwith="editBusinessPage" class="WalletCon8 openHelp">
      <div class="WalletText4">¿Como puede administrar mi negocio?</div>
    </div>
  </div>
</div>

    `;
  }
  _businesMain() {
    return `
<div class="OwnBusinessCon1">
  <div class="OwnBusinessCon2">
    <div id="OwnBusinessButtonCreateListing" class="OwnBusinessButtonCreateListing">
        <img height="20px" src="${iconDarkAdd}"/>
      Crear Listado
    </div>
  </div>
  <div
    id="OwnBusinessConBusinessMessageCon"
    class="OwnBusinessConBusinessMessageCon"
  ></div>
  <div
    id="OwnBusinessConBusinessListingsCon"
    class="OwnBusinessConBusinessListingsCon"
  ></div>
</div>
    `;
  }
  _businesListings(html) {
    return `
  <div class="OwnBusinessCon16">
    <div class="OwnBusinessCon17">
      <div class="OwnBusinessCon18">
        <div class="OwnBusinessTitle2">Tus listados</div>
      </div>
    </div>
    <div class="OwnBusinessCon19">
      <div id="OwnBusinessConButtonEditBusinessPageLists1" class="OwnBusinessButtonEditLists OwnBusinessButtonsActionOnBusinessForEventListining">
        <img height="12px" src="${iconWhiteEdit}"/>
        <div class="OwnBusinessButtonEditListsText">Editar listas</div>
      </div>
      <div id="OwnBusinessConButtonEditBusinessPageLists2" class="OwnBusinessButtonEditLists OwnBusinessButtonsActionOnBusinessForEventListining">
        <img height="12px" src="${iconWhiteAdd}"/>
        <div class="OwnBusinessButtonEditListsText">Nueva lista</div>
      </div>
    </div>
    <div class="LogInCon6">
      <div data-helpwith="editListing" class="WalletCon8 openHelp">
        <div class="WalletText4">
          ¿Como puedo editar o eliminar un listado?
        </div>
      </div>
    </div>
    <div class="OwnBusinessListingsCon1">${html}</div>
  </div>
    `;
  }
  _createBusinessPageMessage(account) {
    let message =
      "Aún no tienes una pagina de negocio con nosotros. Crea una página ahorita con un mes gratuito para subir todos los listados que quieras.";

    if (!account) {
      message +=
        " \n O si ya tienes una página de negocio, debes acceder a tu cuenta para poder administrarlo.";
    }
    return `
  <div class="OwnBusinessCon19">
    <div class="OwnBusinessCon20">
      <div class="OwnBusinessConMessage">
        ${message}
      </div>
    </div>
  </div>
    `;
  }
  _createBusinessPageButtons(account) {
    let accessAccountBtn = ``;
    if (!account) {
      accessAccountBtn = ` <div class="OwnBusinessCon21">
      <div id="OwnBusinessConButtonLogin" class="OwnBusinessConButtonWhatIsBusinessPage">
        Acceder a mi cuenta
      </div>
    </div>`;
    }
    return `
  <div class="OwnBusinessCon21">
    <div class="LogInCon6">
      <div data-helpwith="whatIsBusinessPage" class="WalletCon8 openHelp">
        <div class="WalletText4">¿Que es una página de negocio?</div>
      </div>
      <div data-helpwith="createBusinessPage" class="WalletCon8 openHelp">
        <div class="WalletText4">¿Como puedo crea mi página de negocio?</div>
      </div>
    </div>
  </div>
  <div class="OwnBusinessCon22">
    <div id="OwnBusinessConButtonCreateBusinessPage" class="OwnBusinessConButtonCreateBusinessPage">
      Crear pagina
        <img height="20px" class="" src="${iconCreateBusinessPage}" />
    </div>
  </div>
  ${accessAccountBtn}


    `;
  }

  _subscriptionPlanHtml(data) {
    let currentSubscriptionHtml = ``;
    if (data) {
      let backgroundColorClass = "mercadoMAIBackground";
      let text1Class = "textColorGreenAction";
      let text2Class = "textColorOrangeAction";
      let endDate = "";
      let startDate = GENRALHELPERS._convertServerTimestampToReadableTime(
        data.startDate
      );
      let listings = data.numberOfListingsIncluded;
      let availableListings = data.numberOfListingsAvailable;
      let plan = "Un mes";

      const calculatedTime = GENRALHELPERS._calculateFutureDate(
        data.startDate,
        data.time
      );
      endDate = calculatedTime.calculatedDate;

      if (data.plan === "year") {
        plan = "Un año";
        text1Class = "textColorOrangeAction";
        text2Class = "textColorGreenAction";
        backgroundColorClass = "mercadoMAIProBackground";
      }
      if (data.unlimited) {
        listings = "ilimitado";
        availableListings = "ilimitado";
      }
      currentSubscriptionHtml = `
        <div class="SubBusPlanCurrPlanCon1 ${backgroundColorClass}">
          <div class="SubBusPlanCurrPlanText1">Tu plan Actual</div>
          <div class="SubBusPlanCurrPlanCon2">
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Plan</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${plan}</div>
            </div>
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Listados incluidos</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${listings}</div>
            </div>            
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Listados disponibles</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${availableListings}</div>
            </div>
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Fecha de inicio</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${startDate}</div>
            </div>
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Tiempo</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${
        data.time
      } días</div>
            </div>
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Vence el</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">${endDate}</div>
            </div>
            <div class="SubBusPlanCurrPlanCon3">
              <div class="SubBusPlanCurrPlanText2 ${text1Class}">Precio</div>
              <div class="SubBusPlanCurrPlanText3 ${text2Class}">$${GENRALHELPERS._formatToPrice(
        data.price
      )}</div>
            </div>
          </div>
        </div>
        `;
    }

    return `
      <div class="SubBusPlanCon1A">
        <div class="LogInCon6">
          <div data-helpwith="subscription" class="WalletCon8 openHelp">
            <div class="WalletText4">Conocer las subscripciones</div>
          </div>
        </div>
        ${currentSubscriptionHtml}
        <div class="SubBusPlanCurrPlanCon4">
          <div id="subscriptionPlanTextHeader" class="SubBusPlanCurrPlanText4">Renueva tu plan</div>
        </div>
        <div id="subscriptionPlanConMonth" class="SubBusPlanCon1 mercadoMAIBackground">
          <div class="SubBusPlanCon2">
            <img height="32px" src="${appIconMini}" alt="" />
            <div class="SubBusPlanCon4">
              <div class="SubBusPlanCon5">
                <div class="SubBusPlanCon6 mercadoMAIBackground">
                  <div class="SubBusPlanConText1">Un mes</div>
                  <div class="SubBusPlanConText2">$290</div>
                </div>
                <div id="subscriptionPlanSelectYearButton" class="SubBusPlanCon7">
                  <div class="SubBusPlanConText3">Un año</div>
                  <div class="SubBusPlanConText2">$2,958</div>
                </div>
              </div>
              <div class="SubBusPlanConText5">ahora 15% en el plan de un año</div>
            </div>
          </div>
          <div class="SubBusPlanCon8">
            <div class="SubBusPlanCon9">
              <div class="SubBusPlanConText6">Listados</div>
            </div>
            <div class="SubBusPlanCon10">
              <div class="SubBusPlanConText7">Escoge el número de listados que quieres con tu plan</div>
              <div id="subscriptionPlanSelectListingsMonth" class="SubBusPlanCon11">
                <div data-selected="true" data-numberoflistings="included" class="SubBusPlanCon12 SubBusPlanCon12Active">
                  <div class="SubBusPlanCon12Text SubBusPlanConText8">10</div>
                  <div class="SubBusPlanConText9">Incluido</div>
                </div>
                <div data-selected="false" data-numberoflistings="20" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10">20</div>
                  <div class="SubBusPlanConText9">+ $290</div>
                </div>
                <div data-selected="false" data-numberoflistings="30" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10">30</div>
                  <div class="SubBusPlanConText9">+ $480</div>
                </div>
                <div data-selected="false" data-numberoflistings="45" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10">45</div>
                  <div class="SubBusPlanConText9">+ $600</div>
                </div>
                <div data-selected="false" data-numberoflistings="unlimited" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10">ilimitado</div>
                  <div class="SubBusPlanConText9">+ $1,280</div>
                </div>
              </div>
            </div>
          </div>
          <div class="SubBusPlanCon8">
            <div class="SubBusPlanCon9">
              <div class="SubBusPlanConText6">Aumentar vistas de tus listados</div>
            </div>
            <div class="SubBusPlanCon10">
              <div class="SubBusPlanCon13">
                <div class="SubBusPlanCon14">
                  <div class="SubBusPlanConText11">Puedes incluir un nivel de prioridad a este plan, y este nivel será agregado a tus listados al publicarlos</div>
                </div>
                <div class="SubBusPlanCon15">
                  <div class="SubBusPlanCon16">
                    <div class="SubBusPlanConText12">puntos</div>
                    <div class="SubBusPlanConText9">+ $.50 cada punto</div>
                  </div>
                  <div class="SubBusPlanCon17">
                  <input class="SubBusPlanCon18 SubBusPlanConText13" id="subscriptionPlanInputPriorityPointsMonth" inputmode="numeric" placeholder="0"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="SubBusPlanCon19">
            <div class="SubBusPlanCon20">
              <div class="SubBusPlanCon21">
                <div id="subscriptionPlanFinalPriceMonth" class="SubBusPlanConText14">$290</div>
                <div class="SubBusPlanConText15">MXN</div>
              </div>
              <div class="SubBusPlanConText16">/mes</div>
            </div>
            <div class="subscriptionPlanNextButtons SubBusPlanCon22">
              <div class="SubBusPlanConText17">Siguiente</div>
              <img height="20px" src="${iconForward}" alt="" />
            </div>
          </div>
        </div>
        <div id="subscriptionPlanConYear" class="SubBusPlanCon1 mercadoMAIProBackground" style="display: none">
          <div class="SubBusPlanCon2">
            <img height="32px" src="${appIconMini}" alt="" />
            <div class="SubBusPlanCon4">
              <div class="SubBusPlanCon5">
                <div id="subscriptionPlanSelectMonthButton" class="SubBusPlanCon6B">
                  <div class="SubBusPlanConText1B">Un mes</div>
                  <div class="SubBusPlanConText2">$290</div>
                </div>
                <div class="SubBusPlanCon7B mercadoMAIProBackground">
                  <div class="SubBusPlanConText1">Un año</div>
                  <div class="SubBusPlanConText2">$2,958</div>
                </div>
              </div>
              <div class="SubBusPlanConText5">ahora 15% en el plan de un año</div>
            </div>
          </div>
          <div class="SubBusPlanCon8">
            <div class="SubBusPlanCon9">
              <div class="SubBusPlanConText6B">Listados</div>
            </div>
            <div class="SubBusPlanCon10">
              <div class="SubBusPlanConText7">Escoge el número de listados que quieres con tu plan</div>
              <div id="subscriptionPlanSelectListingsYear" class="SubBusPlanCon11">
                <div data-selected="true" data-numberoflistings="included" class="SubBusPlanCon12 SubBusPlanCon12ActivePro">
                  <div class="SubBusPlanCon12Text SubBusPlanConText8">120</div>
                  <div class="SubBusPlanConText9">Incluido</div>
                </div>
                <div data-selected="false" data-numberoflistings="240" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10B">240</div>
                  <div class="SubBusPlanConText9">+ $2,958</div>
                </div>
                <div data-selected="false" data-numberoflistings="360" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10B">360</div>
                  <div class="SubBusPlanConText9">+ $4,896</div>
                </div>
                <div data-selected="false" data-numberoflistings="540" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10B">540</div>
                  <div class="SubBusPlanConText9">+ $5,508</div>
                </div>
                <div data-selected="false" data-numberoflistings="unlimited" class="SubBusPlanCon12 SubBusPlanCon12NoneActive">
                  <div class="SubBusPlanCon12Text SubBusPlanConText10B">ilimitado</div>
                  <div class="SubBusPlanConText9">+ $13,056</div>
                </div>
              </div>
            </div>
          </div>
          <div class="SubBusPlanCon8">
            <div class="SubBusPlanCon9">
              <div class="SubBusPlanConText6B">Aumentar vistas de tus listados</div>
            </div>
            <div class="SubBusPlanCon10">
              <div class="SubBusPlanCon13">
                <div class="SubBusPlanCon14">
                  <div class="SubBusPlanConText11 textColorOrangeAction">Puedes incluir un nivel de prioridad a este plan, y este nivel será agregado a tus listados al publicarlos</div>
                </div>
                <div class="SubBusPlanCon15">
                  <div class="SubBusPlanCon16">
                    <div class="SubBusPlanConText12">puntos</div>
                    <div class="SubBusPlanConText9">+ $.50 cada punto</div>
                  </div>
                  <div class="SubBusPlanCon17">
                    <input class="SubBusPlanCon18 SubBusPlanConText13" id="subscriptionPlanInputPriorityPointsYear" inputmode="numeric" placeholder="0"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="SubBusPlanCon19">
            <div class="SubBusPlanCon20">
              <div class="SubBusPlanCon21">
                <div id="subscriptionPlanFinalPriceYear"  class="SubBusPlanConText14B">$2,958</div>
                <div class="SubBusPlanConText15B">MXN</div>
              </div>
              <div class="SubBusPlanConText16B">/año</div>
            </div>
            <div class="subscriptionPlanNextButtons SubBusPlanCon22B">
              <div class="SubBusPlanConText17">Siguiente</div>
              <img height="20px" src="${iconForward}" alt="" />
            </div>
          </div>
        </div>
        <div class="subscriptionPlanExitButtons SubBusPlanCon3">Cerrar</div>
      </div>
    
    `;
  }
}

export default new GenerateHtml();
