import iconExitDarkCharcoal from "../../../Icons/icons-charcoal-dark/exit.png";
import iconAddDarkCharcoal from "../../../Icons/icons-charcoal-dark/add.png";
import iconPriorityDarkCharcoal from "../../../Icons/icons-charcoal-dark/priority.png";
import iconPriorityUpDarkCharcoal from "../../../Icons/icons-charcoal-dark/priority-up.png";
import iconPriorityDownDarkCharcoal from "../../../Icons/icons-charcoal-dark/priority-down.png";
import iconEditDarkCharcoal from "../../../Icons/icons-charcoal-dark/edit.png";
import GENRALHELPERS from "../GENRAL-HELPERS";

class generateHtml {
  _main() {
    return `
<div class="OrderMainContainer">
  <div id="OrderConSeeOrder" class="OrderCon1" style="display: flex">
    <div class="OrderCon2">
      <div id="OrderDiscountConDisplayText1" class="OrderDiscountDisplayText1"></div>
      <div id="OrderDiscountConDisplayText2" class="OrderDiscountDisplayText2"></div>
      <div id="OrderDiscountConDisplayText3" class="OrderDiscountDisplayText3"></div>
    </div>
    <div class="LogInCon6">
      <div data-helpwith="navigateOrders" class="WalletCon8 openHelp">
        <div class="WalletText4">Necesito ayuda con mi pedido</div>
      </div>
    </div>
    <div id="OrderConB1" class="OrderConB1">
      <div class="OrderConB2">
        <div class="OrderTextHeader1">Tu pedido</div>
      </div>
      <div class="OrderConB3">
        <div id="OrderConForOrdersInsert" class="OrderConB4"></div>
        <div class="OrderConB21">
          <div id="OrderConButtonAddOrders" class="OrderConB22">
            <img height="12px" src="${iconAddDarkCharcoal}" />
            <div class="PagarOtrosTambiN">Pagar otros también</div>
          </div>
        </div>
      </div>
    </div>
    <div class="OrderCon10">
      <div class="OrderCon11">
        <div class="OrderTextHeader2">Ingresa un cupón</div>
        <div class="OrderCon12">
          <div class="OrderCon13">
            <input id="OrderInputCupon" placeholder="c u p ó n" class="OrderCon14" clatype="text" />
          </div>
          <div id="OrderCuponApliedMessage" style="display: none" class="OrderCon15 DeCashbackAplicado">
          </div>
          <div id="OrderCuponAplyButton" style="display: none" class="OrderCon16 OrderCuponAplyButtonNoneAct">
            <div  class="Aplicar">aplicar</div>
          </div>
        </div>
        <div
          id="OrderCuponImportantMessage"
          class="OrderCuponImportantMessage"
        ></div>
      </div>
      <div class="OrderCon17">
        <div class="OrderCon5">
          <div class="OrderTextHeader1">Final a pagar</div>
        </div>
        <div class="OrderCon19">
          <div class="OrderCon20">
            <div class="OrderCon21">
              <div class="OrderText1">Total:</div>
              <div id="OrderTotalPayTotal" class="OrderText1"></div>
            </div>
          </div>
          <div class="OrderCon20">
            <div class="OrderCon21">
              <div class="OrderText1">Descuento:</div>
              <div id="OrderTotalPayDiscountGeneral" class="OrderText1"></div>
            </div>
          </div>
        </div>
        <div class="OrderCon22">
          <div class="OrderText2">Total a pagar:</div>
          <div id="OrderTotalPayFinal" class="OrderText2"></div>
        </div>
      </div>
      <div id="OrderTotalPayAheadButton" class="OrderConButtonNext">
          Seguir a pagar
      </div>
        <div style="display: none" class="OrderConCashBackMessageCon1">
        <div class="OrderConCashBackMessageCon2">
            <div class="OrderConCashBackMessageCon3">
            <div id="OrderTotalPayCashBackTotal" class="OrderConCashBackMessageText1"></div>
            <div class="OrderConCashBackMessageText2">¡de CashBack para ti!</div>
            </div>
            <div class="OrderConCashBackMessageText3">Lo encontrarás en tu wallet y lo podras usar para compras futuras</div>
        </div>
        </div>
  
    </div>
    <div class="OrderButtonNext">
      <div id="OrderButtonCancel" class="OrderButtonBack">Cancelar</div>
    </div>
  </div>

  <div id="OrderConUseWalletForPayment" class="OrderCon1" style="display: none">
    <div class="OrderCon23">
      <div class="OrderCon5">
        <div class="OrderTextHeader1">¿Pagar con Wallet?</div>
      </div>
      <div class="OrderCon25">
        <div class="OrderCon26">
          <div class="OrderText1">Usar el saldo en tu Wallet</div>
          <div class="OrderCon27">
            <div id="OrderWalletBalance" class="OrderText1"></div>
            <input class="Frame418" type="checkbox" />
          </div>
        </div>
      </div>
      <div class="OrderCon28">
        <div class="OrderCon29">
          <div class="OrderText1">Pagas el resto con un método diferente</div>
          <div id="OrderWalletPayRestWith" class="OrderText1"></div>
        </div>
      </div>
      <div class="OrderButtonNext">
        <div id="OrderWalletButtonAheadPayNow" class="Button">
          Completar y pagar
        </div>
      </div>
      <div class="OrderButtonNext">
        <div id="OrderWalletButtonBack" class="OrderButtonBack">Cancelar</div>
      </div>
    </div>
  </div>

  <div
    id="OrderConForPayPalInsert"
    class="OrderCon1"
    style="display: none"
  ></div>

    <div id="OrderConPointHandler" style="display: none" class="OrderCon1 OrderCon1DarkTransparentBackground">
        <div class="OrderConInputPointsCon1">
            <div class="OrderConInputPointsCon2">
                <div class="OrderTextHeader1">¿Cuantos puntos quieres aumentar este listado?</div>
                <div class="OrderTextHeader1F">Con esto puedes aumentar la visibilidad de tu listado</div>
            </div>
            <input id="OrderConInputPointsInput" class="OrderConInputPointsCon3 OrderTextHeader1G"/>
            <div id="OrderConInputPointsButtonSet" class="OrderConB22">
                <div class="AplicarPuntosDePrioridad">Aplicar puntos de prioridad</div>
            </div>
        </div>
    </div>
    <div id="OrderConAddOrdersHandler" style="display: none" class="OrderCon1 OrderCon1DarkTransparentBackground">
        <div id="OrderConAddOrdersOtherOrdersCon" class="OrderConInputPointsCon1">
            <div id="OrderConAddOrdersButton" class="OrderConB22">
            <div class="AplicarPuntosDePrioridad">Agregar a tu pedido</div>
            </div>
        </div>
    </div>
</div>

`;
  }
  _makeOrder(item) {
    return `
      <div id="${item.id}" class="MainItemConForSelectUse OrderConB5">
            <div id="OrderConDeleteFromOrder" class="OrderConB6">
                <img height="8px" src="${iconExitDarkCharcoal}" />
            </div>
            <div class="OrderConB7">
              <div class="OrderConB8">
                <div class="OrderConB9">
                <div class="OrderConB10">
                    <img
                        class="OrderConB10Img ImgForBigDisplay"
                        src="${item.imgId}"
                    />
                </div>

                  <div class="OrderConB11">
                    <div class="OrderText1B">${item.type}:</div>
                    <div class="OrderText1">${item.name}</div>
                  </div>
                </div>
                <div class="OrderText1">$${item.price}</div>
              </div>
              <div class="OrderConB12">
                <div data-helpwith="priorityPoints" class="OrderConB13 openHelp">
                  <div class="OrderText1D">
                    ¿Que son los puntos de prioridad?
                  </div>
                </div>
              </div>
              <div class="OrderConB14">
                <div class="OrderConB15">
                    <div class="OrderConB16">
                        <div class="OrderText1C">Puntos de prioridad $.50/c</div>
                    </div>
                    <div id="OrderConPriorityPoint" class="OrderConB17B">
                        <img height="12px" src="${iconPriorityDarkCharcoal}" />
                        <div class="OrderConB17">
                        <div class="OrderConB19 OrderText1">${
                          item.priority.points
                        }</div>
                        <div class="OrderConB18">
                            <img height="14px" src="${iconEditDarkCharcoal}" />
                        </div>
                        </div>
                    </div>
                </div>
                <div class="OrderText1">$${item.priority.price}</div>
              </div>
              <div class="OrderConB20">
                <div class="OrderText1B">Total:</div>
                <div class="OrderText1B">$${GENRALHELPERS._formatToPrice(
                  item.totalPrice
                )}</div>
              </div>
            </div>
          </div>`;
  }

  _makeSubscriptionOrder(item) {
    console.log(item.price);
    console.log(item);
    let backgroundColorClass = "mercadoMAIBackground";
    let planTime = "Un mes";
    let listings = item.listings;

    if (item.listings === "included") {
      listings = 10;
    }
    if (item.listings === "unlimited") {
      listings = "ilimitado";
    }

    if (item.plan === "year") {
      backgroundColorClass = "mercadoMAIProBackground";
      planTime = "Un año";
      if (item.listings === "included") {
        listings = 120;
      }
    }

    return `
      <div id="${item.id}" class="MainItemConForSelectUse OrderConB5">
            <div id="OrderConDeleteFromOrder" class="OrderConB6">
                <img height="8px" src="${iconExitDarkCharcoal}" />
            </div>
            <div class="OrderConB7 ${backgroundColorClass}">
              <div class="OrderConB8">
                <div class="OrderConB9">
                  <div class="OrderConB11">
                    <div class="OrderText1B">${item.type}:</div>
                    <div class="OrderText1">${item.name}</div>
                  </div>
                </div>
              </div>
              <div class="OrderConSubB1">
                <div class="OrderConSubB2">
                  <div class="OrderConSubB3">
                    <div class="OrderText4">Plan:</div>
                    <div class="OrderText4B">${planTime}</div>
                  </div>
                  <div class="OrderConSubB3">
                    <div class="OrderText4">Listados:</div>
                    <div class="OrderText4B">${listings}</div>
                  </div>
                  <div class="OrderConSubB3B">
                    <div class="OrderText4">Nivel de prioridad:</div>
                    <div class="OrderText4B">${item.priorityLevel}</div>
                  </div>
                </div>
                <div class="OrderConSubB4 subscriptionEditButtonInOrder">
                  <img height="12px" src="${iconEditDarkCharcoal}" />
                  <div class="OrderText3">Editar plan</div>
                </div>
              </div>
              <div class="OrderConB20">
                <div class="OrderText1B">Total:</div>
                <div class="OrderText1B">$${GENRALHELPERS._formatToPrice(
                  item.price
                )}</div>
              </div>
            </div>
          </div>`;
  }
}

export default new generateHtml();
