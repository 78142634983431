import iconDarkSearch from "../../../Icons/icons-charcoal-dark/search.png";
import iconDarkFilter from "../../../Icons/icons-charcoal-dark/filter.png";
import iconDarkFilterOpen from "../../../Icons/icons-charcoal-dark/filter-open.png";
import iconDarkFilterReset from "../../../Icons/icons-charcoal-dark/filter-reset.png";
import iconDarkSearch from "../../../Icons/icons-charcoal-dark/search.png";
import iconDarkMenuUp from "../../../Icons/icons-charcoal-dark/menu-up.png";
import iconDarkMenuDown from "../../../Icons/icons-charcoal-dark/menu-down.png";
import iconDarkBusiness from "../../../Icons/nav-icons/active/business.png";

class generateHtml {
  _createPriceSliderContainer(minPrice, maxPrice, slider, id) {
    return `
  <div id="${id}" class="PriceAdjustComponentCon1">
    <div class="PriceAdjustComponentCon2">${slider}</div>
    <div class="PriceAdjustComponentCon2">
      <div class="PriceAdjustComponentCon13">
        <div id="PriceAdjustComponentConTextMin" class="PriceAdjustComponentConText">
          ${minPrice}
        </div>
        <div id="PriceAdjustComponentConTextMax" class="PriceAdjustComponentConText">
          ${maxPrice}
        </div>
      </div>
      <div class="PriceAdjustComponentCon14">
        <div class="PriceAdjustComponentCon14Text">
          Hemos convertido los precios a MXN para facilitar tu experiencia, pero
          es posible que la tasa de conversión no sea la más reciente.
        </div>
      </div>
    </div>
  </div>
    `;
  }
  _silderComponentCreator(presetMin, presetMax) {
    // Insert HTML for the slider
    return `
        <div class="price-slider-container">
          <div class="input-container">
            <div class="input-group">
              <input class="input-group-input" type="text" inputmode="numeric" id="min-input" value="${presetMin}" />
              <div class="input-label">Mínimo</div>
            </div>
            <div class="input-group">
              <input class="input-group-input" type="text" inputmode="numeric" id="max-input" value="${presetMax}" />
              <div class="input-label">Máximo</div>
            </div>
          </div>
          <div class="slider-track">
            <div class="slider-range" id="slider-range"></div>
            <div class="slider-handle" id="min-handle"></div>
            <div class="slider-handle" id="max-handle"></div>
          </div>
        </div>
      `;
  }
  //   <div class="SearchStartUpComponentCon11">
  //   <select
  //     class="SearchStartUpComponentCon12"
  //     name=""
  //     id="${prefixId}SearchStartUpComponentSelectMachineType"
  //   >
  //     <option value="todos">Todos</option>
  //   </select>
  // </div>

  _createWholeSearchComponent(htmlPriceAdjuster, backgroundClass, prefixId) {
    return `
<div
  id="${prefixId}SearchStartUpComponentAllCon"
  class="SearchStartUpComponentCon1 ${backgroundClass}"
>
  <div class="SearchStartUpComponentCon2">
    <div class="SearchStartUpComponentCon3">
      <div class="SearchStartUpComponentCon4">
        <label class="SearchStartUpComponentCon5">
          <img height="20px" src="${iconDarkSearch}" alt="" />
          <input
            id="${prefixId}SearchStartUpComponentSearchInput"
            class="SearchStartUpComponentCon5Input"
            type="text"
            placeholder="Buscar listados"
            enterkeyhint="search" 
          />
          <div data-active="false" id="${prefixId}ActivateOnlyBusinessSeachingButton" class="ActivateOnlyBusinessSeachingButton">
            <img height="12px" src="${iconDarkBusiness}" alt="" />
          </div>
        </label>
      </div>
      <div id="${prefixId}SearchStartUpComponentFilterButton" class="SearchStartUpComponentCon6">
      <div id="" class="SearchStartUpComponentCon6B">
        <p>búsqueda avanzada</p>
        </div>
        <img height="14px" src="${iconDarkFilter}" alt="" />
      </div>
    </div>
    <div
      id="${prefixId}SearchStartUpComponentCon7"
      class="SearchStartUpComponentCon7"
    >
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineType"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div class="SearchStartUpComponentCon10">
            <div class="SearchStartUpComponentCon10Label">Maquina</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones de maquina"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineBrand"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div class="SearchStartUpComponentCon10">
            <div class="SearchStartUpComponentCon10Label">Marca</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones de marca"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineModel"
        class="SearchStartUpComponentCon8"
      >
        <div class="SearchStartUpComponentCon9">
          <div class="SearchStartUpComponentCon10">
            <div class="SearchStartUpComponentCon10Label">Modelo</div>
            <div class="select-wrapper">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Buscar opciones modelo"
              />
              <div id="option-list" class="option-list option-list-for-bottom-list"></div>
            </div>
          </div>
        </div>
      </div>


     
      <div class="SearchStartUpComponentCon14">
        <div
          id="${prefixId}SearchStartUpComponentFilterNowButton"
          class="SearchStartUpComponentCon15"
        >
          <img height="18px" src="${iconDarkSearch}" alt="" />
          <div class="Button">buscar ahora</div>
        </div>
        <p class="SearchStartUpComponentFilterButtonClose" id="${prefixId}SearchStartUpComponentFilterButtonClose">Cerrar</p>
      </div>
    </div>
  </div>
  <div  id="${prefixId}SearchStartUpComponentByTrends" class="SearchStartUpComponentCategoryCon1">
    <div class="SearchStartUpComponentCategoryCon1Label">Acceso rápido</div>
    <div class="SearchStartUpComponentCategoryCon2">
      <button data-openby="other" data-openwhat="businesses" class="SearchStartUpComponentCategoryCon3">Negocios
      </button>
      <button data-openby="other" data-openwhat="discount" class="SearchStartUpComponentCategoryCon3">En oferta
      </button>
      <button data-openby="category" data-openwhat="tractor" class="SearchStartUpComponentCategoryCon3">Tractores
      </button>
      <button data-openby="category" data-openwhat="sembradora" class="SearchStartUpComponentCategoryCon3">Sembradoras
      </button>
    </div>
  </div>
  <div class="SearchListingsResultsContianerForSnap">
  <div id="${prefixId}SearchStartUpComponentCon13" class="SearchStartUpComponentCon13">
  <div class="SearchStartUpComponentCon13B">
      <button data-actiondo="hide" id="${prefixId}SearchUicSearchFilterResultsShowHide" data-prefixid="${prefixId}" class="SearchUicSearchFilterResultsButton ">
        <img height="10px" src="${iconDarkMenuUp}"/>
      </button>
        <button id="${prefixId}SearchUicSearchFilterResultsButton" data-prefixid="${prefixId}" class="SearchUicSearchFilterResultsButton ">
        <img height="10px" src="${iconDarkFilterOpen}"/> <p>Filtrar resultados</p> 
      </button>
      <button id="${prefixId}SearchUicSearchRemoveFilterResultsButton" data-prefixid="${prefixId}" class="SearchUicSearchFilterResultsButton">
        <img height="10px" src="${iconDarkFilterReset}"/>Quitar filtros
      </button>
      </div>
    <div id="${prefixId}SearchStartUpComponentCon13Number" class="SearchStartUpComponentCon13Text"><span class="SearchStartUpComponentCon13Text"></span></div>
  </div>
  <div class="SearchListingsResultsContianer" id="${prefixId}SearchListingsResultsContianer"></div> 
  <div class="SearchListingsResultsContianerTrigerLoadMore" id="${prefixId}SearchListingsResultsContianerTrigerLoadMore"></div>
  </div>
</div>

<div id="${prefixId}SearchStartUpComponentSelectFilter" class="SearchStartUpComponentSelectForFilter" >
     <div class="SearchStartUpComponentSelectForFilterCon2">
     <div class="SearchStartUpComponentSelectForFilterCon3">
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineYear"
        class="SearchStartUpComponentCon8 SearchStartUpComponentCon8B"
      >
        <div class="SearchStartUpComponentCon9">
          <div
            class="SearchStartUpComponentCon10 SearchStartUpComponentCon10Clear"
          >
            <div class="SearchStartUpComponentCon10Label">Año</div>
            <div class="select-wrapper-2">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Seleccionar años"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="${prefixId}SearchStartUpComponentSelectMachineCondition"
        class="SearchStartUpComponentCon8 SearchStartUpComponentCon8B"
      >
        <div class="SearchStartUpComponentCon9">
          <div
            class="SearchStartUpComponentCon10 SearchStartUpComponentCon10Clear"
          >
            <div class="SearchStartUpComponentCon10Label">Condición</div>
            <div class="select-wrapper-2">
              <div class="selected-options" id="selected-options"></div>
              <input
                type="text"
                id="search-input"
                class="search-input"
                placeholder="Seleccionar Condición"
              />
              <div id="option-list" class="option-list"></div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="SearchStartUpComponentCon16">${htmlPriceAdjuster}</div>
        </div>
      </div>



    `;
  }
}

export default new generateHtml();
