import nav from "../home-screen/nav";
import spinner from "../spinner";
import generateHtml from "./generate-html";
import displayListingGenerateHtml from "../display-listing/generate-html";
import firebase from "../firebase";
const colors = {
  headerPrimary: "FFFFFF",
  headerSecondary: "E0E0E0",
  packTitles: "6D6D6D",
  packTitlesHighlight: "3A7D44",
  itemBackgroundPrimary: "3A7D44",
  itemBackgroundSecondary: "fff",
  itemTextPrimary: "FFFFFF",
  itemTextSecondary: "2B2B2B",
  itemTextTerteriary: "3A7D44",
  itemTextFour: "3A7D44",
  itemHighlight: "FF7043",
  itemHighlightText: "FFFFFF",
};

class saved {
  #sortButtonsActived = false;
  #loadedData;
  constructor() {}

  async _loadSaved() {
    const SavedAndFollowingTextB10UpateMessage = document.getElementById(
      "SavedAndFollowingTextB10UpateMessage"
    );

    this.#sortButtonsActived = false;
    const account = firebase._getLatestCurrentUserData();
    if (!account) {
      SavedAndFollowingTextB10UpateMessage.textContent =
        "Dedes ingresar a tu cuenta para ver tus guardados.";
      return;
    }

    const allPaths = [];
    let activeBusinessFollows = [];
    let savedBusinesses = [];
    let savedListings = [];

    if (account.activeFollows) {
      activeBusinessFollows = account.activeFollows;
    }
    if (account.activeBusinessSaves) {
      savedBusinesses = account.activeBusinessSaves;
    }
    if (account.activeSaves) {
      savedListings = account.activeSaves;
    }

    // Add paths to the allPaths array
    if (activeBusinessFollows.length > 0) {
      activeBusinessFollows.forEach((save) => {
        if (typeof save === "object") {
          allPaths.push(save.path);
        }
        if (typeof save === "string") {
          allPaths.push(save);
        }
      });
    } else {
    }
    if (savedBusinesses.length > 0) {
      savedBusinesses.forEach((save) => {
        if (typeof save === "object") {
          allPaths.push(save.path);
        }
        if (typeof save === "string") {
          allPaths.push(save);
        }
      });
    } else {
    }
    if (savedListings.length > 0) {
      savedListings.forEach((save) => {
        if (typeof save === "object") {
          allPaths.push(save.path);
        }
        if (typeof save === "string") {
          allPaths.push(save);
        }
      });
    } else {
    }

    if (
      !activeBusinessFollows.length > 0 &&
      !savedBusinesses.length > 0 &&
      !savedListings.length > 0
    ) {
      SavedAndFollowingTextB10UpateMessage.textContent =
        "No tienes guardados aún.";
      return;
    }

    const loadedSaves = {
      businesses: [],
      individualListings: [],
    };

    // To track which businesses are loaded
    const loadedBusinessIds = new Set();

    // Sequential loading function for each path
    async function loadData(path) {
      const save = await firebase._getAnyDocById(path);

      // Extract business ID if it's a Type 1 path (businesses/{businessId}/listings/{listingId})
      let businessId = null;
      if (path.startsWith("businesses/")) {
        const parts = path.split("/");
        businessId = parts[1]; // Extract business ID from the path
      }

      // Type 1: Business and listing path (businesses/{businessId}/listings/{listingId})
      if (path.includes("businesses/") && path.includes("/listings/")) {
        // Check if the business is already loaded (businessId)
        if (!loadedBusinessIds.has(businessId)) {
          // Load the business if not already loaded
          const business = await firebase._getAnyDocById(
            `businesses/${businessId}`
          );
          loadedSaves.businesses.push({
            ...business,
            id: businessId, // Ensure you have the `id` property here
            listings: [], // Initialize the listings array
          });

          // Mark this business as loaded
          loadedBusinessIds.add(businessId);
        }

        // Add the listing to the corresponding business
        const business = loadedSaves.businesses.find(
          (b) => b.id === businessId
        );
        business.listings.push(save);
      }
      // Type 2: Individual listing path (listings/{listingId})
      else if (path.startsWith("listings/")) {
        loadedSaves.individualListings.push(save);
      }

      // Type 3: Business path (businesses/{businessId})
      else if (path.startsWith("businesses/")) {
        // Check if the business is already loaded (businessId)
        if (!loadedBusinessIds.has(businessId)) {
          // Load the business if not already loaded
          loadedSaves.businesses.push({
            ...save,
            id: businessId, // Ensure you have the `id` property here
            listings: [], // Initialize the listings array
          });

          // Mark this business as loaded
          loadedBusinessIds.add(businessId);
        }
      }
    }

    // Sequentially process each path one at a time (wait for each to finish before starting the next)
    try {
      // Loop through each path and load the data one by one
      for (let i = 0; i < allPaths.length; i++) {
        await loadData(allPaths[i]); // This ensures each path loads in sequence
      }

      // After all data is loaded, display and finalize
      this.#loadedData = loadedSaves;
      this._displayAllSaved("all");
      this.#sortButtonsActived = true;
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }

  async _displayAllSaved(display) {
    const dataIn = this.#loadedData;
    const containerForSpecialDiscountListings = document.getElementById(
      "SavedAndFollowingCon9InsertSaved"
    );
    // const SavedAndFollowingTextB10UpateMessage = document.getElementById(
    //   "SavedAndFollowingTextB10UpateMessage"
    // );

    containerForSpecialDiscountListings.innerHTML = "";
    if (display === "all" || display === "businesses") {
      for (const business of dataIn.businesses) {
        let listingsHtml = ``;

        if (
          !Array.isArray(business.listings) ||
          business.listings.length === 0
        ) {
          listingsHtml +=
            displayListingGenerateHtml._listingVerticalMiniNoListings(
              null,
              "No tines listados guardados en este negocio."
            );
        } else {
          for (const listing of business.listings) {
            listingsHtml +=
              await displayListingGenerateHtml._listingVerticalMini(
                listing,
                business.colors,
                "listingInBusiness",
                business.businessId
              );
          }
        }

        const businessInPackHtml =
          await displayListingGenerateHtml._businessInPack(
            listingsHtml,
            business
          );
        let isInSearch = false;

        if (isInSearch) {
          return businessInPackHtml;
        } else {
          // console.warn("html", businessInPackHtml);
          containerForSpecialDiscountListings.insertAdjacentHTML(
            "beforeend",
            businessInPackHtml
          );
        }
      }
    }
    if (display === "all" || display === "listings") {
      for (const listing of dataIn.individualListings) {
        const html = await displayListingGenerateHtml._listingHorizontal(
          listing,
          colors,
          "listing"
        );
        containerForSpecialDiscountListings.insertAdjacentHTML(
          "beforeend",
          html
        );
      }
    }
  }

  async _open() {
    spinner._hideSpinner();
    nav._changeNav("Saved");
    this._displayRightNavWindow();
    this._setContent();
    this._loadSaved();
  }

  _displayRightNavWindow() {
    const windows = document.querySelectorAll(
      ".MainAppConWithNavigationContentCon"
    );

    windows.forEach((window) => {
      if (window.id === "MainAppConWithNavigationContentConSaved") {
        window.style.display = "";
      } else {
        window.style.display = "none";
      }
    });
  }

  _setContent() {
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConSaved"
    );
    HomeScreenContenConForAll.style.paddingTop = "0px";
    HomeScreenContenConForAll.innerHTML = "";
    HomeScreenContenConForAll.innerHTML = generateHtml._main();
    this._events();
  }

  _events() {
    const SavedConMainSavedButtonSortAll = document.getElementById(
      "SavedConMainSavedButtonSortAll"
    );
    const SavedConMainSavedButtonSortBusiness = document.getElementById(
      "SavedConMainSavedButtonSortBusiness"
    );
    const SavedConMainSavedButtonSortListings = document.getElementById(
      "SavedConMainSavedButtonSortListings"
    );
    const SavedConMainSavedButtonSortFollowing = document.getElementById(
      "SavedConMainSavedButtonSortFollowing"
    );
    SavedConMainSavedButtonSortAll.addEventListener("click", () => {
      if (!this.#sortButtonsActived) return;
      this._displayAllSaved("all");
    });
    SavedConMainSavedButtonSortBusiness.addEventListener("click", () => {
      if (!this.#sortButtonsActived) return;
      this._displayAllSaved("businesses");
    });
    SavedConMainSavedButtonSortListings.addEventListener("click", () => {
      if (!this.#sortButtonsActived) return;
      this._displayAllSaved("listings");
    });
  }
}
export default new saved();
