import { headerIn, headerOut } from "../header";
import firebase from "../firebase";

class HandlenAccount {
  #accountState;
  constructor() {}

  async _accountInit() {
    const localKey = headerIn("accountId");
    const data = localStorage.getItem(localKey);
    if (!data) return null;
    const accountId = headerOut(data);
    const accountData = await firebase._getAccountDataById(accountId);
    this.#accountState = accountData;
  }

  async _getAccountState() {
    await this._accountInit();
    return this.#accountState;
  }

  _getLocalAccountState() {
    return this.#accountState;
  }
}

export default new HandlenAccount();
