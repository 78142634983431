import firebase from "../firebase";
import generateHtml from "./generate-html";
import iconWhiteExit from "../../../Icons/icons-white/exit.png";

const HelpOpenCon1Main = document.getElementById("HelpOpenCon1Main");
const MainContainerForVideoDisplayer = document.getElementById(
  "MainContainerForVideoDisplayer"
);
const HelpOpenCon1MainButtonBack = document.getElementById(
  "HelpOpenCon1MainButtonBack"
);

// const HelpSectionChatButton = document.getElementById("HelpSectionChatButton");
const HelpSectionInsertVideos = document.getElementById(
  "HelpSectionInsertVideos"
);
const HelpSectionSearchInput = document.getElementById(
  "HelpSectionSearchInput"
);
const HelpSectionCon9All = document.querySelectorAll(".HelpSectionCon9");

class help {
  #helpVideos;
  #generalEventInitialized = false;
  constructor() {
    this._eventsGeneral();
  }
  async _open(openOn) {
    console.log(openOn);
    HelpOpenCon1Main.style.display = "flex";
    await this._getAllVideos();
    if (openOn) {
      this._searchWhat(openOn);
    }
    this._setHelpMessageForWhatsAppButton(openOn);
  }
  _close() {
    console.log("Close help was called");
    HelpOpenCon1Main.style.display = "none";
  }

  _setHelpMessageForWhatsAppButton(input) {
    const whatsAppHelpButton = document.getElementById("whatsAppHelpButton");
    let helpMessage =
      "https://wa.me/9967306118?text=Necesito%20ayuda%20en%20Mercado%20Mai%20con:%20";

    if (input === "mercadoMai") {
      helpMessage += "la%20aplicación.";
    }
    if (input === "createAccount") {
      helpMessage += "crear%20una%20cuenta.";
    }
    if (input === "logIn") {
      helpMessage += "ingresar%20a%20mi%20cuenta.";
    }
    if (input === "wallet") {
      helpMessage += "mi%20wallet.";
    }

    whatsAppHelpButton.href = helpMessage;
  }

  _search(input) {
    let vids = [];
    if (!input || input.length < 1) {
      vids = this.#helpVideos;
    } else {
      const words = input.toLowerCase().split(" "); // Convert to lowercase for better matching
      console.log("Search words:", words);

      const videoScores = new Map(); // Stores video ID → score mapping

      words.forEach((word) => {
        this.#helpVideos.forEach((vid) => {
          console.log(vid);
          const matchCount = vid.tokens.filter((token) =>
            token.includes(word)
          ).length; // Count matches

          if (matchCount > 0) {
            if (!videoScores.has(vid)) {
              videoScores.set(vid, matchCount); // Initialize with match count
            } else {
              videoScores.set(vid, videoScores.get(vid) + matchCount); // Add to existing score
            }
          }
        });
      });

      // Convert Map to sorted array based on score (higher first)
      vids = [...videoScores.entries()]
        .sort((a, b) => b[1] - a[1]) // Sort by score (descending)
        .map((entry) => entry[0]); // Extract video objects

      console.log("Sorted videos by relevance:", vids);
    }

    this._insertVideos(vids);
  }

  _myOwnVidDisplayerNotAIs(vidUrls) {
    const container = document.getElementById("MainContainerForVideoDisplayer");
    container.style.display = "flex";

    container.addEventListener("click", (e) => {
      const isCloseButton = e.target.closest(".ExitButtonForImgDisplayer");
      if (isCloseButton) {
        container.style.display = "none";
      }
    });
    function getYouTubeVideoId(url) {
      const regex =
        /(?:youtube\.com\/(?:.*v=|.*\/embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);
      return match ? match[1] : null;
    }

    let imgHtml = ``;
    vidUrls.forEach((vid) => {
      // src="https://www.youtube.com/embed/${getYouTubeVideoId(vid)}?autoplay=1"
      imgHtml += `
        <iframe 
        id="iframForVideoForClose"
        width="600"
        height="500"
        src="https://www.youtube.com/embed/${vid}?autoplay=1"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
        ></iframe>
`;

      console.log(vid);

      console.log(getYouTubeVideoId(vid));
    });

    const finalHtml = `
            <div id="ExitButtonForVidDisplayer" class="ExitButtonForVidDisplayer closeVidBig">
              <img height="32px" src="${iconWhiteExit}" alt="" />
            </div>
            <div id="MyOwnImgDisplayerNotAIsCon1" class="MyOwnImgDisplayerNotAIsCon1">${imgHtml}</div>`;

    container.innerHTML = finalHtml;
    this._eventSBigVid();
  }
  _eventSBigVid() {
    const ExitButtonForVidDisplayer = document.getElementById(
      "ExitButtonForVidDisplayer"
    );
    ExitButtonForVidDisplayer.addEventListener("click", (e) => {
      console.log("also clicked here");
      console.log("click here");
      const iframForVideoForClose = document.getElementById(
        "iframForVideoForClose"
      );
      console.log(iframForVideoForClose);
      if (iframForVideoForClose) {
        iframForVideoForClose.remove();
      }
      MainContainerForVideoDisplayer.style.display = "none";
    });
  }
  async _getAllVideos() {
    // helper._logToConsole("here it worked");
    this.#helpVideos = await firebase._getAllDocsFromPathByMostRecent(
      "helpVideos"
    );

    console.log(this.#helpVideos);
    this._insertVideos(this.#helpVideos);
    // this._myOwnVidDisplayerNotAIs([this.#helpVideos[0].videoUrl]);
  }

  //   <div class="HelpSectionVideoText2">
  //    </div>

  _insertVideos(videos) {
    let html = ``;

    videos.forEach((video) => {
      let sections = ``;
      console.log(video.sections);
      video.sections.forEach((section) => {
        console.log("here");
        sections += `<div class="HelpSectionVideoText2B">
        ${section}
                        </div>`;
      });
      console.log(sections);

      html += generateHtml._helpVideo(video, sections);
    });

    HelpSectionInsertVideos.innerHTML = "";
    HelpSectionInsertVideos.insertAdjacentHTML("beforeend", html);
  }
  _searchWhat(input) {
    let vids = [];
    this.#helpVideos.forEach((vid) => {
      if (vid.shortcut === input) {
        vids.push(vid);
      }
    });
    if (vids.length < 1) {
      this.#helpVideos.forEach((vid) => {
        if (vid.shortcut === "unavailable") {
          vids.push(vid);
        }
      });
      // vids = this.#helpVideos;
      // HelpSectionSearchInput.value = "";
    } else {
    }
    HelpSectionSearchInput.value = input;
    this._insertVideos(vids);
    console.log(input);
  }

  _eventsGeneral() {
    HelpOpenCon1MainButtonBack.addEventListener("click", () => {
      this._close();
    });
    HelpSectionCon9All.forEach((button) => {
      button.addEventListener("click", () => {
        const searchWhat = button.dataset.searchwhat;
        this._searchWhat(searchWhat);
      });
    });
    // HelpSectionChatButton.addEventListener("click", () => {
    //   console.log("open whatsApp");
    // });

    if (this.#generalEventInitialized) return;
    HelpSectionInsertVideos.addEventListener("click", (e) => {
      const thisVideo = e.target.closest(".HelpSectionVideoCon1");
      if (thisVideo) {
        const allvids = document.querySelectorAll(".HelpSectionVideoCon1");

        allvids.forEach((vid) => {
          if (vid !== thisVideo) {
            vid.classList.remove("HelpSectionVideoCon1Active");
          } else {
            thisVideo.classList.add("HelpSectionVideoCon1Active");
          }
        });
        const url = thisVideo.dataset.videourl;
        if (url) {
          this._myOwnVidDisplayerNotAIs([url]);
        }
      }
    });
    HelpSectionSearchInput.addEventListener("keyup", () => {
      this._search(HelpSectionSearchInput.value.trim());
    });
    HelpSectionSearchInput.addEventListener("blur", () => {
      this._search(HelpSectionSearchInput.value.trim());
    });
    this.#generalEventInitialized = true;
  }
}

export default new help();
