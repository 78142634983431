// import AppLogo from "../../../Icons/logos/App-logo.png";
// import iconShow from "../../../Icons/icons-charcoal-dark/eye-show.png";
// import iconDone from "../../../Icons/icons-charcoal-dark/done.png";
import iconStar from "../../../Icons/icons-yellow/star.png";
import iconViews from "../../../Icons/icons-charcoal-dark/views.png";
import firebase from "../firebase";
import displayListing from "./display-listing";
import iconVerified from "../../../Icons/icons-white/verified.png";

import GENRALHELPERS from "../GENRAL-HELPERS";

// import { title } from "process";

//
// style="background: #${colors.}; color: #${colors.};"

//

class GenarateHtml {
  _generateBusinessRewiewStars(number, reviews, data) {
    // TODO for now in use. Need to develop a rating system

    if (data.verifiedBusiness) {
      return ` 
      <div class="RatingStarCon2">
        <img class="" height="12px" src="${iconVerified}" alt=""/>
        <div class="BusinessEditorBusinessHeaderVerifiedBadgeText">verificado de Mercado MAI</div>
      </div>
       `;
    }
    return ``;
    let starHtml = ``;
    const stars = Math.round(number);
    if (stars === 0) {
      starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
    } else {
      for (let i = 0; i < stars; i++) {
        starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
      }
    }
    // <div class="RatingStarReviewText">
    //   ${reviews} calificaciones
    // </div>
    return `<div class="RatingStarCon1">
    <div class="RatingStarCon2">
      <div class="RatingStarRatedAtNumber">
        ${number}
      </div>
      <div class="RatingStarCon3">
        ${starHtml}
      </div>
    </div>

  </div>`;
  }
  _businessPackOfPacks(html) {
    return `
    <div class="BusinessesPackOfPacksDisplayCon1">${html}</div>
    `;
  }
  _businessPack(title, id, colors) {
    return `
  <div class="BusinessesPackDisplayCon1">
    <div class="BusinessesPackDisplayCon2">
      <div  style="color: #${colors.packTitles};" class="BusinessesPackDisplayTitle">
        ${title}
      </div>
      <div data-generall="expandforviewallbusiness" class="BusinessesPackDisplayVerTodo">
        Ver mas
      </div>
    </div>
    <div id="${id}" class="BusinessesPackDisplayCon3"></div>
  </div>`;
  }
  _listingPack(title, colors, id, actionData = "expandforviewall") {
    return `
  <div class="ListingPackDisplayCon1">
    <div class="ListingPackDisplayCon2">
      <div  style="color: #${colors.packTitles};" class="BusinessesPackDisplayTitle">
        ${title}
      </div>
      <div data-generall="${actionData}"  style="color: #${colors.packTitlesHighlight};" class="BusinessesPackDisplayVerTodo">
        Ver mas
      </div>
    </div>
    <div id="${id}" class="ListingPackDisplayCon3"></div>
  </div>`;
  }
  async _businessInPack(listings, data) {
    const backImgUrl = data.backgroundImg;
    const ProfileImgUrl = data.profileImg;
    return `
<div
  data-openTo="business"
  data-businessId="${data.businessId}"
  class="BusinessInSearchCon1 ActionOpenForDisplay"
>
  <div class="BusinessInSearchCon2">
    <img
      id=""
      class="BusinessInSearchCon1BackgroundImg"
      loading="lazy"
      src="${backImgUrl}"
      alt=""
    >
    <div class="BusinessInSearchCon3">
      <div class="BusinessInSearchCon4">
        <div class="BusinessInSearchCon5">
          <div class="BusinessInSearchCon6">
            <div
              style="color: #${data.colors.headerPrimary};"
              class="BusinessInSearchBusinessName"
            >
              ${data.name}
            </div>
            <div
              style="color: #${data.colors.headerSecondary};"
              class="BusinessInSearchBusinessShortDescription"
            >
              ${data.shortDescription}
            </div>
          </div>
          <div class="BusinessInSearchCon7">
            ${this._generateBusinessRewiewStars(
              data.ratedAt,
              data.numberOfReviews,
              data
            )}
          </div>
        </div>
        <img
          id="BusinessInSearchCon8ProfileImg"
          class="BusinessInSearchCon8 ImgForBigDisplay"
          src="${ProfileImgUrl}"
          loading="lazy"
          alt=""
        >
      </div>
    </div>
  </div>
  <div class="BusinessInSearchCon9 BusinessInSearchCon9B">
    <div class="BusinessInSearchCon10">${listings}</div>
  </div>
</div>
`;
  }
  async _listingHorizontal(data, colors, openToIn, from) {
    let priceToDisplay = data.listingPrice;
    // const openTo = data.openTo;
    let openTo = "listing";
    if (data.businessId) {
      openTo = "listingInBusiness";
    }

    const itemImg = data.listingMainImg;
    // const itemImg = await firebase._getImageUrl(data.listingMainImg);

    let onDiscountTagHtml = "";
    let onDiscountTextHtml = "";

    if (data.onDiscount) {
      if (!data.onDiscount.amount || parseInt(data.onDiscount.amount) < 1) {
      } else {
        priceToDisplay = data.onDiscount.finalPrice;
        console.log(data.listingName);

        onDiscountTagHtml = `
        <div
          style="background: #${colors.itemHighlight};"
          class="ListingVeriticalCon10"
          >
          <div
            style="color: #${colors.itemHighlightText};"
            class="ListingVeriticalOnDiscount"
          >
            En oferta ${data.onDiscount.percent}% ↓
          </div>
        </div>
      `;

        onDiscountTextHtml = `
          <div
            style="color: #${colors.itemHighlight};"
            class="ListingVeriticalPriceBeforeText"
            >
            <div class="Antes">Antes</div>
            <div
              style="color: #${colors.itemHighlight};"
              class="ListingVeriticalPriceBeforePrice"
            >
              ${GENRALHELPERS._convertToPrice(
                data.listingPrice,
                data.listingCoin
              )}
            </div>
          </div>
      `;
      }
    }

    return `
<div
  data-openTo="${openTo}"
  data-openfrom="${from}"
  data-listingId="${data.listingId}"
  data-businessId="${data.businessId}"
    class="ListingHorizontalCon1 ActionOpenForDisplay">
  <img class="ListingHorizontalCon2Image ImgForBigDisplay" src="${itemImg}" loading="lazy">
  <div style="background: #${colors.itemBackgroundSecondary};"
       class="ListingHorizontalCon3">
    <div class="ListingHorizontalCon4">
      <div style="background: #${
        colors.itemBackgroundPrimary
      };" class="ListingHorizontalCon5">
        <div style="color: #${
          colors.itemTextPrimary
        };" class="ListingVeriticalTitle">
          ${data.listingName}
        </div>
      </div>
      <div style="background: #${
        colors.itemBackgroundSecondary
      };" class="ListingHorizontalCon6">
        <div class="ListingHorizontalCon7">
          <div class="ListingHorizontalCon8">
            <div class="ListingHorizontalCon9">
             ${onDiscountTagHtml}
              <div style="color: #${
                colors.itemTextFour
              };" class="ListingVeriticalPrice">
                ${GENRALHELPERS._convertToPrice(
                  priceToDisplay,
                  data.listingCoin
                )}
              </div>
            </div>
            <div style="color: #${
              colors.itemTextFour
            };" class="ListingVeriticalCondition">
              ${data.listingCondition}
            </div>
          </div>
          ${onDiscountTextHtml}
        </div>
        <div style="color: #${
          colors.itemTextSecondary
        };" class="ListingVeriticalDescription">
          ${GENRALHELPERS._shortenText(data.listingDescription, 20)}
        </div>
      </div>
    </div>
    <div class="ListingHorizontalCon12">
      <div class="ListingDisplayDateCon">
          <p class="ListingDisplayDateText" style="color: #${
            colors.itemTextTerteriary
          };">
          ${GENRALHELPERS._convertServerTimestampToReadableTime(data.updatedAt)}
          </p>
      </div>
      <div class="ListingMiniVeriticalCon12">
        <div class="ListingDisplayViewsText" style="color: #${
          colors.itemTextTerteriary
        };">
          ${GENRALHELPERS._formatNumberShort(data.listingViews)}
        </div>
                <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M7.46885 5.36328L7.46807 5.3625L7.46729 5.36172C7.12275 4.63281 6.58916 4.25781 5.879 4.25781C5.16885 4.25781 4.63525 4.63359 4.28994 5.36172V5.3625C4.24775 5.45234 4.24775 5.55703 4.28994 5.64687C4.63525 6.375 5.16885 6.75 5.879 6.75C6.58916 6.75 7.12275 6.37422 7.46807 5.64609C7.51025 5.55625 7.51025 5.45234 7.46885 5.36328ZM5.879 6.25C5.39385 6.25 5.03994 6.01562 4.77666 5.50391C5.03916 4.99219 5.39385 4.75781 5.879 4.75781C6.36416 4.75781 6.71807 4.99219 6.98135 5.50391C6.71885 6.01562 6.36416 6.25 5.879 6.25Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.44531 5.50781C5.44531 5.62384 5.49141 5.73512 5.57345 5.81717C5.6555 5.89922 5.76678 5.94531 5.88281 5.94531C5.99884 5.94531 6.11012 5.89922 6.19217 5.81717C6.27422 5.73512 6.32031 5.62384 6.32031 5.50781C6.32031 5.39178 6.27422 5.2805 6.19217 5.19845C6.11012 5.11641 5.99884 5.07031 5.88281 5.07031C5.76678 5.07031 5.6555 5.11641 5.57345 5.19845C5.49141 5.2805 5.44531 5.39178 5.44531 5.50781ZM1.0625 1.8125H6.5625V3.78906H7.125V1.5C7.125 1.36172 7.01328 1.25 6.875 1.25H0.75C0.611719 1.25 0.5 1.36172 0.5 1.5V5.5625C0.5 5.70078 0.611719 5.8125 0.75 5.8125H3.5V5.25H1.0625V1.8125Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.66348 2.64136L5.37598 2.35386C5.35176 2.32964 5.31191 2.32964 5.2877 2.35386L3.85176 3.79136L3.1791 3.11792C3.15488 3.0937 3.11504 3.0937 3.09082 3.11792L1.96348 4.24526C1.93926 4.26948 1.93926 4.30933 1.96348 4.33354L2.25098 4.62104C2.2752 4.64526 2.31504 4.64526 2.33926 4.62104L3.13457 3.82573L3.80723 4.49917C3.83145 4.52339 3.87129 4.52339 3.89551 4.49917L5.66348 2.72964C5.68848 2.70542 5.68848 2.66558 5.66348 2.64136Z"
          fill="#${colors.itemTextTerteriary}"
          />
        </svg>
      </div>
    </div>
  </div>
</div>
    `;
  }
  async _listingVertical(data, colors, openToIn, businessId, from) {
    let priceToDisplay = data.listingPrice;
    const itemImg = data.listingMainImg;
    // const itemImg = await firebase._getImageUrl(data.listingMainImg);
    // const openTo = data.openTo;
    let openTo = "listing";
    if (data.businessId) {
      openTo = "listingInBusiness";
    }

    let onDiscountTagHtml = "";
    let onDiscountTextHtml = "";

    if (data.onDiscount) {
      if (!data.onDiscount.amount || parseInt(data.onDiscount.amount) < 1) {
      } else {
        priceToDisplay = data.onDiscount.finalPrice;

        onDiscountTagHtml = `
        <div
          style="background: #${colors.itemHighlight};"
          class="ListingVeriticalCon10"
          >
          <div
            style="color: #${colors.itemHighlightText};"
            class="ListingVeriticalOnDiscount"
          >
            En oferta ${data.onDiscount.percent}% ↓
          </div>
        </div>
      `;

        onDiscountTextHtml = `
          <div
            style="color: #${colors.itemHighlight};"
            class="ListingVeriticalPriceBeforeText"
            >
            <div class="Antes">Antes</div>
            <div
              style="color: #${colors.itemHighlight};"
              class="ListingVeriticalPriceBeforePrice"
            >
              ${GENRALHELPERS._convertToPrice(
                data.listingPrice,
                data.listingCoin
              )}
            </div>
          </div>
      `;
      }
    }

    return `
<div
  data-openTo="${openTo}"
  data-openfrom="${from}"
  data-listingId="${data.listingId}"
  data-businessId="${data.businessId}"
  class="ListingVeriticalCon1 ActionOpenForDisplay"
>
  <img class="ListingVirticalCon2Image ImgForBigDisplay" src="${itemImg}" loading="lazy">
  <div
    style="background: #${colors.itemBackgroundSecondary}; "
    class="ListingVeriticalCon3"
  >
    <div class="ListingVeriticalCon4">
      <div
        style="background: #${colors.itemBackgroundPrimary};"
        class="ListingVeriticalCon5"
      >
        <div style="color: #${
          colors.itemTextPrimary
        };" class="ListingVeriticalTitle">
          ${data.listingName}
        </div>
      </div>
      <div class="ListingVeriticalCon6">
        <div class="ListingVeriticalCon7">
          <div class="ListingVeriticalCon8">
            <div class="ListingVeriticalCon9">
              ${onDiscountTagHtml}
              <div
                style="color: #${colors.itemTextFour};"
                class="ListingVeriticalPrice"
              >
                ${GENRALHELPERS._convertToPrice(
                  priceToDisplay,
                  data.listingCoin
                )}
              </div>
            </div>
            <div
              style="color: #${colors.itemTextFour};"
              class="ListingVeriticalCondition"
            >
              ${data.listingCondition}
            </div>
          </div>
         ${onDiscountTextHtml}
        </div>
        <div
          style="color: #${colors.itemTextSecondary};"
          class="ListingVeriticalDescription"
        >
          ${GENRALHELPERS._shortenText(data.listingDescription, 20)}
        </div>
      </div>
    </div>
    <div class="ListingVeriticalCon12">
      <div class="ListingDisplayDateCon">
        <p
          class="ListingDisplayDateText"
          style="color: #${colors.itemTextTerteriary};"
        >
          ${GENRALHELPERS._convertServerTimestampToReadableTime(data.updatedAt)}
        </p>
      </div>
      <div class="ListingMiniVeriticalCon12">
        <div
          class="ListingDisplayViewsText"
          style="color: #${colors.itemTextTerteriary};"
        >
          ${GENRALHELPERS._formatNumberShort(data.listingViews)}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M7.46885 5.36328L7.46807 5.3625L7.46729 5.36172C7.12275 4.63281 6.58916 4.25781 5.879 4.25781C5.16885 4.25781 4.63525 4.63359 4.28994 5.36172V5.3625C4.24775 5.45234 4.24775 5.55703 4.28994 5.64687C4.63525 6.375 5.16885 6.75 5.879 6.75C6.58916 6.75 7.12275 6.37422 7.46807 5.64609C7.51025 5.55625 7.51025 5.45234 7.46885 5.36328ZM5.879 6.25C5.39385 6.25 5.03994 6.01562 4.77666 5.50391C5.03916 4.99219 5.39385 4.75781 5.879 4.75781C6.36416 4.75781 6.71807 4.99219 6.98135 5.50391C6.71885 6.01562 6.36416 6.25 5.879 6.25Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.44531 5.50781C5.44531 5.62384 5.49141 5.73512 5.57345 5.81717C5.6555 5.89922 5.76678 5.94531 5.88281 5.94531C5.99884 5.94531 6.11012 5.89922 6.19217 5.81717C6.27422 5.73512 6.32031 5.62384 6.32031 5.50781C6.32031 5.39178 6.27422 5.2805 6.19217 5.19845C6.11012 5.11641 5.99884 5.07031 5.88281 5.07031C5.76678 5.07031 5.6555 5.11641 5.57345 5.19845C5.49141 5.2805 5.44531 5.39178 5.44531 5.50781ZM1.0625 1.8125H6.5625V3.78906H7.125V1.5C7.125 1.36172 7.01328 1.25 6.875 1.25H0.75C0.611719 1.25 0.5 1.36172 0.5 1.5V5.5625C0.5 5.70078 0.611719 5.8125 0.75 5.8125H3.5V5.25H1.0625V1.8125Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.66348 2.64136L5.37598 2.35386C5.35176 2.32964 5.31191 2.32964 5.2877 2.35386L3.85176 3.79136L3.1791 3.11792C3.15488 3.0937 3.11504 3.0937 3.09082 3.11792L1.96348 4.24526C1.93926 4.26948 1.93926 4.30933 1.96348 4.33354L2.25098 4.62104C2.2752 4.64526 2.31504 4.64526 2.33926 4.62104L3.13457 3.82573L3.80723 4.49917C3.83145 4.52339 3.87129 4.52339 3.89551 4.49917L5.66348 2.72964C5.68848 2.70542 5.68848 2.66558 5.66348 2.64136Z"
          fill="#${colors.itemTextTerteriary}"
          />
        </svg>
      </div>
    </div>
  </div>
</div>

    `;
  }
  async _listingVerticalMini(data, colors, openToIn, businessId, from) {
    let priceToDisplay = data.listingPrice;
    const itemImg = data.listingMainImg;
    // const itemImg = await firebase._getImageUrl(data.listingMainImg);
    // const openTo = data.openTo;
    let openTo = "listing";
    if (data.businessId) {
      openTo = "listingInBusiness";
    }

    let onDiscountTagHtml = "";
    let onDiscountTextHtml = "";

    if (data.onDiscount) {
      if (!data.onDiscount.amount || parseInt(data.onDiscount.amount) < 1) {
      } else {
        priceToDisplay = data.onDiscount.finalPrice;
        console.log(data.listingName);

        onDiscountTagHtml = `
              <div style="background: #${colors.itemHighlight};" class="ListingMiniVeriticalCon10">
                <div style="color: #${colors.itemHighlightText};" class="ListingVeriticalOnDiscount">
                  En oferta ${data.onDiscount.percent}% ↓
                </div>
              </div>
      `;

        onDiscountTextHtml = `
          <div
            style="color: #${colors.itemHighlight};"
            class="ListingVeriticalPriceBeforeText"
            >
            <div class="Antes">Antes</div>
            <div
              style="color: #${colors.itemHighlight};"
              class="ListingVeriticalPriceBeforePrice"
            >
              ${GENRALHELPERS._convertToPrice(
                data.listingPrice,
                data.listingCoin
              )}
            </div>
          </div>
      `;
      }
    }

    return `
  <div
      data-openTo="${openTo}"
      data-openfrom="${from}"
      data-listingId="${data.listingId}"
      data-businessId="${data.businessId}"
      class="ListingMiniVeriticalCon1 ActionOpenForDisplay">
  <img class="ListingMiniVeriticalCon2Image ImgForBigDisplay" src="${itemImg}" loading="lazy">
  <div  style="background: #${
    colors.itemBackgroundSecondary
  };" class="ListingMiniVeriticalCon3">
    <div class="ListingMiniVeriticalCon4">
      <div style="background: #${
        colors.itemBackgroundPrimary
      };" class="ListingMiniVeriticalCon5">
        <div style="color: #${
          colors.itemTextPrimary
        };" class="ListingVeriticalTitleMini">
          ${data.listingName}
        </div>
      </div>
      <div class="ListingMiniVeriticalCon6">
        <div class="ListingMiniVeriticalCon7">
          <div class="ListingMiniVeriticalCon8">
            <div class="ListingMiniVeriticalCon9">
             ${onDiscountTagHtml}
              <div style="color: #${
                colors.itemTextFour
              };" class="ListingVeriticalPriceMini">
              ${GENRALHELPERS._convertToPrice(priceToDisplay, data.listingCoin)}
              </div>
            </div>
            <div style="color: #${
              colors.itemTextFour
            };" class="ListingVeriticalConditionMini">
              ${data.listingCondition}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ListingMiniVeriticalCon11">
      <div class="ListingDisplayDateCon">
          <p class="ListingDisplayDateTextMini" style="color: #${
            colors.itemTextTerteriary
          };">
          ${GENRALHELPERS._convertServerTimestampToReadableTime(data.updatedAt)}
          </p>
      </div>
      <div class="ListingMiniVeriticalCon12">
        <div class="ListingDisplayDateTextMini" style="color: #${
          colors.itemTextTerteriary
        };">
          ${GENRALHELPERS._formatNumberShort(data.listingViews)}
        </div>
               <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M7.46885 5.36328L7.46807 5.3625L7.46729 5.36172C7.12275 4.63281 6.58916 4.25781 5.879 4.25781C5.16885 4.25781 4.63525 4.63359 4.28994 5.36172V5.3625C4.24775 5.45234 4.24775 5.55703 4.28994 5.64687C4.63525 6.375 5.16885 6.75 5.879 6.75C6.58916 6.75 7.12275 6.37422 7.46807 5.64609C7.51025 5.55625 7.51025 5.45234 7.46885 5.36328ZM5.879 6.25C5.39385 6.25 5.03994 6.01562 4.77666 5.50391C5.03916 4.99219 5.39385 4.75781 5.879 4.75781C6.36416 4.75781 6.71807 4.99219 6.98135 5.50391C6.71885 6.01562 6.36416 6.25 5.879 6.25Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.44531 5.50781C5.44531 5.62384 5.49141 5.73512 5.57345 5.81717C5.6555 5.89922 5.76678 5.94531 5.88281 5.94531C5.99884 5.94531 6.11012 5.89922 6.19217 5.81717C6.27422 5.73512 6.32031 5.62384 6.32031 5.50781C6.32031 5.39178 6.27422 5.2805 6.19217 5.19845C6.11012 5.11641 5.99884 5.07031 5.88281 5.07031C5.76678 5.07031 5.6555 5.11641 5.57345 5.19845C5.49141 5.2805 5.44531 5.39178 5.44531 5.50781ZM1.0625 1.8125H6.5625V3.78906H7.125V1.5C7.125 1.36172 7.01328 1.25 6.875 1.25H0.75C0.611719 1.25 0.5 1.36172 0.5 1.5V5.5625C0.5 5.70078 0.611719 5.8125 0.75 5.8125H3.5V5.25H1.0625V1.8125Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.66348 2.64136L5.37598 2.35386C5.35176 2.32964 5.31191 2.32964 5.2877 2.35386L3.85176 3.79136L3.1791 3.11792C3.15488 3.0937 3.11504 3.0937 3.09082 3.11792L1.96348 4.24526C1.93926 4.26948 1.93926 4.30933 1.96348 4.33354L2.25098 4.62104C2.2752 4.64526 2.31504 4.64526 2.33926 4.62104L3.13457 3.82573L3.80723 4.49917C3.83145 4.52339 3.87129 4.52339 3.89551 4.49917L5.66348 2.72964C5.68848 2.70542 5.68848 2.66558 5.66348 2.64136Z"
          fill="#${colors.itemTextTerteriary}"
          />
        </svg>
      </div>
    </div>
  </div>
</div>

    `;
  }

  _viewAllListingsMini(colors) {
    return `
<div class="ListingMiniVeriticalCon3B" style="background: #${colors.itemBackgroundSecondary};">
  <div class="ListingMiniVeriticalCon5" style="background: #${colors.itemBackgroundPrimary};">
    <div style="color: #${colors.itemTextPrimary};" class="ListingVeriticalTitleMini">Visita este negocio</div>
  </div>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 49" fill="none">
  <path d="M24 0.5C24.8302 0.5 25.6265 0.829812 26.2135 1.41688C26.8006 2.00395 27.1304 2.80019 27.1304 3.63043V21.3696H44.8696C45.6998 21.3696 46.496 21.6994 47.0831 22.2864C47.6702 22.8735 48 23.6698 48 24.5C48 25.3302 47.6702 26.1265 47.0831 26.7135C46.496 27.3006 45.6998 27.6304 44.8696 27.6304H27.1304V45.3696C27.1304 46.1998 26.8006 46.996 26.2135 47.5831C25.6265 48.1702 24.8302 48.5 24 48.5C23.1698 48.5 22.3735 48.1702 21.7864 47.5831C21.1994 46.996 20.8696 46.1998 20.8696 45.3696V27.6304H3.13043C2.30019 27.6304 1.50395 27.3006 0.916883 26.7135C0.329812 26.1265 0 25.3302 0 24.5C0 23.6698 0.329812 22.8735 0.916883 22.2864C1.50395 21.6994 2.30019 21.3696 3.13043 21.3696H20.8696V3.63043C20.8696 2.80019 21.1994 2.00395 21.7864 1.41688C22.3735 0.829812 23.1698 0.5 24 0.5Z" fill="#${colors.itemBackgroundPrimary}"/>
</svg>
  <div class="ListingMiniVeriticalCon5" style="background: #${colors.itemBackgroundPrimary};">
    <div style="color: #${colors.itemTextPrimary};"  class="ListingVeriticalTitleMini">Para ver todos los listados</div>
  </div>
</div>`;
  }

  _listingVerticalMiniNoListings(individual, messageIn) {
    let message = "Este negocio aún no tiene listados. Regrese pronto.";

    if (individual) {
      message = "No hay listados aqui.";
    }
    if (messageIn) {
      message = messageIn;
    }
    return `
  <div class="NoListingsMiniCon1">
    <div class="NoListingsMiniCon2">
      <div class="NoListingsMiniTitle">
        Sin listados
      </div>
    </div>
    <div class="NoListingsMiniCon3">
      <div class="NoListingsMiniMessage">
       ${message}
      </div>
    </div>
  </div>
    `;
  }

  async _listingVerticalBig(data, colors, openToIn, businessId, from) {
    let priceToDisplay = data.listingPrice;
    const itemImg = data.listingMainImg;
    // let businessIdToUse = "nothing";
    // const itemImg = await firebase._getImageUrl(data.listingMainImg);
    // const openTo = data.openTo;
    let openTo = "listing";
    if (data.businessId) {
      openTo = "listingInBusiness";
    }

    let onDiscountTagHtml = "";
    let onDiscountTextHtml = "";

    if (data.onDiscount) {
      if (!data.onDiscount.amount || parseInt(data.onDiscount.amount) < 1) {
      } else {
        priceToDisplay = data.onDiscount.finalPrice;
        onDiscountTagHtml = `
        <div
          style="background: #${colors.itemHighlight};"
          class="ListingVeriticalBigCon10"
          >
          <div
            style="color: #${colors.itemHighlightText};"
            class="ListingVeriticalBigOnDiscount"
          >
            En oferta ${data.onDiscount.percent}% ↓
          </div>
        </div>
      `;

        onDiscountTextHtml = `
          <div
            style="color: #${colors.itemHighlight};"
            class="ListingVeriticalBigPriceBeforeText"
            >
            <div class="Antes">Antes</div>
            <div
              style="color: #${colors.itemHighlight};"
              class="ListingVeriticalBigPriceBeforePrice"
            >
              ${GENRALHELPERS._convertToPrice(
                data.listingPrice,
                data.listingCoin
              )}
            </div>
          </div>
      `;
      }
    }
    return `
<div
  data-openTo="${openTo}"
  data-openfrom="${from}"
  data-listingId="${data.listingId}"
  data-businessId="${data.businessId}"
  class="ListingVeriticalBigCon1 ActionOpenForDisplay"
>
  <img class="ListingVirticalBigCon2Image ImgForBigDisplay" src="${itemImg}" loading="lazy">
  <div
    style="background: #${colors.itemBackgroundSecondary}; "
    class="ListingVeriticalBigCon3"
  >
    <div class="ListingVeriticalBigCon4">
      <div
        style="background: #${colors.itemBackgroundPrimary};"
        class="ListingVeriticalBigCon5"
      >
        <div style="color: #${
          colors.itemTextPrimary
        };" class="ListingVeriticalBigTitle">
          ${data.listingName}
        </div>
      </div>
      <div class="ListingVeriticalBigCon6">
        <div class="ListingVeriticalBigCon7">
          <div class="ListingVeriticalBigCon8">
            <div class="ListingVeriticalBigCon9">
              ${onDiscountTagHtml}
              <div
                style="color: #${colors.itemTextFour};"
                class="ListingVeriticalBigPrice"
              >
                ${GENRALHELPERS._convertToPrice(
                  priceToDisplay,
                  data.listingCoin
                )}
              </div>
            </div>
            <div
              style="color: #${colors.itemTextFour};"
              class="ListingVeriticalBigCondition"
            >
              ${data.listingCondition}
            </div>
          </div>
         ${onDiscountTextHtml}
        </div>
        <div
          style="color: #${colors.itemTextSecondary};"
          class="ListingVeriticalBigDescription"
        >
          ${GENRALHELPERS._shortenText(data.listingDescription, 20)}
        </div>
      </div>
    </div>
    <div class="ListingVeriticalBigCon12">
      <div class="ListingDisplayBigDateCon">
        <p
          class="ListingDisplayBigDateText"
          style="color: #${colors.itemTextTerteriary};"
        >
          ${GENRALHELPERS._convertServerTimestampToReadableTime(data.updatedAt)}
        </p>
      </div>
      <div class="ListingMiniVeriticalBigCon12">
        <div
          class="ListingDisplayBigViewsText"
          style="color: #${colors.itemTextTerteriary};"
        >
          ${GENRALHELPERS._formatNumberShort(data.listingViews)}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M7.46885 5.36328L7.46807 5.3625L7.46729 5.36172C7.12275 4.63281 6.58916 4.25781 5.879 4.25781C5.16885 4.25781 4.63525 4.63359 4.28994 5.36172V5.3625C4.24775 5.45234 4.24775 5.55703 4.28994 5.64687C4.63525 6.375 5.16885 6.75 5.879 6.75C6.58916 6.75 7.12275 6.37422 7.46807 5.64609C7.51025 5.55625 7.51025 5.45234 7.46885 5.36328ZM5.879 6.25C5.39385 6.25 5.03994 6.01562 4.77666 5.50391C5.03916 4.99219 5.39385 4.75781 5.879 4.75781C6.36416 4.75781 6.71807 4.99219 6.98135 5.50391C6.71885 6.01562 6.36416 6.25 5.879 6.25Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.44531 5.50781C5.44531 5.62384 5.49141 5.73512 5.57345 5.81717C5.6555 5.89922 5.76678 5.94531 5.88281 5.94531C5.99884 5.94531 6.11012 5.89922 6.19217 5.81717C6.27422 5.73512 6.32031 5.62384 6.32031 5.50781C6.32031 5.39178 6.27422 5.2805 6.19217 5.19845C6.11012 5.11641 5.99884 5.07031 5.88281 5.07031C5.76678 5.07031 5.6555 5.11641 5.57345 5.19845C5.49141 5.2805 5.44531 5.39178 5.44531 5.50781ZM1.0625 1.8125H6.5625V3.78906H7.125V1.5C7.125 1.36172 7.01328 1.25 6.875 1.25H0.75C0.611719 1.25 0.5 1.36172 0.5 1.5V5.5625C0.5 5.70078 0.611719 5.8125 0.75 5.8125H3.5V5.25H1.0625V1.8125Z"
          fill="#${colors.itemTextTerteriary}"
          />
          <path
            d="M5.66348 2.64136L5.37598 2.35386C5.35176 2.32964 5.31191 2.32964 5.2877 2.35386L3.85176 3.79136L3.1791 3.11792C3.15488 3.0937 3.11504 3.0937 3.09082 3.11792L1.96348 4.24526C1.93926 4.26948 1.93926 4.30933 1.96348 4.33354L2.25098 4.62104C2.2752 4.64526 2.31504 4.64526 2.33926 4.62104L3.13457 3.82573L3.80723 4.49917C3.83145 4.52339 3.87129 4.52339 3.89551 4.49917L5.66348 2.72964C5.68848 2.70542 5.68848 2.66558 5.66348 2.64136Z"
          fill="#${colors.itemTextTerteriary}"
          />
        </svg>
      </div>
    </div>
  </div>
</div>

    `;
  }
}
export default new GenarateHtml();
