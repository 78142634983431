// const serviceID = "service_6x8djwo";
// const templateID = "template_5a8mhm8";
// const userID = "OF0WkI8He575rSuOy";

class Email {
  constructor() {}

  async _sendEmail(mailTo, replyTo, otp, message) {
    const serviceID = "service_6x8djwo";
    const templateID = "template_5a8mhm8";
    const userID = "OF0WkI8He575rSuOy";
    const templateParams = {
      send_to: mailTo,
      reply_to: replyTo,
      otp: otp,
      message: message,
    };

    try {
      // Make the API request
      const response = await fetch(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            service_id: serviceID,
            template_id: templateID,
            user_id: userID,
            template_params: templateParams,
          }),
        }
      );

      // If response is not OK, handle it and return an error response
      if (!response.ok) {
        const errorData = await response.text(); // Get the response as text for error details
        return { status: "error", message: errorData }; // Return error status
      }

      // The response is OK, so check the content-type header to determine how to handle the body
      const contentType = response.headers.get("Content-Type");

      let result;
      if (contentType && contentType.includes("application/json")) {
        // If the response is JSON, try to parse it
        result = await response.json();
      } else {
        // If it's not JSON, read it as plain text
        result = await response.text();
      }

      return { status: "success", result }; // Return success status and result
    } catch (error) {
      // Catch any errors that occur during the fetch request
      return { status: "error", message: error.message }; // Return error status and error message
    }
  }
}

export default new Email();
