import iconAppLogo from "../../images/fav-mini.png";
import iconHome from "../../../Icons/nav-icons/active/home.png";
import iconWallet from "../../../Icons/nav-icons/active/wallet.png";
import iconBusiness from "../../../Icons/nav-icons/active/business.png";
import iconSaved from "../../../Icons/nav-icons/active/saved.png";
import iconNotifications from "../../../Icons/icons-charcoal-dark/notify.png";
import iconListings from "../../../Icons/icons-charcoal-dark/listed.png";
import iconHelp from "../../../Icons/icons-charcoal-dark/help.png";
import iconMore from "../../../Icons/icons-charcoal-dark/add.png";

import iconInstall from "../../../Icons//icons-beige/icon-install.png";
import profileImgPlaceHolder from "../../../Icons/icons-white/profile-img-placeholder.png";
import iconExit from "../../../Icons/icons-charcoal-dark/exit.png";

class GenerateHtml {
  _mainMenuGenerate(userName, email) {
    let userNameForUse = userName;
    let style = "";
    console.log(userName);

    if (!userName || userName === "sin nombre") {
      userNameForUse = "! completa tu perfil de vendedor";
      style = 'style="color: #FF8400"';
    }
    return `  
<div class="MainMenuCon1A">
  <div class="MainMenuCon1">
    <div class="MainMenuCon2B">
    <div class="MainMenuCon2">
      <div id="MainMenuCon3OpenProfile" class="MainMenuCon3">
        <div class="ProfileOpenFotoConBForMenu">
        <img id="ProfileFotoInMenuTop" class="ProfileOpenFoto ImgForBigDisplay" src="${profileImgPlaceHolder}"/>
        </div>
        <div class="MainMenuCon4">
          <div ${style} class="MainMenuProfileName">
            ${userNameForUse}
          </div>
          <div class="MainMenuProfileEmail">
            ${email}
          </div>
        </div>
      </div>
      <div
        id="PreInstallConInMenu"
        class="PrePageConForInstallationConB"
      >
        <div class="PrePageCon2A3">
          <div class="PrePageCon2A3Title">Instalar esta aplicación</div>
          <div class="PrePageCon2A4">
            <div class="PrePageCon2A4Description">
              Ahora también puedes instalar Mercado MAI en tu celular como una
              aplicación móvil, y lo puedes abrir con un solo click.
            </div>
            <div id="ButtonInstallAppInMenu" class="PrePageCon2A4Button">
              <img height="32px" src="${iconInstall}"/>
              <div id="installBtn" class="PrePageCon2A4ButtonText">
                Instalar ahora
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="MainMenuCon5">
        <div class="MainMenuCon6 openButtonFromMenu" data-open="home">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconHome}" alt="" />
            <div class="MainMenuOptionText">Inicio</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="notifications">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconNotifications}" alt="" />
            <div class="MainMenuOptionText">Notificaciones</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
      </div>
      <div class="MainMenuCon5">
        <div id="MainMenuCon6OpenListings" class="MainMenuCon6 openButtonFromMenu" data-open="ownlistings">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconListings}" alt="" />
            <div class="MainMenuOptionText">Tus listados</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="wallet">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconWallet}" alt="" />
            <div class="MainMenuOptionText">Wallet</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="business">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconBusiness}" alt="" />
            <div class="MainMenuOptionText">Negocio</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="saved">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconSaved}" alt="" />
            <div class="MainMenuOptionText">Guardado</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
      </div>
      <div class="MainMenuCon5">
        <div class="MainMenuCon6 openButtonFromMenu" data-open="about">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconAppLogo}" alt="" />
            <div class="MainMenuOptionText">Acerca de MQ México</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="moreapps">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconMore}" alt="" />
            <div class="MainMenuOptionText">Mas aplicaciones</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
        <div class="MainMenuCon6 openButtonFromMenu" data-open="help">
            <div class="MainMenuCon7">
            <img height="16px" src="${iconHelp}" alt="" />
            <div class="MainMenuOptionText">Ayuda</div>
            </div>
            <div class="MainMenuOptionSeperator"></div>
        </div>
      </div>
    </div>
    <button id="ButtonCloseMenu">
      <img height="24px" src="${iconExit}"/>
    </button>
    </div>
  </div>
</div>`;
  }
}
export default new GenerateHtml();
