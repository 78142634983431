import firebase from "../firebase";
import GENRALHELPERS from "../GENRAL-HELPERS";
import spinner from "../spinner";
import generateHtml from "./serach-generate-html";
import generateListings from "../display-listing/generate-html";
import iconMenuDown from "../../../Icons/icons-charcoal-dark/menu-down.png";
import iconMenuUp from "../../../Icons/icons-charcoal-dark/menu-up.png";
import iconWhiteBusiness from "../../../Icons/icons-white/business.png";
import iconCharcoalDarkBusiness from "../../../Icons/nav-icons/active/business.png";

import iconExit from "../../../Icons/icons-white/exit.png";

import allHandeling from "../handeling-all-displays/all-handeling";

const messages = [
  { message: "Take this onboarding tour", attachEl: "step1", hideAfter: 3000 },
  { message: "Here's another feature", attachEl: "step2", hideAfter: 3000 },
  {
    message: "Finally, explore this section",
    attachEl: "step3",
    hideAfter: 3000,
  },
];
// OnboardingTour.start(messages);

// import SelectPure from "select-pure"; // Import the SelectPure librar
const colors = {
  headerPrimary: "FFFFFF",
  headerSecondary: "E0E0E0",
  packTitles: "6D6D6D",
  packTitlesHighlight: "3A7D44",
  itemBackgroundPrimary: "3A7D44",
  itemBackgroundSecondary: "fff",
  itemTextPrimary: "FFFFFF",
  itemTextSecondary: "2B2B2B",
  itemTextTerteriary: "3A7D44",
  itemTextFour: "3A7D44",
  itemHighlight: "FF7043",
  itemHighlightText: "FFFFFF",
};

const omittedWords = ["de", "para", "el", "del", "y", "en", "con"];

class search {
  #filterEventsInitialized = false;
  #lastVisibleUseForSearch;
  #bottomScrollDetectActivatedGenral;
  #isCurrentDisplayResultsOnfilter = false;
  #wasFilterReset = false;
  #lasVisibleForTagetedSearchNew;
  #lastVisbleForDiscount;
  #bottomScrollDetectActivatedTargeted;
  #bottomScrollDetectActivatedbusiness;
  #lastVisibleDocForBusiness;

  #lastSearchQuery = "";
  #lastSearchBusinessQuery = "";
  #machineBrandsOnCleared = false;
  #machineModelsOnCleared = false;
  #prefixId;
  #resultsTotalInDisplayNow = 0;
  #isLoading;
  #searchType;
  #currentDisplayedResultsWithOutFilter = [];
  #currentDisplayedBusinessResultsWithOutFilter = [];

  #isSearchingForBusiness = false;
  displayedData = {};

  #businessesLoadedForColors = [];

  #filterData = {
    machine: "",
    brand: "",
    model: "",
    years: [],
    condition: "todos",
    priceMin: 0,
    priceMax: 0,
  };

  _toggleSearchResultsConShowHide(what) {
    let whatForUse = what;
    const SearchUicSearchFilterResultsShowHide = document.getElementById(
      `${this.#prefixId}SearchUicSearchFilterResultsShowHide`
    );
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    const status = SearchUicSearchFilterResultsShowHide.dataset.actiondo;

    if (!what) {
      whatForUse = status;
    }

    console.log(whatForUse);

    if (whatForUse === "hide") {
      searchResultsContainer.style.maxHeight = "50px";
      SearchUicSearchFilterResultsShowHide.querySelector("img").src =
        iconMenuDown;
      SearchUicSearchFilterResultsShowHide.dataset.actiondo = "show";
    }
    if (whatForUse === "show") {
      searchResultsContainer.style.maxHeight = "";
      SearchUicSearchFilterResultsShowHide.querySelector("img").src =
        iconMenuUp;
      SearchUicSearchFilterResultsShowHide.dataset.actiondo = "hide";
    }

    console.log(status);
  }

  _filterResultsNow(display, results) {
    const SearchUicSearchFilterResultsButton = document.getElementById(
      `${this.#prefixId}SearchUicSearchFilterResultsButton`
    );

    let resultsLocal = this.#currentDisplayedResultsWithOutFilter;
    if (results) {
      resultsLocal = results;
    }

    if (!this.#filterData || Object.keys(this.#filterData).length === 0) {
      return this.#currentDisplayedResultsWithOutFilter; // No filters applied
    }
    this.#isCurrentDisplayResultsOnfilter = true;

    let filteredResults = [];

    console.log(this.#filterData);
    console.log(this.#currentDisplayedResultsWithOutFilter);
    console.log(resultsLocal);

    resultsLocal.forEach((result) => {
      let takeCurrent = true;

      // Filter by condition
      if (
        this.#filterData.condition &&
        this.#filterData.condition !== "todos" &&
        this.#filterData.condition !== result.listingCondition
      ) {
        takeCurrent = false;
        console.log("rej condition");
      }

      // Filter by price range
      let listingPrice = result.listingPrice;
      let UDSinMXN = 19;
      if (result.listingCoin === "USD") {
        listingPrice *= UDSinMXN;
      }

      if (
        this.#filterData.priceMin &&
        listingPrice < this.#filterData.priceMin
      ) {
        takeCurrent = false;
        console.log("rej minprice");
      }
      if (
        this.#filterData.priceMax &&
        listingPrice > this.#filterData.priceMax
      ) {
        takeCurrent = false;
        console.log("rej maxprice");
      }

      // Filter by year
      if (this.#filterData.years && this.#filterData.years.length > 0) {
        let takeYear = false;
        this.#filterData.years.forEach((year) => {
          if (result.listingYear === year) {
            takeYear = true;
          }
        });
        if (!takeYear) {
          console.log("rej year");
          takeCurrent = false;
        }
      }

      if (takeCurrent) {
        filteredResults.push(result);
      } else {
      }
    });

    if (display) {
      if (
        this.#currentDisplayedResultsWithOutFilter.length !==
        filteredResults.length
      ) {
        SearchUicSearchFilterResultsButton.querySelector("p").textContent =
          "Filtros aplicados";
        SearchUicSearchFilterResultsButton.classList.add(
          "SearchUicSearchFilterResultsButtonActive"
        );
      }
      this._displaySearchResults(filteredResults, null, null, true, true);
    }

    console.log(filteredResults);
    return filteredResults; // Return filtered results
  }

  _removeAndAddActiveForTrends = (addTo) => {
    const SearchStartUpComponentByTrends = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentByTrends`
    );
    const allTrends = SearchStartUpComponentByTrends.querySelectorAll(
      ".SearchStartUpComponentCategoryCon3"
    );
    allTrends.forEach((trend) => {
      if (trend.dataset.openwhat !== addTo) {
        trend.classList.remove("SearchStartUpComponentCategoryCon3Active");
      } else {
        trend.classList.add("SearchStartUpComponentCategoryCon3Active");
      }
    });
  };

  _initializeSelectLists() {
    const SearchStartUpComponentByTrends = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentByTrends`
    );
    const allTrends = SearchStartUpComponentByTrends.querySelectorAll(
      ".SearchStartUpComponentCategoryCon3"
    );
    const resetFilterdata = () => {
      this.#filterData.machine = "";
      this.#filterData.brand = "";
      this.#filterData.model = "";
    };

    const makeSearchforTrendCategory = async () => {
      this._handleActivatedBusinessSearch("true");
      this.#lasVisibleForTagetedSearchNew = null;
      const results = await this._makeTargetedSearch();
      // await target();
      console.log(this.#lasVisibleForTagetedSearchNew);

      this.#currentDisplayedResultsWithOutFilter = results;
      this._displaySearchResults(results, null, null, true);
      // toggleSearchContainer();
      this.#lastSearchQuery = "";
      this._activateDetectScrollToBottomForTarNew(100);
    };

    const makeSearchforTrendOther = async (openWhat) => {
      this._handleActivatedBusinessSearch("true");
      if (openWhat === "discount") {
        console.log(" looking for discounts");
        this.#lasVisibleForTagetedSearchNew = null;
        const results = await this._makeTargetedSearch(true);
        console.log(this.#lasVisibleForTagetedSearchNew);

        this._displaySearchResults(results, null, null, true);

        this.#currentDisplayedResultsWithOutFilter = results;
        // toggleSearchContainer();
        this.#lastSearchQuery = "";

        // this._activateDetectScrollToBottomForTarNew(100);
      }
      if (openWhat === "businesses") {
        this.#lastVisibleDocForBusiness = null;
        const results = await this._makeBusinessSearch();
        console.log(this.#lastVisibleDocForBusiness);

        this.#currentDisplayedBusinessResultsWithOutFilter = results;
        this._displaySearchBusinessResults(results, null, true);
        this._handleActivatedBusinessSearch("false");
        // toggleSearchContainer();
        this.#lastSearchQuery = "";

        this._activateDetectScrollToBottomForBusiness(100);
        console.log(results);
        console.log(" looking for business");
      }
    };

    SearchStartUpComponentByTrends.addEventListener("click", async (e) => {
      const isTrend = e.target.closest(".SearchStartUpComponentCategoryCon3");
      if (isTrend) {
        const openBy = isTrend.dataset.openby;
        const openWhat = isTrend.dataset.openwhat;
        this._removeAndAddActiveForTrends(openWhat);
        resetFilterdata();
        if (openBy === "category") {
          this.#filterData.machine = openWhat;
          makeSearchforTrendCategory();
        } else {
          makeSearchforTrendOther(openWhat);
        }
      }
    });
  }

  _handleFilteringResultsAndInit(business) {
    if (this.#filterEventsInitialized) return;
    const SearchUicSearchRemoveFilterResultsButton = document.getElementById(
      `${this.#prefixId}SearchUicSearchRemoveFilterResultsButton`
    );
    const SearchUicSearchFilterResultsButton = document.getElementById(
      `${this.#prefixId}SearchUicSearchFilterResultsButton`
    );
    const SearchUicSearchFilterResultsShowHide = document.getElementById(
      `${this.#prefixId}SearchUicSearchFilterResultsShowHide`
    );
    const SearchStartUpComponentCon13 = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13`
    );
    const SearchStartUpComponentSelectFilter = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentSelectFilter`
    );

    SearchStartUpComponentCon13.style.display = "flex";
    SearchUicSearchFilterResultsShowHide.addEventListener("click", () => {
      this._toggleSearchResultsConShowHide();
    });

    this._setMachineYears();
    this._setMachineConditions();
    this._handlePriceSliderEvents(2000, 5000000, 2000, 5000000, this.#prefixId);

    // Start functions
    SearchUicSearchRemoveFilterResultsButton.addEventListener("click", () => {
      if (this.#searchType === "business") {
        alert(
          "Filtrar resultados de búquedas de negocios no está disponible por el momento"
        );
        return;
      }
      this.#filterData.machine = "";
      this.#filterData.brand = "";
      this.#filterData.model = "";
      this.#filterData.condition = "";
      this.#filterData.priceMax = "";
      this.#filterData.priceMin = "";
      this.#filterData.years = [];
      this.#isCurrentDisplayResultsOnfilter = false;
      SearchUicSearchFilterResultsButton.querySelector("p").textContent =
        "Filtrar resultados";
      SearchUicSearchFilterResultsButton.classList.remove(
        "SearchUicSearchFilterResultsButtonActive"
      );
      this.#wasFilterReset = true;

      this._filterResultsNow(true);
    });

    SearchUicSearchFilterResultsButton.addEventListener("click", () => {
      console.log("click");
      if (this.#searchType === "business") {
        alert(
          "Filtrar resultados de búquedas de negocios no está disponible por el momento"
        );
        return;
      }

      SearchStartUpComponentSelectFilter.classList.add(
        "SearchStartUpComponentSelectForFilterDisp"
      );
      if (!this.#wasFilterReset) return;
      this._handlePriceSliderEvents(
        2000,
        5000000,
        2000,
        5000000,
        this.#prefixId
      );
      this._setMachineYears();
      this._setMachineConditions();
    });

    SearchStartUpComponentSelectFilter.addEventListener("click", (e) => {
      const el = e.target.closest(".option-item");
      console.log(el);
      console.log(e.target);
      if (e.target.closest(".option-item")) return;
      if (!e.target.closest(".SearchStartUpComponentSelectForFilterCon2")) {
        SearchStartUpComponentSelectFilter.classList.remove(
          "SearchStartUpComponentSelectForFilterDisp"
        );
        this.#wasFilterReset = false;
        this._filterResultsNow(true);
      } else {
        if (e.target.closest(".SearchStartUpComponentSelectForFilterCon2")) {
          if (e.target.closest("#search-input")) return;
          const options = e.target
            .closest(".SearchStartUpComponentSelectForFilterCon2")
            .querySelectorAll(".option-list");
          options.forEach((opt) => {
            opt.classList.remove("active");
          });
        }
      }
    });
    this.#filterEventsInitialized = true;
  }
  _setMachineYears = async () => {
    const years = GENRALHELPERS._generateYearsArray(1990);
    const stringYearsOptions = [];

    years.forEach((year) => {
      stringYearsOptions.push({ id: year.toString() });
    });
    this._initiateSelectOptions(
      true,
      `${this.#prefixId}SearchStartUpComponentSelectMachineYear`,
      `${this.#prefixId}SearchStartUpComponentAllCon`,
      this.#prefixId,
      stringYearsOptions,
      true
    );
  };
  _setMachineConditions = async () => {
    const conditions = ["nuevo", "usado"];
    const stringConditionOptions = [];

    conditions.forEach((condition) => {
      stringConditionOptions.push({ id: condition.toString() });
    });
    this._initiateSelectOptions(
      false,
      `${this.#prefixId}SearchStartUpComponentSelectMachineCondition`,
      `${this.#prefixId}SearchStartUpComponentAllCon`,
      this.#prefixId,
      stringConditionOptions,
      true
    );
  };

  _displaySearchResults = async (
    results,
    searchQuery,
    omittedWords,
    isNewDisplay,
    fromFilter
  ) => {
    console.log(results, searchQuery, omittedWords, isNewDisplay, fromFilter);
    // Start elements
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    const NumberOfSearchResults = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13Number`
    );
    const SearchStartUpComponentCon13 = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13`
    );

    // Start Functionality

    if (searchQuery && !results && isNewDisplay) {
      SearchStartUpComponentCon13.style.display = "flex";
      searchResultsContainer.innerHTML = "";
      NumberOfSearchResults.textContent = `No encontramos nada con '${searchQuery}'`;
    }

    if (!results) return;

    // Ranking
    let rankedResults = results;
    if (searchQuery) {
      rankedResults = GENRALHELPERS._rankListings(
        results,
        searchQuery,
        omittedWords
      );
    }

    console.log(rankedResults);

    if (isNewDisplay) {
      if (!fromFilter) {
        // this.#currentDisplayedResultsWithOutFilter = [];
      }
      this.#resultsTotalInDisplayNow = 0;
      searchResultsContainer.innerHTML = "";
    }

    let html = ``;
    let displayNum = 0;
    for (const listing of rankedResults) {
      if (!fromFilter) {
        // this.#currentDisplayedResultsWithOutFilter.push(listing);
      }
      this.#resultsTotalInDisplayNow++;
      // if (displayNum === 4 || displayNum === 7) {
      //   if (listing.businessId) {
      //     html += await generateListings._listingHorizontal(
      //       listing,
      //       colors,
      //       "listingInBusiness",
      //       listing.businessId
      //     );
      //   } else {
      //     html += await generateListings._listingHorizontal(
      //       listing,
      //       colors,
      //       "listing"
      //     );
      //   }
      // } else {
      if (listing.businessId) {
        let colorsLocal = colors;
        const businessInDownloads = allHandeling._getLocalBusinessData(
          listing.businessId
        );
        if (businessInDownloads) {
          colorsLocal = businessInDownloads.colors;
        } else {
          const alreadyDownloadedBusiness =
            this.#businessesLoadedForColors.find(
              (business) => business.businessId === listing.businessId
            );
          if (alreadyDownloadedBusiness) {
            colorsLocal = alreadyDownloadedBusiness.colors;
          } else {
            // TODO this else block will load business for colors from fire store. deactivate if not needed.
            spinner._displaySpinner("Cargando");
            const businessDownloaded = await firebase._getBusinessById(
              listing.businessId
            );
            if (businessDownloaded) {
              this.#businessesLoadedForColors.push(businessDownloaded);
              colorsLocal = businessDownloaded.colors;
            }
            spinner._hideSpinner();
          }
        }

        // const business

        // html += await generateListings._listingHorizontal(
        //   listing,
        //   colorsLocal,
        //   "listingInBusiness",
        //   listing.businessId
        // );
        html += await generateListings._listingVertical(
          listing,
          colorsLocal,
          "listingInBusiness",
          listing.businessId
        );
      } else {
        // html += await generateListings._listingHorizontal(
        //   listing,
        //   colors,
        //   "listing"
        // );
        html += await generateListings._listingVertical(
          listing,
          colors,
          "listing"
        );
      }
      // }

      displayNum++;
    }
    this._toggleSearchResultsConShowHide("show");
    searchResultsContainer.insertAdjacentHTML("beforeend", html);
    searchResultsContainer.style.display = "flex";
    this._handleFilteringResultsAndInit();
    if (!fromFilter) {
      this.#isLoading = false;
    }
    NumberOfSearchResults.textContent = `${
      this.#resultsTotalInDisplayNow
    } resultados`;
    console.log(this.#currentDisplayedResultsWithOutFilter);
  };

  _displaySearchBusinessResults = async (
    results,
    searchQuery,
    isNewDisplay
  ) => {
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    const NumberOfSearchResults = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13Number`
    );
    const SearchStartUpComponentCon13 = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13`
    );

    // Initialize displayedData object
    this.displayedData = {
      businesses: {},
    };

    if (searchQuery && !results && isNewDisplay) {
      SearchStartUpComponentCon13.style.display = "flex";
      searchResultsContainer.innerHTML = "";
      NumberOfSearchResults.textContent = `No encontramos nada con '${searchQuery}'`;
    }

    if (!results) return;

    // Ranking
    let rankedResults = results;
    if (searchQuery) {
      rankedResults = GENRALHELPERS._rankBusinesses(
        results,
        searchQuery,
        omittedWords
      );
    }

    if (isNewDisplay) {
      this.#resultsTotalInDisplayNow = 0;
      searchResultsContainer.innerHTML = "";
    }

    try {
      // Store businesses in data object
      const businessesLocal = await Promise.all(
        rankedResults.map(async (business) => {
          // Fetch listings for each business
          const { listings, lastVisibleDoc } =
            await firebase._getRecentListingsWithFilter(
              null,
              6,
              `businesses/${business.businessId}/listings`
            );

          // Attach listings to the business object
          return { ...business, listings };
        })
      );

      // Generate and display HTML for businesses and their listings
      for (const business of businessesLocal) {
        const generateBusiness = async () => {
          if (business.status !== "active") return;
          let listingsHtml = ``;

          if (
            !Array.isArray(business.listings) ||
            business.listings.length === 0
          ) {
            listingsHtml += generateListings._listingVerticalMiniNoListings();
          } else {
            for (const listing of business.listings) {
              listingsHtml += await generateListings._listingVerticalMini(
                listing,
                business.colors,
                "listingInBusiness",
                business.businessId
              );
            }
          }

          const businessInPackHtml = await generateListings._businessInPack(
            listingsHtml,
            business
          );
          this.#resultsTotalInDisplayNow++;

          searchResultsContainer.style.display = "flex";
          searchResultsContainer.insertAdjacentHTML(
            "beforeend",
            businessInPackHtml
          );

          // Store business and its listings in displayedData
          this.displayedData.businesses[business.businessId] = {
            ...business,
            listings: business.listings || [],
          };
        };
        generateBusiness();
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display Businesses aborted.");
      } else {
        console.error(err);
      }
    } finally {
      console.log(this.displayedData);
      this.#isLoading = false;
      allHandeling._dataMerge(this.displayedData);
    }

    this._toggleSearchResultsConShowHide("show");
    this._handleFilteringResultsAndInit("business");
    NumberOfSearchResults.textContent = `${
      this.#resultsTotalInDisplayNow
    } resultados`;
  };

  async _doSearch(searchQuery) {
    this.#searchType = "general";
    this.#isLoading = true;
    console.warn("General searching...");
    const { listings, lastVisible } = await firebase._searchListingsGlobal(
      searchQuery,
      omittedWords,
      this.#lastVisibleUseForSearch
    );
    this.#lastVisibleUseForSearch = lastVisible;
    return listings;
  }
  async _makeTargetedSearch(discount) {
    console.warn(this.#lasVisibleForTagetedSearchNew);

    this.#searchType = "targeted";
    this.#isLoading = true;
    console.warn("Targeted searching...");

    if (this.#lasVisibleForTagetedSearchNew === "itwaslast") return;

    const { results, lastVisibleDoc } = await firebase._globalTargetedSearch(
      this.#lasVisibleForTagetedSearchNew,
      this.#filterData.machine,
      this.#filterData.brand,
      this.#filterData.model,
      discount,
      10
    );
    console.log(results, lastVisibleDoc);

    this.#lasVisibleForTagetedSearchNew = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleForTagetedSearchNew = "itwaslast";
    }
    console.log(this.#lasVisibleForTagetedSearchNew);
    return results;
  }
  async _makeBusinessSearch(searchQuery) {
    console.warn(this.#lastVisibleDocForBusiness);

    this.#searchType = "business";
    this.#isLoading = true;
    console.warn("Business searching...");

    if (this.#lastVisibleDocForBusiness === "itwaslast") return;

    const { results, lastVisibleDoc } = await firebase._searchBusinesses(
      searchQuery,
      omittedWords,
      this.#lastVisibleDocForBusiness,
      10
    );

    console.log(results, lastVisibleDoc);

    this.#lastVisibleDocForBusiness = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lastVisibleDocForBusiness = "itwaslast";
    }
    console.log(this.#lastVisibleDocForBusiness);
    return results;
  }

  async _activateDetectScrollToBottomForTarNew(margin = 0) {
    console.log("detect targeted called");
    console.log("search type:", this.#searchType);
    if (this.#bottomScrollDetectActivatedTargeted) return;
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    if (!searchResultsContainer) {
      console.log("Error: Container not found.");
      return;
    }

    // Add a scroll event listener
    searchResultsContainer.addEventListener("scroll", async () => {
      const isScrolledToBottom =
        searchResultsContainer.scrollHeight -
          searchResultsContainer.scrollTop -
          searchResultsContainer.clientHeight <=
        margin;

      if (isScrolledToBottom) {
        if (this.#isLoading) return;
        if (this.#searchType !== "targeted") return;
        console.log("detect targeted accepted");

        const results = await this._makeTargetedSearch();
        let resultsToUse = results;

        if (results) {
          results.forEach((result) => {
            this.#currentDisplayedResultsWithOutFilter.push(result);
          });
          if (this.#isCurrentDisplayResultsOnfilter) {
            console.log("Is on filter, displaying new resluts through filter.");
            const filteredResults = this._filterResultsNow(false, results);
            if (!filteredResults && results) {
              this.#isLoading = false;
            }
            resultsToUse = filteredResults;
          }
        }

        this._displaySearchResults(resultsToUse, null, null, false);

        console.log(results);
        console.log("Scrolled near the bottom");
      }
    });
    this.#bottomScrollDetectActivatedTargeted = true;
  }
  async _activateDetectScrollToBottomForBusiness(margin = 0) {
    console.log("detect business called");
    console.log("search type:", this.#searchType);
    if (this.#bottomScrollDetectActivatedbusiness) return;
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    if (!searchResultsContainer) {
      console.log("Error: Container not found.");
      return;
    }

    // Add a scroll event listener
    searchResultsContainer.addEventListener("scroll", async () => {
      const isScrolledToBottom =
        searchResultsContainer.scrollHeight -
          searchResultsContainer.scrollTop -
          searchResultsContainer.clientHeight <=
        margin;

      if (isScrolledToBottom) {
        if (this.#isLoading) return;
        if (this.#searchType !== "business") return;
        console.log("detect business acepted");

        const results = await this._makeBusinessSearch(
          this.#lastSearchBusinessQuery
        );
        let resultsToUse = results;

        this._displaySearchBusinessResults(
          resultsToUse,
          this.#lastSearchBusinessQuery,
          false
        );

        console.log(results);
        console.log("Scrolled near the bottom");
      }
    });
    this.#bottomScrollDetectActivatedbusiness = true;
  }

  _activateDetectScrollToBottom = (margin = 0) => {
    if (this.#bottomScrollDetectActivatedGenral) return;
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    if (!searchResultsContainer) {
      console.log("Error: Container not found.");
      return;
    }

    // Add a scroll event listener
    searchResultsContainer.addEventListener("scroll", async () => {
      const isScrolledToBottom =
        searchResultsContainer.scrollHeight -
          searchResultsContainer.scrollTop -
          searchResultsContainer.clientHeight <=
        margin;

      if (isScrolledToBottom) {
        if (this.#isLoading) return;
        let resultsToUse;

        if (this.#searchType !== "general") return;

        const results = await this._doSearch(this.#lastSearchQuery);
        resultsToUse = results;

        if (results) {
          results.forEach((result) => {
            this.#currentDisplayedResultsWithOutFilter.push(result);
          });
          if (this.#isCurrentDisplayResultsOnfilter) {
            // console.log("Is on filter, displaying new resluts through filter.");
            const filteredResults = this._filterResultsNow(false, results);
            if (!filteredResults && results) {
              this.#isLoading = false;
            }
            resultsToUse = filteredResults;
          }
        }

        // if (this.#searchType === "targeted") {
        //   this._displaySearchResults(resultsToUse, null, null, false);
        // } else {
        this._displaySearchResults(
          resultsToUse,
          this.#lastSearchQuery,
          omittedWords,
          false
        );
        // }
        console.log("Scrolled near the bottom");
      }
    });
    this.#bottomScrollDetectActivatedGenral = true;
  };

  async _makeSearchFromSearchInput() {
    this.#isLoading = false;
    const searchInput = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentSearchInput`
    );
    const NumberOfSearchResults = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13Number`
    );
    const SearchStartUpComponentCon13 = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentCon13`
    );
    const searchResultsContainer = document.getElementById(
      `${this.#prefixId}SearchListingsResultsContianer`
    );
    searchResultsContainer.innerHTML = "";

    // let lastVisibleUse;

    searchInput.addEventListener("keyup", async (e) => {
      if (e.key === "Enter") {
        e.target.blur();
      }
    });
    searchInput.addEventListener("blur", async (e) => {
      const targetValueUp = e.target.value.trim();
      const targetValue = targetValueUp.toLowerCase();
      if (targetValue.length > 3) {
        // lastVisibleUse = null;
        this._removeAndAddActiveForTrends();
        if (this.#isSearchingForBusiness) {
          if (this.#lastSearchBusinessQuery !== targetValue) {
            this.#lastVisibleDocForBusiness = null;
            const results = await this._makeBusinessSearch(targetValue);

            this.#currentDisplayedBusinessResultsWithOutFilter = results;
            this._displaySearchBusinessResults(results, targetValue, true);
            this._handleActivatedBusinessSearch("false");
            // toggleSearchContainer();
            this.#lastSearchQuery = "";
            this.#lastSearchBusinessQuery = targetValue;

            this._activateDetectScrollToBottomForBusiness(100);
          }
        } else {
          if (this.#lastSearchQuery !== targetValue) {
            this.#lastVisibleUseForSearch = null;
            const results = await this._doSearch(targetValue);
            this.#currentDisplayedResultsWithOutFilter = results;
            this._displaySearchResults(
              results,
              targetValue,
              omittedWords,
              true
            );
            this.#lastSearchQuery = targetValue;
            this.#lastSearchBusinessQuery = "";
          }
        }
      } else {
        SearchStartUpComponentCon13.style.display = "flex";
        NumberOfSearchResults.textContent = `Busqueda no válida`;
      }
    });

    this._activateDetectScrollToBottom(100);
  }

  _setSearchComponentHtml(listName, CategoriesArr, id) {
    let minPrice = 0;
    let maxPrice = 0;
    let minPreset = 0;
    let maxPreset = 0;

    this.#prefixId = GENRALHELPERS._getUniqueIdfunction();
    const prefixId = this.#prefixId;
    const [Html, idForEvents] = this._displayPriceAdjuster(
      minPrice,
      maxPrice,
      minPreset,
      maxPreset
    );
    const allSearchHtml = generateHtml._createWholeSearchComponent(
      Html,
      null,
      prefixId
    );

    const con = document.getElementById(id);
    con.insertAdjacentHTML("beforeend", allSearchHtml);
    this._otherSearchEvents(prefixId);
    this._makeSearchFromSearchInput();
    this._initializeSelectLists();
  }
  _displayPriceAdjuster(minPrice, maxPrice, presetMin, presetMax) {
    const sliderHtml = generateHtml._silderComponentCreator(
      presetMin,
      presetMax
    );
    const priceContainerHtml = generateHtml._createPriceSliderContainer(
      minPrice,
      maxPrice,
      sliderHtml,
      this.#prefixId
    );
    return [priceContainerHtml];
  }
  _handlePriceSliderEvents(
    minPrice,
    maxPrice,
    presetMin,
    presetMax,
    attachContainerId
  ) {
    const container = document.getElementById(attachContainerId);
    this.#filterData.priceMin = presetMin;
    this.#filterData.priceMax = presetMax;

    // Get elements
    const minInput = container.querySelector("#min-input");
    const maxInput = container.querySelector("#max-input");
    const sliderRange = container.querySelector("#slider-range");
    const minHandle = container.querySelector("#min-handle");
    const maxHandle = container.querySelector("#max-handle");
    const minAvailable = container.querySelector(
      `#PriceAdjustComponentConTextMin`
    );
    const maxAvailable = container.querySelector(
      `#PriceAdjustComponentConTextMax`
    );

    minInput.value = GENRALHELPERS._formatToPrice(presetMin);
    minAvailable.textContent = GENRALHELPERS._formatToPrice(minPrice);
    maxInput.value = GENRALHELPERS._formatToPrice(presetMax);
    maxAvailable.textContent = GENRALHELPERS._formatToPrice(maxPrice);
    // Constants
    const trackWidth = 100; // Slider track width in percentage
    const minHandleLimit = 0; // Minimum position of the left handle
    const maxHandleLimit = trackWidth; // Maximum position of the right handle

    let minPosition =
      ((presetMin - minPrice) / (maxPrice - minPrice)) * trackWidth;
    let maxPosition =
      ((presetMax - minPrice) / (maxPrice - minPrice)) * trackWidth;

    function updateSlider() {
      sliderRange.style.left = `${minPosition}%`;
      sliderRange.style.width = `${maxPosition - minPosition}%`;
      minHandle.style.left = `${minPosition}%`;
      maxHandle.style.left = `${maxPosition}%`;
    }

    function validateInput(value, isMin) {
      let num = Math.round(value / 1000) * 1000; // Round to thousands
      if (num < minPrice) num = minPrice;
      if (num > maxPrice) num = maxPrice;
      if (isMin && num >= presetMax) num = presetMax - 1000;
      if (!isMin && num <= presetMin) num = presetMin + 1000;
      return num;
    }

    const handleInputBlur = (e, isMin) => {
      const value = parseInt(e.target.value.replace(/,/g, ""), 10) || 0;
      if (isMin) {
        presetMin = validateInput(value, true);
        minPosition =
          ((presetMin - minPrice) / (maxPrice - minPrice)) * trackWidth;
      } else {
        presetMax = validateInput(value, false);
        maxPosition =
          ((presetMax - minPrice) / (maxPrice - minPrice)) * trackWidth;
      }
      minInput.value = presetMin.toLocaleString();
      maxInput.value = presetMax.toLocaleString();
      this.#filterData.priceMin = presetMin;
      this.#filterData.priceMax = presetMax;

      updateSlider();
    };

    function handleInputChange(e) {
      const value = e.target.value.replace(/,/g, ""); // Remove commas
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      e.target.value = parseInt(numericValue || 0, 10).toLocaleString(); // Format as thousands
    }

    const handleDrag = (event, isMin, isTouch = false) => {
      const trackRect = sliderRange.parentNode.getBoundingClientRect();
      const clientX = isTouch ? event.touches[0].clientX : event.clientX;
      const offsetX =
        ((clientX - trackRect.left) / trackRect.width) * trackWidth;

      if (isMin) {
        minPosition = Math.min(
          Math.max(minHandleLimit, offsetX),
          maxPosition - 1
        );
        presetMin = Math.round(
          (minPosition / trackWidth) * (maxPrice - minPrice) + minPrice
        );
        presetMin = validateInput(presetMin, true);
      } else {
        maxPosition = Math.max(
          Math.min(maxHandleLimit, offsetX),
          minPosition + 1
        );
        presetMax = Math.round(
          (maxPosition / trackWidth) * (maxPrice - minPrice) + minPrice
        );
        presetMax = validateInput(presetMax, false);
      }

      minInput.value = presetMin.toLocaleString();
      maxInput.value = presetMax.toLocaleString();
      this.#filterData.priceMin = presetMin;
      this.#filterData.priceMax = presetMax;
      updateSlider();
    };

    function addDragEvent(handle, isMin) {
      const onMove = (event) =>
        handleDrag(event, isMin, event.type.startsWith("touch"));
      const onEnd = () => {
        window.removeEventListener("mousemove", onMove);
        window.removeEventListener("mouseup", onEnd);
        window.removeEventListener("touchmove", onMove);
        window.removeEventListener("touchend", onEnd);
      };

      const onStart = (event) => {
        event.preventDefault();
        window.addEventListener("mousemove", onMove);
        window.addEventListener("mouseup", onEnd);
        window.addEventListener("touchmove", onMove);
        window.addEventListener("touchend", onEnd);
      };

      handle.addEventListener("mousedown", onStart);
      handle.addEventListener("touchstart", onStart);
    }

    minInput.addEventListener("input", handleInputChange);
    maxInput.addEventListener("input", handleInputChange);
    minInput.addEventListener("blur", (e) => handleInputBlur(e, true));
    maxInput.addEventListener("blur", (e) => handleInputBlur(e, false));

    addDragEvent(minHandle, true);
    addDragEvent(maxHandle, false);

    // Initialize the slider
    updateSlider();
  }

  _handleActivatedBusinessSearch(active) {
    const buttonFilter = document.getElementById(
      `${this.#prefixId}SearchStartUpComponentFilterButton`
    );
    const ActivateOnlyBusinessSeachingButton = document.getElementById(
      `${this.#prefixId}ActivateOnlyBusinessSeachingButton`
    );

    const searchInput =
      ActivateOnlyBusinessSeachingButton.parentElement.querySelector("input");

    console.log(active);

    if (active === "true") {
      this.#isSearchingForBusiness = false;
      ActivateOnlyBusinessSeachingButton.dataset.active = "false";
      ActivateOnlyBusinessSeachingButton.classList.remove(
        "ActivateOnlyBusinessSeachingButtonActive"
      );
      ActivateOnlyBusinessSeachingButton.querySelector("img").src =
        iconCharcoalDarkBusiness;
      searchInput.placeholder = "Buscar listados";
      buttonFilter.style.display = "";
    }
    if (active === "false") {
      this.#isSearchingForBusiness = true;
      ActivateOnlyBusinessSeachingButton.dataset.active = "true";
      ActivateOnlyBusinessSeachingButton.classList.add(
        "ActivateOnlyBusinessSeachingButtonActive"
      );
      ActivateOnlyBusinessSeachingButton.querySelector("img").src =
        iconWhiteBusiness;
      searchInput.placeholder = "Buscar negocios";
      buttonFilter.style.display = "none";
    }
  }

  async _otherSearchEvents(prefixId) {
    const searchInputParent = document.getElementById(
      `${prefixId}SearchStartUpComponentSearchInput`
    ).parentElement.parentElement;
    const searchInput = document.getElementById(
      `${prefixId}SearchStartUpComponentSearchInput`
    );
    const buttonFilterNow = document.getElementById(
      `${prefixId}SearchStartUpComponentFilterNowButton`
    );
    const buttonFilter = document.getElementById(
      `${prefixId}SearchStartUpComponentFilterButton`
    );
    const buttonFilterClose = document.getElementById(
      `${prefixId}SearchStartUpComponentFilterButtonClose`
    );
    const allFiltersCon = document.getElementById(
      `${prefixId}SearchStartUpComponentCon7`
    );
    const ActivateOnlyBusinessSeachingButton = document.getElementById(
      `${prefixId}ActivateOnlyBusinessSeachingButton`
    );

    // Start Functions
    //toogle FilterContainer
    const toggleSearchContainer = (hide) => {
      if (hide) {
        allFiltersCon.classList.remove("SearchStartUpComponentCon7Display");
        searchInputParent.classList.remove(
          "SearchStartUpComponentCon4WithPadding"
        );
        setTimeout(() => {
          allFiltersCon.style.display = "none"; // Hide after transition
        }, 300); // Match the transition duration

        return;
      }

      if (
        allFiltersCon.classList.contains("SearchStartUpComponentCon7Display")
      ) {
        allFiltersCon.classList.remove("SearchStartUpComponentCon7Display");
        searchInputParent.classList.remove(
          "SearchStartUpComponentCon4WithPadding"
        );
        setTimeout(() => {
          allFiltersCon.style.display = "none"; // Hide after transition
        }, 300); // Match the transition duration
      } else {
        this._setSelectOptionTakecare(prefixId);
        searchInputParent.classList.add(
          "SearchStartUpComponentCon4WithPadding"
        );
        allFiltersCon.style.display = "flex"; // Show immediately before adding class
        setTimeout(() => {
          allFiltersCon.classList.add("SearchStartUpComponentCon7Display");
        }, 10); // Slight delay to ensure display change is applied
      }
    };

    ActivateOnlyBusinessSeachingButton.addEventListener("click", () => {
      const isActive = ActivateOnlyBusinessSeachingButton.dataset.active;
      this._handleActivatedBusinessSearch(isActive);
    });

    searchInput.addEventListener("focus", () => {
      console.log("focus");
      toggleSearchContainer(true);
      buttonFilter
        .querySelector(".SearchStartUpComponentCon6B")
        .classList.add("SearchStartUpComponentCon6BHide");
    });
    buttonFilter.addEventListener("click", () => {
      console.log("click");
      buttonFilter
        .querySelector(".SearchStartUpComponentCon6B")
        .classList.remove("SearchStartUpComponentCon6BHide");
      toggleSearchContainer();

      // allFiltersCon.classList.add("SearchStartUpComponentCon7Display");
      // console.log(searchInputParent);
    });

    buttonFilterClose.addEventListener("click", toggleSearchContainer);
    buttonFilterNow.addEventListener("click", async () => {
      console.log(this.#filterData);
      if (!this.#filterData.machine) {
        alert(
          "Necesitas especificar al menos una opción antes de hacer la busqueda."
        );
        return;
      }

      this.#lasVisibleForTagetedSearchNew = null;
      this._removeAndAddActiveForTrends();
      const results = await this._makeTargetedSearch();
      // await target();
      console.log(this.#lasVisibleForTagetedSearchNew);

      this.#currentDisplayedResultsWithOutFilter = results;
      this._displaySearchResults(results, null, null, true);
      toggleSearchContainer();
      this.#lastSearchQuery = "";

      this._activateDetectScrollToBottomForTarNew(100);
    });
  }
  _initiateSelectOptions = async (
    isMultiple,
    parrentId,
    parrentAllId,
    prefixId,
    optionsData,
    isForReset
  ) => {
    const options = [{ id: "todos", label: "Todos" }];
    if (!optionsData) {
      return;
    } else if (!optionsData.length > 0) return;
    optionsData.forEach((option) => {
      options.push({
        id: option.id,
        label: GENRALHELPERS._formatReadableString(option.id),
      });
    });

    let selectedValues = [];
    let createdOptions = []; // To track options created by the user

    const parentAllConEl = document.getElementById(parrentAllId);
    const parentConEl = document.getElementById(parrentId);
    const selectedOptionsDiv = parentConEl.querySelector(`#selected-options`);
    const searchInput = parentConEl.querySelector(`#search-input`);
    const optionList = parentConEl.querySelector(`#option-list`);
    parentConEl.classList.add("SearchStartUpComponentCon10Clear");
    if (isForReset) {
      selectedOptionsDiv.innerHTML = "";
    }

    // Function to render the selected options
    const renderSelectedOptions = () => {
      selectedOptionsDiv.innerHTML = "";
      selectedValues.forEach((value) => {
        const option =
          options.find((opt) => opt.id === value) ||
          createdOptions.find((opt) => opt.id === value);
        const optionDiv = document.createElement("div");
        optionDiv.classList.add("selected-option");
        optionDiv.innerHTML = `${option.label} ${
          option.isCreated ? "<span class='created-tag'>(Creado)</span>" : ""
        } <span class="remove" data-id="${option.id}">x</span>`;
        selectedOptionsDiv.appendChild(optionDiv);
      });

      // Attach remove functionality to each selected option
      parentConEl.querySelectorAll(".remove").forEach((removeButton) => {
        removeButton.addEventListener("click", (e) => {
          const id = e.target.dataset.id;
          selectedValues = selectedValues.filter((value) => value !== id);

          let Type;

          if (parentConEl.id.includes("Type")) {
            Type = "Type";

            this.#filterData.machine = "";
            this.#filterData.brand = "";
            this.#filterData.model = "";
          } else if (parentConEl.id.includes("Brand")) {
            Type = "Brand";

            this.#filterData.brand = "";
            this.#filterData.model = "";
          } else if (parentConEl.id.includes("Model")) {
            Type = "Model";

            this.#filterData.model = "";
          } else if (parentConEl.id.includes("Year")) {
            Type = "Year";
            const indexToRemove = this.#filterData.years.indexOf(id);
            if (indexToRemove !== -1) {
              this.#filterData.years.splice(indexToRemove, 1);
            }
          } else if (parentConEl.id.includes("Condition")) {
            Type = "Condition";

            this.#filterData.condition = "";
          }
          this._callTheRightFunction(prefixId, Type);

          // Log when a value is removed
          renderSelectedOptions();
          renderOptionList();
        });
      });
    };

    // Function to render the option list based on search input
    const renderOptionList = () => {
      if (parrentId.includes("Brand") && this.#machineBrandsOnCleared) {
        return;
      }
      if (parrentId.includes("Model") && this.#machineModelsOnCleared) {
        return;
      }
      const query = searchInput.value.toLowerCase();
      const filteredOptions = options
        .concat(createdOptions)
        .filter(
          (option) =>
            option.label.toLowerCase().includes(query) &&
            !selectedValues.includes(option.id)
        );

      optionList.innerHTML = ""; // Clear the current list

      if (filteredOptions.length > 0) {
        filteredOptions.forEach((option) => {
          const optionItem = document.createElement("div");
          optionItem.classList.add("option-item");
          optionItem.textContent = option.label;
          optionItem.addEventListener("click", () => {
            if (isMultiple) {
              if (!selectedValues.includes(option.id)) {
                selectedValues.push(option.id);
              }
            } else {
              selectedValues = [option.id];
            }

            let Type;

            if (parentConEl.id.includes("Type")) {
              Type = "Type";
              this.#filterData.machine = option.id;
              this.#filterData.brand = "";
              this.#filterData.model = "";
            } else if (parentConEl.id.includes("Brand")) {
              Type = "Brand";
              this.#filterData.brand = option.id;
              this.#filterData.model = "";
            } else if (parentConEl.id.includes("Model")) {
              Type = "Model";
              this.#filterData.model = option.id;
            } else if (parentConEl.id.includes("Year")) {
              Type = "Year";
              this.#filterData.years.push(option.id);
            } else if (parentConEl.id.includes("Condition")) {
              Type = "Condition";
              this.#filterData.condition = option.id;
            }
            this._callTheRightFunction(prefixId, Type);

            renderSelectedOptions();
            renderOptionList();
            searchInput.value = "";
          });
          optionList.appendChild(optionItem);
        });
      } else if (query.trim() !== "") {
        const createOption = document.createElement("div");
        createOption.classList.add("create-option");
        createOption.textContent = `Crear nueva opción: "${query}"`;
        createOption.addEventListener("click", () => {
          const optionIdForHere = query.toLowerCase().split(" ").join("-"); // Unique ID for created options
          const newOption = {
            id: optionIdForHere,
            label: GENRALHELPERS._formatReadableString(query),
            isCreated: true,
          };
          console.log(`User created and selected: ${newOption.label}`); // Log the created and selected value
          let Type;

          if (parentConEl.id.includes("Type")) {
            Type = "Type";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: Type`);
            this.#filterData.machine = optionIdForHere;
            this.#filterData.brand = "";
            this.#filterData.model = "";
          } else if (parentConEl.id.includes("Brand")) {
            Type = "Brand";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: Brand`);
            this.#filterData.brand = optionIdForHere;
            this.#filterData.model = "";
          } else if (parentConEl.id.includes("Model")) {
            Type = "Model";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: model`);
            this.#filterData.model = optionIdForHere;
          } else if (parentConEl.id.includes("Year")) {
            Type = "Year";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: year`);
            this.#filterData.years.push(optionIdForHere);
          } else if (parentConEl.id.includes("Condition")) {
            Type = "Condition";
            console.log(`User selected: ${optionIdForHere}`);
            console.log(`selected: condition`);
            this.#filterData.condition = optionIdForHere;
          }
          this._callTheRightFunction(prefixId, Type);
          createdOptions.push(newOption);

          if (isMultiple) {
            // Allow multiple selections
            selectedValues.push(newOption.id);
          } else {
            // Single selection: Replace the current selection
            selectedValues = [newOption.id];
          }

          renderSelectedOptions();
          renderOptionList();
          searchInput.value = "";
        });

        optionList.appendChild(createOption);
      }

      optionList.classList.add("active");
    };

    // Handle the search input
    searchInput.addEventListener("input", renderOptionList);

    // Show option list when input is focused
    searchInput.addEventListener("focus", () => {
      renderOptionList();
      searchInput.placeholder = "Buscar opciones";
      optionList.classList.add("active");
    });

    // Hide option list when input loses focus or user clicks outside
    parentAllConEl.addEventListener("click", (e) => {
      if (!searchInput.contains(e.target) && !optionList.contains(e.target)) {
        optionList.classList.remove("active");
      }
    });

    // Prevent closing the dropdown when clicking on the options or removing selected options
    optionList.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("option-item") ||
        e.target.classList.contains("create-option")
      ) {
        if (isMultiple) {
          e.stopPropagation(); // Prevent closing the dropdown when an option is clicked
        }
      }
    });

    selectedOptionsDiv.addEventListener("click", (e) => {
      if (e.target.classList.contains("remove")) {
        e.stopPropagation(); // Prevent closing the dropdown when the "x" is clicked
      }
    });
  };
  async _setSelectOptionTakecare(prefixId) {
    this._setMachineTypes(prefixId);
  }
  _callTheRightFunction = (prefixId, Type) => {
    const machineSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineType`
    );
    const brandSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineBrand`
    );
    const modelSelect = document.getElementById(
      `${prefixId}SearchStartUpComponentSelectMachineModel`
    );
    if (Type === "Type") {
      brandSelect.querySelector("#selected-options").innerHTML = "";
      brandSelect.querySelector("#option-list").innerHTML = "";

      this.#machineBrandsOnCleared = true;
      this.#machineModelsOnCleared = true;

      brandSelect.classList.remove("SearchStartUpComponentCon10Clear");
      modelSelect.classList.remove("SearchStartUpComponentCon10Clear");
      brandSelect.querySelector("#search-input").placeholder = "Todos";
      modelSelect.querySelector("#search-input").placeholder = "Todos";

      // SearchStartUpComponentCon10Clear

      modelSelect.querySelector("#selected-options").innerHTML = "";
      modelSelect.querySelector("#option-list").innerHTML = "";

      if (!this.#filterData.machine) return;
      this._setMachineBrand(prefixId);
    }
    if (Type === "Brand") {
      this.#machineModelsOnCleared = true;
      modelSelect.classList.remove("SearchStartUpComponentCon10Clear");

      modelSelect.querySelector("#selected-options").innerHTML = "";
      modelSelect.querySelector("#option-list").innerHTML = "";
      modelSelect.querySelector("#search-input").placeholder = "Todos";

      if (!this.#filterData.brand) return;
      this._setMachineModel(prefixId);
    }
  };
  _setMachineTypes = async (prefixId) => {
    spinner._displaySpinner("Cargando información");
    const machineData = await firebase._getMachineData("machineTypes");
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineType`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineData
    );
    spinner._hideSpinner();
  };
  _setMachineBrand = async (prefixId) => {
    spinner._displaySpinner("Cargando marcas");

    const machineData = await firebase._getMachineData(
      `machineTypes/${this.#filterData.machine}/brands`
    );
    this.#machineBrandsOnCleared = false;
    this.#machineModelsOnCleared = false;
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineBrand`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineData
    );

    spinner._hideSpinner();
  };
  _setMachineModel = async (prefixId) => {
    spinner._displaySpinner("Cargando modelos");
    const machineData = await firebase._getMachineData(
      `machineTypes/${this.#filterData.machine}/brands/${
        this.#filterData.brand
      }/models`
    );

    this.#machineModelsOnCleared = false;
    this._initiateSelectOptions(
      false,
      `${prefixId}SearchStartUpComponentSelectMachineModel`,
      `${prefixId}SearchStartUpComponentAllCon`,
      prefixId,
      machineData
    );
    spinner._hideSpinner();
  };
}

export default new search();
