import generateHtml from "./generate-html";
import nav from "../home-screen/nav";
import editorTools from "./editor-tools";
import { list } from "firebase/storage";
import { constants } from "buffer";
// import startUp from "../main-start-up-screen/start-up";
import handleAccount from "../register/handle-account";
import spinner from "../spinner";
import businessOwn from "../business-page-own-veiw/busine-own";

import firebase from "../firebase";

const prefil = {
  name: "Nombre de negocio",
  shortDescription: "Descripción corta de negocio",
  contacts: {
    call: { contact: "Número de llamadas" },
    whatsApp: { contact: "Número de WhatsApp" },
    email: { contact: "Correo electrónico" },
    website: { contact: "Sitio web" },
  },
  place: {
    name: "",
    locationUrl: "",
    description: "Describe el lugar, da referencias.",
  },
  about: "Acerca de tu negocio",
};

let businessData = {
  name: "Nombre de negocio",
  shortDescription: "Descripción corta de negocio",
  contacts: {
    call: { contact: "Número de llamadas" },
    whatsApp: { contact: "Número de WhatsApp" },
    email: { contact: "Correo electrónico" },
    website: { contact: "Sitio web" },
  },
  place: {
    name: "",
    locationUrl: "",
    description: "Describe el lugar, da referencias.",
  },
  about: "Acerca de tu negocio",
  backgroundImg: "",
  profileImg: "",
  about: prefil.about,
  listingsApear: "",
  ratedAt: 5,
  numberOfReviews: 0,
  listingsApear: "horizontal",
  lists: [
    {
      name: "En oferta",
      type: "auto",
      tag: "en oferta",
      ids: [],
    },
    {
      name: "Tractores",
      type: "auto",
      tag: "tractor",
      ids: [],
    },
    {
      name: "Todos",
      type: "todos",
      ids: [],
    },
  ],
  colors: {
    headerPrimary: "FFFFFF",
    headerSecondary: "E0E0E0",
    packTitles: "6D6D6D",
    packTitlesHighlight: "3A7D44",
    itemBackgroundPrimary: "3A7D44",
    itemBackgroundSecondary: "6D6D6D",
    itemTextPrimary: "FFFFFF",
    itemTextSecondary: "2B2B2B",
    itemTextTerteriary: "AEAEAE",
    itemTextFour: "FFFFFF",
    itemHighlight: "FF7043",
    itemHighlightText: "FFFFFF",
  },
};

const tags = ["en oferta"];

class BusinessPageEditor {
  #colors = false;
  #hasScrolledToError = false;
  #currentInterval = null;
  #eventsInitialized = false;

  #businessBackGroundImgChange = false;
  #businessProfileImgChange = false;
  #editCurrent;
  constructor() {}
  _setimageChangeState(which) {
    console.log(which);
    if (!which) return;
    if (which === "background") {
      this.#businessBackGroundImgChange = true;
    }
    if (which === "profile") {
      this.#businessProfileImgChange = true;
    }
    if (which === "reset") {
      this.#businessProfileImgChange = false;
      this.#businessBackGroundImgChange = false;
    }
  }

  _changeColorsReturn(input) {
    this.#colors = input;
  }
  async _init(edit, open) {
    console.log(edit, open);
    this.#eventsInitialized = false;
    spinner._displaySpinner("Un momento");
    this.#editCurrent = edit;
    console.log(edit);

    const machineData = await firebase._getMachineData("machineTypes");
    console.log(machineData);
    machineData.forEach((machineType) => {
      tags.push(machineType.id);
    });

    this._setimageChangeState("reset");
    if (!edit) {
      // await this._getBusinessDataFromLocalStorage();
    }
    if (edit) {
      const account = firebase._getLatestCurrentUserData();
      console.log(account);
      spinner._displaySpinner("Cargando tu negocio");
      businessData = await firebase._getBusinessById(account.business);
    }
    spinner._hideSpinner();
    this._setMainEditor(edit);

    if (open === "colors") {
      editorTools._init("colors", businessData, tags);
      this._hideShowTopEditorTools("hide");
    }
    if (open === "lists") {
      editorTools._init("listings", businessData, tags);
      this._hideShowTopEditorTools("hide");
    }

    // this._events(edit);
  }

  _displayErrorMessage(id, message) {
    const errorMessageCon = document
      .getElementById(id)
      .parentElement.querySelector(".editorErrorInputMessage");
    errorMessageCon.textContent = message;
    if (!this.#hasScrolledToError) {
      const scrollToElementWithMargin = (id) => {
        const element = document.getElementById(id);
        if (element) {
          // if (!scrolled) {
          // Scroll into view
          const container = document.getElementById(
            "BusinessPageVisualizerConForInsert"
          ); // Scrollable container

          container.scrollTo({
            top: element.offsetTop - (container.offsetTop + 80), // Adjusts position relative to container
            behavior: "smooth",
          });

          // scrolled = true;
          // }

          // Scroll the element into view
          // element.scrollIntoView({ behavior: "smooth", block: "start" });

          // Adjust the scroll position to add a 50px margin
          // window.scrollBy(0, -50); // Scroll 50px up from the element
          this.#hasScrolledToError = true;
        }
      };
      scrollToElementWithMargin(id);
    }
  }

  _checkFields() {
    this.#hasScrolledToError = false;
    let alertMessage =
      "Por favor complete los datos necesarios antes de seguir.";
    let canGoOn = true;

    let alertFields = "";
    if (businessData.name === "" || businessData.name === prefil.name) {
      alertFields += "\n- Nombre de negocio";
      this._displayErrorMessage(
        "BusinessEditorInputBusinessName",
        "El nombre de negocio es obligatorio"
      );
    }
    if (
      businessData.shortDescription === "" ||
      businessData.shortDescription === prefil.shortDescription
    ) {
      alertFields += "\n- Descripción corta de negocio";
      this._displayErrorMessage(
        "BusinessEditorInputBusinessShortDescription",
        "El descripción corta de negocio es obligatorio"
      );
    }
    if (businessData.backgroundImg === "") {
      alertFields += "\n- Imagen de fondo de negocio";
      this._displayErrorMessage(
        "BusinessEditorBCon9EditorToolsButtonBackgroungImg",
        "La imagen de fondo de negocio es obligatorio"
      );
    }
    if (businessData.profileImg === "") {
      alertFields += "\n- Imagen de perfil de negocio";
      this._displayErrorMessage(
        "BusinessEditorBCon9EditorToolsButtonProfileImg",
        "La imagen de perfil de negocio es obligatorio"
      );
    }
    if (
      businessData.listingsApear !== "horizontal" &&
      businessData.listingsApear !== "vertical"
    ) {
      alertFields += "\n- Como aparecen tu listados en 'ver todo'";
    }
    if (businessData.place.name === "") {
      alertFields += "\n- Lugar, ubicación.";
      this._displayErrorMessage(
        "BusinessPageEditorLocationInput",
        "La ubicación de tu negocio es obligatorio"
      );
    }
    if (
      businessData.place.description === "" ||
      businessData.place.description === prefil.place.description
    ) {
      alertFields += "\n- Lugar, descripción del lugar.";
      this._displayErrorMessage(
        "BusinessEditorInputBusinessLocationDescription",
        "La descripción de la ubicación de tu negocio es obligatorio"
      );
    }
    if (businessData.contacts.call) {
      if (
        businessData.contacts.call.contact === "" ||
        businessData.contacts.call.contact === prefil.contacts.call.contact
      ) {
        alertFields += "\n- Número de llamadas";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoPhoneNumber",
          "El número de llamadas es obligatorio"
        );
      }
      if (
        businessData.contacts.call.contact.length < 10 ||
        businessData.contacts.call.contact.length > 10
      ) {
        alertFields += "\n- Número de llamadas no es un número válido.";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoPhoneNumber",
          "El número de llamadas no es válido"
        );
      }
    } else {
      alertFields += "\n- Número de llamadas";
    }

    if (businessData.contacts.whatsApp) {
      if (
        businessData.contacts.whatsApp.contact === "" ||
        businessData.contacts.whatsApp.contact ===
          prefil.contacts.whatsApp.contact
      ) {
        alertFields += "\n- Número de WhatsApp";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoWhatsAppNumber",
          "El número de WhatsApp es obligatorio"
        );
      }
      if (
        businessData.contacts.whatsApp.contact.length < 10 ||
        businessData.contacts.whatsApp.contact.length > 10
      ) {
        alertFields += "\n- Número de WhatsApp no es un número válido.";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoWhatsAppNumber",
          "El número de WhatsApp no es válido"
        );
      }
    } else {
      alertFields += "\n- Número de WhatsApp";
    }

    if (businessData.contacts.email) {
      if (
        businessData.contacts.email.contact === "" ||
        businessData.contacts.email.contact === prefil.contacts.email.contact
      ) {
        alertFields += "\n- Correo electrónico";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoEmail",
          "El correo electrónico es obligatorio"
        );
      }
      const email = businessData.contacts.email.contact;
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        alertFields += "\n- El correo electrónico no es un correo válido.";
        this._displayErrorMessage(
          "BusinessEditorInputBusinessInfoEmail",
          "El correo electrónico no es válido"
        );
      }
    } else {
      alertFields += "\n- Correo electrónico";
      this._displayErrorMessage(
        "BusinessEditorInputBusinessInfoEmail",
        "El correo electrónico es obligatorio"
      );
    }

    if (businessData.contacts.website) {
      const website = businessData.contacts.website.contact;
      if (website.length > 0 && website !== "Sitio web") {
        if (!website.startsWith("http://") && !website.startsWith("https://")) {
          alertFields +=
            "\n- El sitio web debe comenzar con 'http://' o 'https://'.";
          this._displayErrorMessage(
            "BusinessEditorInputBusinessInfoWebsite",
            "El sitio web debe comenzar con 'http://' o 'https://'. Es opcional, y puede ser omitido."
          );
        } else if (!website.includes(".")) {
          alertFields += "\n- El sitio web no es válido.";
          this._displayErrorMessage(
            "BusinessEditorInputBusinessInfoWebsite",
            "El sitio web no es válido. Es opcional, y puede ser omitido."
          );
        }
      }
    }
    if (businessData.about === "" || businessData.about === prefil.about) {
      alertFields += "\n- Acerca del negocio";
      this._displayErrorMessage(
        "BusinessEditorInputBusinessInfoAbout",
        "Acerca de tu negocio es obligatorio"
      );
    }

    // Start handle alert
    if (alertFields.length > 0) {
      canGoOn = false;
    }
    if (canGoOn) return canGoOn;
    alert(alertMessage + alertFields);
    return canGoOn;
  }

  _navigateEditorSections() {
    const businessSectionsMainIds = [
      "SectionIndicatedBusinessEditorBusinessHeaderCon1",
      "SectionIndicatedListingsExampleDisplayCon1",
      "SectionIndicatedBusinessInfoCon1",
      "SectionIndicatedBusinessColors",
    ];

    const sections = businessSectionsMainIds.map((id) =>
      document.getElementById(id)
    );
    const buttonBack = document.getElementById("BusinessPageEditorButtonBack");
    const buttonNext = document.getElementById("BusinessPageEditorButtonNext");
    const buttonCreatePage = document.getElementById(
      "BusinessPageEditorButtonCreatePage"
    );

    let currSectionNum = 0;
    let isButtonNavigating = false; // Prevents manual scroll interruptions during button clicks

    const scrollTo = (index) => {
      this.#colors = false;
      if (index < 0 || index >= sections.length) return; // Ensure index is valid
      isButtonNavigating = true;
      currSectionNum = index;
      updateButtonStates();
      if (!sections[index]) return;
      sections[index].scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => (isButtonNavigating = false), 600); // Allow manual scrolling after animation
    };

    const updateButtonStates = () => {
      if (this.#colors) return;
      // Update editor tools based on section
      const editorActions = ["header", "listings", "info", "colors"];
      if (editorActions[currSectionNum]) {
        // editorTools._init(editorActions[currSectionNum], businessData, tags);
      }

      // // Update button visibility and interactivity
      // buttonBack.classList.toggle(
      //   "BusinessPageEditorButtonInactive",
      //   currSectionNum === 0
      // );
      // buttonNext.classList.toggle(
      //   "BusinessPageEditorButtonInactive",
      //   currSectionNum === sections.length - 1
      // );

      // Show "Create Page" button only on the last section, but don't hide it again
      if (currSectionNum === sections.length - 1) {
        buttonCreatePage.style.display = "";
      }
    };

    // buttonBack.addEventListener("click", () => {
    //   scrollTo(currSectionNum - 1);
    // });

    // buttonNext.addEventListener("click", () => {
    //   scrollTo(currSectionNum + 1);
    // });

    const observer = new IntersectionObserver(
      (entries) => {
        if (isButtonNavigating) return; // Ignore updates during button navigation

        let closestSection = null;
        let closestDistance = Infinity;

        entries.forEach((entry) => {
          const index = businessSectionsMainIds.indexOf(entry.target.id);

          if (index !== -1) {
            // Calculate distance from the top of the viewport
            const distanceFromTop = Math.abs(entry.boundingClientRect.top);

            // Check if this section is closer to the top than the current closest
            if (distanceFromTop < closestDistance) {
              closestDistance = distanceFromTop;
              closestSection = index;
            }
          }
        });

        if (closestSection !== null && closestSection !== currSectionNum) {
          currSectionNum = closestSection;

          const InputForFocus = document.querySelectorAll(
            ".InputForFocusCheck"
          );

          function isAnyInputFocused() {
            return Array.from(InputForFocus).some(
              (input) => input === document.activeElement
            );
          }

          // Example usage:
          if (isAnyInputFocused()) {
            return;
          }
          updateButtonStates();
        }
      },
      { root: null, threshold: 0.1 } // Trigger when at least 10% of the section is visible
    );

    sections.forEach((section) => {
      if (!section) return;
      observer.observe(section);
    });
    const BusinessPageEditorCon4 = document.getElementById(
      "BusinessPageEditorCon4"
    );
    if (BusinessPageEditorCon4.innerHTML === "") {
      // editorTools._init("header", businessData, tags);
    }
  }

  _setBusinessInfo() {
    const placeHtml = generateHtml._visualizerPopulateBusinessInfoPortion(
      "Lugar",
      generateHtml._visualizerPopulateBusinessInfoPlace(businessData.place)
    );

    const contactHtml = generateHtml._visualizerPopulateBusinessInfoPortion(
      "Contacto",
      generateHtml._visualizerPopulateBusinessInfoContact(businessData.contacts)
    );

    const aboutHtml = generateHtml._visualizerPopulateBusinessInfoPortion(
      "Acerca de nosotros",
      generateHtml._visualizerPopulateBusinessInfoAbout(businessData.about)
    );
    const finalPortionsHtml = placeHtml + contactHtml + aboutHtml;

    return generateHtml._visualizerPopulateBusinessInfoMain(finalPortionsHtml);
  }

  _closeBusinessEditor() {
    const MainContainerBusinessPageBuild = document.getElementById(
      "MainContainerBusinessPageBuild"
    );
    MainContainerBusinessPageBuild.remove();
  }

  _sortAndSaveContent(eTarget, text) {
    const id = eTarget.id;
    const errorMessageCon = document
      .getElementById(id)
      .parentElement.querySelector(".errorMessage");

    if (!errorMessageCon) {
      console.log("No error container");
    }
    console.log("here too");
    if (!id) return;
    if (id === "BusinessEditorInputBusinessName") {
      if (text.length < 1) {
        eTarget.textContent = prefil.name;
        businessData.name = prefil.name;
      } else {
        businessData.name = text;
      }
    }
    if (id === "BusinessEditorInputBusinessShortDescription") {
      if (text.length < 1) {
        eTarget.textContent = prefil.shortDescription;
        businessData.shortDescription = prefil.shortDescription;
      } else {
        businessData.shortDescription = text;
      }
    }
    if (id === "BusinessEditorInputBusinessLocationDescription") {
      if (text.length < 1) {
        eTarget.textContent = prefil.place.description;
        businessData.place.description = prefil.place.description;
      } else {
        businessData.place.description = text;
      }
    }
    if (id === "BusinessEditorInputBusinessInfoPhoneNumber") {
      if (text.length < 1) {
        eTarget.textContent = prefil.contacts.call.contact;
        businessData.contacts.call.contact = prefil.contacts.call.contact;
      } else {
        businessData.contacts.call.contact = text;
      }
    }
    if (id === "BusinessEditorInputBusinessInfoWhatsAppNumber") {
      if (text.length < 1) {
        eTarget.textContent = prefil.contacts.whatsApp.contact;
        businessData.contacts.whatsApp.contact =
          prefil.contacts.whatsApp.contact;
      } else {
        businessData.contacts.whatsApp.contact = text;
      }
    }
    if (id === "BusinessEditorInputBusinessInfoEmail") {
      if (text.length < 1) {
        eTarget.textContent = prefil.contacts.email.contact;
        businessData.contacts.email.contact = prefil.contacts.email.contact;
      } else {
        businessData.contacts.email.contact = text;
      }
    }
    if (id === "BusinessEditorInputBusinessInfoWebsite") {
      if (text.length < 1) {
        eTarget.textContent = prefil.contacts.website.contact;
        businessData.contacts.website.contact = prefil.contacts.website.contact;
      } else {
        businessData.contacts.website.contact = text;
      }
    }
    if (id === "BusinessEditorInputBusinessInfoAbout") {
      console.log("About here");
      if (text.length < 1) {
        eTarget.textContent = prefil.about;
        businessData.about = prefil.about;
      } else {
        businessData.about = text;
      }
    }
  }
  _checkAndClear(eTarget) {
    const id = eTarget.id;
    const text = eTarget.textContent.trim();
    const BusinessEditorInputBusinessInfoLabel = document
      .getElementById(id)
      .parentElement.querySelector(".BusinessEditorInputBusinessInfoLabel");
    const BusinessEditorInputBusinessErrorCon = document
      .getElementById(id)
      .parentElement.querySelector(".editorErrorInputMessage");
    console.log(BusinessEditorInputBusinessInfoLabel);
    console.log(BusinessEditorInputBusinessErrorCon);
    BusinessEditorInputBusinessErrorCon.textContent = "";
    if (!id) return;
    if (!text) return;

    if (id === "BusinessEditorInputBusinessName" && text === prefil.name) {
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessShortDescription" &&
      text === prefil.shortDescription
    ) {
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessLocationDescription" &&
      text === prefil.place.description
    ) {
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessInfoPhoneNumber" &&
      text === prefil.contacts.call.contact
    ) {
      BusinessEditorInputBusinessInfoLabel.textContent = text;
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessInfoWhatsAppNumber" &&
      text === prefil.contacts.whatsApp.contact
    ) {
      BusinessEditorInputBusinessInfoLabel.textContent = text;
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessInfoEmail" &&
      text === prefil.contacts.email.contact
    ) {
      BusinessEditorInputBusinessInfoLabel.textContent = text;
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessInfoWebsite" &&
      text === prefil.contacts.website.contact
    ) {
      BusinessEditorInputBusinessInfoLabel.textContent = text;
      eTarget.textContent = "";
    }
    if (
      id === "BusinessEditorInputBusinessInfoAbout" &&
      text === prefil.about
    ) {
      console.log("About here");
      eTarget.textContent = "";
    }
  }

  _hideShowTopEditorTools(hideShow) {
    const BusinessEditorBCon9EditorTools = document.getElementById(
      "BusinessEditorBCon9EditorTools"
    );
    if (hideShow === "show") {
      BusinessEditorBCon9EditorTools.classList.remove(
        "BusinessEditorBCon9Shrinked"
      );
    } else {
      BusinessEditorBCon9EditorTools.classList.add(
        "BusinessEditorBCon9Shrinked"
      );
    }
    if (
      BusinessEditorBCon9EditorTools.classList.contains(
        "BusinessEditorBCon9Shrinked"
      )
    ) {
      BusinessEditorBCon9EditorTools.classList.remove(
        "BusinessEditorBCon9Expanded"
      );
    } else {
      BusinessEditorBCon9EditorTools.classList.add(
        "BusinessEditorBCon9Expanded"
      );
    }
  }

  _startLoadingEffect(id = null, stop = false) {
    // If no id is provided, simply return (this handles the case where no element is targeted)
    if (!id) return;

    const element = document.getElementById(id);
    if (!element) return; // Ensure the element exists

    // If an effect is already running and stop is true, stop the effect and reset the background
    if (this.#currentInterval !== null && stop) {
      clearInterval(this.#currentInterval);
      this.#currentInterval = null;
      element.style.backgroundColor = "#fff"; // Reset background color to white
      element.style.opacity = 1; // Reset opacity to fully opaque
      return; // Exit the function once we stop the effect
    }

    // If stop is false or no previous effect is running, start the "loading" effect
    // Set a blueish background color to the element
    element.style.backgroundColor = "rgba(0, 123, 255, 0.3)";
    element.style.transition = "background-color 0.5s ease";

    let opacity = 1;
    let decreasing = true;

    // Start the "loading" effect with a set interval
    this.#currentInterval = setInterval(() => {
      if (decreasing) {
        opacity -= 0.05; // Decrease opacity
        if (opacity <= 0.3) decreasing = false;
      } else {
        opacity += 0.05; // Increase opacity
        if (opacity >= 1) decreasing = true;
      }

      // Apply the new opacity to the element
      element.style.opacity = opacity;
    }, 50); // Adjust the speed by changing the interval time
  }

  _events(edit) {
    // Selects
    const BusinessEditorBCon9EditorToolsShowHideButton =
      document.getElementById("BusinessEditorBCon9EditorToolsShowHideButton");
    const BusinessEditorBCon9EditorTools = document.getElementById(
      "BusinessEditorBCon9EditorTools"
    );
    const BusinessPageEditorButtonCreatePage = document.getElementById(
      "BusinessPageEditorButtonCreatePage"
    );
    const BusinessEditorBCon13ButtonEditColors = document.getElementById(
      "BusinessEditorBCon13ButtonEditColors"
    );
    const BusinessEditorBCon13ButtonEditLists = document.getElementById(
      "BusinessEditorBCon13ButtonEditLists"
    );
    const BusinessPageEditorButtonCancel = document.getElementById(
      "BusinessPageEditorButtonCancel"
    );

    const handleImageUpload = (imgInputId, imgPutInto) => {
      const fileInput = document.getElementById(imgInputId);
      const outputElement = document.getElementById(imgPutInto);

      fileInput.addEventListener("change", () => {
        const file = fileInput.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (imgInputId === "BusinessPageEditorSelectImgBackground") {
              businessData.backgroundImg = e.target.result;
              this._setimageChangeState("background");
            }
            if (imgInputId === "BusinessPageEditorSelectImgProfile") {
              businessData.profileImg = e.target.result;
              this._setimageChangeState("profile");
            }
            // this._setSelected
            // this._setVisualizer(this.#editCurrent);
            outputElement.src = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      });
    };

    spinner._displaySpinner("Un momento");
    setTimeout(() => {
      this._justIntergratingTheMap();
      spinner._displaySpinner("Un momento");
      const BusinessEditorInputBusinessForKeyUpListenersAll =
        document.querySelectorAll(
          ".BusinessEditorInputBusinessForKeyUpListeners"
        );
      // Events
      BusinessEditorInputBusinessForKeyUpListenersAll.forEach((input) => {
        input.addEventListener("focus", (e) => {
          const targetBlur = e.target;
          const text = targetBlur.textContent.trim();
          this._checkAndClear(targetBlur);
        });
      });
      BusinessEditorInputBusinessForKeyUpListenersAll.forEach((input) => {
        input.addEventListener("blur", (e) => {
          const targetBlur = e.target;
          const text = targetBlur.textContent.trim();
          this._sortAndSaveContent(targetBlur, text);
        });
      });
      handleImageUpload(
        "BusinessPageEditorSelectImgBackground",
        "BusinessEditorBusinessHeaderCon6BackgroundImgCon"
      );
      handleImageUpload(
        "BusinessPageEditorSelectImgProfile",
        "BusinessEditorBusinessHeaderCon6ProfileImgCon"
      );

      if (this.#eventsInitialized) {
        spinner._hideSpinner();
        return;
      }

      BusinessEditorBCon9EditorToolsShowHideButton.addEventListener(
        "click",
        () => {
          BusinessEditorBCon9EditorTools.classList.toggle(
            "BusinessEditorBCon9Shrinked"
          );

          if (
            BusinessEditorBCon9EditorTools.classList.contains(
              "BusinessEditorBCon9Shrinked"
            )
          ) {
            BusinessEditorBCon9EditorTools.classList.remove(
              "BusinessEditorBCon9Expanded"
            );
          } else {
            BusinessEditorBCon9EditorTools.classList.add(
              "BusinessEditorBCon9Expanded"
            );
          }
        }
      );

      BusinessPageEditorButtonCancel.addEventListener("click", () => {
        const confirmExit = confirm(
          "¿Cerrar? Perderás todos los cambios que hiciste a tu página."
        );

        if (confirmExit) {
          this._closeBusinessEditor();
        }
        // MainContainerBusinessPageBuild.remove();
      });

      BusinessEditorBCon13ButtonEditColors.addEventListener("click", () => {
        editorTools._init("colors", businessData, tags);
        this._hideShowTopEditorTools("hide");
      });
      BusinessEditorBCon13ButtonEditLists.addEventListener("click", () => {
        editorTools._init("listings", businessData, tags);
        this._hideShowTopEditorTools("hide");
      });

      const slider = document.querySelector(
        ".BusinessPageEditorConSilderUopDownCon1"
      );
      const editorContainer = document.querySelector(".BusinessPageEditorCon1");
      const visualizerContainer = document.querySelector(
        ".BusinessPageVisualizerCon1"
      );

      let isSliding = false;
      let startY = 0;
      let startHeight = 0;
      let totalContainerHeight = editorContainer.parentElement.offsetHeight; // Parent container height

      const onMouseDown = (event) => {
        isSliding = true;
        startY = event.clientY || event.touches[0].clientY; // Handle both mouse and touch events
        startHeight = editorContainer.offsetHeight;

        // Prevent selection while dragging
        document.body.style.userSelect = "none";
      };

      const onMouseMove = (event) => {
        if (!isSliding) return;

        const currentY = event.clientY || event.touches[0].clientY;
        const heightDiff = startY - currentY;

        // Update the height of the editor
        const newEditorHeight = Math.max(startHeight + heightDiff, 140); // Minimum height set to 140px
        editorContainer.style.height = `${newEditorHeight}px`;

        // Adjust the visualizer height inversely
        const newVisualizerHeight = Math.max(
          totalContainerHeight - newEditorHeight,
          140
        ); // Ensure minimum height of 140px
        visualizerContainer.style.height = `${newVisualizerHeight}px`;
      };

      const onMouseUp = () => {
        isSliding = false;
        document.body.style.userSelect = ""; // Re-enable selection
      };

      // Event listeners for mouse/touch events
      slider.addEventListener("mousedown", onMouseDown);
      slider.addEventListener("touchstart", onMouseDown);
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("touchmove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("touchend", onMouseUp);

      //

      //

      BusinessPageEditorButtonCreatePage.addEventListener("click", () => {
        this._saveBusiness(edit);
      });
      spinner._hideSpinner();
      this.#eventsInitialized = true;
    }, 200);
  }

  async _saveBusiness() {
    const edit = this.#editCurrent;
    if (!this._checkFields()) return;
    console.log(edit);
    spinner._displaySpinner("Validando información de negocio");
    const account = firebase._getLatestCurrentUserData();
    try {
      let backgroundImgRef;
      let profileImgRef;
      spinner._displaySpinner("Subiendo imágenes de negocio");
      if (edit) {
        if (this.#businessBackGroundImgChange) {
          console.log(this.#businessBackGroundImgChange);
          backgroundImgRef = await firebase._uploadImageById(
            "BusinessEditorBusinessHeaderCon6BackgroundImgCon"
          );
        } else {
          console.log("else");
          console.log(businessData.backgroundImg);
          backgroundImgRef = businessData.backgroundImg;
        }

        if (this.#businessProfileImgChange) {
          console.log(this.#businessProfileImgChange);
          profileImgRef = await firebase._uploadImageById(
            "BusinessEditorBusinessHeaderCon6ProfileImgCon"
          );
        } else {
          console.log("else");
          profileImgRef = businessData.profileImg;
        }
      }
      if (!edit) {
        backgroundImgRef = await firebase._uploadImageById(
          "BusinessEditorBusinessHeaderCon6BackgroundImgCon"
        );
        profileImgRef = await firebase._uploadImageById(
          "BusinessEditorBusinessHeaderCon6ProfileImgCon"
        );
      }

      console.log(backgroundImgRef, profileImgRef);

      // Ensure businessData is defined or valid
      if (!businessData) {
        spinner._hideSpinner();
        throw new Error("Business data is missing or invalid.");
      }
      spinner._displaySpinner("Guardando información de negocio");
      const createResult = await firebase._createUpdateBusiness(
        businessData,
        backgroundImgRef,
        profileImgRef,
        edit
      );
      console.log(createResult);
      if (createResult.data.status === "error") {
        throw new Error(createResult.data.message);
      }
      if (createResult.data.status === "success") {
        spinner._hideSpinner();
        this._closeBusinessEditor();
        alert(`Exito: ${createResult.data.message}`);
        businessOwn._init();
      }
    } catch (error) {
      spinner._hideSpinner();
      console.error("Error saving business info:", error);
      alert(`Error: ${error.message}`);
    }
  }

  async _getBusinessDataFromLocalStorage() {
    const retrievedData = localStorage.getItem("createBusinessData");
    if (retrievedData) {
      const parsedData = JSON.parse(retrievedData);

      const finishWhereLeftOf = confirm(
        "Puedes seguir creando tu página de negocio. Presiona cancelar para empezar de nuevo."
      );
      if (!finishWhereLeftOf) {
        localStorage.removeItem("createBusinessData");
        return;
      }
      businessData = parsedData;
    }
  }

  async _setVisualizer(edit) {
    const container = document.getElementById(
      "BusinessPageVisualizerConForInsert"
    );
    let listingHtml = generateHtml._visualizerPopulateListing(
      businessData.colors
    );
    listingHtml += generateHtml._visualizerPopulateListing(businessData.colors);
    listingHtml += generateHtml._visualizerPopulateListing(businessData.colors);
    listingHtml += generateHtml._visualizerPopulateListing(businessData.colors);
    listingHtml += generateHtml._visualizerPopulateListing(businessData.colors);

    // let listingsHtmlPack;

    let headerAndListingsHtml = await generateHtml._visualizerPopulateHeader(
      businessData,
      businessData.colors,
      edit,
      this.#businessBackGroundImgChange,
      this.#businessProfileImgChange
    );
    headerAndListingsHtml += `<div id="SectionIndicatedListingsExampleDisplayCon1"></div>`;

    businessData.lists.forEach((list) => {
      headerAndListingsHtml += generateHtml._visualizerPopulateListingsSection(
        listingHtml,
        list,
        businessData.colors
      );
    });

    headerAndListingsHtml += this._setBusinessInfo();

    container.innerHTML = "";

    container.insertAdjacentHTML("beforeend", headerAndListingsHtml);
    this._events();
  }

  _getTheListsData() {
    const lists = [];

    // Get all list containers
    const listContainers = document.querySelectorAll(
      ".BusinessPageEditorListingsListsCon2"
    );

    listContainers.forEach((container) => {
      // Get the list name
      const name = container.querySelector(
        ".BusinessPageEditorListingsListsInputName"
      ).value;

      // Get the list type
      const type = container.querySelector(
        "#BusinessPageEditorListingsListsListTypeSelect"
      ).value;

      // Find the tag select if type is auto, otherwise it's undefined
      const tagSelect = container.querySelector(
        "#BusinessPageEditorListingsListsListTagSelect"
      );

      const idArray = container.dataset.idsarray;
      const tag = type === "auto" && tagSelect ? tagSelect.value : undefined;

      // Push list object to the array

      let idsArrayForSave = [];
      if (idArray) {
        idArray.split(",").forEach((id) => {
          idsArrayForSave.push(id);
        });
      }

      console.log(idArray);
      lists.push({
        name: name.trim(),
        type: type,
        ids: idsArrayForSave,
        ...(tag && { tag: tag.trim() }), // Only include tag if it's defined
      });
    });

    businessData.lists = lists;
    this._setVisualizer(this.#editCurrent);
  }

  _getTheListsDataActive() {
    // // Start get data

    // Event listener for keyup on input fields
    document
      .querySelectorAll(".BusinessPageEditorListingsListsInputName")
      .forEach((input) => {
        input.addEventListener("keyup", () => {
          this._getTheListsData();
        });
      });

    // Event listener for change on select elements
    document
      .querySelectorAll(".BusinessPageEditorListingsListsChoose")
      .forEach((select) => {
        select.addEventListener("change", () => {
          this._getTheListsData();
        });
      });
  }

  _eventBusinessLists() {
    // Function to handle radio button selection
    const handleRadioChange = () => {
      const selectedValue = document.querySelector(
        'input[name="layout"]:checked'
      ).value;
      console.log("Selected layout:", selectedValue); // You can handle this as needed
      businessData.listingsApear = selectedValue;
    };

    // Attach the event listener to both radio buttons
    const radioButtons = document.querySelectorAll(".BusinessPageEditorRadio");
    radioButtons.forEach((radio) => {
      radio.addEventListener("change", handleRadioChange);
    });

    // Function to programmatically set the radio button
    function setLayout(layout) {
      if (layout === "vertical" || layout === "horizontal") {
        const value = layout.toLowerCase();
        const radioToSelect = document.querySelector(`input[value="${value}"]`);
        if (radioToSelect) {
          radioToSelect.checked = true;
          handleRadioChange(); // Optionally trigger the change handler
        }
      } else {
        console.error("Invalid layout value. Use 'Vertical' or 'Horizontal'.");
      }
    }

    if (businessData.listingsApear) {
      setLayout(businessData.listingsApear);
    }

    // Start lists ordering
    const listsContainer = document.querySelector(
      ".BusinessPageEditorListingsListsCon1"
    );
    const orderButtons = document.querySelectorAll(
      ".BusinessPageEditorListingsListOrder"
    );

    let draggingItem = null;
    let touchStartY = 0;

    // Add event listeners for both mouse and touch events
    orderButtons.forEach((button) => {
      button.addEventListener("mousedown", startDrag);
      button.addEventListener("touchstart", startDrag);
    });

    function startDrag(e) {
      e.preventDefault();
      const listItem = e.target.closest(".BusinessPageEditorListingsListsCon2");
      draggingItem = listItem;
      draggingItem.classList.add("dragging");
      touchStartY = e.touches ? e.touches[0].clientY : e.clientY;

      listsContainer.addEventListener("mousemove", handleDrag);
      listsContainer.addEventListener("touchmove", handleDrag);
      document.addEventListener("mouseup", endDrag);
      document.addEventListener("touchend", endDrag);
    }

    function handleDrag(e) {
      e.preventDefault();
      const currentY = e.touches ? e.touches[0].clientY : e.clientY;
      const afterElement = getDragAfterElement(listsContainer, currentY);
      if (afterElement == null) {
        listsContainer.appendChild(draggingItem);
      } else {
        listsContainer.insertBefore(draggingItem, afterElement);
      }
    }

    const endDrag = () => {
      if (draggingItem) {
        draggingItem.classList.remove("dragging");

        this._getTheListsData();
        draggingItem = null;
      }
      listsContainer.removeEventListener("mousemove", handleDrag);
      listsContainer.removeEventListener("touchmove", handleDrag);
      document.removeEventListener("mouseup", endDrag);
      document.removeEventListener("touchend", endDrag);
    };

    function getDragAfterElement(container, y) {
      const draggableElements = [
        ...container.querySelectorAll(
          ".BusinessPageEditorListingsListsCon2:not(.dragging)"
        ),
      ];
      return draggableElements.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect();
          const offset = y - box.top - box.height / 2;
          if (offset < 0 && offset > closest.offset) {
            return { offset: offset, element: child };
          } else {
            return closest;
          }
        },
        { offset: Number.NEGATIVE_INFINITY }
      ).element;
    }

    //Start delete list
    const deleteButtons = document.querySelectorAll(
      ".BusinessPageEditorListingsListDelete"
    );

    deleteButtons.forEach((button) => {
      button.addEventListener("click", () => {
        // Find the corresponding input element for the list name
        const listContainer = button.closest(
          ".BusinessPageEditorListingsListsCon2"
        );
        const listNameInput = listContainer.querySelector(
          ".BusinessPageEditorListingsListsInputName"
        );
        const listName = listNameInput.value.trim();

        // Confirm with the user
        const userConfirmed = confirm(
          `¿Estás seguro de que deseas eliminar la lista "${listName}"?`
        );

        if (userConfirmed) {
          // Remove the corresponding list container
          listContainer.remove();
          this._getTheListsData();
          alert(`La lista "${listName}" ha sido eliminada.`);
        } else {
          alert(`La eliminación de la lista "${listName}" fue cancelada.`);
        }
      });
    });
    //

    // Start Hide Tags options
    const typeSelects = document.querySelectorAll(
      "#BusinessPageEditorListingsListsListTypeSelect"
    );

    typeSelects.forEach((select) => {
      select.addEventListener("change", function () {
        const listContainer = select.closest(
          ".BusinessPageEditorListingsListsCon2"
        );
        const tagContainer = listContainer
          .querySelector('[id="BusinessPageEditorListingsListsListTagSelect"]')
          .closest(".BusinessPageEditorListingsListsCon5");

        // Toggle visibility based on selected value
        if (select.value === "auto") {
          tagContainer.style.display = "";
        } else {
          tagContainer.style.display = "none";
        }
      });

      // Trigger the change event on load to set initial visibility
      select.dispatchEvent(new Event("change"));
    });

    this._getTheListsDataActive();

    // Start New List
    const BusinessPageEditorListingsListsButtonNewList =
      document.getElementById("BusinessPageEditorListingsListsButtonNewList");

    BusinessPageEditorListingsListsButtonNewList.addEventListener(
      "click",
      () => {
        const newList = {
          name: "Nombre de lista",
          type: "auto",
          tag: "escoge",
        };
        businessData.lists.push(newList);
        console.log("clicked");
        console.log(businessData.lists);
        this._setVisualizer(this.#editCurrent);
        editorTools._init("listings", businessData, tags);
        this._hideShowTopEditorTools("hide");
        // this._getTheListsData();
      }
    );
  }

  _eventBusinessInfo() {
    const BusinessPageEditorLocationDescriptionInput = document.getElementById(
      "BusinessPageEditorLocationDescriptionInput"
    );
    BusinessPageEditorLocationDescriptionInput.addEventListener("keyup", () => {
      businessData.place.description =
        BusinessPageEditorLocationDescriptionInput.value.trim();
    });

    // Start contacts data

    const inputs = document.querySelectorAll(
      ".BusinessPageEditorPortionCon3 .InputMiniCon2"
    );

    const createContactsObject = () => {
      const inputs = document.querySelectorAll(
        ".BusinessPageEditorPortionCon3 .InputMiniCon2"
      );

      // Initialize the contacts object without predefined keys
      const contacts = {};

      // Map labels to contact types
      const labelToTypeMap = {
        "Numero de teléfono para llamadas": "call",
        "Numero de teléfono para WhatsApp": "whatsApp",
        "Correo electrónico": "email",
        "Página web": "website",
      };

      inputs.forEach((inputContainer) => {
        const label = inputContainer
          .querySelector(".InputMiniLabel")
          .textContent.trim();
        const input = inputContainer.querySelector("input");

        if (input && input.value.trim() !== "") {
          const type = labelToTypeMap[label];
          if (type) {
            contacts[type] = { contact: input.value.trim() };
          }
        }
      });

      businessData.contacts = contacts;
      console.log(contacts);
      console.log(businessData.contacts);
      this._setVisualizer(this.#editCurrent);
    };

    inputs.forEach((input) => {
      input.addEventListener("keyup", () => {
        const result = createContactsObject();
      });
    });

    // Start about
    const BusinessEditorInfoTextAreaCon1 = document.getElementById(
      "BusinessEditorInfoTextAreaCon1"
    );
    BusinessEditorInfoTextAreaCon1.addEventListener("keyup", () => {
      businessData.about = BusinessEditorInfoTextAreaCon1.value.trim();
      this._setVisualizer(this.#editCurrent);
    });

    // Start Map
    this._justIntergratingTheMap();
  }

  _colorSetter(color, whatForColor) {
    console.log(color, whatForColor);
    if (whatForColor === "headerPrimary") {
      businessData.colors.headerPrimary = color;
    }
    if (whatForColor === "headerSecondary") {
      businessData.colors.headerSecondary = color;
    }
    if (whatForColor === "packTitles") {
      businessData.colors.packTitles = color;
    }
    if (whatForColor === "packTitlesHighlight") {
      businessData.colors.packTitlesHighlight = color;
    }
    if (whatForColor === "itemBackgroundPrimary") {
      businessData.colors.itemBackgroundPrimary = color;
    }
    if (whatForColor === "itemBackgroundSecondary") {
      businessData.colors.itemBackgroundSecondary = color;
    }
    if (whatForColor === "itemTextPrimary") {
      businessData.colors.itemTextPrimary = color;
    }
    if (whatForColor === "itemTextFour") {
      businessData.colors.itemTextFour = color;
    }
    if (whatForColor === "itemTextSecondary") {
      businessData.colors.itemTextSecondary = color;
    }
    if (whatForColor === "itemTextTerteriary") {
      businessData.colors.itemTextTerteriary = color;
    }
    if (whatForColor === "itemHighlight") {
      businessData.colors.itemHighlight = color;
    }
    if (whatForColor === "itemHighlightText") {
      businessData.colors.itemHighlightText = color;
    }

    console.log(businessData.colors);
    this._setVisualizer(this.#editCurrent);
  }

  _justIntergratingTheMap() {
    console.log("Integrating the map");
    const TheLocationInputForLocationBusinessPageEditorLocationInput =
      document.getElementById("BusinessPageEditorLocationInput");

    function loadGoogleMaps(apiKey) {
      return new Promise((resolve, reject) => {
        if (document.getElementById("googleMapsScript")) {
          resolve(); // If already loaded, resolve immediately
          return;
        }

        const script = document.createElement("script");
        script.id = "googleMapsScript";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onerror = () =>
          reject(new Error("Failed to load Google Maps API"));
        document.body.appendChild(script);

        window.initMap = resolve;
      });
    }

    const initMap = async () => {
      try {
        const apiKey = "AIzaSyAiZwvYIzOaWxCfghLdC0OZWhBKe5TruEk"; // Replace with your actual API key
        await loadGoogleMaps(apiKey);

        const mapContainer = document.getElementById(
          "BusinessPageEditorMapContainer"
        );

        let latForStart = 20.6595382;
        let lngForStart = -103.3494376;

        if (businessData.place.lat || businessData.place.lng) {
          latForStart = businessData.place.lat;
          lngForStart = businessData.place.lng;
        }

        const map = new google.maps.Map(mapContainer, {
          center: { lat: latForStart, lng: lngForStart }, // Default center
          zoom: 13,
        });

        const geocoder = new google.maps.Geocoder();

        // Update input with address from map center
        map.addListener("idle", () => {
          const center = map.getCenter();
          geocoder.geocode({ location: center }, (results, status) => {
            if (status === "OK" && results[0]) {
              if (
                TheLocationInputForLocationBusinessPageEditorLocationInput.value.trim() ===
                ""
              )
                return;
              const locatedLocation = results[0].formatted_address;
              TheLocationInputForLocationBusinessPageEditorLocationInput.value =
                locatedLocation;
              businessData.place.name = locatedLocation;
              businessData.place.locationUrl = `https://www.google.com/maps?q=${center.lat()},${center.lng()}`;

              businessData.place.lat = center.lat();
              businessData.place.lng = center.lng();

              console.log(center.lat(), center.lng());
            } else {
              console.error("Geocoder failed:", status);
            }
          });
        });

        // Autocomplete for input field
        const input = document.getElementById(
          "BusinessPageEditorLocationInput"
        );
        const autocomplete = new google.maps.places.Autocomplete(input);

        autocomplete.addListener("place_changed", function () {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            map.setCenter(place.geometry.location);
            map.setZoom(15);
            new google.maps.Marker({
              position: place.geometry.location,
              map: map,
            });
            console.log(
              "Selected place from search:",
              place.name,
              place.geometry.location
            );
          } else {
            console.error("No details available for the selected place.");
          }
        });

        // Locate user's current position
        document
          .getElementById("BusinessEditorLocationMyLocationCon1")
          .addEventListener("click", () => {
            this._startLoadingEffect("BusinessEditorLocationMyLocationCon1");
            // toggleSpinner("start");
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };

                  map.setCenter(userLocation);
                  map.setZoom(15);

                  new google.maps.Marker({
                    position: userLocation,
                    map: map,
                  });

                  geocoder.geocode(
                    { location: userLocation },
                    (results, status) => {
                      this._startLoadingEffect(
                        "BusinessEditorLocationMyLocationCon1",
                        true
                      );
                      console.log(results[5].formatted_address);
                      if (status === "OK" && results[0]) {
                        const locatedLocation = results[0].formatted_address;

                        TheLocationInputForLocationBusinessPageEditorLocationInput.value =
                          locatedLocation;
                        businessData.place.name = locatedLocation;
                        businessData.place.locationUrl = `https://www.google.com/maps?q=${userLocation.lat},${userLocation.lng}`;
                        // toggleSpinner("stop");
                      } else {
                        console.error("Geocoder failed:", status);
                      }
                    }
                  );
                },
                (error) => {
                  console.error("Error getting location:", error);
                  alert(
                    "Unable to retrieve your location. Please enable location services."
                  );
                }
              );
            } else {
              alert("Geolocation is not supported by this browser.");
            }
          });
      } catch (error) {
        console.error("Error loading Google Maps:", error);
      }
    };

    // // Map initialization trigger
    // document
    //   .getElementById("BusinessPageEditorOpenMapButton")
    //   .addEventListener("click", initMap);

    document
      .getElementById("BusinessPageEditorLocationInput")
      .addEventListener("click", () => {
        const mapContainer = document.getElementById(
          "BusinessPageEditorMapContainer1"
        );
        const btnLocator = document.getElementById(
          "BusinessEditorLocationMyLocationCon1"
        );
        initMap();
        // mapContainer.classList.add("BusinessPageEditorMapContainer1");
        mapContainer.style.display = "";
        btnLocator.style.display = "";
      });
  }

  _eventBusinessHeader() {
    const BusinessEditorNameInput = document.getElementById(
      "BusinessEditorNameInput"
    );
    const BusinessEditorShorDescriptionInput = document.getElementById(
      "BusinessEditorShortDescriptionInput"
    );
    BusinessEditorNameInput.addEventListener("keyup", () => {
      businessData.name = BusinessEditorNameInput.value.trim();
      this._setVisualizer(this.#editCurrent);
    });
    BusinessEditorShorDescriptionInput.addEventListener("keyup", () => {
      businessData.shortDescription =
        BusinessEditorShorDescriptionInput.value.trim();
      this._setVisualizer(this.#editCurrent);
    });

    const handleImageUpload = (imgInputId, imgPutInto) => {
      const fileInput = document.getElementById(imgInputId);
      const outputElement = document.getElementById(imgPutInto);

      fileInput.addEventListener("change", () => {
        const file = fileInput.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (imgInputId === "BusinessPageEditorSelectImgBackground") {
              businessData.backgroundImg = e.target.result;
              this._setimageChangeState("background");
            }
            if (imgInputId === "BusinessPageEditorSelectImgProfile") {
              businessData.profileImg = e.target.result;
              this._setimageChangeState("profile");
            }
            this._setVisualizer(this.#editCurrent);
            // outputElement.src = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      });
    };

    // Initialize the function with IDs

    handleImageUpload(
      "BusinessPageEditorSelectImgBackground",
      "BusinessEditorBusinessHeaderCon6BackgroundImgCon"
    );
    handleImageUpload(
      "BusinessPageEditorSelectImgProfile",
      "BusinessEditorBusinessHeaderCon6ProfileImgCon"
    );
  }

  _setMainEditor(edit) {
    const container = document.getElementById("MainAppContentContainer");

    const busineesPageEditorHtml = generateHtml._initialPopulation();

    container.insertAdjacentHTML("beforeend", busineesPageEditorHtml);
    this._setVisualizer(edit);
  }
}
export default new BusinessPageEditor();
