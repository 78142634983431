import GenerateHtml from "./generate-html";
// import { headerIn, headerOut } from "../header";

// import handleAccount from "../register/handle-account";
import nav from "../home-screen/nav";
import firebase from "../firebase";
import ManageAccountAndProfileChanges from "./account-profile-change";
import yourListings from "./your-listings";
// import orders from "../orders/orders";
import installApp from "../install-app";
import wallet from "../wallet/wallet";
import homeScreen from "../home-screen/home-screen";
import busineOwn from "../business-page-own-veiw/busine-own";
import saved from "../saved-and-Followed/saved";
import help from "../help/help";
import GENRALHELPERS from "../GENRAL-HELPERS";
// import startUp from "../main-start-up-screen/start-up";

class Menu {
  #accountState;
  constructor() {}

  async _setMenu() {
    const container = document.getElementById("MainAppContentContainer");

    const consToRemove = container.querySelectorAll(".MainMenuCon1A");
    if (consToRemove.length > 0) {
      consToRemove.forEach((con) => {
        con.remove();
      });
    }
    this.#accountState = firebase._getLatestCurrentUserData();

    let userName = "Perfil de vendedor";
    let email = "Tu cuenta";

    if (this.#accountState) {
      userName = this.#accountState.userName;
      email = "Cuenta:" + " " + this.#accountState.email;
    }
    const menuScreen = GenerateHtml._mainMenuGenerate(userName, email);
    container.insertAdjacentHTML("beforeend", menuScreen);
    const MainMenuCon2 = document.querySelector(".MainMenuCon2B");
    setTimeout(() => {
      MainMenuCon2.style.left = "0%";
    }, 1);
    const MainMenuCon1A = document.querySelector(".MainMenuCon1A");
    // MainMenuCon1A.innerHTML = "";

    let startX = 0; // Starting X coordinate
    let startY = 0; // Starting Y coordinate
    let currentX = 0; // Current X coordinate
    let isSwiping = false; // Flag to track if a swipe is happening
    let isClick = false; // Flag to track if it's a click event

    MainMenuCon1A.addEventListener("touchstart", (e) => {
      // Record the initial touch position
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      isSwiping = true; // Start tracking swipe
      isClick = true; // Assume it's a click until proven otherwise
      MainMenuCon2.style.transition = "none"; // Disable smooth transition during swipe
    });

    MainMenuCon1A.addEventListener("touchmove", (e) => {
      if (!isSwiping) return;

      isClick = false; // Not a click anymore since there's movement
      currentX = e.touches[0].clientX;
      const deltaX = startX - currentX;
      const deltaY = Math.abs(e.touches[0].clientY - startY);

      // Ensure the swipe is horizontal
      if (deltaY < 20 && deltaX > 0) {
        // Swipe must be horizontal and to the left
        const leftPosition = Math.min(0, -deltaX); // Calculate new position (clamp to 0%)
        MainMenuCon2.style.left = `${leftPosition}%`; // Move MainMenuCon2 as user swipes
      }
    });

    MainMenuCon1A.addEventListener("touchend", () => {
      if (!isSwiping) return;

      const deltaX = startX - currentX;
      isSwiping = false;
      MainMenuCon2.style.transition = "left 0.3s ease"; // Re-enable smooth transition

      // If it was a click, do nothing
      if (isClick) return;

      // Check swipe length and finalize position
      if (deltaX > 100) {
        MainMenuCon2.style.left = "0%"; // Keep MainMenuCon2 hidden
        setTimeout(() => {
          MainMenuCon1A.remove();
        }, 1); // Adjust time if needed
        nav._changeNav("last");
      } else {
        MainMenuCon2.style.left = "0%"; // Reset to the initial position
      }
    });
    if (this.#accountState) {
      if (this.#accountState.profileImageUrl) {
        const ProfileFotoInMenuTop = document.getElementById(
          "ProfileFotoInMenuTop"
        );
        const displayProfileImg = async () => {
          const profileImgUrl = this.#accountState.profileImageUrl;
          // const profileImgUrl = await firebase._getImageUrl(
          //   this.#accountState.profileImageUrl
          // );
          ProfileFotoInMenuTop.src = profileImgUrl;
        };
        displayProfileImg();
      }
    }
    this._eventsMenu();
  }

  _close() {
    const MainMenuCon1A = document.querySelector(".MainMenuCon1A");
    MainMenuCon1A.remove();
    nav._changeNav("last");
  }

  _eventsMenu() {
    const PreInstallConInMenu = document.getElementById("PreInstallConInMenu");
    const MainMenuCon3OpenProfile = document.getElementById(
      "MainMenuCon3OpenProfile"
    );
    GENRALHELPERS._animateCTA("MainMenuCon3OpenProfile");

    const isInstalled = installApp._getNeedsInstalation();
    if (isInstalled) {
      PreInstallConInMenu.classList.add("PrePageConForInstallationConDisplayB");
      const ButtonInstallAppInMenu = document.getElementById(
        "ButtonInstallAppInMenu"
      );
      ButtonInstallAppInMenu.addEventListener("click", () => {
        this._close();
        installApp._installNow();
      });
    } else {
      PreInstallConInMenu.classList.remove(
        "PrePageConForInstallationConDisplayB"
      );
    }

    const openButtonFromMenuAll = document.querySelectorAll(
      ".openButtonFromMenu"
    );

    openButtonFromMenuAll.forEach((button) => {
      button.addEventListener("click", (e) => {
        console.log(button);
        const dataOpen = e.target.closest(".openButtonFromMenu").dataset.open;
        console.log(dataOpen);

        if (dataOpen === "ownlistings") {
          yourListings._open();
          return;
        }
        if (dataOpen === "wallet") {
          wallet._open();
          this._close();
          nav._changeNav("Wallet");
          return;
        }
        if (dataOpen === "home") {
          homeScreen._init();
          this._close();
          nav._changeNav("Home");
          return;
        }
        if (dataOpen === "business") {
          busineOwn._init();
          this._close();
          nav._changeNav("Business");
          return;
        }
        if (dataOpen === "saved") {
          saved._open();
          this._close();
          nav._changeNav("Saved");
          return;
        }
        if (dataOpen === "notifications") {
          // about._open();
          alert("Proximamente. Esta función aún no está lista.");
          // this._close();
          return;
        }
        if (dataOpen === "about") {
          // about._open();
          alert("Proximamente. Esta función aún no está lista.");
          // this._close();
          return;
        }
        if (dataOpen === "help") {
          // help._open();
          help._open();
          // this._close();
          return;
        }
        if (dataOpen === "moreapps") {
          // moreApps._open();
          alert("Proximamente. Esta función aún no está lista.");
          // this._close();
          return;
        }
      });
    });

    // const MainMenuCon6OpenListings = document.getElementById(
    //   "MainMenuCon6OpenListings"
    // );

    const ButtonCloseMenu = document.getElementById("ButtonCloseMenu");

    MainMenuCon3OpenProfile.addEventListener("click", (e) => {
      if (e.target.closest("#ProfileFotoInMenuTop")) return;
      ManageAccountAndProfileChanges._open();
    });
    // MainMenuCon6OpenListings.addEventListener("click", (e) => {
    //   if (e.target.closest("#ProfileFotoInMenuTop")) return;
    //   yourListings._open();
    // });
    ButtonCloseMenu.addEventListener("click", () => {
      this._close();
    });
  }
}

export default new Menu();
