import iconDeposit from "../../../Icons/icons-wallet/deposit.png";
import iconwithdraw from "../../../Icons/icons-wallet/withdraw.png";
import iconTransfer from "../../../Icons/icons-wallet/transfer.png";
import iconMore from "../../../Icons/icons-wallet/more.png";
import iconInProcess from "../../../Icons/icons-wallet/in-process.png";

import iconSuccessMini from "../../../Icons/icons-wallet/success-mini.png";
import iconSuccess from "../../../Icons/icons-wallet/success.png";
import iconErrorMini from "../../../Icons/icons-wallet/error-mini.png";
import iconErros from "../../../Icons/icons-wallet/error.png";
import iconSort from "../../../Icons/icons-wallet/sort.png";
import iconIn from "../../../Icons/icons-wallet/in.png";
import iconOut from "../../../Icons/icons-wallet/out.png";
import iconAhead from "../../../Icons/icons-wallet/ahead.png";
import iconBack from "../../../Icons/icons-wallet/back.png";
import iconPayPal from "../../../Icons/pay-options/paypal.png";
import iconMercadoPago from "../../../Icons/pay-options/mercado-pago.png";
import iconCard from "../../../Icons/pay-options/card.png";
import iconDelete from "../../../Icons/icons-red-danger/delete.png";

import GENRALHELPERS from "../GENRAL-HELPERS";

class generateHtml {
  _main() {
    return `
<div id="WalletCon1MainWalletCon" class="WalletCon1">
  <div class="WalletCon2">
    <div class="WalletCon3">
      <div class="WalletCon4">
        <div class="WalletCon5">
          <div class="WalletText1">Saldo actual</div>
          <div class="WalletCon6">
            <div class="WalletCon7">
              <div class="WalletText2">$</div>
              <div id="WalletCon1MainWalletConBalanceNow" class="WalletText2">0</div>
              <div id="WalletCon1MainWalletConBalanceNowCents" class="WalletText3">00</div>
            </div>
          </div>
        </div>
        <div data-helpwith="whatIsWallet" class="WalletCon8 openHelp">
          <div class="WalletText4">Acerca de 'Wallet'</div>
        </div>
      </div>
    </div>
    <div id="WalletCon1MainWalletConWalletMessage" class="WalletCon9" style="display: none">
      <div class="WalletCon10">
        <div id="WalletCon1MainWalletConWalletMessageText" class="WalletText5">Si ingresas mas de $500 a tu wallet ahora, te regalamos $50</div>
      </div>
    </div>
    <div class="WalletCon11">
      <div class="WalletCon12">
        <div data-action="open-deposit" class="WalletCon13 Action">
          <div class="WalletCon14">
            <img height="32px" src="${iconDeposit}" alt="" /> 
          </div>
          <div class="WalletCon15">
            <div class="WalletText6">Ingresar</div>
          </div>
        </div>
        <div data-action="open-transfer" class="WalletCon13 Action">
          <div class="WalletCon14">
            <img height="32px" src="${iconTransfer}" alt="" /> 
          </div>
          <div class="WalletCon15">
            <div class="WalletText6">Transferir</div>
          </div>
        </div>
        <div data-action="open-withdraw" class="WalletCon13 Action">
          <div class="WalletCon14">
            <img height="32px" src="${iconwithdraw}" alt="" /> 
          </div>
          <div class="WalletCon15">
            <div class="WalletText6">Retirar</div>
          </div>
        </div>
        <div data-action="open-more" class="WalletCon13 Action">
          <div class="WalletCon14">
            <img height="32px" src="${iconMore}" alt="" /> 
          </div>
          <div class="WalletCon15">
            <div class="WalletText6">Mas</div>
          </div>
        </div>
      </div>
      <div class="LogInCon6">
        <div data-helpwith="useWallet" class="WalletCon8 openHelp">
          <div class="WalletText4">¿Como puedo usar mi wallet?</div>
        </div>
      </div>
    </div>
    <div class="WalletCon11">
        <div id="WalletCon12BPendingPaymentsCon" class="WalletCon12B">
        </div>
    </div>
  </div>
  <div class="WalletCon16">
    <div class="WalletCon17">
      <div class="WalletText1">Tus movimientos</div>
        <img height="20px" src="${iconSort}" alt="" /> 
    </div>
    <div id="WalletCon1MainWalletConLogsInsert" class="WalletCon18 WalletCon18MaxHeight">
    </div>
    <div id="WalletCon1MainWalletConLogsInsertViewMoreButton" class="WalletCon19">
      <div class="WalletText13">Ver mas</div>
    </div>
  </div>
  
</div>


<div id="WalletConOverlayConfirmPayment" class="WalletConOverlay" style="display: none">
  <div class="WalletCon20A">
  <div class="WalletCon20">
    <div class="WalletCon21">
      <div class="Wallet2Text1">Confirmar pago con tu wallet</div>
    </div>
    <div class="WalletCon22">
      <div class="WalletCon23">
        <div id="WalletConOverlayConfirmPaymentAmount" class="Wallet2Text2"></div>
      </div>
    </div>
    <div class="WalletCon24">
      <div class="WalletCon25">
        <img id="WalletConOverlayConfirmPaymentIcon" height="12px" src="${iconSuccessMini}" alt="" /> 
        <div class="WalletCon26">
          <div id="WalletConOverlayConfirmPaymentMessage1" class="Wallet2Text3"></div>
        </div>
      </div>
      <div id="WalletConOverlayConfirmPaymentMessage2Con" class="WalletCon27">
        <div id="WalletConOverlayConfirmPaymentMessage2" class="Wallet2Text4"></div>
      </div>
    </div>
    <div id="WalletConOverlayConfirmPaymentButtonPayNow" class="WalletCon28"  style="display: none">
      <div class="Wallet2Text5">Comfirmar y pagar pedido ahora</div>
    </div>
    <div id="WalletConOverlayConfirmPaymentButtonDeposit" class="WalletCon29"  style="display: none">
      <div class="Wallet2Text6"></div>
        <img height="20px" src="${iconAhead}" alt="" /> 
    </div>
    <div id="WalletConOverlayConfirmPaymentButtonBack" class="WalletCon30">
        <img height="20px" src="${iconBack}" alt="" /> 
        <div class="Wallet2Text6B">Cerrar pago</div>
    </div>
  </div>
  </div>
</div>


<div id="WalletConOverlayMoneyIn" class="WalletConOverlay" style="display: none">
  <div class="WalletCon20A">
<div class="WalletCon20">
  <div class="WalletCon21">
    <div class="Wallet2Text1">Monto a ingresar a tu Wallet</div>
  </div>
  <div class="WalletConMoneyIn1">
    <input id="WalletConOverlayMoneyInInputAmount" type="text" inputmode="numeric" class="Wallet2Text2" placeholder="MXN" />
    <div id="WalletConOverlayMoneyInErrorMessage" class="WalletCon25" style="display: none">
      <img id="WalletConOverlayConfirmPaymentIcon" height="12px" src="${iconErrorMini}" alt="" /> 
      <div class="WalletCon26">
        <div class="Wallet2Text3 Wallet2Text3Error">El monto mínimo es $10</div>
      </div>
    </div>
    <label for="WalletConOverlayMoneyInInputAmount" id="WalletConOverlayMoneyInButtonChangeAmount" class="WalletConMoneyIn2">
      <div class="WalletConMoneyInText1">Cambiar</div>
    </label>
  </div>
  <div class="WalletConMoneyIn3">
    <div class="WalletConMoneyIn4">
      <div class="WalletText1">Ingresar por medio de</div>
    </div>
    <div class="WalletConMoneyIn5 WalletConMoneyInButtonCon1NoneAct">
      <div data-option="card" class="WalletConMoneyInButtonCon1 ">
        <div class="WalletConMoneyInButtonCon2">
          <img height="20px" src="${iconCard}" alt="" /> 
          <div class="WalletConMoneyInButtonText1">Target crédito / débito</div>
        </div>
      </div>
      <div data-option="paypal" class="WalletConMoneyInButtonCon1 ">
        <div class="WalletConMoneyInButtonCon2">
          <img height="20px" src="${iconPayPal}" alt="" /> 
          <div class="WalletConMoneyInButtonText2">PayPal</div>
        </div>
      </div>
      <div data-option="mercadopago" class="WalletConMoneyInButtonCon1" style="display: none">
        <div class="WalletConMoneyInButtonCon2">
          <img height="20px" src="${iconMercadoPago}" alt=""/> 
          <div class="WalletConMoneyInButtonText3">Mercado Pago</div>
        </div>
      </div>
    </div>
  </div>
  <div id="WalletConOverlayMoneyInButtonBack" class="WalletCon30">
    <div class="Wallet2Text6B">Cancelar</div>
  </div>
</div>
</div>
</div>





<div id="WalletConOverlayActivateWallet" class="WalletConOverlay" style="display: none">
  <div class="WalletCon20A">
  <div class="WalletCon20">
    <div class="WalletCon21">
      <div class="Wallet2Text1">Activa tu wallet ahora y disfruta de los benificios</div>
    </div>
    <div class="WalletCon22New">
        <div class="Wallet2Text2New">Tu nombre</div>
        <input id="WalletConOverlayActivateWalletInputName" placeholder="Nombre aqui" class="WalletCon23New Wallet2Text2New" />
        <div id="WalletConOverlayActivateWalletInputErrorMessage" class="Wallet2Text3 Wallet2Text3Error"></div>
    </div>
    <div id="WalletConTermsConditionsNew" class="WalletConTermsConditionsNew">
        <div class="WalletConTermsConditionsNewText">Al activar tu wallet, aceptas todos los condiciones y términos de uso. </div>
    </div>
    <div class="WalletCon24">
      <div class="WalletCon25">
        <img id="WalletConOverlayConfirmPaymentIcon" height="12px" src="${iconSuccessMini}" alt="" /> 
        <div class="WalletCon26">
          <div class="Wallet2Text3 Wallet2Text3Success">Seguridad</div>
        </div>
      </div>
      <div class="WalletCon27 WalletCon27Succes">
        <div class="Wallet2Text4">Para cada pago, solicitamos tu confirmación</div>
      </div>
    </div>
    <div id="WalletConOverlayActivateWalletButtonActivate" class="WalletCon28">
      <div class="Wallet2Text5">Activar tu Wallet ahora</div>
    </div>
    <div id="WalletConOverlayActivateWalletButtonCancel" class="WalletCon30">
        <div class="Wallet2Text6B">Cancelar</div>
    </div>
      <div class="LogInCon6">
        <div data-helpwith="useWallet" class="WalletCon8 openHelp">
          <div class="WalletText4">¿Como puedo activar mi Wallet?</div>
        </div>
      </div>
  </div>
  </div>
</div>





<div id="WalletConOverlayPaymentStatus" class="WalletConOverlay" style="display: none">
    <div class="OwnBusinessCon1">
        <div class="WalletCon30ASuccess">
            <div id="WalletCon30Success" class="WalletCon30Success" style="display: none">
                <div class="WalletCon31Success">
                <img id="WalletCon30ASuccessIcon" height="64px" src="${iconInProcess}" alt="" /> 
                </div>
                <div class="WalletCon32Success">
                    <p id="WalletCon30ASuccessMessage1" class="Wallet3Text1Success"></p>
                    <p id="WalletCon30ASuccessMessage2" class="Wallet3Text2Success"></p>
                </div>
            </div>
            <div id="WalletCon30ASuccessButton" style="display: none" class="Wallet3ButtonClose">
                Cerrar
            </div>
        </div>
        <div class="WalletCon30ProcessingVideoCon" style="display: none">Video goes here</div>
    </div>
</div>
        `;
  }

  _pendingOrders(data) {
    console.log(data);
    return `
            <div data-id="${data.id}" data-amount="${
      data.amount
    }" class="WalletConPedingOrder1">
                <div class="WalletConPedingOrder4">
                    <div class="WalletCon25">
                        <img id="WalletConOverlayConfirmPaymentIcon" height="12px" src="${iconErrorMini}" alt="" /> 
                        <div class="WalletCon26">
                            <div class="Wallet2Text3 Wallet2Text3Error">Pedido pendiente</div>
                        </div>
                    </div>
                    <img class="WalletCon25OrderButtonDelete" id="WalletConOverlayConfirmPaymentIcon" height="20px" src="${iconDelete}" alt="" /> 
                </div>
                <div class="WalletConPedingOrder2">
                    <div class="WalletText1">Monto a pagar</div>
                    <div class="Wallet2Text5">$${GENRALHELPERS._formatToPrice(
                      data.amount
                    )}</div>
                </div>
                <div class="WalletConPedingOrder3">
                    <div class="WalletCon30 WalletCon25OrderButtonView">
                        <div class="Wallet2Text6B">Ver</div>
                    </div>
                    <div class="WalletCon28 WalletCon25OrderButtonPay">
                        <div class="Wallet2Text5 ">Pagar</div>
                    </div>
                </div>
            </div>
        `;
  }

  _logs(data) {
    let iconInOut = iconIn;
    let backgroundClass = "WalletMoveCon1In";
    let amountClass = "WalletText9In";
    let tagClass = "WalletMoveCon8In";
    if (data.icon === "out") {
      iconInOut = iconOut;
      backgroundClass = "WalletMoveCon1Out";
      amountClass = "WalletText9Out";
      tagClass = "WalletMoveCon8Out";
    }
    if (data.icon === "error") {
      iconInOut = iconErrorMini;
      backgroundClass = "WalletMoveCon1Out";
      amountClass = "WalletText9Out";
      tagClass = "WalletMoveCon8Out";
    }
    // let time = data.time;
    let time = GENRALHELPERS._convertServerTimestampToReadableTime(data.time);

    return `
        <div class="WalletMoveCon1 ${backgroundClass}">
        <div class="WalletMoveCon2">
          <div class="WalletMoveCon3">
            <div class="WalletMoveCon4">
              <div class="WalletText7">${data.name}</div>
              <div class="WalletMoveCon5">
                <div class="WalletText8">${data.description}</div>
              </div>
            </div>
            <div class="WalletMoveCon6">
              <div class="WalletText9 ${amountClass}">$${data.amount}</div>
            <img height="20px" src="${iconInOut}" alt="" /> 
            </div>
          </div>
          <div class="WalletMoveCon7">
            <div class="WalletMoveCon8 ${tagClass}">
              <div class="WalletText10">${data.tagName}</div>
            </div>
            <div class="WalletText11">${time}</div>
          </div>
        </div>
      </div>
        `;
  }
}

export default new generateHtml();
