import iconNavActHome from "../../../Icons/nav-icons/active/home.png";
import iconNavActSaved from "../../../Icons/nav-icons/active/saved.png";
import iconNavActWallet from "../../../Icons/nav-icons/active/wallet.png";
import iconNavActBusiness from "../../../Icons/nav-icons/active/business.png";
import iconNavActMenu from "../../../Icons/nav-icons/active/menu.png";
import iconNavHome from "../../../Icons/nav-icons/none-active/home.png";
import iconNavSaved from "../../../Icons/nav-icons/none-active/saved.png";
import iconNavWallet from "../../../Icons/nav-icons/none-active/wallet.png";
import iconNavBusiness from "../../../Icons/nav-icons/none-active/business.png";
import iconNavMenu from "../../../Icons/nav-icons/none-active/menu.png";
import homeScreen from "./home-screen";
import BusinessOwn from "../business-page-own-veiw/busine-own";

// import BusinessPageEditor from "../business-page-editor/business-page-editor";

import Menu from "../main-menu/menu";
import wallet from "../wallet/wallet";
import { doc } from "firebase/firestore";
import firebase from "../firebase";
import saved from "../saved-and-Followed/saved";

class Nav {
  #navWas = "Home";
  #navIs = "Home";
  constructor() {
    this.icons = {
      Home: { active: iconNavActHome, inactive: iconNavHome },
      Saved: { active: iconNavActSaved, inactive: iconNavSaved },
      Wallet: { active: iconNavActWallet, inactive: iconNavWallet },
      Business: { active: iconNavActBusiness, inactive: iconNavBusiness },
      Menu: { active: iconNavActMenu, inactive: iconNavMenu },
    };
  }

  _changeNav(menuOptionIn) {
    const navTaps = document.querySelectorAll(".NavBarCon2");
    let menuOption = menuOptionIn;

    if (menuOptionIn === "last") {
      menuOption = this.#navWas;
    }
    navTaps.forEach((navTap) => {
      const navIcon = navTap.querySelector(".NavBarIconCon");
      const NavText = navTap.querySelector(".NavBarText");

      if (navIcon && NavText) {
        if (navTap.dataset.menuoption === menuOption) {
          navIcon.src = this.icons[menuOption].active;
          NavText.classList.add("NavBarTextActive");
        } else {
          navIcon.src = this.icons[navTap.dataset.menuoption].inactive;
          NavText.classList.remove("NavBarTextActive");
        }
      }
    });
  }

  _blockNav(block) {
    const HeaderCon1BolckAction = document.getElementById(
      "HeaderCon1BolckAction"
    );
    const NavBarCon1BlockNav = document.getElementById("NavBarCon1BlockNav");
    if (block) {
      HeaderCon1BolckAction.style.display = "flex";
      NavBarCon1BlockNav.style.display = "flex";
    } else {
      HeaderCon1BolckAction.style.display = "none";
      NavBarCon1BlockNav.style.display = "none";
    }
  }

  _init() {
    const navTaps = document.querySelectorAll(".NavBarCon2");

    navTaps.forEach((navTap) => {
      navTap.addEventListener("click", () => {
        const menuOption = navTap.dataset.menuoption;
        this._changeNav(menuOption);

        if (this.#navIs !== "Menu") {
          console.log("here this time");
          this.#navWas = this.#navIs;
        }
        this.#navIs = menuOption;

        if (menuOption === "Menu") {
          Menu._setMenu();
        }
        if (menuOption === "Home") {
          homeScreen._init();
        }
        if (menuOption === "Business") {
          BusinessOwn._init();
        }
        if (menuOption === "Wallet") {
          wallet._open();
          console.log("wallet now");
        }
        if (menuOption === "Saved") {
          saved._open();
        }
      });
    });
    this._changeNav("Home");
  }
}

export default new Nav();
