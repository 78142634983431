const MainOneOnOverTheOther = document.getElementById("MainOneOnOverTheOther");

class StackDisplay {
  #initiated = false;
  #lastHtmlInStack;
  _display(html) {
    if (this.#lastHtmlInStack === html) {
      console.log("same");
    }
    this.#lastHtmlInStack = html;

    MainOneOnOverTheOther.style.display = "flex";

    MainOneOnOverTheOther.insertAdjacentHTML(
      "beforeend",
      this._generateWraper(html)
    );
    if (!this.#initiated) {
      this._events();
      this.#initiated = true;
    }
  }

  _generateWraper(html) {
    return `
        <div class="MainOneOnOverTheOtherInner">${html}</div>
    `;
  }

  _swipeAction() {
    console.log("Valid swipe");
    const MainOneOnOverTheOtherInnerAll = document.querySelectorAll(
      ".MainOneOnOverTheOtherInner"
    );

    MainOneOnOverTheOtherInnerAll.forEach((con, i, arr) => {
      if (i === MainOneOnOverTheOtherInnerAll.length - 1) {
        con.classList.add("shrink-and-remove");

        // Wait for the animation to complete before removing the element
        con.addEventListener("animationend", () => {
          con.remove();

          // Check if the container is empty
          if (MainOneOnOverTheOther.childElementCount === 0) {
            console.log("Hiden stack");
            console.log("MainOneOnOverTheOther is empty");
            MainOneOnOverTheOther.style.display = "";
          }
        });
      }
    });
  }

  _initSwipe() {
    let startX, startTime;
    let notValid = false;

    const MIN_SWIPE_DISTANCE = 50; // Minimum swipe distance in px
    const MAX_SWIPE_TIME = 800; // Maximum swipe duration in ms
    const LEFT_EDGE_MARGIN = 30; // Margin from the left edge in px

    const detectSwipe = (event) => {
      const touch = event.changedTouches[0];
      const endX = touch.pageX;
      const endTime = new Date().getTime();

      const distance = endX - startX;
      const duration = endTime - startTime;

      if (distance >= MIN_SWIPE_DISTANCE && duration <= MAX_SWIPE_TIME) {
        // Swipe is valid, trigger the action
        this._swipeAction();
      }
    };

    function touchStartHandler(event) {
      const touch = event.touches[0];
      startX = touch.pageX;
      startTime = new Date().getTime();

      // Only proceed if the swipe starts within the 10px margin from the left
      if (startX > LEFT_EDGE_MARGIN) {
        notValid = true;
        return; // Ignore this touch start if it's not within the margin
      } else {
        notValid = false;
      }
    }

    function touchEndHandler(event) {
      if (notValid) return;
      detectSwipe.call(this, event);
    }

    // Bind event listeners
    document.addEventListener("touchstart", touchStartHandler, false);
    document.addEventListener("touchend", touchEndHandler, false);
  }

  _events() {
    this._initSwipe();
    // MainOneOnOverTheOther.addEventListener("click", (e) => {
    //   const closestCloseButtonCheck = e.target.closest(
    //     ".CloseCurrentStackPageButton"
    //   );

    //   if (closestCloseButtonCheck) {
    //     console.log("closed");
    //     e.target.closest(".MainOneOnOverTheOtherInner").remove();
    //   }

    //   if (MainOneOnOverTheOther.childElementCount === 0) {
    //     console.log("Hiden stack");
    //     console.log("MainOneOnOverTheOther is empty");
    //     MainOneOnOverTheOther.style.display = "";
    //   }
    // });
    MainOneOnOverTheOther.addEventListener("click", (e) => {
      const closestCloseButtonCheck = e.target.closest(
        ".CloseCurrentStackPageButton"
      );

      if (closestCloseButtonCheck) {
        console.log("closed");

        const elementToRemove = e.target.closest(".MainOneOnOverTheOtherInner");

        // Add a class for the shrinking animation
        elementToRemove.classList.add("shrink-and-remove");

        // Wait for the animation to complete before removing the element
        elementToRemove.addEventListener("animationend", () => {
          elementToRemove.remove();

          // Check if the container is empty
          if (MainOneOnOverTheOther.childElementCount === 0) {
            console.log("Hiden stack");
            console.log("MainOneOnOverTheOther is empty");
            MainOneOnOverTheOther.style.display = "";
          }
        });
      }
    });
  }
}

export default new StackDisplay();
