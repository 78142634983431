class terms {
  constructor() {
    this._events();
  }
  _close() {
    const TermsConditions = document.getElementById("TermsConditions");
    TermsConditions.style.display = "none";
  }
  _open() {
    const TermsConditions = document.getElementById("TermsConditions");
    TermsConditions.style.display = "";
  }

  _events() {
    const ButtonCloseTermsConditions = document.getElementById(
      "ButtonCloseTermsConditions"
    );
    ButtonCloseTermsConditions.addEventListener("click", () => {
      this._close();
    });
  }
}

export default new terms();
