const ratedObj = {
  ratedAs: "",
  ratedAt: 0,
  comment: "",
};

class rateNow {
  constructor() {
    this._events();
    this._selectRatingActions();
  }
  _close() {
    ratedObj.ratedAs = "";
    ratedObj.ratedAt = 0;
    const RatingNowCon = document.getElementById("RatingNowCon");
    RatingNowCon.style.display = "none";
  }
  _open() {
    ratedObj.ratedAs = "";
    ratedObj.ratedAt = 0;
    const RatingNowCon = document.getElementById("RatingNowCon");
    RatingNowCon.style.display = "";
  }

  _selectRatingActions() {
    const ButtonSubmitRating = document.getElementById("ButtonSubmitRating");
    const RatingSystemConSelectRatingAll = document.querySelectorAll(
      ".RatingSystemConSelectRating"
    );
    const RatingSystemSelectedMessage = document.getElementById(
      "RatingSystemSelectedMessage"
    );
    const RatingSystemCommentTextArea = document.getElementById(
      "RatingSystemCommentTextArea"
    );
    const SelectedSelectsArr = [
      "Muy malo",
      "Regular",
      "Aceptable",
      "Muy bueno",
      "Exelente",
    ];
    const SelectedMessagesArr = [
      "¿Como podemos mejorar? Describe tu opinión abajo.",
      "Necesitamos mejorar. Describe como podemos mejorar abajo.",
      "Cumplimos con lo esperado",
      "Superamos expectativas",
      "Somos altamente recomendables",
    ];

    const setRatedAtMessage = (rated) => {
      let messageNumber = Number(rated) - 1;
      ratedObj.ratedAs = SelectedSelectsArr[messageNumber];
      ratedObj.ratedAt = Number(rated);

      RatingSystemSelectedMessage.textContent =
        SelectedMessagesArr[messageNumber];

      if (ratedObj.ratedAt < 3) {
        if (ratedObj.comment.length > 23) {
          ButtonSubmitRating.classList.add("RatingSystemCon12Active");
        } else {
          ButtonSubmitRating.classList.remove("RatingSystemCon12Active");
        }
      } else {
        ButtonSubmitRating.classList.add("RatingSystemCon12Active");
      }
    };

    RatingSystemConSelectRatingAll.forEach((selector) => {
      selector.addEventListener("click", () => {
        RatingSystemConSelectRatingAll.forEach((selectorEach) => {
          selectorEach.classList.remove("RatingSystemConSelectRatingActive");
        });
        selector.classList.add("RatingSystemConSelectRatingActive");
        setRatedAtMessage(selector.dataset.ratedat);
      });
    });
    RatingSystemCommentTextArea.addEventListener("keyup", () => {
      ratedObj.comment = RatingSystemCommentTextArea.value.trim();
      //   if (ratedObj.ratedAt > 2 && ratedObj.comment.length > 23) {
      //     ButtonSubmitRating.classList.add("RatingSystemCon12Active");
      //   } else {
      //     ButtonSubmitRating.classList.remove("RatingSystemCon12Active");
      //   }
      if (ratedObj.ratedAt < 3) {
        if (ratedObj.comment.length > 23) {
          ButtonSubmitRating.classList.add("RatingSystemCon12Active");
        } else {
          ButtonSubmitRating.classList.remove("RatingSystemCon12Active");
        }
      } else {
        ButtonSubmitRating.classList.add("RatingSystemCon12Active");
      }
    });
  }

  _submitRating() {
    let alertMessage = "";

    if (ratedObj.ratedAt < 1) {
      alertMessage =
        "Debes Seleccionar tu opinion antes de Guardar esta calificación.";
    }

    if (
      ratedObj.ratedAt > 0 &&
      ratedObj.ratedAt < 3 &&
      ratedObj.comment.length < 24
    ) {
      alertMessage = `Selecionaste '${ratedObj.ratedAs}', y debes describir como podemos mejorar, o lo que no te gusta de nuestra aplicación.`;
    }

    if (
      ratedObj.ratedAt < 3 &&
      ratedObj.comment.length < 24 &&
      ratedObj.comment.length > 0
    ) {
      alertMessage = `Tu comentarion tiene que ser por lo menos 24 caráteres.`;
    }

    if (alertMessage.length > 0) {
      alert(alertMessage);
      return;
    }
    console.log(ratedObj.ratedAs, ratedObj.ratedAt, ratedObj.comment);

    this._close();
  }

  _events() {
    const ButtonRateLater = document.getElementById("ButtonRateLater");
    ButtonRateLater.addEventListener("click", () => {
      this._close();
    });
    const ButtonSubmitRating = document.getElementById("ButtonSubmitRating");
    ButtonSubmitRating.addEventListener("click", () => {
      this._submitRating();
    });
  }
}

export default new rateNow();
