import FireBaseHelperOwn from "./modules/firebase";
import installApp from "./modules/install-app";
import openUrls from "./modules/open-app-urls/open-urls";

installApp._initiateInstallApp();
// TODO this is MERCADO MAI
function processHTML(htmlString) {
  // Parse the HTML string into a DOM element
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Set to store unique class names
  const uniqueClasses = new Set();

  // Recursive function to process the element
  function cleanElement(element) {
    if (element.nodeType !== Node.ELEMENT_NODE) return;

    // Remove the `style` attribute
    element.removeAttribute("style");

    // Remove the `data-layer` attribute
    element.removeAttribute("data-layer");

    // Collect class names
    const className = element.className;
    if (className) {
      className.split(/\s+/).forEach((cls) => uniqueClasses.add(cls));
    }

    // Recursively process child elements
    Array.from(element.children).forEach(cleanElement);
  }

  // Start processing the body of the parsed HTML
  const body = doc.body;
  Array.from(body.children).forEach(cleanElement);

  // Serialize the processed HTML back to a string
  const cleanedHTML = body.innerHTML;

  // Format the unique class names as CSS
  const cssClasses = Array.from(uniqueClasses)
    .map((cls) => `.${cls} {}`)
    .join("\n");

  // Log the cleaned HTML and formatted CSS class names
  console.log("Cleaned HTML:", cleanedHTML);
  console.log("CSS Classes:\n" + cssClasses);
}
// Example usage
const htmlString = `

  `;
// processHTML(htmlString);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(new URL("/service-worker.js", import.meta.url))
      .then((registration) => {
        // console.log(
        //   "Service Worker registered with scope:",
        //   registration.scope
        // );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

// document.addEventListener("DOMContentLoaded", () => {
//   const body = document.body;

//   // Function to show a click/tap effect
//   const showClickEffect = (x, y) => {
//     const ripple = document.createElement("div");
//     ripple.className = "click-effect";
//     ripple.style.left = `${x}px`;
//     ripple.style.top = `${y}px`;
//     body.appendChild(ripple);

//     // Remove the ripple after animation
//     setTimeout(() => {
//       ripple.remove();
//     }, 600); // Match animation duration
//   };

//   // Function to start tracking a swipe trail
//   const startSwipeTrail = (x, y) => {
//     const trail = document.createElement("div");
//     trail.className = "swipe-trail";
//     trail.style.left = `${x}px`;
//     trail.style.top = `${y}px`;
//     body.appendChild(trail);

//     return trail;
//   };

//   // Event listeners
//   body.addEventListener("pointerdown", (e) => {
//     showClickEffect(e.clientX, e.clientY);
//   });

//   let swipeTrail;
//   body.addEventListener("pointermove", (e) => {
//     if (!swipeTrail) {
//       swipeTrail = startSwipeTrail(e.clientX, e.clientY);
//     } else {
//       swipeTrail.style.left = `${e.clientX}px`;
//       swipeTrail.style.top = `${e.clientY}px`;
//     }
//   });

//   body.addEventListener("pointerup", () => {
//     if (swipeTrail) {
//       swipeTrail.remove();
//       swipeTrail = null;
//     }
//   });
// });

//

//

//

// document.addEventListener("DOMContentLoaded", () => {
//   const body = document.body;
//   let swipeTrail = null;
//   let isSwiping = false;

//   // Function to show a click/tap effect
//   const showClickEffect = (x, y) => {
//     const ripple = document.createElement("div");
//     ripple.className = "click-effect";
//     ripple.style.left = `${x}px`;
//     ripple.style.top = `${y}px`;
//     body.appendChild(ripple);

//     // Remove the ripple after animation
//     setTimeout(() => {
//       ripple.remove();
//     }, 600); // Match animation duration
//   };

//   // Function to start tracking a swipe trail
//   const startSwipeTrail = (x, y) => {
//     const trail = document.createElement("div");
//     trail.className = "swipe-trail";
//     trail.style.left = `${x}px`;
//     trail.style.top = `${y}px`;
//     body.appendChild(trail);

//     return trail;
//   };

//   // Event listener for clicks
//   body.addEventListener("pointerdown", (e) => {
//     // For clicks, trigger the ripple effect
//     showClickEffect(e.clientX, e.clientY);

//     // Start swipe tracking if swipe is initiated
//     isSwiping = true;
//     swipeTrail = startSwipeTrail(e.clientX, e.clientY);
//   });

//   // Event listener for swipe movement
//   body.addEventListener("pointermove", (e) => {
//     if (isSwiping) {
//       // Update the swipe trail's position to follow the mouse/touch
//       swipeTrail.style.left = `${e.clientX}px`;
//       swipeTrail.style.top = `${e.clientY}px`;
//     }
//   });

//   // Event listener to stop swipe tracking
//   body.addEventListener("pointerup", () => {
//     if (isSwiping) {
//       // Remove the swipe trail after the swipe ends
//       swipeTrail.remove();
//       isSwiping = false;
//       swipeTrail = null;
//     }
//   });

//   // Prevent default for touch gestures (optional)
//   body.addEventListener("touchmove", (e) => e.preventDefault(), {
//     passive: false,
//   });
// });

//

//

//

// document.addEventListener("DOMContentLoaded", () => {
//   const body = document.body;
//   let swipeTrail = null;
//   let isSwiping = false;

//   // Function to show a mouse tracking dot
//   const showMouseTrackingDot = (x, y) => {
//     const dot = document.createElement("div");
//     dot.className = "mouse-tracking-dot";
//     dot.style.left = `${x - 5}px`; // Center the dot
//     dot.style.top = `${y - 5}px`; // Center the dot
//     body.appendChild(dot);

//     // Remove the dot after 300ms (fading effect duration)
//     setTimeout(() => {
//       dot.remove();
//     }, 300); // Adjust this to change how long the dot stays
//   };

//   // Function to show a click effect with a bigger fading line
//   const showClickEffect = (x, y) => {
//     const ripple = document.createElement("div");
//     ripple.className = "click-effect";
//     ripple.style.left = `${x - 25}px`; // Center the ripple
//     ripple.style.top = `${y - 25}px`; // Center the ripple
//     body.appendChild(ripple);

//     // Remove the ripple after animation
//     setTimeout(() => {
//       ripple.remove();
//     }, 600); // Match animation duration
//   };

//   // Function to start tracking a swipe trail
//   const startSwipeTrail = (x, y) => {
//     const trail = document.createElement("div");
//     trail.className = "swipe-trail";
//     trail.style.left = `${x}px`;
//     trail.style.top = `${y}px`;
//     body.appendChild(trail);

//     return trail;
//   };

//   // Event listener for mouse or touch movements (for tracking mouse position)
//   body.addEventListener("pointermove", (e) => {
//     // Only track if the user is not clicking (no swipe initiated)
//     if (!isSwiping) {
//       showMouseTrackingDot(e.clientX, e.clientY);
//     }
//     if (isSwiping) {
//       // Update the swipe trail's position to follow the mouse/touch
//       swipeTrail.style.left = `${e.clientX}px`;
//       swipeTrail.style.top = `${e.clientY}px`;
//     }
//   });

//   // Event listener for pointer down (click or swipe start)
//   body.addEventListener("pointerdown", (e) => {
//     // For clicks, trigger the ripple effect
//     showClickEffect(e.clientX, e.clientY);

//     // Start swipe tracking if swipe is initiated
//     isSwiping = true;
//     swipeTrail = startSwipeTrail(e.clientX, e.clientY);
//   });

//   // Event listener for pointer up (end swipe)
//   body.addEventListener("pointerup", () => {
//     if (isSwiping) {
//       // Remove the swipe trail after the swipe ends
//       swipeTrail.remove();
//       isSwiping = false;
//       swipeTrail = null;
//     }
//   });

//   // Prevent default for touch gestures (optional)
//   body.addEventListener("touchmove", (e) => e.preventDefault(), {
//     passive: false,
//   });
// });
