import firebase from "../firebase";
import displayListing from "../display-listing/display-listing";
import displayListingGenerateHtml from "../display-listing/generate-html";

import displayListingEach from "../dispay-listings-each/display";

import DisplayBusinessEach from "../display-business-each/display";
import GENRALHELPERS from "../GENRAL-HELPERS";
import ownListings from "../own-listing-actions/own-listings";
import help from "../help/help";

// import openUrls from "../open-app-urls/open-urls";

// display._init(data);

let data = { listings: [], businesses: {} };
const colors = {
  headerPrimary: "FFFFFF",
  headerSecondary: "E0E0E0",
  packTitles: "6D6D6D",
  packTitlesHighlight: "3A7D44",
  itemBackgroundPrimary: "3A7D44",
  itemBackgroundSecondary: "fff",
  itemTextPrimary: "FFFFFF",
  itemTextSecondary: "2B2B2B",
  itemTextTerteriary: "3A7D44",
  itemTextFour: "3A7D44",
  itemHighlight: "FF7043",
  itemHighlightText: "FFFFFF",
};

class AllDisplaysInitial {
  #initiated = false;

  #idForBusinesses;
  #isLoadingForBusinesses;
  #lasVisibleDocBusinesses;
  #isMoreLoadingInitializedForBusinesses;

  #idForDiscount;
  #isLoadingForMostDiscount;
  #lasVisibleDocFromDiscount;
  #isMoreLoadingInitializedForDiscount;

  #idForMostRecentCon;
  #isLoadingForMostRecent;
  #lasVisibleDocFromRecent;
  #isMoreLoadingInitializedForMostRecent;

  #idForRelevant;
  #isLoadingForMostRelevant;
  #lasVisibleDocFromRelevant;
  #isMoreLoadingInitializedForMostRelevant;

  #idForDestacados;
  #isLoadingForDestacados;
  #lasVisibleDocFromDestacados;
  #isMoreLoadingInitializedForDestacados;
  constructor() {
    this.abortController = new AbortController(); // Initialize AbortController
  }

  _abortDisplays() {
    this.abortController.abort(); // Abort all ongoing tasks
    console.log("All display tasks have been aborted.");
    // Reset for future calls
    this.abortController = new AbortController();
  }

  async _getBusinessDataById(id) {
    return data.businesses.find((business) => business.businessId === id);
  }
  // Start Initial Displays

  async _displayTheRightWay(
    businessId,
    listingId,
    openTo,
    openFromBusiness,
    share
  ) {
    console.log(businessId, listingId, openTo, openFromBusiness);

    if (businessId && openTo === "business") {
      let businessData = data.businesses[businessId];
      if (!businessData) {
        console.error(`Business with ID ${businessId} not found.`);
        return;
      }
      console.log("Opening business", businessData);
      businessData = await firebase._getBusinessById(businessId);
      DisplayBusinessEach._init(businessData, share);
    }

    if (businessId && listingId && openTo === "listingInBusiness") {
      let businessData = data.businesses[businessId];
      if (!businessData) {
        console.error(
          `Business with ID ${businessId} not found, need to load it.`
        );

        const businessDataDownloaded = await firebase._getBusinessById(
          businessId
        );
        console.log(businessDataDownloaded);
        const businessListingsData =
          await firebase._getAllDocsFromPathByMostRecent(
            `businesses/${businessId}/listings`
          );
        //         await firebase._getMostRelevantListingsWithFilter(
        //   `businesses/${businessId}/listings`
        // );
        console.log(businessListingsData);
        data.businesses[businessId] = {
          ...businessDataDownloaded, // Add the new business data
          listings: businessListingsData, // Add the listings for the new business
        };
        businessData = data.businesses[businessId];
      }

      console.log(businessData);
      let listingData = businessData.listings.find(
        (listing) => listing.listingId === listingId
      );
      // This error comes up when a new listing is created
      // for a business and trying to open it without reloading after uploading
      if (!listingData) {
        listingData = await firebase._getAnyDocById(
          `businesses/${businessId}/listings/${listingId}`
        );
        if (!listingData) {
          console.error(
            `Listing with ID ${listingId} not found in business ${businessId}.`
          );
          return;
        }
      }

      console.log(openFromBusiness);

      if (!openFromBusiness || openFromBusiness === "undefined") {
        await DisplayBusinessEach._init(businessData);
      }

      displayListingEach._init(
        listingData,
        businessData,
        openFromBusiness,
        share
      );
    }
    if (listingId && openTo === "listing") {
      let listingDataFound = data.listings.find(
        (listing) => listing.listingId === listingId
      );

      if (!listingDataFound) {
        listingDataFound = await firebase._getAnyDocById(
          `listings/${listingId}`
        );
      }
      console.log("Opening listing", listingDataFound);
      displayListingEach._init(listingDataFound, null, null, share);
    }
  }

  _events() {
    // if (!this.#initiated) {
    //   window.addEventListener("click", (e) => {
    //     const targetElement = e.target.closest(".ActionOpenForDisplay"); // Find the closest ancestor with the class

    //     const isReturn = e.target.closest(".returnForActionOpenForDisplay");
    //     if (targetElement) {
    //       const parentOfClickedOpen = targetElement.parentElement; // Get the parent element

    //       // Remove the class from all siblings
    //       Array.from(parentOfClickedOpen.children).forEach((child) => {
    //         child.classList.remove("ListingActiveAndLastOpend");
    //       });

    //       // Add the class to the clicked element
    //       targetElement.classList.add("ListingActiveAndLastOpend");

    //       if (isReturn) return;

    //       console.log(targetElement);
    //       // Extract data attributes and call your function
    //       const businessId = targetElement.dataset.businessid;
    //       const listingId = targetElement.dataset.listingid;
    //       const openTo = targetElement.dataset.opento;
    //       const openFrom = targetElement.dataset.openfrom;

    //       console.log("data:", businessId, listingId, openTo, openFrom);

    //       this._displayTheRightWay(businessId, listingId, openTo, openFrom);
    //       // this._displayTheRightWay(listingId, businessId, openTo, openFrom);
    //     }
    //   });

    if (!this.#initiated) {
      window.addEventListener("click", (e) => {
        const targetElement = e.target.closest(".ActionOpenForDisplay"); // Find the closest ancestor with the class

        if (!targetElement) return; // Ensure an element was clicked

        const isReturn = e.target.closest(".returnForActionOpenForDisplay");
        if (isReturn) return;

        console.log("Clicked Element:", targetElement);

        // // Extract data attributes (use correct camelCase names)
        // const businessId = targetElement.dataset.businessId || ""; // ✅ Use camelCase
        // const listingId = targetElement.dataset.listingId || ""; // ✅ Use camelCase
        // const openTo = targetElement.dataset.openTo || ""; // ✅ Use camelCase
        // const openFrom = targetElement.dataset.openFrom || ""; // ✅ Use camelCase

        const businessId = targetElement.getAttribute("data-businessid") || "";
        const listingId = targetElement.getAttribute("data-listingid") || "";
        const openTo = targetElement.getAttribute("data-opento") || "";
        const openFrom = targetElement.getAttribute("data-openfrom") || "";

        // let businessIdToUse = businessId;

        // if (businessId === "nothing") {
        //   businessIdToUse = null;
        // }

        console.log("Extracted Data:", {
          businessId,
          listingId,
          openTo,
          openFrom,
        });

        // Ensure correct data is passed
        this._displayTheRightWay(businessId, listingId, openTo, openFrom);
      });

      window.addEventListener("click", (e) => {
        // ActionOpenForDisplay;
        const isImg = e.target.closest(".ImgForBigDisplay");
        const openListingEditOptions = e.target.closest(
          ".openListingEditOptionsGlobal"
        );
        const openListingShareGlobal = e.target.closest(
          ".openListingShareGlobal"
        );
        const openListingBoostGlobal = e.target.closest(
          ".openListingBoostGlobal"
        );
        const PopOverActionsMenuCon1Closest = e.target.closest(
          ".PopOverActionsMenuCon1"
        );

        if (!PopOverActionsMenuCon1Closest) {
          ownListings._closePopOver();
        }

        if (openListingShareGlobal) {
          const actionCon = e.target.closest(".ActionOpenForDisplay");
          if (!actionCon) return;
          const listingId = actionCon.dataset.id;
          const businessId = actionCon.dataset.businessid;
          ownListings._openPopOver("share", businessId, listingId, e);
          console.log("should open share");
          return;
        }
        if (openListingBoostGlobal) {
          const actionCon = e.target.closest(".ActionOpenForDisplay");
          if (!actionCon) return;
          const listingId = actionCon.dataset.id;
          const businessId = actionCon.dataset.businessid;
          ownListings._openPopOver("boost", businessId, listingId, e);
          console.log("should open boost");
          return;
        }
        if (openListingEditOptions) {
          const actionCon = e.target.closest(".ActionOpenForDisplay");
          if (!actionCon) return;
          const listingId = actionCon.dataset.id;
          const businessId = actionCon.dataset.businessid;
          ownListings._openPopOver("options", businessId, listingId, e);

          console.log("should open edit options", listingId, businessId);
          return;
        }
        if (isImg) {
          displayListingEach._myOwnImgDisplayerNotAIs([isImg.src]);
        }
      });
      window.addEventListener("click", (e) => {
        const openHelp = e.target.closest(".openHelp");
        if (openHelp) {
          const helpWith = openHelp.dataset.helpwith;
          if (helpWith) {
            help._open(helpWith);
          }
        }
      });

      this.#initiated = true;
    }
  }

  async _init() {
    // this.#initiated = false;
    this.#lasVisibleDocBusinesses = null;
    this.#isLoadingForBusinesses = false;
    this.#isMoreLoadingInitializedForBusinesses = false;

    this.#lasVisibleDocFromRecent = null;
    this.#isLoadingForMostRecent = false;
    this.#isMoreLoadingInitializedForMostRecent = false;

    this.#lasVisibleDocFromDiscount = null;
    this.#isLoadingForMostDiscount = false;
    this.#isMoreLoadingInitializedForDiscount = false;

    this.#lasVisibleDocFromRelevant = null;
    this.#isLoadingForMostRelevant = false;
    this.#isMoreLoadingInitializedForMostRelevant = false;

    this.#lasVisibleDocFromDestacados = null;
    this.#isLoadingForDestacados = false;
    this.#isMoreLoadingInitializedForDestacados = false;
    // console.warn("yep called it here");
    this._events();
    this._displayDestacados();
    this._createBusinessesCon();
    this._displayMostRelevant();
    this._displaySpecialDiscounts();
    this._displayFromRecent();
    // console.warn("yep called it here");
  }

  _loadMoreByScrollingMostRecent(id) {
    function isAlmostAtBottom(container, offset = 100) {
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;

      // Check if the container is scrolled within `offset` pixels of the bottom
      return scrollHeight - scrollTop - clientHeight <= offset;
    }

    // Example of how to use it with an event listener:
    const container = document.getElementById(id); // Replace with your container element

    container.addEventListener("scroll", () => {
      if (isAlmostAtBottom(container, 100)) {
        this._insertFromMostRecent();
        // Do something, e.g., load more content or trigger a function
      }
    });
  }
  _loadMoreByScrollingRight(id, from) {
    function isAlmostAtRight(container, offset = 100) {
      const scrollWidth = container.scrollWidth; // Total width of content
      const scrollLeft = container.scrollLeft; // How much is scrolled
      const clientWidth = container.clientWidth; // Visible width

      // Check if the container is scrolled within `offset` pixels of the right
      return scrollWidth - scrollLeft - clientWidth <= offset;
    }

    // Example of how to use it with an event listener:
    const container = document.getElementById(id); // Replace with your container element

    container.addEventListener("scroll", () => {
      if (isAlmostAtRight(container, 100)) {
        if (from === "discount") {
          this._insertFromDiscount();
        }
        if (from === "relevant") {
          this._insertFromRelevant();
        }
        if (from === "destacados") {
          this._insertFromDesatcados();
        }
        if (from === "businesses") {
          this._insertBusinesses(this.#idForBusinesses);
        }
      }
    });
  }
  //

  //
  _createBusinessesCon() {
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );
    this.#idForBusinesses = htmlContainerId;

    HomeScreenContenConForAll.insertAdjacentHTML(
      "beforeend",
      displayListingGenerateHtml._businessPackOfPacks(
        displayListingGenerateHtml._businessPack(
          "Negocios",
          htmlContainerId,
          colors
        )
      )
    );

    this._insertBusinesses(this.#idForBusinesses);
  }
  async _getbusinesses() {
    if (this.#isLoadingForBusinesses) return [];
    this.#isLoadingForBusinesses = true;

    if (this.#lasVisibleDocBusinesses === "itwaslast") {
      return [];
    }
    const { listings, lastVisibleDoc } =
      await firebase._getRecentListingsWithFilter(
        this.#lasVisibleDocBusinesses,
        6,
        "businesses",
        "status",
        "active"
      );
    this.#lasVisibleDocBusinesses = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleDocBusinesses = "itwaslast";
    }
    this.#isLoadingForBusinesses = false;
    return listings;
  }
  async _insertBusinesses(id, isInSearch, businessesIn) {
    const dataLocal = { businesses: {} }; // Initialize data object

    try {
      let businesses = businessesIn;
      if (!isInSearch) {
        const results = await this._getbusinesses();
        businesses = results;
      }

      // Process businesses
      const processedBusinesses = await Promise.all(
        businesses.map(async (business) => {
          // Fetch listings for the business
          const { listings, lastVisibleDoc } =
            await firebase._getRecentListingsWithFilter(
              null,
              6,
              `businesses/${business.businessId}/listings`
            );

          // Overwrite business in the data object
          dataLocal.businesses[business.businessId] = {
            ...business,
            listings: listings || [],
          };

          return { ...business, listings };
        })
      );

      // Generate and display HTML for businesses and their listings
      for (const business of processedBusinesses) {
        let listingsHtml = ``;

        if (
          !Array.isArray(business.listings) ||
          business.listings.length === 0
        ) {
          listingsHtml +=
            displayListingGenerateHtml._listingVerticalMiniNoListings();
        } else {
          for (const listing of business.listings) {
            listingsHtml +=
              await displayListingGenerateHtml._listingVerticalMini(
                listing,
                business.colors,
                "listingInBusiness",
                business.businessId
              );
          }
          listingsHtml += displayListingGenerateHtml._viewAllListingsMini(
            business.colors
          );
        }

        const businessInPackHtml =
          await displayListingGenerateHtml._businessInPack(
            listingsHtml,
            business
          );

        if (isInSearch) {
          return businessInPackHtml;
        } else {
          // console.warn("html", businessInPackHtml);
          const businessCon = document.getElementById(id);
          businessCon.insertAdjacentHTML("beforeend", businessInPackHtml);
        }
      }

      if (!isInSearch) {
        if (this.#isMoreLoadingInitializedForBusinesses) return;
        this._loadMoreByScrollingRight(this.#idForBusinesses, "businesses");
        this.#isMoreLoadingInitializedForBusinesses = true;
      }
    } catch (err) {
      if (err.name === "AbortError") {
        // console.log("Display Businesses aborted.");
      } else {
        console.error(err);
      }
    }

    data.businesses = dataLocal.businesses;
  }
  //

  //
  async _displaySpecialDiscounts() {
    const signal = this.abortController.signal;
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    try {
      this.#idForDiscount = htmlContainerId;
      HomeScreenContenConForAll.insertAdjacentHTML(
        "beforeend",
        displayListingGenerateHtml._businessPackOfPacks(
          displayListingGenerateHtml._listingPack(
            "Descuentos Especiales",
            colors,
            htmlContainerId
          )
        )
      );
      this._insertFromDiscount();
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display Special Discounts aborted.");
      } else {
        console.error(err);
      }
    }
  }
  async _getDiscount() {
    if (this.#isLoadingForMostDiscount) return [];
    this.#isLoadingForMostDiscount = true;

    if (this.#lasVisibleDocFromDiscount === "itwaslast") {
      return [];
    }
    const { listings, lastVisibleDoc } =
      await firebase._getMostRelevantListingsWithFilter(
        this.#lasVisibleDocFromDiscount,
        10,
        "listingDiscount",
        "onDiscount"
      );
    this.#lasVisibleDocFromDiscount = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleDocFromDiscount = "itwaslast";
    }
    this.#isLoadingForMostDiscount = false;
    return listings;
  }
  async _insertFromDiscount() {
    const listings = await this._getDiscount();
    if (listings.length < 1) return;
    const containerForFromMostRecent = document.getElementById(
      this.#idForDiscount
    );

    if (!containerForFromMostRecent) return;
    for (const listing of listings) {
      const html = await displayListingGenerateHtml._listingVertical(
        listing,
        colors,
        "listing"
      );
      containerForFromMostRecent.insertAdjacentHTML("beforeend", html);
    }

    if (this.#isMoreLoadingInitializedForDiscount) return;
    this._loadMoreByScrollingRight(this.#idForDiscount, "discount");
    this.#isMoreLoadingInitializedForDiscount = true;
  }
  //

  //
  async _displayFromRecent() {
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    try {
      this.#idForMostRecentCon = htmlContainerId;
      HomeScreenContenConForAll.insertAdjacentHTML(
        "beforeend",
        displayListingGenerateHtml._businessPackOfPacks(
          displayListingGenerateHtml._listingPack(
            "Mas recientes",
            colors,
            htmlContainerId,
            "viewMore"
          )
        )
      );

      this._insertFromMostRecent();
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display Special From recent aborted.");
      } else {
        console.error(err);
      }
    }
  }
  async _insertFromMostRecent() {
    const listings = await this._getMostRecent();
    if (listings.length < 1) return;
    const containerForFromMostRecent = document.getElementById(
      this.#idForMostRecentCon
    );

    if (!containerForFromMostRecent) return;
    containerForFromMostRecent.classList.add("VIEW-ALL-CLASS");
    for (const listing of listings) {
      const html = await displayListingGenerateHtml._listingVerticalBig(
        listing,
        colors,
        "listing"
      );
      containerForFromMostRecent.insertAdjacentHTML("beforeend", html);
    }
    if (this.#isMoreLoadingInitializedForMostRecent) return;
    this._loadMoreByScrollingMostRecent(
      "MainAppConWithNavigationContentConHome"
    );
    this.#isMoreLoadingInitializedForMostRecent = true;
  }
  async _getMostRecent() {
    if (this.#isLoadingForMostRecent) return [];
    this.#isLoadingForMostRecent = true;

    if (this.#lasVisibleDocFromRecent === "itwaslast") {
      return [];
    }
    const { results, lastVisibleDoc } =
      await firebase._globalGetFromMostRecentWithOutPriority(
        this.#lasVisibleDocFromRecent
      );

    this.#lasVisibleDocFromRecent = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleDocFromRecent = "itwaslast";
    }
    this.#isLoadingForMostRecent = false;
    return results;
  }
  //

  //
  async _displayDestacados() {
    const signal = this.abortController.signal;
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    try {
      this.#idForDestacados = htmlContainerId;

      HomeScreenContenConForAll.insertAdjacentHTML(
        "beforeend",
        displayListingGenerateHtml._businessPackOfPacks(
          displayListingGenerateHtml._listingPack(
            "Destacados",
            colors,
            htmlContainerId
          )
        )
      );
      this._insertFromDesatcados();
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display For You aborted.");
      } else {
        console.error(err);
      }
    }
  }
  async _getDestacados() {
    if (this.#isLoadingForDestacados) return [];
    this.#isLoadingForDestacados = true;

    if (this.#lasVisibleDocFromDestacados === "itwaslast") {
      return [];
    }
    const { listings, lastVisibleDoc } =
      await firebase._getMostRelevantListingsWithFilter(
        this.#lasVisibleDocFromDestacados,
        4,
        "destacado",
        "true"
      );
    this.#lasVisibleDocFromDestacados = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleDocFromDestacados = "itwaslast";
    }
    this.#isLoadingForDestacados = false;
    return listings;
  }
  async _insertFromDesatcados() {
    const listings = await this._getDestacados();
    if (listings.length < 1) return;
    const containerForFromMostRecent = document.getElementById(
      this.#idForDestacados
    );

    if (!containerForFromMostRecent) return;
    for (const listing of listings) {
      const html = await displayListingGenerateHtml._listingHorizontal(
        listing,
        colors,
        "listing"
      );
      containerForFromMostRecent.insertAdjacentHTML("beforeend", html);
    }
    if (this.#isMoreLoadingInitializedForDestacados) return;
    this._loadMoreByScrollingRight(this.#idForDestacados, "destacados");
    this.#isMoreLoadingInitializedForDestacados = true;
  }
  //

  //
  async _displayMostRelevant() {
    const htmlContainerId = GENRALHELPERS._getUniqueIdfunction();
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    try {
      this.#idForRelevant = htmlContainerId;
      HomeScreenContenConForAll.insertAdjacentHTML(
        "beforeend",
        displayListingGenerateHtml._businessPackOfPacks(
          displayListingGenerateHtml._listingPack(
            "Listados mas relevantes",
            colors,
            htmlContainerId
          )
        )
      );

      this._insertFromRelevant();
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Display For You aborted.");
      } else {
        console.error(err);
      }
    }
  }
  async _insertFromRelevant() {
    const listings = await this._getRelevant();
    if (listings.length < 1) return;
    const containerForFromMostRecent = document.getElementById(
      this.#idForRelevant
    );

    if (!containerForFromMostRecent) return;
    for (const listing of listings) {
      const html = await displayListingGenerateHtml._listingVertical(
        listing,
        colors,
        "listing"
      );
      containerForFromMostRecent.insertAdjacentHTML("beforeend", html);
    }
    if (this.#isMoreLoadingInitializedForMostRelevant) return;
    this._loadMoreByScrollingRight(this.#idForRelevant, "relevant");
    this.#isMoreLoadingInitializedForMostRelevant = true;
  }
  async _getRelevant() {
    if (this.#isLoadingForMostRelevant) return [];
    this.#isLoadingForMostRelevant = true;

    if (this.#lasVisibleDocFromRelevant === "itwaslast") {
      return [];
    }
    const { listings, lastVisibleDoc } =
      await firebase._getMostRelevantListingsWithFilter(
        this.#lasVisibleDocFromRelevant,
        10
      );
    this.#lasVisibleDocFromRelevant = lastVisibleDoc;
    if (!lastVisibleDoc) {
      this.#lasVisibleDocFromRelevant = "itwaslast";
    }
    this.#isLoadingForMostRelevant = false;
    return listings;
  }
  //

  //
  _dataMerge(obj) {
    if (!obj || !obj.businesses) {
      console.error(
        "Invalid object provided for merge. No 'businesses' found."
      );
      return;
    }

    // Merge businesses from obj into the existing data
    for (const businessId in obj.businesses) {
      if (obj.businesses.hasOwnProperty(businessId)) {
        // If the business exists in the target data, overwrite it; otherwise, add it
        data.businesses[businessId] = obj.businesses[businessId];
      }
    }

    console.log("Businesses merged successfully.");
  }

  _getLocalBusinessData(businessId) {
    return data.businesses[businessId];
  }
}
export default new AllDisplaysInitial();
