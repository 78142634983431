//

//

const App = document.querySelector(".App");
const miniScreen = document.getElementById("miniScreenPri");
const miniScreenHeaderTitle = document.getElementById("MiniScreenHeaderTitle");
const miniScreenContentCon = document.getElementById(
  "InsertContentInMiniScreenCon"
);

class MiniScreen {
  constructor() {
    this._events();
  }
  _display(what, title, content) {
    const HeaderCon2ForColorChangeWhenMiniScreenOnDisplay =
      document.getElementById(
        "HeaderCon2ForColorChangeWhenMiniScreenOnDisplay"
      );
    if (what === "hide") {
      miniScreenHeaderTitle.innerHTML = "";
      miniScreenContentCon.innerHTML = "";
      miniScreen.classList.remove("MiniScreenConShow");
      miniScreen.classList.add("MiniScreenConHide");
      App.classList.remove("AppMiniOnDisplay");
      HeaderCon2ForColorChangeWhenMiniScreenOnDisplay.classList.remove(
        "HeaderCon2ForColorChangeWhenMiniScreenOnDisplay"
      );
    }
    if (what === "show") {
      miniScreenHeaderTitle.innerHTML = title;
      miniScreenContentCon.innerHTML = content;
      miniScreen.classList.remove("MiniScreenConHide");
      miniScreen.classList.add("MiniScreenConShow");
      App.classList.add("AppMiniOnDisplay");
      HeaderCon2ForColorChangeWhenMiniScreenOnDisplay.classList.add(
        "HeaderCon2ForColorChangeWhenMiniScreenOnDisplay"
      );
    }
  }
  _events() {
    miniScreen.addEventListener("click", () => {
      // this._display("hide");
    });
    App.addEventListener("click", () => {
      // this._display("show", "Cuenta necesaria", "Thank you for being here");
    });
  }
}
export default new MiniScreen();
