import createListing from "../create-listing/create-listing";
import firebase from "../firebase";
import allHandeling from "../handeling-all-displays/all-handeling";
import yourListings from "../main-menu/your-listings";
import spinner from "../spinner";
import DisplayBusinessOwn from "../business-page-own-veiw/busine-own";

const PopOverActionsMenuCon1 = document.getElementById(
  "PopOverActionsMenuCon1"
);
class ownListings {
  #listingId;
  #e;
  #businessId;
  constructor() {
    this._events();
  }
  _openPopOver(option, businessId, listingId, e) {
    console.log(e.target);
    console.log(option);
    console.log(option, businessId, listingId);

    // if (option === "options") {
    this.#listingId = listingId;
    this.#businessId = businessId;
    this.#e = e;

    if (option && option === "share") {
      this._shareListing();
      return;
    }
    if (option && option === "boost") {
      this._boostListing();
      return;
    }
    setTimeout(() => {
      PopOverActionsMenuCon1.classList.add("PopOverActionsMenuCon1Display");
    }, 100);
    // }
  }
  _closePopOver() {
    PopOverActionsMenuCon1.classList.remove("PopOverActionsMenuCon1Display");
  }

  _shareListing() {
    console.log(this.#e.target);
    let openTo = "listing";
    if (this.#businessId && this.#businessId !== "undefined") {
      openTo = "listingInBusiness";
    }
    allHandeling._displayTheRightWay(
      this.#businessId,
      this.#listingId,
      openTo,
      null,
      true
    );
    this._closePopOver();
    yourListings._close();
  }
  _boostListing() {
    alert("Proximamente. Esta funcion aún no está lista.");
  }

  async _onAction(action) {
    console.log(action);

    if (action === "close") {
      this._closePopOver();
    }
    if (action === "share") {
      this._shareListing();
    }
    if (action === "boost") {
      this._boostListing();
    }
    if (action === "open") {
      let openTo = "listing";
      if (this.#businessId && this.#businessId !== "undefined") {
        openTo = "listingInBusiness";
      }
      allHandeling._displayTheRightWay(
        this.#businessId,
        this.#listingId,
        openTo
      );
      this._closePopOver();
      yourListings._close();
    }
    if (action === "edit") {
      let path = `listings/${this.#listingId}`;
      console.log(path);
      if (this.#businessId && this.#businessId !== "undefined") {
        path = `businesses/${this.#businessId}/listings/${this.#listingId}`;
      }
      spinner._displaySpinner("Cargando listado...");
      console.log(path);
      const data = await firebase._getAnyDocById(path);
      console.log(data);
      if (!data || data.length < 1) {
        alert("Hubo un error. Intenta de nuevo mas tarde.");
        spinner._hideSpinner();
        return;
      }
      createListing._init(data);
      spinner._hideSpinner();
      this._closePopOver();
    }
    if (action === "delete") {
      let path = `listings/${this.#listingId}`;
      console.log(path);
      if (this.#businessId && this.#businessId !== "undefined") {
        path = `businesses/${this.#businessId}/listings/${this.#listingId}`;
      }
      console.log(path);

      const confirmDelete = confirm(
        "¿Estas seguro que quieres eliminar este listado?"
      );
      if (!confirmDelete) {
        return;
      }
      spinner._displaySpinner("Cargando listado...");

      const deleteResult = await firebase._deleteListing({ path: path });
      if (deleteResult.data.status === "success") {
        alert("Listado eliminado con éxito.");
        DisplayBusinessOwn._init();
      }
      if (deleteResult.data.status === "error") {
        alert(`Ocurio un error: ${deleteResult.data.message}`);
      }
      spinner._hideSpinner();
      this._closePopOver();
    }
  }

  _events() {
    PopOverActionsMenuCon1.addEventListener("click", (e) => {
      console.log("clicked");
      const action = e.target.closest(".PopOverActionsMenuActionOption").dataset
        .action;
      console.log(action);
      if (action) {
        this._onAction(action);
      }
    });
  }
}

export default new ownListings();
