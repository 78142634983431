import generateHtml from "./generate-html";
import CreateListing from "../create-listing/create-listing";
// import startUp from "../main-start-up-screen/start-up.js";

// import DisplayListing from "../display-listing/display-listing";
// import Register from "../register/register.js";
import firebase from "../firebase.js";
// import handleAccount from "../register/handle-account.js";

import AllDisplaysInitial from "../handeling-all-displays/all-handeling.js";
import search from "../search/search.js";
import GENRALHELPERS from "../GENRAL-HELPERS.js";
import accountProfileChange from "../main-menu/account-profile-change.js";
import help from "../help/help.js";
const omittedWords = ["for", "a", "the", "and"];

class HomeScreen {
  #accountState;
  #alreadlyInitialized;

  constructor() {
    // firebase._storeMachineData("trilladora", "case-ih", "Axial-Flow-7250");
    // firebase
    //   ._getMachineData("machineTypes")
    //   .then((data) => console.log("All Machine Types:", data))
    //   .catch((err) => console.error("Error:", err));
    // // Get all brands for 'tractor'
    // firebase
    //   ._getMachineData("machineTypes/tractor/brands")
    //   .then((data) => console.log("Brands for Tractor:", data))
    //   .catch((err) => console.error("Error:", err));
    // // Get all models for 'tractor' -> 'John-Deere'
    // firebase
    //   ._getMachineData("machineTypes/trilladora/brands/Massey/models")
    //   .then((data) => console.log("Models for Tractor -> John-Deere:", data))
    //   .catch((err) => console.error("Error:", err));
    // this._populateMachineDataInFireBase();
    // this._try();
  }

  async _try() {
    const foundTargetedListings = await firebase._globalTargetedSearch(
      "sembradora"
    );
    console.log(foundTargetedListings);
  }

  async _populateMachineDataInFireBase() {
    // const foundListings = await firebase._searchListings(
    //   `businesses/6xGLA4DYXMexwn3BWGvB/listings`,
    //   "Sembradora New Holland",
    //   omittedWords
    // );
    // const rankedResults = GENRALHELPERS._rankListings(
    //   foundListings,
    //   "Sembradora New Holland",
    //   omittedWords
    // );
    setTimeout(async () => {
      await firebase._storeMachineData("boleadora", "boleadora", "800-kg");
      await firebase._storeMachineData("boleadora", "boleadora", "600-kg");
    }, 5000);
  }

  //

  //

  //

  //

  _buttonAnimation() {
    function startUploadAnimation() {
      const iluUploadButtonTractor = document.getElementById(
        "iluUploadButtonTractor"
      );
      const iluUploadButtonCombine = document.getElementById(
        "iluUploadButtonCombine"
      );

      function animateMachine(machine, nextMachine) {
        machine.style.display = "flex";
        machine.classList.remove("iluUploadMidle", "iluUploadUp"); // Reset position
        machine.classList.add("iluUploadStart"); // Set it below view first

        // Delay a bit before applying the transition to ensure it starts from below
        setTimeout(() => {
          machine.classList.remove("iluUploadStart");
          machine.classList.add("iluUploadMidle");
        }, 50); // Small delay to trigger transition

        setTimeout(() => {
          machine.classList.remove("iluUploadMidle");
          machine.classList.add("iluUploadUp");

          setTimeout(() => {
            machine.style.display = "none";
            machine.classList.remove("iluUploadUp");
            if (nextMachine) {
              animateMachine(nextMachine, machine); // Swap order to loop infinitely
            } else {
              animateMachine(iluUploadButtonTractor, iluUploadButtonCombine);
            }
          }, 1000);
        }, 2000);
      }

      // Start the animation loop
      animateMachine(iluUploadButtonTractor, iluUploadButtonCombine);
    }
    startUploadAnimation();
    GENRALHELPERS._animateCTA("HomeScreenButtonCreateListing");
    setTimeout(() => {
      GENRALHELPERS._animateCTA("HomeScreenButtonHelp");
      GENRALHELPERS._startLoopAnimation("HomeScreenButtonCreateListing");
    }, 4000);
  }

  async _events() {
    this._buttonAnimation();
    if (this.#alreadlyInitialized) return;
    // const HomeScreenButtonCreateListing = document.getElementById(
    //   "HomeScreenButtonCreateListing"
    // );
    window.addEventListener("click", async (e) => {
      if (e.target.closest("#HomeScreenButtonCreateListing")) {
        console.log("creatye lisitng called");
        CreateListing._init();
      }
      if (e.target.closest("#HomeScreenButtonHelp")) {
        console.log("creatye lisitng called");
        help._open();
        // CreateListing._init();
      }
      if (e.target.closest("#HeaderConWellcomeWithName")) {
        accountProfileChange._open();
      }
      if (e.target.dataset.generall === "expandforviewall") {
        console.log("here");
        const parent = e.target.closest(".ListingPackDisplayCon1");
        const parentCon2 = parent.querySelector(".ListingPackDisplayCon3");
        const lastChild = parentCon2.lastElementChild; // Get the last child
        console.log(lastChild);
        if (lastChild) {
          parentCon2.scrollTo({
            left: lastChild.offsetLeft - parentCon2.offsetLeft, // Scroll to the last child's position
            behavior: "smooth", // Enable smooth scrolling
          });
        }

        // child.classList.toggle("VIEW-ALL-CLASS");
      }
      if (e.target.dataset.generall === "viewMore") {
        console.log("here");
        const parent = e.target.closest(".ListingPackDisplayCon1");
        const HomeScreenContenConForSeachAndAdd = document.getElementById(
          "MainAppConWithNavigationContentConHome"
        );
        const parentCon2 = parent.querySelector(".ListingPackDisplayCon3");
        const lastChild = parentCon2.lastElementChild; // Get the last child

        console.log(lastChild);

        if (lastChild) {
          HomeScreenContenConForSeachAndAdd.scrollTo({
            top:
              lastChild.offsetTop -
              parentCon2.offsetTop +
              lastChild.clientHeight,
            behavior: "smooth",
          });
        }

        // child.classList.toggle("VIEW-ALL-CLASS");
      }
      if (e.target.dataset.generall === "expandforviewallEditMode") {
        const parent = e.target.closest(".OwnBusinessListingsCon2");
        const parentCon2 = parent.querySelector(".OwnBusinessListingsCon4");
        const lastChild = parentCon2.lastElementChild; // Get the last child

        if (lastChild) {
          parentCon2.scrollTo({
            left: lastChild.offsetLeft - parentCon2.offsetLeft, // Scroll to the last child's position
            behavior: "smooth", // Enable smooth scrolling
          });
        }
      }
      if (e.target.dataset.generall === "expandforviewallbusiness") {
        const parent = e.target.closest(".BusinessesPackDisplayCon1");
        const parentCon2 = parent.querySelector(".BusinessesPackDisplayCon3");
        const lastChild = parentCon2.lastElementChild; // Get the last child

        if (lastChild) {
          parentCon2.scrollTo({
            left: lastChild.offsetLeft - parentCon2.offsetLeft, // Scroll to the last child's position
            behavior: "smooth", // Enable smooth scrolling
          });
        }
      }
    });
    window.addEventListener("click", (e) => {
      const help = e.target.closest(".openHelp");

      if (help) {
        const helpWith = help.dataset.helpwith;
      }
    });
  }

  _clearContainer() {
    const HomeScreenContenConForAll = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );
    HomeScreenContenConForAll.innerHTML = "";
  }

  //

  //

  _displayRightNavWindow() {
    const windows = document.querySelectorAll(
      ".MainAppConWithNavigationContentCon"
    );

    windows.forEach((window) => {
      if (window.id === "MainAppConWithNavigationContentConHome") {
        window.style.display = "";
      } else {
        window.style.display = "none";
      }
    });
  }

  async _init(init) {
    if (this.#alreadlyInitialized && !init) {
      const HomeScreenContenConForSeachAndAdd = document.getElementById(
        "MainAppConWithNavigationContentConHome"
      );
      // HomeScreenContenConForSeachAndAdd.scrollTo(130, 0);

      if (HomeScreenContenConForSeachAndAdd) {
        HomeScreenContenConForSeachAndAdd.scrollTo({
          top: 0, // Scroll to the top
          behavior: "smooth", // Smooth scrolling
        });
      }

      this._displayRightNavWindow();
    } else {
      this.#accountState = firebase._getLatestCurrentUserData();
      this._clearContainer();
      this._inserntSearchComponent();
      AllDisplaysInitial._init();
      this._events();
      this._displayRightNavWindow();
      this.#alreadlyInitialized = true;
    }
  }

  _inserntSearchComponent() {
    let activated = false;
    const HomeScreenContenConForSeachAndAdd = document.getElementById(
      "MainAppConWithNavigationContentConHome"
    );

    let id = GENRALHELPERS._getUniqueIdfunction();

    HomeScreenContenConForSeachAndAdd.innerHTML = "";

    const topToolsSetterComponent = generateHtml._topToolsSetter();
    HomeScreenContenConForSeachAndAdd.insertAdjacentHTML(
      "beforeend",
      topToolsSetterComponent
    );
    search._setSearchComponentHtml(
      "Categorias",
      ["Todos", "Negocios", "Ventas individuales"],
      "HomeScreenContenConForSeachAndAdd"
    );

    HomeScreenContenConForSeachAndAdd.scrollTo(0, 0);
    HomeScreenContenConForSeachAndAdd.addEventListener("scroll", () => {
      if (activated) return;
      HomeScreenContenConForSeachAndAdd.style.scrollSnapType = "y mandatory";
      HomeScreenContenConForSeachAndAdd.style.paddingTop = "130px";
      HomeScreenContenConForSeachAndAdd.scrollTo(130, 0);
      activated = true;
    });
  }

  _insenrtAppAd() {
    // const HomeScreenContenConForSeachAndAdd = document.getElementById(
    //   "HomeScreenContenConForSeachAndAdd"
    // );
    // const AppAd = generateHtml._appAd();
    // HomeScreenContenConForSeachAndAdd.insertAdjacentHTML("beforeend", AppAd);
  }
}
export default new HomeScreen();
