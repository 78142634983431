const spinnerMain = document.getElementById("SpinnerMain");
const spinnerMessage = document.getElementById("SpinnerMessage");

class Spinner {
  #spinnerStartTimeStamp = "";
  #timeBeforeClosing = 300;

  _displaySpinner(message) {
    this.#spinnerStartTimeStamp = Date.now();

    let messageLocal = "";
    if (message) {
      messageLocal = message;
    }
    spinnerMain.classList.remove("SpinnerHide");
    spinnerMain.classList.add("SpinnerShow");
    spinnerMessage.textContent = messageLocal;
  }
  _hideSpinner() {
    const timestamp = Date.now();
    const timeBetween = timestamp - this.#spinnerStartTimeStamp;
    if (timeBetween >= this.#timeBeforeClosing) {
      spinnerMain.classList.add("SpinnerHide");
      spinnerMain.classList.remove("SpinnerShow");
      spinnerMessage.textContent = "";
    } else {
      const timeNeeded = this.#timeBeforeClosing - timeBetween;
      setTimeout(() => {
        spinnerMain.classList.add("SpinnerHide");
        spinnerMain.classList.remove("SpinnerShow");
        spinnerMessage.textContent = "";
      }, timeNeeded);
    }
  }
}

export default new Spinner();
