import iconVerified from "../../../Icons/icons-white/verified.png";
import iconVerifiedNeeded from "../../../Icons/icons-white/verification-needed.png";
import iconExit from "../../../Icons/icons-white/exit.png";
import iconCamara from "../../../Icons/icons-white/camara.png";
import iconViews from "../../../Icons/icons-charcoal-dark/views.png";
import iconCall from "../../../Icons/icons-white/call.png";
import iconGo from "../../../Icons/icons-white/go.png";
import iconBack from "../../../Icons/icons-white/back.png";
import iconWeb from "../../../Icons/icons-white/web.png";
import iconBackArrow from "../../../Icons/icons-white/back-arrow.png";
import iconGrayWhatsApp from "../../../Icons/icons-gray/whatsApp.png";
import iconGrayCall from "../../../Icons/icons-gray/call.png";
import iconGrayLocation from "../../../Icons/icons-gray/location.png";
import iconGrayEmail from "../../../Icons/icons-gray/email.png";
import iconGrayWebsite from "../../../Icons/icons-gray/website.png";
import iconStar from "../../../Icons/icons-yellow/star.png";
import iconWhiteViews from "../../../Icons/icons-white/views.png";
import iconWhiteLikes from "../../../Icons/icons-white/like.png";
import iconWhiteShare from "../../../Icons/icons-white/share.png";
import iconWhiteListed from "../../../Icons/icons-white/listed.png";
import iconCharcoalDarkSave from "../../../Icons/icons-charcoal-dark/save.png";
import iconCharcoalDarkSaved from "../../../Icons/icons-charcoal-dark/saved.png";
import iconCharcoalDarkShare from "../../../Icons/icons-charcoal-dark/share.png";
import iconCharcoalNotify from "../../../Icons/icons-charcoal-dark/notify.png";
import iconWhiteNotify from "../../../Icons/icons-white/notify.png";
import iconCharcoalNotNotify from "../../../Icons/icons-charcoal-dark/not-notify.png";
import iconDarkSearch from "../../../Icons/icons-charcoal-dark/search.png";
import iconDarkExit from "../../../Icons/icons-charcoal-dark/exit.png";

import GENRALHELPERS from "../GENRAL-HELPERS";
import handleAccount from "../register/handle-account";
import firebase from "../firebase";

class GenerateHtml {
  _generateBusinessRewiewStars(number, numberOfRewviews) {
    // TODO need to develop a rating system
    return ``;
    let starHtml = ``;
    const stars = Math.round(number);
    if (stars === 0) {
      starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
    } else {
      for (let i = 0; i < stars; i++) {
        starHtml += `<img style="height: 10px;" src="${iconStar}" alt="">`;
      }
    }
    // <div class="RatingStarReviewText">
    //   ${reviews} calificaciones
    // </div>
    return `<div class="RatingStarCon1">
        <div class="RatingStarCon2">
          <div class="RatingStarRatedAtNumber">
            ${number}
          </div>
          <div class="RatingStarCon3">
            ${starHtml}
          </div>
          <div class="RatingStarReviewText">
            ${numberOfRewviews} calificaciones
          </div>
        </div>
    
      </div>`;
  }

  _generateMain(
    data,
    headerHtml,
    anouncementHtmlIn,
    listingsHtml,
    infoHtml,
    id
  ) {
    const anouncementHtml = ``;
    // TODO not using right now. Needs notification set up
    // let followingHtml = ``;
    let followingHtml = `
        <div class="BusinessViewEachUserActionsCon4"
          id="BusinessViewEachUserActionsCon4Follow">
          <img
            class="DisplayListingEachButtonActioIcons"
            src="${iconCharcoalNotify}"
            alt=""
          />
          <p>
          Seguir
          </p>
        </div>`;
    let SavedHtml = `
        <div
          id="BusinessViewEachUserActionsCon6Save"
          class="BusinessViewEachUserActionsCon6"
          >
          <img
            class="DisplayListingEachButtonActioIcons"
            src="${iconCharcoalDarkSave}"
            alt=""
          />
        </div>`;

    const account = firebase._getLatestCurrentUserData();

    const checkIfSaved = (path) => {
      let saved = false;

      if (account && account.activeSaves) {
        if (account.activeSaves.includes(path)) {
          saved = true;
        }
        // {
        //   forEach((curObj, i, arr) => {
        //   console.log(curObj.path, path);
        //   if (curObj.path === path) {
        //     console.log("found");
        //     saved = true;
        //   } else {
        //     console.log("not saved");
        //   }
        // });}
      }

      if (saved) {
        SavedHtml = `
        <div
          id="BusinessViewEachUserActionsCon6Save"
          class="BusinessViewEachUserActionsCon6"
          >
          <img
            class="DisplayListingEachButtonActioIcons"
            src="${iconCharcoalDarkSaved}"
            alt=""
          />
        </div>`;
      }
    };
    const checkIfFollowing = (path) => {
      let following = false;
      console.log(account);
      console.log(account.activeFollows);

      if (account && account.activeFollows) {
        if (account.activeFollows.includes(path)) {
          following = true;
          console.log("here too");
        }
        // .forEach((curObj, i, arr) => {
        //   if (curObj.path === path) {
        //     console.log("found");
        //     following = true;
        //   } else {
        //     console.log("not following");
        //   }
        // });
      }
      console.log(following);

      if (following) {
        followingHtml = `
        <div class="BusinessViewEachUserActionsCon4 BusinessViewEachUserActionsCon4B"
          id="BusinessViewEachUserActionsCon4Follow">
          <img 
            class="DisplayListingEachButtonActioIcons"
            src="${iconCharcoalNotNotify}"
            alt=""
          />
          <p>
          Dejar de seguir
          </p>
        </div>`;
      }
    };

    if (account) {
      checkIfSaved(`businesses/${data.businessId}`);
      // TODO Following maybe use later, needs notifications set up.
      // checkIfFollowing(`businesses/${data.businessId}`);
    } else {
    }
    return `
<div
  data-share="business"
  data-businessid="${data.businessId}"
  data-businesswhatsappnumber="${data.contacts.whatsApp.contact}"
  data-businessname="${data.name}"
  data-businessshordescription="${data.shortDescription}"
 class="BusinessViewEachCon1 ActionForShare">
  ${headerHtml}
  <div class="BusinessViewEachUserActionsCon1">
    <div class="BusinessViewEachUserActionsCon2">
      <div class="BusinessViewEachUserActionsCon3">
        ${followingHtml}
      </div>
      <div class="BusinessViewEachUserActionsCon5">
        ${SavedHtml}
        <div
          id="BusinessViewEachUserActionsCon6Share"
          class="BusinessViewEachUserActionsCon6"
        >
          <img id="${id}BusinessViewEachUserActionsCon6"
            class="DisplayListingEachButtonActioIcons"
            src="${iconCharcoalDarkShare}"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="DisplayListingEachCon9">
      <div class="DisplayListingEachCon10">
        <div class="DisplayListingEachStatsCon">
          <div class="DisplayListingEachStatsConText">${GENRALHELPERS._formatNumberShort(
            data.views
          )}</div>
          <img
            class="DisplayListingEachButtonActioIconsStats"
            src="${iconWhiteViews}"
            alt=""
          />
        </div>
        <div class="DisplayListingEachStatsCon">
          <div class="DisplayListingEachStatsConText">
            ${GENRALHELPERS._formatNumberShort(data.followers)}
          </div>
          <img
            class="DisplayListingEachButtonActioIconsStats"
            src="${iconWhiteNotify}"
            alt=""
          />
        </div>
        <div class="DisplayListingEachStatsCon">
          <div class="DisplayListingEachStatsConText">${GENRALHELPERS._formatNumberShort(
            data.shares
          )}</div>
          <img
            class="DisplayListingEachButtonActioIconsStats"
            src="${iconWhiteShare}"
            alt=""
          />
        </div>
      </div>
      <div class="DisplayListingEachStatsCon">
        <div class="DisplayListingEachStatsConText">
          ${GENRALHELPERS._convertServerTimestampToReadableTime(data.createdAt)}
        </div>
        <img
          class="DisplayListingEachButtonActioIconsStats"
          src="${iconWhiteListed}"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="BusinessEachSearchCon1">
    <div class="BusinessEachSearchCon5">
      <label class="BusinessEachSearchCon2">
        <img height="20px" src="${iconDarkSearch}" alt="" />
        <input
          id="BusinessEachSearchInputIdForLabel"
          class="BusinessEachSearchInput"
          type="text"
          placeholder="Buscar listados en este negocio"
          enterkeyhint="search" 
        />
      </label>
      <div class="BusinessEachSearchCon6">
        <img height="18px" src="${iconDarkExit}" alt="" />
      </div>
    </div>
    <div class="BusinessEachSearchCon4">
      <div class="BusinessEachSearchCon3">
        <p class="SearchStartUpComponentCon13Text">0 resultados</p>
      </div>
      <div class="BusinessEachSearchCon4ResultsCon"></div>
    </div>
  </div>

    
  <div id="BusinessViewEachSpecialAnouncement" class="BusinessViewEachCon2">
    ${anouncementHtml}
  </div>
  <div
    id="BusinessViewEachListingsContainer"
    class="BusinessViewEachCon9"
  >${listingsHtml}</div>
  <div id="BusinessViewEachInfoContainer" class="BusinessViewEachCon10">${infoHtml}</div>
  <div
    id="BusinessViewEachOtherListingsContainer"
    class="BusinessViewEachCon11" style="display: none"
  >
    Other Content like businesses and other listings
  </div>
</div>

    `;
  }
  async _generateHeader(data) {
    console.log(data);
    let backgroundImg = data.backgroundImg;
    // let backgroundImg = await firebase._getImageUrl(data.backgroundImg);
    let profileImg = data.profileImg;

    let verifiedHtml = `
      <img class="BusinessEditorBusinessHeaderImportantIconVerified" src="${iconVerifiedNeeded}" alt="" />
      <div class="BusinessEditorBusinessHeaderVerifiedBadgeText">no verificado</div>
    `;

    if (data.verifiedBusiness) {
      verifiedHtml = ` 
        <img class="BusinessEditorBusinessHeaderImportantIconVerified" src="${iconVerified}" alt="" />
        <div class="BusinessEditorBusinessHeaderVerifiedBadgeText">verificado</div>
      `;
    }
    // let profileImg = await firebase._getImageUrl(data.profileImg);

    return `
<div class="BusinessViewEachHeaderCon1 BusinessEditorBusinessHeaderCon1">
      <img
      id="BusinessEditorBusinessHeaderCon6BackgroundImgCon"
      class="BusinessEditorBusinessHeaderCon6ImgBackground"
      src="${backgroundImg}"
      alt=""
    />
    <div class="BusinessViewEachHeaderCon2">
      <div class="BusinessViewEachHeaderCon3">
        <div class="BusinessViewEachHeaderCon4">
          <div class="BusinessViewEachHeaderCon5 CloseCurrentStackPageButton">
            <img class="removeForShare" height="20px" src="${iconBackArrow}" />
            <div class="Regresar removeForShare">
              regresar
            </div>
          </div>
          <div class="BusinessEditorBusinessHeaderVerifiedBadgeCon1">
          ${verifiedHtml}
          </div>
        </div>
        <div class="BusinessViewEachHeaderCon7">
          <div class="BusinessViewEachHeaderCon8">
            <div class="BusinessViewEachHeaderCon9">
              <div class="BusinessEditorBusinessHeaderCon10">
                <div style="color: #${
                  data.colors.headerPrimary
                }" class="BusinessEditorBusinessHeaderBusinessName">
                  ${data.name}
                </div>
                <div style="color: #${data.colors.headerSecondary}"
                  class="BusinessEditorBusinessHeaderBusinessShortDescription"
                >
                  ${data.shortDescription}
                </div>
              </div>
            </div>
            <div class="BusinessViewEachHeaderCon11">
              <img
                id=""
                class="BusinessViewEachHeaderCon11Img ImgForBigDisplay"
                src="${profileImg}"
                alt=""
              />
            </div>
            <div class="addForShareText"> <img height="12px" src="${iconWeb}"/> Visita <span>: maquinariasonline.com</span></div>
          </div>
          <div class="BusinessViewEachHeaderCon12">
            <div class="BusinessEditorBusinessHeaderCon8B">
            <div class="BusinessEditorBusinessHeaderCon8 removeForShare">
              <div id="BusinessEditorBusinessHeaderButtonInfo" class="BusinessEditorBusinessHeaderButtonCon1">
                <div class="BusinessEditorBusinessHeaderButtonCon2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.905 14.52 11.0007 14.2827 11 14V10C11 9.71667 10.904 9.47933 10.712 9.288C10.52 9.09667 10.2827 9.00067 10 9C9.71733 8.99933 9.48 9.09533 9.288 9.288C9.096 9.48067 9 9.718 9 10V14C9 14.2833 9.096 14.521 9.288 14.713C9.48 14.905 9.71733 15.0007 10 15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667932 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                      fill="#${data.colors.headerPrimary}"
                    />
                  </svg>
                </div>
                <div  style="color: #${
                  data.colors.headerPrimary
                }" class="BusinessEditorBusinessHeaderButtonLabel">Info</div>
              </div>
               <a href="tel:+52${data.contacts.call.contact}">
              <div class="BusinessEditorBusinessHeaderButtonCon1">
              <div  class="BusinessEditorBusinessHeaderButtonCon2">
               
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                  >
                    <path
                      d="M20.7093 16.6718C19.9458 15.9026 18.0967 14.7801 17.1996 14.3277C16.0313 13.7393 15.9351 13.6912 15.0168 14.3734C14.4043 14.8287 13.9971 15.2354 13.2802 15.0825C12.5634 14.9296 11.0056 14.0676 9.64167 12.7081C8.27769 11.3485 7.36565 9.7457 7.21228 9.03131C7.05891 8.31691 7.47239 7.91453 7.92336 7.30061C8.55895 6.43526 8.51087 6.29104 7.96759 5.12282C7.54402 4.2142 6.38871 2.38255 5.61657 1.62297C4.79059 0.807138 4.79059 0.951363 4.25836 1.17251C3.82505 1.35477 3.40936 1.57636 3.01651 1.8345C2.24726 2.34553 1.82032 2.77003 1.52176 3.40799C1.22319 4.04594 1.08905 5.54155 2.63092 8.34239C4.17278 11.1432 5.25454 12.5754 7.49354 14.808C9.73254 17.0406 11.4542 18.241 13.9711 19.6525C17.0846 21.3962 18.2789 21.0563 18.9188 20.7582C19.5587 20.4601 19.9852 20.0371 20.4972 19.2679C20.756 18.8757 20.9781 18.4605 21.1607 18.0276C21.3824 17.4973 21.5266 17.4973 20.7093 16.6718Z"
                      stroke="#${data.colors.headerPrimary}"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                  </svg>
                  </div>
                
                <div  style="color: #${
                  data.colors.headerPrimary
                }" class="BusinessEditorBusinessHeaderButtonLabel">Llamar</div>
              </div>
              </a>
              <div id="BusinessEditorBusinessHeaderButtonWhatsApp" class="BusinessEditorBusinessHeaderButtonCon1">
                <div class="BusinessEditorBusinessHeaderButtonCon2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M17.2519 2.91005C16.3304 1.98416 15.2329 1.25002 14.0233 0.750414C12.8137 0.250812 11.5163 -0.00426316 10.2067 5.3895e-05C4.71927 5.3895e-05 0.246906 4.45004 0.246906 9.91003C0.246906 11.66 0.709218 13.36 1.57354 14.86L0.166504 20L5.44289 18.62C6.90018 19.41 8.53837 19.83 10.2067 19.83C15.6942 19.83 20.1665 15.38 20.1665 9.92003C20.1665 7.27004 19.1313 4.78004 17.2519 2.91005ZM10.2067 18.15C8.71927 18.15 7.26199 17.75 5.9856 17L5.6841 16.82L2.54842 17.64L3.38259 14.6L3.18158 14.29C2.355 12.9771 1.91618 11.4593 1.91525 9.91003C1.91525 5.37004 5.63384 1.67005 10.1967 1.67005C12.4077 1.67005 14.4881 2.53005 16.0459 4.09004C16.8174 4.85391 17.4287 5.76258 17.8445 6.76337C18.2603 7.76415 18.4722 8.83712 18.468 9.92003C18.4881 14.46 14.7695 18.15 10.2067 18.15ZM14.7494 11.99C14.4982 11.87 13.272 11.27 13.0509 11.18C12.8198 11.1 12.659 11.06 12.4881 11.3C12.3173 11.55 11.8449 12.11 11.7042 12.27C11.5635 12.44 11.4127 12.46 11.1615 12.33C10.9102 12.21 10.1062 11.94 9.16148 11.1C8.41777 10.44 7.9253 9.63003 7.77455 9.38003C7.63384 9.13003 7.75445 9.00003 7.8851 8.87003C7.99565 8.76003 8.13636 8.58003 8.25696 8.44003C8.37756 8.30003 8.42782 8.19003 8.50822 8.03003C8.58862 7.86004 8.54842 7.72004 8.48812 7.60004C8.42782 7.48004 7.9253 6.26004 7.7243 5.76004C7.52329 5.28004 7.31224 5.34004 7.16148 5.33004H6.67907C6.50822 5.33004 6.24691 5.39004 6.01575 5.64004C5.79465 5.89004 5.15143 6.49004 5.15143 7.71004C5.15143 8.93003 6.0459 10.11 6.16651 10.27C6.28711 10.44 7.9253 12.94 10.4178 14.01C11.0107 14.27 11.473 14.42 11.8349 14.53C12.4278 14.72 12.9705 14.69 13.4027 14.63C13.8851 14.56 14.8801 14.03 15.0811 13.45C15.2921 12.87 15.2921 12.38 15.2218 12.27C15.1514 12.16 15.0007 12.11 14.7494 11.99Z"
                      fill="#${data.colors.headerPrimary}"
                    />
                  </svg>
                </div>
                <div  style="color: #${
                  data.colors.headerPrimary
                }" class="BusinessEditorBusinessHeaderButtonLabel">WhatsApp</div>
              </div>
              <label for="BusinessEachSearchInputIdForLabel" id="BusinessEditorBusinessHeaderButtonSearch" class="BusinessEditorBusinessHeaderButtonCon1">
                <div class="BusinessEditorBusinessHeaderButtonCon2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.39687 14.7937C5.32954 14.7937 3.58009 14.0776 2.14851 12.6452C0.716928 11.2129 0.000759255 9.46344 6.02106e-07 7.39687C-0.000758051 5.3303 0.715411 3.58084 2.14851 2.14851C3.5816 0.716169 5.33106 0 7.39687 0C9.46268 0 11.2125 0.716169 12.6464 2.14851C14.0802 3.58084 14.796 5.3303 14.7937 7.39687C14.7937 8.23139 14.661 9.01849 14.3954 9.75818C14.1299 10.4979 13.7696 11.1522 13.3144 11.7212L19.6871 18.0939C19.8957 18.3025 20 18.568 20 18.8905C20 19.2129 19.8957 19.4784 19.6871 19.6871C19.4784 19.8957 19.2129 20 18.8905 20C18.568 20 18.3025 19.8957 18.0939 19.6871L11.7212 13.3144C11.1522 13.7696 10.4979 14.1299 9.75818 14.3954C9.01849 14.661 8.23139 14.7937 7.39687 14.7937ZM7.39687 12.5178C8.81935 12.5178 10.0286 12.0201 11.0248 11.0247C12.0209 10.0294 12.5185 8.8201 12.5178 7.39687C12.517 5.97364 12.0193 4.76472 11.0248 3.77013C10.0302 2.77553 8.82086 2.27748 7.39687 2.27596C5.97288 2.27444 4.76396 2.7725 3.77013 3.77013C2.77629 4.76776 2.27824 5.97667 2.27596 7.39687C2.27368 8.81707 2.77174 10.0264 3.77013 11.0247C4.76852 12.0231 5.97743 12.5208 7.39687 12.5178Z"
                      fill="#${data.colors.headerPrimary}"
                    />
                  </svg>
                </div>
                <div style="color: #${
                  data.colors.headerPrimary
                }" class="BusinessEditorBusinessHeaderButtonLabel">Buscar</div>
            </label>
            </div>
        </div>
           ${this._generateBusinessRewiewStars(
             data.ratedAt,
             data.numberOfReviews,
             data
           )}
          </div>
        </div>
      </div>
    </div>
  </div>
    `;
  }
  _generateSpecialAnouncment(data) {
    return `
<div class="BusinessViewEachCon3">
  <div class="BusinessViewEachCon4">
    <div class="BusinessViewEachCon4Text">Tenemos ofertas hoy</div>
    <div class="BusinessViewEachCon4Text2">
      Tenemos ofertas especiales para ti hoy. En todo lo que compras hoy, te
      damos un 10% de descuento adicional.
    </div>
  </div>
  <div class="BusinessViewEachCon5">
    <div class="BusinessViewEachCon5Text">En ${data.name}</div>
    <div class="BusinessViewEachCon6">
    </div>
  </div>
</div>    
    `;
    //   <div class="BusinessViewEachCon7">
    //   <div class="BusinessViewEachCon7Text">hoy, 10:23 am</div>
    //   <img src="${iconWhiteViews}"/>
    // </div>
    // <div class="BusinessViewEachCon8">
    //   <div class="Ver">Ver</div>
    // </div>
  }
  // Start GEnerating Business Info
  _generateInfo(businessData) {
    const placeHtml = this._visualizerPopulateBusinessInfoPortion(
      "Lugar",
      this._visualizerPopulateBusinessInfoPlace(businessData.place)
    );

    const contactHtml = this._visualizerPopulateBusinessInfoPortion(
      "Contacto",
      this._visualizerPopulateBusinessInfoContact(businessData.contacts)
    );

    const aboutHtml = this._visualizerPopulateBusinessInfoPortion(
      "Acerca de nosotros",
      this._visualizerPopulateBusinessInfoAbout(businessData.about)
    );
    const finalPortionsHtml = placeHtml + contactHtml + aboutHtml;

    return this._visualizerPopulateBusinessInfoMain(finalPortionsHtml);
  }
  _visualizerPopulateBusinessInfoMain(html) {
    return `
  <div id="SectionIndicatedBusinessInfoCon1" class="BusinessInfoCon1">
    <div class="BusinessInfoCon2">
      <div class="BusinessInfoHeaderText">
        Información de este negocio
      </div>
    </div>
    <div class="BusinessInfoCon3">${html}</div>
  </div>
    `;
  }
  _visualizerPopulateBusinessInfoPortion(title, html) {
    return ` 
  <div id="BusinessInfoPortionCon1" class="BusinessInfoPortionCon1">
    <div class="BusinessInfoPortionCon2">
      <div class="BusinessInfoPortionHeaderText">
        ${title}
      </div>
    </div>
    ${html}
  </div>
    `;
  }
  _visualizerPopulateBusinessInfoPlace(data) {
    return `
<div id="BusinessInfoLocationCon1" class="BusinessInfoLocationCon1">
  <div class="BusinessInfoPortionLinkCon1">
    <img height="18px" src="${iconGrayLocation}" alt="" />
    <a class="BusinessInfoPortionLinkText" href="${data.locationUrl}"
      >${data.name}</a
    >
  </div>
  <div class="BusinessInfoLocationCon2">
    <div class="BusinessInfoLocationHeaderText">Lugar</div>
    <div class="BusinessInfoLocationCon3">
      <div class="BusinessInfoLocationDescriptionText">${data.description}</div>
    </div>
  </div>
</div>
    `;
  }
  _visualizerPopulateBusinessInfoContact(data) {
    let contactWays = ""; // Initialize as an empty string to avoid 'undefined'

    // Map contact types to their corresponding icon paths
    const iconMap = {
      Call: iconGrayCall,
      Email: iconGrayEmail,
      Website: iconGrayWebsite,
      WhatsApp: iconGrayWhatsApp,
      // Add more types and icons as needed
    };

    if (data.call) {
      contactWays += `
      <a href="tel:+52${data.call.contact}">
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayCall}" alt="" />
        <div class="BusinessInfoPortionLinkText">${data.call.contact}</div>
      </div>
      </a>`;
    }
    if (data.whatsApp) {
      contactWays += `
      <div id="DsiplayBusinessEachInfoPortionWahtsApp" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayWhatsApp}" alt="" />
        <div class="BusinessInfoPortionLinkText">${data.whatsApp.contact}</div>
      </div>`;
    }
    if (data.email) {
      contactWays += `
      <div data-email="${data.email.contact}" id="DsiplayBusinessEachInfoPortionEmail" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayEmail}" alt="" />
        <div class="BusinessInfoPortionLinkText">${data.email.contact}</div>
      </div>`;
    }
    if (data.website) {
      contactWays += `
      <a href="${data.website.contact}" target="_blank" rel="noopener noreferrer">
      <div id="BusinessInfoPortionLinkCon1" class="BusinessInfoPortionLinkCon1">
        <img height="18px" src="${iconGrayWebsite}" alt="" />
        <div class="BusinessInfoPortionLinkText">${data.website.contact}</div>
      </div>
      </a>`;
    }

    return `<div class="BusinessInfoContactCon1">${contactWays}</div>`;
  }
  _visualizerPopulateBusinessInfoAbout(about) {
    return `
  <div id="BusinessInfoAboutCon1" class="BusinessInfoAboutCon1">
    <div class="BusinessInfoAboutText">
      ${about}
    </div>
  </div>
    `;
  }
}

export default new GenerateHtml();
