import generateHtmlEditorTools from "./generate-html-editor-tools";
import BusinessPageEditor from "./business-page-editor";
import businessPageEditor from "./business-page-editor";

class EditorTools {
  elementThatOpenedColorPicker;
  whatForColor;
  constructor() {}

  _colorGetBacker = (color) => {
    console.log("now here");
    const allColorsPickers = document.querySelectorAll(
      ".BusinessPageEditorColorsCon3"
    );
    allColorsPickers.forEach((colorPicker) => {
      if (colorPicker.dataset.for === this.whatForColor) {
        const colorSquare = colorPicker.querySelector(
          ".BusinessPageEditorColorsColorInEditor"
        );
        colorSquare.style.backgroundColor = `#${color}`;
      }
    });
  };

  _openColorPickerAndLogColor(colorIn) {
    const colorInput = document.getElementById("colorPickerForOpining");
    colorInput.value = `#${colorIn}`; // Set the initial color

    // Helper function to ensure the color is in '#000000' format
    function formatToHex(color) {
      if (!color.startsWith("#")) {
        const ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = color;
        return ctx.fillStyle; // Converts color to hex
      }
      return color; // Already in hex format
    }

    // Debounce function to limit function calls to 100ms
    function debounce(func, delay) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    }

    // Function to handle color changes
    const handleColorChange = debounce((selectedColor) => {
      const colorWithoutHash = selectedColor.substring(1); // Remove the first character ('#')
      BusinessPageEditor._colorSetter(colorWithoutHash, this.whatForColor);
      this._colorGetBacker(colorWithoutHash);
      console.log("Selected color:", selectedColor);
    }, 100);

    // Attach a change event listener to log the color
    colorInput.addEventListener("change", () => {
      let selectedColor = colorInput.value; // Get the selected color
      selectedColor = formatToHex(selectedColor); // Ensure it's in '#000000' format
      handleColorChange(selectedColor); // Call the debounced handler
    });

    // Simulate a click to open the color picker
    setTimeout(() => {
      colorInput.click(); // Open the color picker
    }, 0); // Ensure it runs after the current event loop
  }

  _eventBusinessColors() {
    const allColorsPickers = document.querySelectorAll(
      ".BusinessPageEditorColorsCon3"
    );

    allColorsPickers.forEach(async (colorPicker) => {
      colorPicker.addEventListener("click", async (e) => {
        this.elementThatOpenedColorPicker = e.target;
        const closestColorPicker = e.target.closest(
          ".BusinessPageEditorColorsCon3"
        );

        this.whatForColor = closestColorPicker.dataset.for;
        console.log(closestColorPicker.dataset.for); // Logs the closest element with the class
        const colorForIn = closestColorPicker.dataset.color;
        console.log(colorForIn);
        console.log("clicked");
        this._openColorPickerAndLogColor(colorForIn);
      });
    });
  }

  _init(part, data, tags) {
    const editorCon = document.getElementById("BusinessPageEditorCon4");
    const editorContainer = document.querySelector(".BusinessPageEditorCon1");
    const BusinessPageEditorListingsApearButtonsHideMoreTools =
      document.querySelectorAll(
        ".BusinessPageEditorListingsApearButtonHideMoreTools"
      );

    editorContainer.classList.remove("BusinessPageEditorCon1Hidden");
    editorContainer.style.height = "";
    BusinessPageEditorListingsApearButtonsHideMoreTools.forEach((button) => {
      button.addEventListener("click", () => {
        editorContainer.style.height = "";
        editorContainer.classList.add("BusinessPageEditorCon1Hidden");
        businessPageEditor._hideShowTopEditorTools("show");
      });
    });
    if (part === "header") {
      let html;
      html = generateHtmlEditorTools._helperPortionCreator(
        "Texto",
        generateHtmlEditorTools._headerInputs(data)
      );
      html += generateHtmlEditorTools._helperPortionCreator(
        "Imagenes",
        generateHtmlEditorTools._headerImages()
      );

      editorCon.innerHTML = html;
      BusinessPageEditor._eventBusinessHeader();
      BusinessPageEditor._changeColorsReturn(false);

      // Scroll to the top of the container
      editorCon.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (part === "listings") {
      const apearHtml = generateHtmlEditorTools._helperPortionCreator(
        "Como aparecen tus listados en busquedas en tu negocio",
        generateHtmlEditorTools._listingsApear()
      );
      const listsHtml = generateHtmlEditorTools._helperPortionCreator(
        "Listas de listados",

        generateHtmlEditorTools._creatLists(data.lists, tags)
      );
      editorCon.innerHTML = apearHtml + listsHtml;

      BusinessPageEditor._eventBusinessLists();
      BusinessPageEditor._changeColorsReturn(false);

      // Scroll to the top of the container
      editorCon.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (part === "info") {
      const locationHtml = generateHtmlEditorTools._helperPortionCreator(
        "Lugar",
        generateHtmlEditorTools._infoLocation(data.place)
      );
      const contactsHtml = generateHtmlEditorTools._helperPortionCreator(
        "Conatactos",
        generateHtmlEditorTools._infoContacts(data)
      );
      const aboutHtml = generateHtmlEditorTools._helperPortionCreator(
        "Acerca de este negocio",
        generateHtmlEditorTools._infoAbout(data)
      );

      editorCon.innerHTML = locationHtml + contactsHtml + aboutHtml;
      BusinessPageEditor._eventBusinessInfo();
      BusinessPageEditor._changeColorsReturn(false);

      // Scroll to the top of the container
      editorCon.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (part === "colors") {
      const colorsHtml = generateHtmlEditorTools._helperPortionCreator(
        "Colores",
        generateHtmlEditorTools._colors(data.colors)
      );

      editorCon.innerHTML = colorsHtml;
      this._eventBusinessColors();

      BusinessPageEditor._changeColorsReturn(true);

      // Scroll to the top of the container
      editorCon.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
}
export default new EditorTools();
