import MiniScreen from "../mini-screen/mini-screen";
import GenerateHtml from "./generate-html";
import iconShow from "../../../Icons/icons-charcoal-dark/eye-show.png";
import iconHide from "../../../Icons/icons-charcoal-dark/eye-hide.png";
import firebase from "../firebase";
import email from "../email";
import { headerIn, headerOut } from "../header";
import spinner from "../spinner";
import createListing from "../create-listing/create-listing";
import handleAccount from "./handle-account";
// import startUp from "../main-start-up-screen/start-up";
import businessPageEditor from "../business-page-editor/business-page-editor";
import busineOwn from "../business-page-own-veiw/busine-own";
import accountProfileChange from "../main-menu/account-profile-change";
import wallet from "../wallet/wallet";
import inAppNotification from "../in-app-notification";
import termsConditions from "../terms-conditions";
import GENRALHELPERS from "../GENRAL-HELPERS";

//
const MiniScreenContent = document.getElementById(
  "InsertContentInMiniScreenCon"
);
const userNames = ["Ben", "Dan"];

class Register {
  #actionAfter;
  #AccountToBeCreated;
  #lastSentOTPTimeStamp = 0;
  #OTP = "000000";
  constructor() {
    this._events();
    // this._displayOptions();
    // this._displayLogIn();
    // this._displayCreateAccount();
    // this._displayConfirmEmail();
    // this._displayAccountInDone();
    // console.log(this._generateOTP());
    // this._handleOPTSend();

    // signInWithEmailAndPassword(auth, "benklassen19@icloud.comc", "Be123456")
    //   .then((userCredential) => {
    //     console.log("User signed in:", userCredential.user);
    //   })
    //   .catch((error) => {
    //     console.error("Error signing in:", error);
    //   });
  }

  _generateOTP() {
    // Generate a random 6-digit number
    const otp = Math.floor(100000 + Math.random() * 900000); // 6 digits
    return otp.toString(); // Return as a string to keep leading zeros, if any
  }
  async _handleOPTSend() {
    console.log(this.#OTP);
    const result = await email._sendEmail(
      this.#AccountToBeCreated.email,
      "webandmobileservice@gmail.com",
      this.#OTP,
      "Este es el código para verificar tu correo"
    );

    if (result.status === "success") {
      console.log("Email sent successfully:", result.result);
      spinner._hideSpinner();
      this.#lastSentOTPTimeStamp = Date.now();
      this._displayConfirmEmail(this.#AccountToBeCreated.email);
    } else {
      alert(
        `No podimos enviar un código de verificación a ${
          this.#AccountToBeCreated.email
        }`
      );
    }
  }
  _toggleTermsAcceptance() {
    const termsAcceptedMessage = document.getElementById(
      "termsAcceptedMessage"
    );
    const TermsAndConditionsInCreateAccount = document.getElementById(
      "TermsAndConditionsInCreateAccount"
    );
    const termsContainer = document.getElementById("CreateAccountAccepterms");
    const accept = document.getElementById("CreateAccountCon6Accept");
    const accepted = document.getElementById("CreateAccountCon6Accepted");

    accept.addEventListener("click", () => {
      termsContainer.setAttribute("data-accepted", "true");
      accept.style.display = "none"; // Show the accept icon
      accepted.style.display = ""; // Hide the accepted icon
      termsAcceptedMessage.textContent = "";
    });
    accepted.addEventListener("click", () => {
      termsContainer.setAttribute("data-accepted", "false");
      accept.style.display = ""; // Show the accept icon
      accepted.style.display = "none"; // Hide the accepted icon
    });
    TermsAndConditionsInCreateAccount.addEventListener("click", () => {
      termsConditions._open();
    });
  }
  _addPasswordToggleListeners() {
    // Select all elements with the class 'InputShowHideBtn' within the container
    const toggleButtons =
      MiniScreenContent.querySelectorAll(".InputShowHideBtn");

    toggleButtons.forEach((button) => {
      button.addEventListener("click", () => {
        // Find the input within the same parent container
        const input = button.closest(".InputCon2").querySelector("input");

        if (input) {
          // Toggle input type between 'password' and 'text'
          if (input.type === "password") {
            input.type = "text";
            button.querySelector("img").src = iconHide; // Update icon (set a hidden icon)
          } else {
            input.type = "password";
            button.querySelector("img").src = iconShow; // Update icon (set a show icon)
          }

          // Re-focus the input after toggling
          input.focus();
        }
      });
    });
  }

  _addInputFocusListenersToRemoveMessage() {
    // Get all input elements within the MiniScreenContent container
    const inputs = MiniScreenContent.querySelectorAll("input");

    // Add a focus event listener to each input
    inputs.forEach((input) => {
      input.addEventListener("focus", () => {
        const messageElement = input
          .closest(".InputCon1")
          .querySelector(".InputMessage");
        if (messageElement) {
          messageElement.textContent = ""; // Clear the error message
        }
      });
    });
  }

  //   Start dsiplay function

  _displayOptions(actionAfter, title = "Cuenta necesaria") {
    this.#actionAfter = actionAfter;
    // Display the mini screen
    MiniScreen._display(
      "show",
      "Cuenta necesaria",
      GenerateHtml._options(title)
    );
    GENRALHELPERS._animateCTA("BtnOptionsLogIn");
    setTimeout(() => {
      GENRALHELPERS._animateCTA("BtnOptionsCreateAccount");
    }, 2000);

    // Attach event listeners to the buttons
    // const cancelButton = document.getElementById("BtnOptionsCancel");

    // // Resolve the promise when the cancel button is clicked
    // if (cancelButton) {
    //   cancelButton.addEventListener("click", () => {
    //     // HomeScreen._didAccountInitChange("Cancelled");
    //   });
    // }
  }

  _displayLogIn() {
    MiniScreen._display("show", "Cuenta necesaria", GenerateHtml._logIn());
    this._addPasswordToggleListeners();
  }
  _displayCreateAccount() {
    MiniScreen._display(
      "show",
      "Cuenta necesaria",
      GenerateHtml._createAccount()
    );
    this._addPasswordToggleListeners();
    this._toggleTermsAcceptance();
  }
  _displayConfirmEmail(email) {
    MiniScreen._display(
      "show",
      "Cuenta necesaria",
      GenerateHtml._confirmEmail(email)
    );
    const code = document.getElementById("ConfirmEmailCodeInput");
    code.focus();
  }
  _displayAccountInDone(message) {
    MiniScreen._display(
      "show",
      "Listo",
      GenerateHtml._createAccountDone(message)
    );
  }

  //   Start Event take care functions
  _eventsOptions(id) {
    console.log(id);
    if (id === "BtnOptionsLogIn") {
      this._displayLogIn();
    }
    if (id === "BtnOptionsCreateAccount") {
      this._displayCreateAccount();
    }
    if (id === "BtnOptionsCancel") {
      MiniScreen._display("hide");
    }
  }
  _eventsLogIn(id) {
    console.log(id);
    if (id === "BtnLogInLogIn") {
      this._processLogIn();
      console.log("called");
    }
    if (id === "BtnLogInCancel") {
      this._displayOptions();
    }
    if (id === "BtnLogInCreateAccount") {
      this._displayCreateAccount();
    }

    if (id === "BtnLogInForgotPassword") {
      const InpEmailUserName = document.getElementById(
        "InputLogInEmailUserName"
      );
      const email = InpEmailUserName.value.trim();
      passwordForgottenHref.href = `https://wa.me/9967306118?text=Olvidé%20mi%20contraseña%20en%20Mercado%20MAI.%20Correo:%20${email}`;
      passwordForgottenHref.click();
    }
  }
  _eventsCreateAccount(id) {
    console.log(id);
    if (id === "BtnCreateAccountCreateAccount") {
      this._processCreateAccount();
    }
    if (id === "BtnCreateAccountCancel") {
      this._displayOptions();
    }
    if (id === "BtnCreateAccountLogIn") {
      this._displayLogIn();
    }
  }
  async _eventsConfirmEmail(id) {
    console.log(id);
    if (id === "BtnConfirmEmailConfirmEmail") {
      this._processConfirmEmail();
    }
    if (id === "BtnConfirmChangeEmail") {
      console.log("here now");
      this._displayCreateAccount();
    }
    if (id === "BtnConfirmEmailNoCode") {
      const currentTimeStamp = Date.now();
      const timeSince = currentTimeStamp - this.#lastSentOTPTimeStamp;
      if (timeSince > 119999) {
        spinner._displaySpinner("Estamos enviando el OTP de nuevo.");
        await this._handleOPTSend();
        inAppNotification._showNotification(
          "OTP reenviado",
          `Hemos reenviado el cógdigo de verificación. Si aún no te llega, asegúrate que tu correo es el correcto.`,
          null,
          null,
          20000
        );
      } else {
        spinner._hideSpinner();
        inAppNotification._showNotification(
          "Espera...",
          `Espera ${Math.floor(
            (120000 - timeSince) / 1000
          )} segundos para mandar el OPT de nuevo`,
          null,
          null,
          5000
        );
      }
    }
  }
  async _eventsAccountInDone(id) {
    const account = await handleAccount._getAccountState();
    console.log(id);
    if (id === "BtnCreateAccountDoneDone") {
      await firebase._getUserDataFromBase();
      MiniScreen._display("hide");

      if (this.#actionAfter === "account_and_profile") {
        accountProfileChange._open();
      }
      if (this.#actionAfter === "create_listing") {
        createListing._init();
      }
      if (this.#actionAfter === "open_wallet") {
        wallet._open();
      }
      if (this.#actionAfter === "create_business_page") {
        const timeIn = setInterval(() => {
          const account = firebase._getLatestCurrentUserData();
          console.log(typeof account);
          if (!account) {
            return;
          } else {
            if (account && account.business !== "") {
              busineOwn._init();
            } else {
              businessPageEditor._init();
            }
            clearInterval(timeIn);
          }
        }, 1000);
        console.log(accountLocal);
      }
    }
  }

  //   Start Process
  async _processLogIn() {
    console.log("process");
    const InpEmailUserName = document.getElementById("InputLogInEmailUserName");
    const InpPassword = document.getElementById("InputLogInPassword");

    this._addInputFocusListenersToRemoveMessage();

    // Check for empty fields
    if (!InpEmailUserName.value.trim() || !InpPassword.value.trim()) {
      alert("Por favor, completa todos los campos.");
      return;
    }
    const logInData = {
      email: InpEmailUserName.value.trim(),
      password: InpPassword.value.trim(),
    };

    try {
      const singInResult = await firebase._signInUser(
        logInData.email,
        logInData.password
      );

      this._displayAccountInDone("Has iniciado sesión con éxito");
    } catch (error) {
      if (error.message.includes("auth/invalid-credential")) {
        alert(
          "No podimos encontrar tu cuenta. Asegurate que los dato son los datos corectos. O si aún no tienes una cuenta en Mercado MAI, crea una primero."
        );
      }
    }
  }

  async _processCreateAccount() {
    console.log("process");
    this._addInputFocusListenersToRemoveMessage();

    const InpEmail = document.getElementById("InputCreateAccountEmail");
    const InpPassword = document.getElementById("InputCreateAccountPassword");
    const InpPasswordConfirm = document.getElementById(
      "InputCreateAccountPasswordConfirm"
    );

    const emailMessage =
      InpEmail.closest(".InputCon1").querySelector(".InputMessage");
    const passwordMessage =
      InpPassword.closest(".InputCon1").querySelector(".InputMessage");
    const confirmPasswordMessage =
      InpPasswordConfirm.closest(".InputCon1").querySelector(".InputMessage");
    const termsAcceptedMessage = document.getElementById(
      "termsAcceptedMessage"
    );

    // Get terms acceptance
    const termsContainer = document.getElementById("CreateAccountAccepterms");
    const isTermsAccepted =
      termsContainer.getAttribute("data-accepted") === "true";

    // Validation flags
    let isValid = true;

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(InpEmail.value.trim())) {
      emailMessage.textContent =
        "Por favor, introduce un correo electrónico válido.";
      isValid = false;
    } else {
      emailMessage.textContent = "";
    }

    // Validate password
    const passwordValue = InpPassword.value.trim();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/; // At least 8 chars, 1 uppercase, 1 lowercase, 1 number
    if (!passwordRegex.test(passwordValue)) {
      passwordMessage.textContent =
        "La contraseña debe tener al menos 8 caracteres, incluyendo 1 mayúscula, 1 minúscula y 1 número.";
      isValid = false;
    } else {
      passwordMessage.textContent = "";
    }

    // Validate password confirmation
    if (InpPasswordConfirm.value.trim() !== passwordValue) {
      confirmPasswordMessage.textContent = "Las contraseñas no coinciden.";
      isValid = false;
    } else {
      confirmPasswordMessage.textContent = "";
    }

    // Check terms acceptance
    if (!isTermsAccepted) {
      termsAcceptedMessage.textContent =
        "Debes aceptar los términos y condiciones.";
      isValid = false;
    } else {
      termsAcceptedMessage.textContent = "";
    }

    // Check for empty fields
    if (
      // !InpUserName.value.trim() ||
      !InpEmail.value.trim() ||
      !InpPassword.value.trim() ||
      !InpPasswordConfirm.value.trim()
    ) {
      alert("Por favor, completa todos los campos.");
      return;
    }
    const accountData = {
      // userName: InpUserName.value.trim(),
      email: InpEmail.value.trim(),
      password: InpPassword.value.trim(),
      termsAccepted: isTermsAccepted,
    };

    // If all validations pass
    if (isValid) {
      spinner._displaySpinner("cargando");
      this.#AccountToBeCreated = accountData;
      const accountCheck = await firebase._getAccountDataByEmail(
        this.#AccountToBeCreated.email
      );
      if (accountCheck) {
        spinner._hideSpinner("cargando");
        alert("Esta Cuenta ya existe");
        return;
      }
      this.#OTP = this._generateOTP();
      this._handleOPTSend();
    }
  }

  _processConfirmEmail() {
    spinner._displaySpinner("cargando");
    const code = document.getElementById("ConfirmEmailCodeInput");
    if (code.value.trim() === this.#OTP) {
      this._createAccountNow();
      console.log("correct code");
      // spinner._hideSpinner();
    } else {
      spinner._hideSpinner();
      alert("Código incorrecto.");
    }
  }
  //   Start Do Now
  async _createAccountNow() {
    try {
      await firebase._authenticateNewUser(
        this.#AccountToBeCreated.email,
        this.#AccountToBeCreated.password
      );
      spinner._hideSpinner();
      this._displayAccountInDone("Has creado una cuenta con éxito");
    } catch (error) {
      // Handle error
      console.error("Error creating account:", error.message);
      // Optionally, add error-handling logic here, such as showing a notification
      spinner._hideSpinner();
      alert("No hemos podido crear una cuenta.");
    }
  }

  // Start  Will Work with evnet delegation on this project
  _events() {
    MiniScreenContent.addEventListener("click", (e) => {
      const id = e.target.id;
      if (id.includes("BtnOptions")) {
        this._eventsOptions(id);
      }
      if (id.includes("BtnLogIn")) {
        this._eventsLogIn(id);
      }
      if (id.includes("BtnConfirmChangeEmail")) {
        this._displayCreateAccount();
      }
      if (id.includes("BtnCreateAccount")) {
        this._eventsCreateAccount(id);
      }
      if (id.includes("BtnConfirmEmail")) {
        this._eventsConfirmEmail(id);
      }
      if (id.includes("BtnCreateAccountDoneDone")) {
        this._eventsAccountInDone(id);
      }
    });
  }
}
export default new Register();
